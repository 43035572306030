import React from 'react'
import img from '../src/images/404.png'
import { useNavigate } from 'react-router'

function PageNotFound() {
   const history = useNavigate();
   const role = localStorage.getItem('role')
  const pageredirect = () => {
      if(role === 'admin' || role === 'manager'){
        history('/admin/vle')
      }else if(role === 'producer'){
        history('/admin/producer-movies')
      }else if(!role || role === 'vle'){
        history('/')
      }
  }

  return (
    <div className=' main_container h-screen  flex items-center justify-center flex-col'>
     <img src={img} className=' mx-auto md:h-[400px] 2xl:h-auto'></img>
     <p className="text-[20px] md:text-[30px] lg:text-[40px] font-poppins font-medium ">
            <span className="logoheading">Something’s Went Wrong!</span>
          </p>
     <p className='font-poppins md:text-[24px] mt-2 text-center'>This page is not found. Please return to <span className='  font-medium font-poppins md:text-[28px]'>Home page</span>.</p>
     <div className='mt-[20px] md:mt-[30px]'>
       <button onClick={() => pageredirect()} className="bg-[#076aff] min-w-[140px]  md:min-w-[175px] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium rounded-[10px] min-h-[35px] md:min-h-[50px] text-white">
        Go to Home
      </button>
     </div>
    </div>
  )
}

export default PageNotFound