export const actionTypes = {
    ACTOR_LIST: "ACTOR_LIST",
  };
  
  const INITIAL_STATE = {
    actorlist: [],
  };
  
  const ActorReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case actionTypes.ACTOR_LIST:
        return {
          ...state,
          actorlist: action.actorlist,
        };
  
      default:
        return state;
    }
  };
  
  export default ActorReducer;