export const actionTypes = {
  PRODUCER_LIST: "PRODUCER_LIST",
  ADD_PRODUCER: "ADD_PRODUCER",
  DELETE_PRODUCER_LIST: "DELETE_PRODUCER_LIST",
  MAIN_PRODUCER_LIST: "MAIN_PRODUCER_LIST",
  PRODUCER_DETAIL: "PRODUCER_DETAIL",
  RESET_PRODUCER_DETAIL: "RESET_PRODUCER_DETAIL",
  PRODUCER_STATUS : 'PRODUCER_STATUS'

};

const INITIAL_STATE = {
  requestproducerlist: [],
  producerlist: [],
  addproducer: [],
  deleteproducerlist: [],
  producerdetail: [],
  producerstatus: [],
};

const ProducerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.PRODUCER_LIST:
      return {
        ...state,
        requestproducerlist: action.requestproducerlist,
      };
    case actionTypes.MAIN_PRODUCER_LIST:
      return {
        ...state,
        producerlist: action.producerlist,
      };
    case actionTypes.PRODUCER_DETAIL:
      return {
        ...state,
        producerdetail: action.producerdetail,
      };
    case actionTypes.RESET_PRODUCER_DETAIL:
      return {
        ...state,
        producerdetail: action.INITIAL_STATE,
      };
    case actionTypes.DELETE_PRODUCER_LIST:
      return {
        ...state,
        deleteproducerlist: action.deleteproducerlist,
      };
    case actionTypes.ADD_PRODUCER:
      return {
        ...state,
        addproducer: action.addproducer,
      };
    case actionTypes.PRODUCER_STATUS:
      return {
        ...state,
        producerstatus: action.producerstatus,
      };

    default:
      return state;
  }
};

export default ProducerReducer;
