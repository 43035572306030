import React from "react";
import upload from "../../images/upload.png";
import arrow from "../../images/rightarr.png";
import { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";
import { Link, useNavigate } from "react-router-dom";
import {
  GetCitylist,
  GetCountrylist,
  GetStatelist,
} from "../../Redux/Action/AllListAction";
import { useDispatch, useSelector } from "react-redux";
import { CreateNewProducer } from "../../Redux/Action/ProducerAction";

function CreateProducer() {
  const [Email, setEmail] = useState("");
  const [Name, setName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Address, setAddress] = useState("");
  const [State, setState] = useState("");
  const [Country, setCountry] = useState("");
  const [City, setCity] = useState("");
  const [ZipCode, setZipCode] = useState("");
  const [AadharNo, setAadharNo] = useState("");
  const [PanNo, setPanNo] = useState("");
  const [GSTNo, setGSTNo] = useState("");
  const [smallimages, setsmallImages] = useState([]);
  const [smallimageURLs, setsmallImagesURLs] = useState([]);
  const [Countrycode, setCountrycode] = useState("");
  const [countryOptions, setcountryOptions] = useState("");
  const [stateOptions, setstateOptions] = useState("");
  const [cityOptions, setcityOptions] = useState("");

  // pan card
  const [Panimages, setPanImages] = useState([]);
  const [PanimageURLs, setPanImagesURLs] = useState([]);

  // gst card
  const [Gstimages, setGstImages] = useState([]);
  const [GstimageURLs, setGstImagesURLs] = useState([]);

  useEffect(() => {
    if (smallimages?.length < 1) return;
    const newImageURLs = [];
    smallimages.forEach((image) =>
      newImageURLs.push(URL.createObjectURL(image))
    );
    setsmallImagesURLs(newImageURLs);
  }, [smallimages]);

  let errorsObj = { uploadimage1 : "" , uploadimage2 : "" , uploadimage3 : "" };
  const [errors, setErrors] = useState(errorsObj);
  const onsmallimagechange = (e) => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (e?.target?.files[0]?.size > 1000000) {
      errorObj.uploadimage1 = "Image Size must be less than 1MB.";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setsmallImages([...e.target.files]);
  };

  // Pan card

  useEffect(() => {
    if (Panimages?.length < 1) return;
    const newImageURLs = [];
    Panimages.forEach((image) => newImageURLs.push(URL.createObjectURL(image)));
    setPanImagesURLs(newImageURLs);
  }, [Panimages]);

  const onpanimagechange = (e) => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (e?.target?.files[0]?.size > 1000000) {
      errorObj.uploadimage2 = "Image Size must be less than 1MB.";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setPanImages([...e.target.files]);
  };

  // Gst card

  useEffect(() => {
    if (Gstimages?.length < 1) return;
    const newImageURLs = [];
    Gstimages.forEach((image) => newImageURLs.push(URL.createObjectURL(image)));
    setGstImagesURLs(newImageURLs);
  }, [Gstimages]);

  const onGstimagechange = (e) => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (e?.target?.files[0]?.size > 1000000) {
      errorObj.uploadimage3 = "Image Size must be less than 1MB.";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setGstImages([...e.target.files]);
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      boxShadow: "none",
      height: 46,
      borderRadius: 5,
      // border : 'none'
      borderColor: "#6D6E70 !important",
    }),
    menu: (provided) => ({
      ...provided,
      border: 1,
      borderColor: "#6D6E70 !important",
      borderStyle: "solid",
      boxShadow: "none",
    }),
    menuList: (base) => ({
      ...base,
      height: "228px !important",
      "::-webkit-scrollbar": {
        width: "5px",
      },
      "::-webkit-scrollbar-track": {
        background: "#fff",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#DAE9FF",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#DAE9FF",
      },
    }),
    option: (provided) => ({
      ...provided,
      cursor: "pointer",
      borderBottom: 1,
      paddingTop: 10,
      paddingBottom: 10,
      borderColor: "#DAE9FF",
      borderStyle: "solid",
      ":hover": {
        backgroundColor: "#DAE9FF !important",
        color: "#076AFF !important",
      },
    }),
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetCountrylist());
  }, []);

  const countrylist = useSelector((state) => state.alllist.countrylist.data);
  const statelist = useSelector((state) => state.alllist.statelist.data);
  const citylist = useSelector((state) => state.alllist.citylist.data);

  useEffect(() => {
    const con =
      countrylist &&
      countrylist.map((c) => ({ value: c.isoCode, label: c.name }));
    setcountryOptions(con);
  }, [countrylist]);

  useEffect(() => {
    const con =
      statelist && statelist.map((c) => ({ value: c.isoCode, label: c.name }));
    setstateOptions(con);
  }, [statelist]);

  useEffect(() => {
    const con = citylist && citylist.map((c) => ({ value: c, label: c }));
    setcityOptions(con);
  }, [citylist]);

  const history = useNavigate();

  const onSumitForm = (e) => {
    e?.preventDefault();
    dispatch(
      CreateNewProducer(
        Name,
        LastName,
        Email,
        Mobile,
        Address,
        Country,
        State,
        City,
        ZipCode,
        smallimages,
        Panimages,
        Gstimages,
        AadharNo,
        PanNo,
        GSTNo ,history
      )
    );
   
  };

  const addproducer = useSelector((state) => state.producer.addproducer);

  useEffect(() => {
    if(addproducer.code === 200){
      setName("");
      setLastName("");
      setEmail("");
      setMobile("");
      setAddress("");
      setCountry(null);
      setState(null);
      setCity(null);
      setZipCode("");
      setAadharNo("");
      setPanNo("");
      setGSTNo("");
      setGstImages("");
      setsmallImages("");
      setPanImages("");
      setGstImagesURLs("");
      setsmallImagesURLs("");
      setPanImagesURLs("");
      }
  },[addproducer])
  return (
    <div>
      {/* <ToastContainer /> */}
      <div className="">
        <div className="flex items-center pt-[20px] ">
          <Link to="/admin/producer">
            <p className=" text-[#076aff] cursor-pointer text-[18px] font-medium font-poppins">
              PRODUCER
            </p>
          </Link>
          <img src={arrow} alt="new" className="mx-3"></img>
          <p className=" text-[#161616] text-[18px] font-medium font-poppins">
            Create Producer
          </p>
        </div>

        {/*--------------------------- Form Start ---------------------------------*/}

        <form onSubmit={onSumitForm}>
          <div className="grid grid-cols-12 gap-x-4 lg:gap-x-7 mt-[20px] sm:mt-[30px]">
            <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
              <p className="sm:text-[18px] text-[#161616] font-medium pb-[15px] font-poppins">
                First Name
              </p>
              <input
                className=" focus:bg-white border border-[#6D6E70] rounded-[5px] w-full min-h-[46px]  placeholder:text-[#979797] pl-5 placeholder:font-medium"
                placeholder="Enter First Name"
                autoComplete="off"
                required
                type="text"
                onChange={(e) => setName(e.target.value)}
                value={Name}
              ></input>
            </div>
            <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
              <p className="sm:text-[18px] text-[#161616] font-medium pb-[15px] font-poppins">
                Last Name
              </p>
              <input
                className=" focus:bg-white border border-[#6D6E70] rounded-[5px] w-full min-h-[46px]  placeholder:text-[#979797] pl-5 placeholder:font-medium"
                placeholder="Enter Last Name"
                autoComplete="off"
                required
                type="text"
                onChange={(e) => setLastName(e.target.value)}
                value={LastName}
              ></input>
            </div>
            <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
              <p className="sm:text-[18px] text-[#161616] font-medium pb-[15px] font-poppins">
                Email
              </p>
              <input
                className=" focus:bg-white border border-[#6D6E70]  rounded-[5px] w-full min-h-[46px]   placeholder:text-[#979797] pl-5 placeholder:font-medium"
                placeholder="Enter Email"
                autoComplete="off"
                required
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                value={Email}
              ></input>
            </div>
            <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
              <p className="sm:text-[18px] text-[#161616] font-medium pb-[15px] font-poppins">
                Mobile No
              </p>
              <div className="relative">
                <input
                  required
                  onChange={(e) => setMobile(e.target.value)}
                  value={Mobile}
                  type="number"
                  className=" focus:bg-white border border-[#6D6E70] rounded-[5px] w-full min-h-[46px]   placeholder:text-[#979797] pl-5 placeholder:font-medium"
                  placeholder="Enter Mobile No"
                  autoComplete="off"
                ></input>
              </div>
            </div>
            <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
              <p className="sm:text-[18px] text-[#161616] font-medium pb-[15px] font-poppins">
                Address
              </p>
              <div className="relative">
                <input
                  required
                  onChange={(e) => setAddress(e.target.value)}
                  value={Address}
                  name="text"
                  className=" focus:bg-white border border-[#6D6E70] rounded-[5px] w-full min-h-[46px]   placeholder:text-[#979797] pl-5 placeholder:font-medium"
                  placeholder="Enter Address"
                  autoComplete="off"
                ></input>
              </div>
            </div>
            <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-[0px]">
              <p className="sm:text-[18px] text-[#161616] font-medium pb-[15px] font-poppins">
                Country
              </p>
              <div className="relative">
                <Select
                  closeMenuOnSelect
                  defaultValue={Country}
                  required
                  options={countryOptions}
                  value={
                   (countryOptions &&
                    countryOptions.find((obj) => obj.label === Country)) || ''
                  }
                  onChange={(e) => {
                    setCountry(e.label);
                    setCountrycode(e.value);
                    dispatch(GetStatelist(e.value));
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  styles={customStyles}
                  placeholder={"Select Country..."}
                />
              </div>
            </div>
            <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-[0px]">
              <p className="sm:text-[18px] text-[#161616] font-medium pb-[15px] font-poppins">
                State
              </p>
              <div className="relative">
                <Select
                  closeMenuOnSelect
                  // defaultValue={State}
                  name="state"
                  options={stateOptions}
                  required
                  value={
                    (stateOptions && stateOptions?.find((obj) => obj.label === State)) || ''
                  }
                  onChange={(e) => {
                    setState(e.label);
                    dispatch(GetCitylist(Countrycode, e.value));
                  }}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  styles={customStyles}
                  placeholder={"Select State..."}
                />
              </div>
            </div>
            <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-0">
              <p className="sm:text-[18px] text-[#161616] font-medium pb-[15px] font-poppins">
                City
              </p>
              <div className="relative">
                <Select
                  closeMenuOnSelect
                  required
                  name="colors"
                  options={cityOptions}
                  // defaultValue={City}
                  value={
                   (cityOptions && cityOptions.find((obj) => obj.value === City)) || ""
                  }
                  onChange={(e) => setCity(e.value)}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  styles={customStyles}
                  placeholder={"Select City..."}
                />
              </div>
            </div>
            <div className=" col-span-12 sm:col-span-4 ">
              <p className="sm:text-[18px] text-[#161616] font-medium pb-[15px] font-poppins">
                Zip Code
              </p>
              <div className="relative">
                <input
                  required
                  onChange={(e) => setZipCode(e.target.value)}
                  value={ZipCode}
                  name="text"
                  type="number"
                  className=" focus:bg-white border border-[#6D6E70] rounded-[5px] w-full min-h-[46px]   placeholder:text-[#979797] pl-5 placeholder:font-medium"
                  placeholder="Enter Zip Code"
                  autoComplete="off"
                ></input>
              </div>
            </div>
          </div>

          {/* -----------------------------upload image ----------------------------------*/}

          <div className="grid grid-cols-12 gap-x-7 my-[20px] sm:my-[30px]">
            <label
              htmlFor="inputTags"
              className=" col-span-12 mb-[20px] sm:mb-0 sm:col-span-4"
            >
              <div className="bg-[#fff] h-[160px] overflow-hidden border border-dashed border-[#076aff] flex items-center justify-center cursor-pointer  rounded-[10px]">
                <input
                  id="inputTags"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={onsmallimagechange}
                />
                {smallimageURLs?.length > 0 ? (
                  smallimageURLs?.map((m, id) => (
                    <div key={id} className="w-full h-full">
                      <img
                        src={m}
                        className="w-full h-full object-cover"
                        alt="new"
                      ></img>
                    </div>
                  ))
                ) : (
                  <div className=" flex justify-center items-center h-full">
                    <div>
                      <img
                        src={upload}
                        className="mx-auto w-[27px] h-[27px]"
                        alt="new"
                      ></img>
                      <p className="text-[#47505F] text-[14px] font-medium font-poppins mt-5">
                        Upload Aadhar Image
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {errors.uploadimage1 && <div className="mt-2 sm:hidden text-[#FF0101] text-sm font-poppins">{errors?.uploadimage1}</div>}
              <div className=" col-span-12 mt-[20px]  sm:col-span-3 ">
                <input
                  required
                  onChange={(e) => setAadharNo(e.target.value)}
                  value={AadharNo}
                  name="text"
                  type="number"
                  className=" focus:bg-white border border-[#6D6E70] rounded-[5px] w-full min-h-[46px] placeholder:text-[#979797] pl-5 placeholder:font-medium"
                  placeholder="Enter Aadhar No."
                  autoComplete="off"
                  pattern="\b[0-9]{12}\b"
                  title="value must be Number and length must be 12!!"
                ></input>
              </div>
              {errors.uploadimage1 && <div className="mt-2 hidden sm:block text-[#FF0101] text-sm font-poppins">{errors?.uploadimage1}</div>}
            </label>
            <label
              htmlFor="inputTagpan"
              className=" col-span-12 mb-[20px] sm:mb-0 sm:col-span-4"
            >
              <div className="bg-[#fff] h-[160px] overflow-hidden border border-dashed border-[#076aff] flex items-center justify-center cursor-pointer  rounded-[10px]">
                <input
                  id="inputTagpan"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={onpanimagechange}
                />
                {PanimageURLs?.length > 0 ? (
                  PanimageURLs?.map((m, id) => (
                    <div key={id} className="w-full h-full">
                      <img
                        src={m}
                        className="w-full h-full object-cover"
                        alt="new"
                      ></img>
                    </div>
                  ))
                ) : (
                  <div className=" flex justify-center items-center h-full">
                    <div>
                      <img
                        src={upload}
                        className="mx-auto w-[27px] h-[27px]"
                        alt="new"
                      ></img>
                      <p className="text-[#47505F] text-[14px] font-medium font-poppins mt-5">
                        Upload PAN Image
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {errors.uploadimage2 && <div className="mt-2 sm:hidden text-[#FF0101] text-sm font-poppins">{errors?.uploadimage2}</div>}
              <div className=" col-span-12 mt-[20px] sm:col-span-3">
                <input
                  required
                  onChange={(e) => setPanNo(e.target.value)}
                  value={PanNo}
                  type="text"
                  name="text"
                  className=" focus:bg-white border border-[#6D6E70] rounded-[5px] w-full min-h-[46px] placeholder:text-[#979797] pl-5 placeholder:font-medium"
                  placeholder="Enter PAN No."
                  autoComplete="off"
                ></input>
              </div>
              {errors.uploadimage2 && <div className="mt-2 hidden sm:block text-[#FF0101] text-sm font-poppins">{errors?.uploadimage2}</div>}
            </label>
            <label
              htmlFor="inputTaggst"
              className=" col-span-12  sm:col-span-4"
            >
              <div className="bg-[#fff] h-[160px] overflow-hidden border border-dashed border-[#076aff] flex items-center justify-center cursor-pointer  rounded-[10px]">
                <input
                  id="inputTaggst"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={onGstimagechange}
                />
                {GstimageURLs?.length > 0 ? (
                  GstimageURLs?.map((m, id) => (
                    <div key={id} className="w-full h-full">
                      <img
                        src={m}
                        className="w-full h-full object-cover"
                        alt="new"
                      ></img>
                    </div>
                  ))
                ) : (
                  <div className=" flex justify-center items-center h-full">
                    <div>
                      <img
                        src={upload}
                        className="mx-auto w-[27px] h-[27px]"
                        alt="new"
                      ></img>
                      <p className="text-[#47505F] text-[14px] font-medium font-poppins mt-5">
                        Upload GST Certificate
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {errors.uploadimage3 && <div className="mt-2 sm:hidden text-[#FF0101] text-sm font-poppins">{errors?.uploadimage3}</div>}
              <div className=" col-span-12 mt-[20px] sm:col-span-3">
                <input
                  required
                  onChange={(e) => setGSTNo(e.target.value)}
                  value={GSTNo}
                  type="text"
                  name="text"
                  className=" focus:bg-white border border-[#6D6E70]  rounded-[5px] w-full min-h-[46px] placeholder:text-[#979797] pl-5 placeholder:font-medium"
                  placeholder="Enter GST No."
                  autoComplete="off"
                  title="value must be Number and length must be 12!!"
                ></input>
              </div>
              {errors.uploadimage3 && <div className="mt-2 hidden sm:block text-[#FF0101] text-sm font-poppins">{errors?.uploadimage3}</div>}
            </label>
          </div>

          {/*---------------------------- Aadhar No and Pan No -------------------------- */}
          <div className="grid grid-cols-12  pb-[20px]">
            <div className="text-center  col-span-12 mb-[20px] sm:mb-0 sm:col-span-3">
              <button
                type="submit"
                className="bg-[#076AFF] font-poppins font-medium w-full rounded-[5px] min-h-[46px] text-white"
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default CreateProducer;
