import { configureStore } from "@reduxjs/toolkit";
import AuthReducer from "./Reducer/AuthReducer";
import ProducerReducer from "./Reducer/ProducerReducer";
import VleReducer from "./Reducer/VleReducer";
import AllListReducer from "./Reducer/AlllistReducer";
import ManagerReducer from "./Reducer/ManagerReducer";
import MovieReducer from "./Reducer/Moviereducer";
import ActorReducer from "./Reducer/ActorReducer";
import HomeBannerReducer from "./Reducer/HomeBannerREducer";
import HomePageReducer from "./Reducer/HomePageReducer";
import MovieDetailReducer from "./Reducer/MovieDetailReducer";
import GetWishListReducer from "./Reducer/GetWishListReducer";
import getCartListReducer from "./Reducer/cartReducer";
import ContactusReducer from "./Reducer/ContactusReducer";
import { OrderReducer } from "./Reducer/OrderReducer";
import TransactionReducer from "./Reducer/TransactionReducer";


export const store = configureStore({
  reducer: {
    Auth: AuthReducer,
    producer: ProducerReducer,
    Vle: VleReducer,
    alllist : AllListReducer,
    manager : ManagerReducer,
    movie : MovieReducer,
    actor : ActorReducer,
    banner : HomeBannerReducer,
    homemovies: HomePageReducer,
    moviedetails : MovieDetailReducer,
    mywishlist : GetWishListReducer,
    cart: getCartListReducer,
    contact : ContactusReducer,
    order: OrderReducer,
    Transactiondata : TransactionReducer
  },
});