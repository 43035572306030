import axios from "axios";
import { Flip, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";


export const actionTypes = {
    WISH_LIST: "WISH_LIST",
  };
  

  // Actor list

export const AddToWishlist = (data , history) => {
    const token = localStorage.getItem('token')
    return async (dispatch) => {
      await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/wishlist`,data, {
            headers: {
                'Authorization' : token
            }
        })
        .then((response) => {
            toast.success(response.data.message, {
                transition: Flip,
                theme: "colored",
                autoClose: 500,
              });
              dispatch({
            
                type: actionTypes.WISH_LIST,
                wishlist: response.data,
                
              });
      
        })
        .catch((err) => {
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
      }
      
      );
    };
  };



  //  MOVIES list

export const GetWishList = (query) => {
  const token = localStorage.getItem('token')

    return async (dispatch) => {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/wishlist`, {
          headers: {
          'Authorization' : token
      },
       params : {...query}
    })
        .then((response) => {
          dispatch({
            type: actionTypes.WISH_LIST,
            wishlist: response.data,
          });
        })
        .catch((err) => {});
    };
  };
  
  // delete movie list

  export const DeleteWishList = (id , history) => {
    const token = localStorage.getItem('token')
    return async (dispatch) => {
      await axios
        .delete(`${process.env.REACT_APP_API_BASE_URL}/wishlist/${id}`, {
          headers: {
            'Authorization' : token
          },
        })
        .then((response) => {
          toast.success(response.data.message, {
            transition: Flip,
            theme: "colored",
            autoClose: 500,
          });
          dispatch({
            type: actionTypes.WISH_LIST,
            wishlist: response.data,
          });
        })
        .catch((err) => {

          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
        } );
    };
  };
 
// delete all wishlist

  export const DeleteAllWishList = (history) => {
    const token = localStorage.getItem('token')
    return async (dispatch) => {
      await axios
        .delete(`${process.env.REACT_APP_API_BASE_URL}/wishlist`, {
          headers: {
            'Authorization' : token
          },
        })
        .then((response) => {
          toast.success(response.data.message, {
            transition: Flip,
            theme: "colored",
            autoClose: 500,
          });
          dispatch(GetWishList());
        })
        .catch((err) => {
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
        });
    };
  };