import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import { useDispatch, useSelector } from 'react-redux';
import { GetIncomelist } from '../../Redux/Action/TransactionAction';
import { useLocation, useNavigate } from 'react-router';
import Select from "react-select";

function IncomeGraph() {
    const refContainer = useRef(null);
    const [dataSource, setDataSource] = useState([]);
    const [Year, setYear] = useState('');
   
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
  dispatch(GetIncomelist({...object} , history))
  },[])

    useEffect(() => {
      const chart = Highcharts.chart(refContainer.current, {
        chart: {
          type: 'column',
          marginTop : 90
        }, 
        title: {
          text: 'Total Income',
          align: 'left',
          x: 25,
          y: 30
        }, 
        subtitle: {
        //   text: 'Lorem Ipsum is simply dummy text'
        }, // subtitle of the chart
        colors: ['#076aff'],
        xAxis: {
          categories: [
            'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
          ], 
          crosshair: true
        },
        yAxis: {
          min: 0, 
          title: {
            text: 'Income'
          } ,
          gridLineDashStyle: 'longdash',
          gridLineColor : '#000',
          // tickInterval: 10000,
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y}</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        }, 
        credits: {
          enabled: false
        },
        plotOptions: {
            series: {
                borderRadius: 10,
                pointWidth: 25
            }
        },
        series: dataSource 
      });
   
      if (dataSource.length > 0) {
        chart.hideLoading();
      }
      else {
        chart.showLoading();
      }
   
    }, [dataSource]);


    const transactiondatalist = useSelector(
      (state) => state.Transactiondata.Incomelist.data
    );
   
    useEffect(() => {
      setTimeout(() => {
        setDataSource([{
          name: 'Yearly Income',
          data: [transactiondatalist?.monthlyIncomeData[0]?.totalIncome, transactiondatalist?.monthlyIncomeData[1]?.totalIncome, transactiondatalist?.monthlyIncomeData[2]?.totalIncome, transactiondatalist?.monthlyIncomeData[3]?.totalIncome, transactiondatalist?.monthlyIncomeData[4]?.totalIncome,transactiondatalist?.monthlyIncomeData[5]?.totalIncome, transactiondatalist?.monthlyIncomeData[6]?.totalIncome, transactiondatalist?.monthlyIncomeData[7]?.totalIncome, transactiondatalist?.monthlyIncomeData[8]?.totalIncome, transactiondatalist?.monthlyIncomeData[9]?.totalIncome, transactiondatalist?.monthlyIncomeData[10]?.totalIncome, transactiondatalist?.monthlyIncomeData[11]?.totalIncome]
        }]);
      }, 500);
    }, [transactiondatalist]);

    useEffect(() => {
     setYear(transactiondatalist?.monthlyIncomeData[0]?.year)
    },[transactiondatalist])
   
    const allvalue = [
      { value: 2010, label: 2010 },
      { value: 2011, label: 2011 },
      { value: 2012, label: 2012 },
      { value: 2013, label: 2013 },
      { value: 2014, label: 2014 },
      { value: 2015, label: 2015 },
      { value: 2016, label: 2016 },
      { value: 2017, label: 2017 },
      { value: 2018, label: 2018 },
      { value: 2019, label: 2019 },
      { value: 2020, label: 2020 },
      { value: 2021, label: 2021 },
      { value: 2022, label: 2022 },
      { value: 2023, label: 2023 },
      { value: 2024, label: 2024 },
      { value: 2025, label: 2025 },
      { value: 2026, label: 2026 },
      { value: 2027, label: 2027 },
      { value: 2028, label: 2028 },
      { value: 2029, label: 2029 },
      { value: 2030, label: 2030 },
      { value: 2031, label: 2031 },
      { value: 2032, label: 2032 },
      { value: 2033, label: 2033 },
      { value: 2034, label: 2034 },
      { value: 2035, label: 2035 },
      { value: 2036, label: 2036 },
      { value: 2037, label: 2037 },
      { value: 2038, label: 2038 },
      { value: 2039, label: 2039 },
      { value: 2040, label: 2040 },
    ];

    const customStyles = {
      control: (base) => ({
        ...base,
        boxShadow: "none",
        height: 35,
        borderRadius: 12,
        width : '150px'
      }),
      menu: (provided) => ({
        ...provided,
        border: 1,
        borderColor: "#979797 !important",
        borderStyle: "solid",
        boxShadow: "none",
        width : '150px'
      }),
      menuList: (base) => ({
        ...base,
        height: "228px !important",
        "::-webkit-scrollbar": {
          width: "5px",
        },
        "::-webkit-scrollbar-track": {
          background: "#fff",
        },
        "::-webkit-scrollbar-thumb": {
          background: "#DAE9FF",
        },
        "::-webkit-scrollbar-thumb:hover": {
          background: "#DAE9FF",
        },
      }),
      option: (provided) => ({
        ...provided,
        cursor: "pointer",
        borderBottom: 1,
        paddingTop: 10,
        paddingBottom: 10,
        borderColor: "#DAE9FF",
        borderStyle: "solid",
        ":hover": {
          backgroundColor: "#DAE9FF !important",
          color: "#076AFF !important",
        },
      }),
    };
 
    const handleYear = (value) => {
      setYear(value);
      const sds = location.search.slice(1).split("&");
  
      let object = {};
      if (location.search.includes("?")) {
        sds.map((item) => {
          let newItem = item.split("=");
          object[newItem[0]] = newItem[1];
        });
        history({
          pathname: "/admin/transaction",
          search:
            "?" + new URLSearchParams({ ...object, year: value }).toString(),
        });
      } else {
        history({
          pathname: "/admin/transaction",
          search: `?year=${value}`,
        });
      }
      dispatch(GetIncomelist({ ...object, year: value }));
    };

  return (
    <div className=' relative'>
      <div className=' absolute right-5 top-4 z-50'>
         <Select
          closeMenuOnSelect
          options={allvalue}
          value={
            (allvalue && allvalue?.find((obj) => obj.value === Year)) ||
            ""
          }
          onChange={(e) => handleYear(e.value)}
          styles={customStyles}
          placeholder={"Select Year"}
          isSearchable={false} 
        />
      </div>
      
      <div ref={refContainer} className=' rounded-[10px] boxshadow '/>
    </div>
  )
}

export default IncomeGraph