import React, { useEffect, useRef, useState } from "react";
import search from "../images/search.png";
import upload from "../images/upload.png";
import cross from "../images/cross.png";
import bluecross from "../images/bluecross.svg";
import UseOutsideClick from "../components/UseOutsideClick";
import Swal from "sweetalert2";
import { useDispatch , useSelector } from "react-redux";
import {
  CreateNewActor,
  DeleteActor,
  GetActorlist,
} from "../Redux/Action/ActorAction";
import { useNavigate } from "react-router";

function AddActor() {
  const [open, setopen] = useState(false);
  const [Name, setName] = useState("");
  const [Search, setSearch] = useState("");
  const errorsObj = {
    imageURLs: "",
    Name: "",
  };
  const [Errors, setErrors] = useState(errorsObj);

  const ref = useRef();
  UseOutsideClick(ref, () => {
    if (open) {
      setopen(false);
      setErrors(false);
      setImageActorData([]);
    }
  });

  //--------------------- For Upload Image-------------------------

  const [images, setImages] = useState([]);
  const [imageURLs, setImagesURLs] = useState([]);

  const [imageActorData, setImageActorData] = useState([]);
  const [newActor, setNewActor] = useState([]);

  const handleActorChange = (event) =>{

    const file = event.target.files[0];
    const reader = new FileReader();
    let error = false;
    const errorObj = { ...errorsObj };
    reader.onload = () =>{
      const image = new Image();
      image.src = reader.result;

      image.onload = () =>{
        const width = image.width;
        const height = image.height;
        const size = file.size;

        if(width === 240 && height === 280 && size <= 1000000){
          setImageActorData(reader.result);
          error = false;
        }else{
            setNewActor([]);
            errorObj.imageURLs = "Image dimensions must be 240x280 and size less than 1MB.";
      error = true;
        }
      };
    };
    setErrors(errorObj);
    if (error) {
      return;
    }
    if(file){
      reader.readAsDataURL(file);
    }
    setNewActor([...event.target.files]);
  }


  useEffect(() => {
    if (images?.length < 1) return;
    const newImageURLs = [];
    images.forEach((image) => newImageURLs.push(URL.createObjectURL(image)));
    setImagesURLs(newImageURLs);
  }, [images]);

  const onimagechange = (e) => {
    setImages([...e.target.files]);
    // console.log([...e.target.files]);
  };

  //------------------ onSubmit Form --------------------------

  const onSubmitName = (e) => {
    e?.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (imageActorData.length === 0) {
      errorObj.imageURLs = "Image is required!!";
      error = true;
    }
    if (imageActorData.length > 0 && Name.length === 0) {
      errorObj.Name = "Name is required!!";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    if (Name?.length > 0 && imageActorData?.length > 0) {
      setopen(false);
      setName("");
      setImageActorData([]);
    }
    dispatch(CreateNewActor(newActor, Name , history));
  };

  useEffect(() => {
    if (imageActorData.length > 0) {
      setErrors(false);
    }
  }, [imageActorData]);

  //--------------- Modal for delete Actor---------------------
const history = useNavigate();
  const modal = (e, id) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Actor!!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#076aff",
      cancelButtonColor: "#ff0101",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteActor(id , history));
      }
    });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(GetActorlist('' , history));
  }, []);

  const actorlist = useSelector((state) => state.actor.actorlist.data);
  const newrole = localStorage.getItem('role')
  
  return (
    <div className="pt-[20px] h-full">
      {/* <ToastContainer /> */}
      <div className="flex items-center justify-between">
        <p className="sm:text-[22px] text-[#161616] font-poppins font-medium">
          ACTORS
        </p>
      </div>
      {/* search bar  */}
      <div className="mt-[15px] mb-[20px] sm:mb-[30px] p-5 bg-[#fff] rounded-[10px] lg:flex justify-between listtableshadow">
        <div>
          <form
            className="h-[40px] relative"
            onSubmit={(e) => {
              e?.preventDefault();
              if (Search) {
                dispatch(GetActorlist({ search: Search } , history));
              }
            }}
          >
            <input
              className="h-full bg-[#F2F2F2] rounded-[8px] pl-[50px] min-w-full lg:min-w-[400px] placeholder:text-[#47505F]  placeholder:text-[14px] placeholder:font-medium"
              placeholder="Search Actor"
              value={Search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value === "") {
                  dispatch(GetActorlist("" , history));
                }
              }}
            ></input>
            <img src={search} className="-mt-7 ml-[20px]" alt="new"></img>
          </form>
        </div>
        <button
          onClick={() => setopen(true)}
          className="bg-[#076AFF] mt-3 lg:mt-0 flex items-center cursor-pointer  pr-7 hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium rounded-[5px] h-[40px] text-white text-[14px]"
        >
        
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mx-[16px]"
            >
              <path
                d="M9.14174 1.85721C9.14174 1.22507 8.63103 0.714355 7.99888 0.714355C7.36674 0.714355 6.85603 1.22507 6.85603 1.85721V7.00007H1.71317C1.08103 7.00007 0.570312 7.51078 0.570312 8.14293C0.570312 8.77507 1.08103 9.28578 1.71317 9.28578H6.85603V14.4286C6.85603 15.0608 7.36674 15.5715 7.99888 15.5715C8.63103 15.5715 9.14174 15.0608 9.14174 14.4286V9.28578H14.2846C14.9167 9.28578 15.4275 8.77507 15.4275 8.14293C15.4275 7.51078 14.9167 7.00007 14.2846 7.00007H9.14174V1.85721Z"
                fill="white"
              />
            </svg>
          
          Add
        </button>
      </div>
      {/*End search bar  */}

     {actorlist?.length > 0 ?
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-x-3 lg:gap-x-6">
        {actorlist?.map((m) => {
          return (
            <div
              className="bg-[#fff] rounded-[10px] boxshadow mb-[20px] sm:mb-[30px] relative"
              key={m?._id}
            >
              <img
                src={`${`https://hooc.b-cdn.net/${m?.image}?t=${Date.now()}`}`}
                alt="new"
                className="w-full h-[150px] md:h-[200px] rounded-t-[10px] 2xl:h-[280px] "
              ></img>
              <div className="px-5 py-[10px]">
                <p className="text-[#161616] pt-[3px] text-[12px] lg:text-[14px] font-medium font-poppins">
                  {m?.actor}
                  {/* {e?.actor?.length > 16 ? (
                          <>{`${e?.actor?.substring(0, 16)}...`}</>
                        ) : (
                          <>{e?.actor}</>
                        )} */}
                </p>
              </div>
             {newrole !== 'producer' && <div
                onClick={(e) => modal(e, m?._id)}
                className=" bg-[#fff] border border-[#076aff] cursor-pointer shadow-xl hover:shadow-2xl p-1.5 rounded-[50%] absolute -right-[7px] -top-[7px]"
              >
                <img src={bluecross} alt="new"></img>
              </div>}
            </div>
          );
        })}
      </div>
      :
<div className=" flex items-center justify-center min-h-[300px] sm:text-[20px] text-[#161616] font-poppins font-medium">No Actor Found!!</div>

      }

      {/* --------------Add Image Modal------------------ */}

      {open && (
        <div className="backcolor absolute z-[50] top-0 bottom-0 right-0 left-0">
          <div className="fixed top-[50%] left-[50%] opacity-100 transform -translate-x-[50%] -translate-y-[50%]">
            <div
              className="bg-[#fff] py-10 min-w-[320px] px-[60px] shadow-xl rounded-[20px] popup relative"
              ref={ref}
            >
              <div>
                <p className="text-[18px] sm:text-[24px] pb-[20px] text-[#000] font-medium font-poppins ">
                  Add Actor
                </p>
              </div>
              <div className=" absolute right-7 top-7">
                <img
                  src={cross}
                  alt="new"
                  onClick={() => {
                    setopen(false);
                    setName("");
                    // setImagesURLs([]);
                    setImageActorData("");
                    setErrors(false);
                  }}
                  className="cursor-pointer"
                ></img>
              </div>

              {/* ------------For Upload Image --------------*/}
              <form>
                <label htmlFor="inputTag">
                  <div className="bg-[#fff] h-[183px] max-w-[200px] border-2 border-dashed border-[#076aff] flex items-center justify-center cursor-pointer overflow-hidden  rounded-[10px]">
                    <input
                      id="inputTag"
                      type="file"
                      accept="image/*"
                      className="hidden"

                      // onChange={onimagechange}
                      onChange={handleActorChange}
                    />
                    {imageActorData?.length > 0 ? (
                      // imageURLs?.map((m, i) => (
                        <div  className="w-full h-full">
                          <img
                            // src={m}
                            src={imageActorData}
                            className="w-full h-full object-cover"
                            alt="new"
                          ></img>
                        </div>
                      // ))
                    ) : (
                      <div className=" flex justify-center items-center h-full">
                        <div>
                          <img src={upload} className="mx-auto" alt="new"></img>
                          <p className="text-[#47505F] text-[18px] font-medium font-poppins mt-5">
                            Upload Image <br/><span className="text-[14px] flex justify-center font-[400]">(240x280)</span>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </label>
               {Errors.imageURLs &&
                  <div className="mt-2 text-[#FF0101] text-sm font-poppins max-w-[200px]">
                    {Errors.imageURLs}
                  </div>
                }
                <input
                  className="rounded-[5px] w-full py-2.5 mt-[20px] border border-[#6D6E70]  placeholder:text-[#979797] pl-5 placeholder:font-medium"
                  placeholder="Enter Actor's Name"
                  type="text"
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                  required
                ></input>
                {Errors.Name && (
                  <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                    {Errors.Name}
                  </div>
                )}
                <div className="mt-[20px]">
                  <button
                    onClick={onSubmitName}
                    className="bg-[#076AFF] font-poppins w-full font-medium rounded-[5px] min-h-[46px] text-white"
                  >
                    ADD
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddActor;
