import React, { useEffect } from "react";

function Termservice() {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <div>
      <div className="py-[25px]  privacyheading">
        <p className=" text-white  main_container text-[18px] sm:text-[22px] font-medium font-poppins">
        Terms Of Conditions
        </p>
      </div>
      <div className=" main_container">
        <div className="mt-[20px] sm:mt-[30px]">
          <p className="text-[#161616] text-[14px] sm:text-[16px] font-poppins">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
            vehicula eros sit amet est tincidunt aliquet. Fusce laoreet ligula
            ac ultrices eleifend. Donec hendrerit fringilla odio, ut feugiat mi
            convallis nec. Fusce elit ex, blandit vitae mattis sit amet, iaculis
            ac elit. Ut diam mauris, viverra sit amet dictum vel, aliquam ac
            quam. Ut mi nisl, fringilla sit amet erat et, convallis porttitor
            ligula. Sed auctor, orci id luctus venenatis, dui dolor euismod
            risus, et pharetra orci lectus quis sapien. Duis blandit ipsum ac
            consectetur scelerisque.
          </p>
        </div>
        <div className="mt-[20px] sm:mt-[30px]">
          <p className="text-[#161616] sm:text-[18px] mb-[10px] font-medium font-poppins">
            THE LAST WITNESS
          </p>
          <p className="text-[#161616] text-[14px] sm:text-[16px] font-poppins">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
            vehicula eros sit amet est tincidunt aliquet. Fusce laoreet ligula
            ac ultrices eleifend. Donec hendrerit fringilla odio, ut feugiat mi
            convallis nec. Fusce elit ex, blandit vitae mattis sit amet, iaculis
            ac elit. Ut diam mauris, viverra sit amet dictum vel, aliquam ac
            quam. Ut mi nisl, fringilla sit amet erat et, convallis porttitor
            ligula. Sed auctor, orci id luctus venenatis, dui dolor euismod
            risus, et pharetra orci lectus quis sapien. Duis blandit ipsum ac
            consectetur scelerisque.
          </p>
        </div>
        <div className="mt-[20px] sm:mt-[30px]">
          <p className="text-[#161616] sm:text-[18px] mb-[10px] font-medium font-poppins">
            THE LAST WITNESS
          </p>
          <p className="text-[#161616] text-[14px] sm:text-[16px] font-poppins">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
            vehicula eros sit amet est tincidunt aliquet. Fusce laoreet ligula
            ac ultrices eleifend. Donec hendrerit fringilla odio, ut feugiat mi
            convallis nec. Fusce elit ex, blandit vitae mattis sit amet, iaculis
            ac elit. Ut diam mauris, viverra sit amet dictum vel, aliquam ac
            quam. Ut mi nisl, fringilla sit amet erat et, convallis porttitor
            ligula. Sed auctor, orci id luctus venenatis, dui dolor euismod
            risus, et pharetra orci lectus quis sapien. Duis blandit ipsum ac
            consectetur scelerisque.
          </p>
        </div>
        <div className="mt-[20px] sm:mt-[30px]">
          <p className="text-[#161616] sm:text-[18px] mb-[10px] font-medium font-poppins">
            THE LAST WITNESS
          </p>
          <p className="text-[#161616] text-[14px] sm:text-[16px] font-poppins">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
            vehicula eros sit amet est tincidunt aliquet. Fusce laoreet ligula
            ac ultrices eleifend. Donec hendrerit fringilla odio, ut feugiat mi
            convallis nec. Fusce elit ex, blandit vitae mattis sit amet, iaculis
            ac elit. Ut diam mauris, viverra sit amet dictum vel, aliquam ac
            quam. Ut mi nisl, fringilla sit amet erat et, convallis porttitor
            ligula. Sed auctor, orci id luctus venenatis, dui dolor euismod
            risus, et pharetra orci lectus quis sapien. Duis blandit ipsum ac
            consectetur scelerisque.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Termservice;
