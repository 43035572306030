import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { ResetMovieDetail } from "../Redux/Action/MovieDetailAction";
import { actionTypes } from "../Redux/Reducer/MovieDetailReducer";
import { useDispatch } from "react-redux";

function Slider(props) {
  // -------------- Carousel responsive -------------//

  const responsive = {
    desktop: {
      breakpoint: { max: 5000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 750 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 750, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const convertIntoTime = (duration) => {
    const time = duration * 60;
    const timestamp = new Date(time * 1000);
    const hours = timestamp.getUTCHours();
    const minutes = timestamp.getUTCMinutes();
    return (
      <p>
        {hours} <span> hr </span> {minutes} <span> mins</span>
      </p>
    );
  };
  const convertReleaseTime = (duration) => {
    const d = new Date(duration);
    // console.log(d)
    const date_month = moment(d).format("dddd");
    const date_date = moment(d).format("DD MMMM");
    const date_year = moment(d).format("YYYY");
    return (
      <span className=" sm:ml-2">
        {" "}
        {date_month}, {date_date} {date_year}
      </span>
    );
  };

  const dispatch = useDispatch();
  return (
    <div className="w-full">
      <Carousel
        swipeable={true}
        // draggable={true} //
        //   showDots={true}
        responsive={responsive}
        ssr={true}
        infinite={props?.movieslist?.length > 1 && true}
        arrows={true}
        autoPlaySpeed={4500}
        keyBoardControl={true}
        autoPlay={props?.movieslist?.length > 1 && true}
        customTransition="all 2.5s"
        transitionDuration={2500}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        pauseOnHover={false}
        // shouldResetAutoplay={true}
      >
        {props.movieslist ? (
          props.movieslist?.map((e) => (
            <div className="relative" key={e?._id}>
              <div className=" absolute top-0 bottom-0 right-0  z-10 left-0 banner-shadow"></div>
              <div
                style={{
                  backgroundImage: `url(${`https://hooc.b-cdn.net/${
                    e?.banner_image
                  }?t=${Date.now()}`})`,
                  width: "100%",
                  backgroundSize: "cover",
                  backgroundRepeat: "round",
                }}
                className=" py-[2rem] md:py-[2.25rem] lg:py-[3.25rem] xl:py-[6.25rem] "
              >
                <div className="main_container ">
                  <div className="flex items-center ">
                    <img
                      src={`${`https://hooc.b-cdn.net/${
                        e?.poster_image
                      }?t=${Date.now()}`}`}
                      alt="new"
                      className="h-[160px] w-[140px] sm:w-[280px] sm:h-[300px] z-30 rounded-[10px]"
                    ></img>
                    <div className="ml-4 sm:ml-10 z-30">
                      <p className="text-[#fff] font-medium bannerheading text-[20px] md:text-[36px] font-poppins">
                        {e?.name}
                      </p>

                      <p className="text-[#E1E1E3] font-normal mt-1 sm:mt-0 bannerdesc text-[18px] md:text-[24px] font-poppins">
                          <div>
                            <p className=" lg:flex lg:items-center ">
                             {e?.isrelease === false ? 'PRE-RELEASE' : 'RELEASE'}  SCREENING{" "}
                              {convertReleaseTime(e.release)}
                            </p>
                          </div>
                      </p>

                      <div className="flex items-center mt-2 sm:mt-3">
                        <div className="w-[5px] h-[5px] mr-2 sm:mr-4 bg-[#fff] rounded-[50%]"></div>
                        <p className="text-[#fff] text-[11px] sm:text-[14px] font-medium font-poppins">
                          {convertIntoTime(e.duration)}
                        </p>
                        <button className="text-[#fff] bg-[#FF0101] ml-5 px-3 cursor-context-menu py-1.5 text-[10px] rounded-[3px] font-poppins">
                          {e.genre.slice(0, 1).map((m) => {
                            return m;
                          })}
                        </button>
                      </div>
                      <Link to={`/movie-detail/${e._id}`}>
                        <button onClick={() => dispatch(ResetMovieDetail(actionTypes.RESET_MOVIE_DETAIL))} className="text-[#fff] bg-[#076aff] mt-[20px] text-sm sm:text-base sm:mt-[25px] min-w-[100px] sm:min-w-[115px] min-h-[30px] sm:min-h-[40px] rounded-[6px] font-poppins">
                          Book Now
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div></div>
        )}
      </Carousel>
    </div>
  );
}

export default Slider;
