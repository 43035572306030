import React, { useRef, useState } from "react";
import logo from "../images/Headerlogo.svg";
// import profile from "../images/PROFILE.png";
import polygon from "../images/Polygon 1.svg";
import mobilelogo from "../images/mobilelogo.png";
import { Link, useHistory } from "react-router-dom";
import menu from "../images/menu.svg";
import UseOutsideClick from "../components/UseOutsideClick";
import "../App.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom/dist";
function Header({ setsidemenu }) {
  const [edit, setedit] = useState(false);
  const ref = useRef();
  UseOutsideClick(ref, () => {
    if (edit) {
      setedit(false);
    }
  });

  const history = useNavigate()
  const logout = () => {
    localStorage.clear();
    history('/login')
  }

  const newlogin = localStorage.getItem('firstname')

  return (
    <>
      <div className="w-full bg-[#fff] py-3 headershadow">
        {/* <!----------------- Header starts --------------------------> */}

        <div className="flex  items-center justify-between">
          <div className="ml-[20px] md:ml-[40px] lg:hidden">
            <img src={menu} alt="new" onClick={() => setsidemenu(true)}></img>
          </div>
          <div className="hidden lg:block ml-[60px]">
              <img src={logo} alt="new"></img>
          </div>
          <div className="lg:hidden ">
            <img
              src={mobilelogo}
              alt="new"
            ></img>
          </div>
          <div
            onClick={() => setedit(true)}
            className=" relative cursor-pointer mr-[20px] md:mr-[40px]"
          >
            
            <div className=" bg-[#dfdfdf] w-[34px] h-[34px] rounded-[50%] mx-auto flex items-center justify-center">
            <p className="text-[14px] font-semibold  font-poppins">{newlogin?.substring(0,1)?.toUpperCase()}</p>
              </div>
            <p className="text-[14px] font-normal font-poppins">{newlogin}</p>

            {/*------------------------ Edit Profile pop up start ------------------------*/}

            {edit && (
              <div className="relative">
                <div
                  className="bg-[#fff] shadow-lg rounded-[10px] absolute top-8 -right-2  p-2 z-10 min-w-[180px]"
                  ref={ref}
                >
                  <img
                    src={polygon}
                    className=" absolute -top-[15px] right-4 z-20"
                    alt="new"
                  ></img>
                    <div
                      onClick={() => {setedit(false)
                      logout()
                      }}
                      className="flex  items-center py-2 hover:bg-[#dae9ff] rounded-[8px] px-5 group"
                    >
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="group-hover:fill-[#076AFF]"
                      >
                        <path
                          className="group-hover:fill-[#076AFF]"
                          d="M13.1818 0.000799158H7.20392C6.9921 0.000799158 6.78896 0.084992 6.63919 0.234856C6.48942 0.384721 6.40527 0.58798 6.40527 0.79992C6.40527 1.01186 6.48942 1.21512 6.63919 1.36498C6.78896 1.51485 6.9921 1.59904 7.20392 1.59904H13.1818C13.855 1.59904 14.4029 2.14484 14.4029 2.8161V13.1839C14.4029 13.8552 13.855 14.4018 13.1818 14.4018H7.20392C6.9921 14.4018 6.78896 14.486 6.63919 14.6358C6.48942 14.7857 6.40527 14.9889 6.40527 15.2009C6.40527 15.4128 6.48942 15.6161 6.63919 15.7659C6.78896 15.9158 6.9921 16 7.20392 16H13.1818C13.9285 15.9998 14.6446 15.7032 15.173 15.1752C15.7014 14.6472 15.9989 13.9311 16.0002 13.1839V2.8161C15.9989 2.06892 15.7014 1.35277 15.173 0.824808C14.6446 0.296847 13.9285 0.000210586 13.1818 0V0.000799158Z"
                          fill="#47505F"
                        />
                        <path
                          className="group-hover:fill-[#076AFF]"
                          d="M2.63312 8.8015H10.3696C10.5814 8.8015 10.7845 8.71731 10.9343 8.56744C11.0841 8.41758 11.1682 8.21432 11.1682 8.00238C11.1682 7.79044 11.0841 7.58718 10.9343 7.43731C10.7845 7.28745 10.5814 7.20326 10.3696 7.20326H2.6491L5.09454 4.59013C5.1694 4.51419 5.2283 4.42402 5.26778 4.32494C5.30726 4.22585 5.32652 4.11987 5.32442 4.01322C5.32232 3.90658 5.2989 3.80143 5.25554 3.70398C5.21219 3.60654 5.14978 3.51876 5.07198 3.44583C4.99419 3.3729 4.90259 3.31629 4.80259 3.27934C4.70259 3.24239 4.59621 3.22584 4.48971 3.23068C4.38322 3.23552 4.27877 3.26164 4.18253 3.3075C4.08628 3.35337 4.00019 3.41805 3.92932 3.49773L0.256364 7.42222C0.168811 7.50418 0.101075 7.60502 0.0583008 7.71709C0.0375362 7.76904 0.0343417 7.82417 0.0255566 7.87851C0.0183688 7.92087 0 7.95843 0 8.00238L0.00159726 8.00717C-0.000282543 8.21177 0.0766528 8.40923 0.216432 8.55857L3.93012 12.5254C4.00153 12.6022 4.08742 12.6642 4.18284 12.7077C4.27827 12.7512 4.38135 12.7754 4.48615 12.7789C4.59096 12.7824 4.69542 12.7651 4.79352 12.728C4.89163 12.691 4.98144 12.6349 5.0578 12.563C5.1344 12.4912 5.19611 12.405 5.23942 12.3094C5.28272 12.2138 5.30677 12.1105 5.31018 12.0056C5.31359 11.9007 5.2963 11.7961 5.2593 11.6978C5.2223 11.5996 5.16631 11.5096 5.09454 11.433L2.63312 8.8015Z"
                          fill="#47505F"
                        />
                      </svg>
                      <p className="pl-5 font-poppins group-hover:text-[#076AFF] text-[#47505F]">
                        Log out
                      </p>
                    </div>
                
                </div>
              </div>
            )}


            {/*----------------------- Edit Profile pop up End --------------------------*/}
          </div>
        </div>
        {/* <!-- Header ends --> */}
      </div>
    </>
  );
}

export default Header;
