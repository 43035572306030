import axios from "axios";
import { Flip, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";


export const actionTypes = {
    BANNER_LIST: "BANNER_LIST",
    BANNER_MOVIE_LIST: "BANNER_MOVIE_LIST",
  };


  
  //  Banner list

export const GetBannerlist = (history) => {
  const token = localStorage.getItem('token')

    return async (dispatch) => {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/banner` , {
          headers : {
            'Authorization' : token
          }
        })
        .then((response) => {
          dispatch({
            type: actionTypes.BANNER_LIST,
            bannerlist: response?.data,
          });
        })
        .catch((err) => {
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
        });
    };
  };


  // banner movie list

export const GetBannermovielist = (query , history) => {
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/bannermovie`, {
        params: { ...query },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.BANNER_MOVIE_LIST,
          bannermovielist: response.data,
        });
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};
  // Delete Banner

export const DeleteBanner = (id , history) => {
  const token = localStorage.getItem('token')
    return async (dispatch) => {
      await axios
        .delete(`${process.env.REACT_APP_API_BASE_URL}/banner/${id}`, {
          headers: {
            Accept: "application/json",
            'Authorization' : token
          },
        })
        .then((response) => {
          toast.success(response.data.message, {
            transition: Flip,
            theme: "colored",
            autoClose: 500,
          });
          dispatch(GetBannerlist());
        })
        .catch((err) => {
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
        });
    };
  };

  export const AddBanner = (data , history) => {
  const token = localStorage.getItem('token')

    return async (dispatch) => {
      await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/banner`, data,{
          headers: {
            Accept: "application/json",
            'Authorization' : token
          },
        })
        .then((response) => {
          toast.success(response.data.message, {
            transition: Flip,
            theme: "colored",
            autoClose: 500,
          });
          dispatch(GetBannerlist());
        })
        .catch((err) => 
        {
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
         toast.error(err.response.data.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        })
        
      }
        
        );
    };
  };