export const actionTypes = {
    CONTACT_LIST: "CONTACT_LIST",
}

const INITIAL_STATE = {
    contactlist : [],
}

const ContactusReducer = (state = INITIAL_STATE, action) => {
 
    switch(action.type){
        case actionTypes.CONTACT_LIST:
            return{
                ...state,
                contactlist: action.contactlist
            }
            default: return state;
    }
};

export default ContactusReducer;