export const actionTypes = {
  VLE_LIST: "VLE_LIST",
  ADD_VLE: "ADD_VLE",
  DELETE_VLE_LIST: "DELETE_VLE_LIST",
  ALL_VLE_LIST: "ALL_VLE_LIST",
  VLE_DETAIL: "VLE_DETAIL",
  RESET_VLE_DETAIL: "RESET_VLE_DETAIL",
};

const INITIAL_STATE = {
  requestvlelist: [],
  vlelist: [],
  addvle: [],
  deletevlelist: [],
  vledetail: [],
};

const VleReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.VLE_LIST:
      return {
        ...state,
        requestvlelist: action.requestvlelist,
      };
    case actionTypes.ALL_VLE_LIST:
      return {
        ...state,
        vlelist: action.vlelist,
      };
    case actionTypes.VLE_DETAIL:
      return {
        ...state,
        vledetail: action.vledetail,
      };
    case actionTypes.RESET_VLE_DETAIL:
      return {
        ...state,
        vledetail: action.INITIAL_STATE,
      };
    case actionTypes.DELETE_VLE_LIST:
      return {
        ...state,
        deletevlelist: action.deletevlelist,
      };
    case actionTypes.ADD_VLE:
      return {
        ...state,
        addvle: action.addvle,
      };

    default:
      return state;
  }
};

export default VleReducer;
