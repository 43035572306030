import React, { Fragment, useEffect, useState } from "react";
import search from "../../images/search.png";
import arrowdown from "../../images/arrow-down.svg";
import arrowd from "../../images/rightarr.png";
import phone from "../../images/email.svg";
import { Menu, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { GetRequestVlelist, UpdateVle } from "../../Redux/Action/VleAction";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import { useNavigate, useSearchParams } from "react-router-dom/dist";
import { UpdateProducerStatus } from "../../Redux/Action/ProducerAction";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function RequestedVLE() {
  const location = useLocation();

  const [sort, setsort] = useState("desc");
  const [sortmob, setsortmob] = useState("desc");
  const [sortemail, setsortemail] = useState("desc");
  const [Search, setSearch] = useState("");

  const ordermethod = (order_by) => {
    switch (order_by) {
      case "name":
        const t = sort === "desc" ? "asc" : "desc";
        setsort(t);
        break;

      case "emailID":
        const te = sortemail === "desc" ? "asc" : "desc";
        setsortemail(te);
        break;

      case "contact":
        const tr = sortmob === "desc" ? "asc" : "desc";
        setsortmob(tr);
        break;

      default:
    }
  };

  const allvalue = [
    { value: "10", label: "10", id: 1 },
    { value: "25", label: "25", id: 2 },
    { value: "50", label: "50", id: 3 },
  ];

  const [page, setpage] = useState();
  const [selectpage, setselectpage] = useState("");

  const history = useNavigate();

  useEffect(() => {
    setpage(allvalue[0]?.value);
  }, []);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const dispatch = useDispatch();

  

  // Vle list
  useEffect(() => {
    searchParams.delete('search');
    setSearchParams(searchParams)
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    delete object.search;
    dispatch(GetRequestVlelist({...object}, history));
  }, []);

  //----------------- for limit filter------------------------

  const onselectedpage = (item) => {
    setpage(item.value);

    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      setpage(item.value);
      history({
        pathname: "/admin/request-vle",
        search:
          "?" +
          new URLSearchParams({ ...object, limit: item.value }).toString(),
      });
    } else {
      history({
        pathname: "/admin/request-vle",
        search: `?limit=${item.value}`,
      });
    }
    dispatch(GetRequestVlelist({ ...object, limit: item.value }, history));
  };

  //----------------- for field and order filter------------------------

  const onApply = (order_by, sort) => {
    const order_typedata = ordermethod(order_by);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/request-vle",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            field: order_by,
            order: sort,
          }).toString(),
      });
    } else {
      history({
        pathname: "/admin/request-vle",
        search: `?field=${order_by}&order=${sort}`,
      });
    }
    dispatch(
      GetRequestVlelist({ ...object, field: order_by, order: sort }, history)
    );
  };

  //----------------- for search filter------------------------

  const submitsearch = (e) => {
    e?.preventDefault();
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/request-vle",
        search:
          "?" + new URLSearchParams({ ...object, search: Search }).toString(),
      });
    } else {
      history({
        pathname: "/admin/request-vle",
        search: `?search=${Search}`,
      });
    }
    dispatch(GetRequestVlelist({ ...object, search: Search }, history));
  };

  const vlelist = useSelector((state) => state.Vle.requestvlelist.data);

  //----------------- for pagination filter------------------------

  const handlePageChange = (selected) => {
    setselectpage(selected.selected);
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      history({
        pathname: "/admin/request-vle",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            page: selected.selected + 1,
          }).toString(),
      });
    } else {
      history({
        pathname: "/admin/request-vle",
        search: `?page=${selected.selected + 1}`,
      });
    }
    dispatch(
      GetRequestVlelist({ ...object, page: selected.selected + 1 }, history)
    );
  };


  const [searchParams, setSearchParams] = useSearchParams();

  const removeQueryParams = () => {
    const param = searchParams.get("search");

    if (param) {
      searchParams.delete("search");
      setSearchParams(searchParams);
      const sds =  location.search.slice(1).split("&")
      let object = {}
      if(location.search.includes('?')){
        sds.map(item => {
          let newItem = item.split("=")
          object[newItem[0]] = newItem[1]
        })
      }
      delete object.search;
      dispatch(GetRequestVlelist( {...object} , history));
    }
  };

  const [Itemperpage, setItemperpage] = useState("");

  const pageCount = Math.ceil(Number(vlelist?.count) / Number(page));

  useEffect(() => {
    if (pageCount && pageCount !== Infinity) {
      setItemperpage(pageCount);
    }
  }, [vlelist]);

  const updatestatus = (id) => {
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    dispatch(UpdateVle(id, { ...object }, history));
  };

  const Contactstatusoption = [
    { value: "Pending", label: "Pending" },
    { value: "Connected", label: "Connected" },
  ];

  const customStyles = {
    control: (base) => ({
      ...base,
      boxShadow: "none",
      borderRadius: 5,
      fontSize: "14px",
      // border : 'none'
      width: "140px",
      borderColor: "#6D6E70 !important",
    }),
    menu: (provided) => ({
      ...provided,
      border: 1,
      borderColor: "#6D6E70 !important",
      borderStyle: "solid",
      boxShadow: "none",
      width: "140px",
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        width: "5px",
      },
      "::-webkit-scrollbar-track": {
        background: "#fff",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#DAE9FF",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#DAE9FF",
      },
    }),
    option: (provided) => ({
      ...provided,
      cursor: "pointer",
      borderBottom: 1,
      paddingTop: 10,
      paddingBottom: 10,
      zIndex: 100,
      fontSize: "14px",
      borderColor: "#DAE9FF",
      borderStyle: "solid",
      ":hover": {
        backgroundColor: "#DAE9FF !important",
        color: "#076AFF !important",
      },
    }),
  };

  const handlestatus = (value, id) => {
    const data = {
      status: value,
    };
    dispatch(UpdateProducerStatus(data, id, history));
  };
  const producerstatus = useSelector((state) => state.producer.producerstatus);
  useEffect(() => {
    if (producerstatus?.code === 200) {
      dispatch(GetRequestVlelist());
    }
  }, [producerstatus]);

  useEffect(() => {
    const newlimit = searchParams.get("limit");
    if(newlimit?.length > 0){
      setpage(newlimit)
    }
  },[])

  useEffect(() => {
    const newpage = searchParams.get("page");
    if(newpage?.length > 0 && newpage !== null){
      setselectpage(newpage - 1)
    }
  },[])
  return (
    <div className="pt-[20px] h-full pb-[20px] md:pb-0">
      {/* <ToastContainer /> */}
      <div>
        <p className=" sm:text-[22px] text-[#161616] font-poppins font-medium">
          VLE
        </p>
      </div>
      {/* search bar  */}

      <div className="mt-[15px] mb-[20px] sm:mb-[30px] p-5 flex items-center justify-between bg-[#fff] rounded-[10px]  listtableshadow">
        <div>
          <form
            className="h-[40px] w-full relative"
            onSubmit={(e) => {
              if (Search) {
                submitsearch(e);
              }
            }}
          >
            <input
              className="h-full bg-[#F2F2F2] rounded-[8px] pl-[50px] min-w-full lg:min-w-[400px] placeholder:text-[#47505F]  placeholder:text-[14px] placeholder:font-medium"
              placeholder="Search by email...."
              value={Search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value === "") {
                  // dispatch(GetRequestVlelist("", history));
                  removeQueryParams();
                }
              }}
            ></input>
            <img src={search} className="-mt-7 ml-[20px]" alt="new"></img>
          </form>
        </div>
      </div>

      {/*End search bar  */}
      {/*------------------------- Table ---------------------------- */}
      {vlelist?.request.length > 0 ? (
        <div>
          <div className="tableFixHead">
            <table className="w-full listtableshadow">
              <thead className="bg-[#F9FAFB]" style={{ minHeight: 60 }}>
                <tr className="text-[#161616] font-medium font-poppins">
                  <td className="py-4 pl-[40px] min-w-[180px]">
                    <div className=" flex items-center">
                      Name{" "}
                      <span>
                        <img
                          src={arrowd}
                          alt="new"
                          onClick={() => onApply("name", sort)}
                          className={`${
                            sort === "asc"
                              ? `${"-rotate-90"}`
                              : `${" rotate-90"}`
                          } ml-2 cursor-pointer`}
                        ></img>
                      </span>
                    </div>
                  </td>
                  <td className="py-4 min-w-[200px]">
                    <div className=" flex items-center">
                      Email{" "}
                      <span>
                        <img
                          src={arrowd}
                          alt="new"
                          onClick={() => onApply("emailID", sortemail)}
                          className={`${
                            sortemail === "asc"
                              ? `${"-rotate-90"}`
                              : `${" rotate-90"}`
                          } ml-2 cursor-pointer`}
                        ></img>
                      </span>
                    </div>
                  </td>
                  <td className="py-4 min-w-[150px]">
                    <div className=" flex items-center">
                      Mobile No{" "}
                      <span>
                        <img
                          src={arrowd}
                          alt="new"
                          onClick={() => onApply("contact", sortmob)}
                          className={`${
                            sortmob === "asc"
                              ? `${"-rotate-90"}`
                              : `${" rotate-90"}`
                          } ml-2 cursor-pointer`}
                        ></img>
                      </span>
                    </div>
                  </td>
                  <td className="py-4 min-w-[80px]">Status</td>
                  <td className="py-4 min-w-[80px]">Action</td>
                  {/* <td className="py-4 min-w-[80px]">Mail</td> */}
                </tr>
              </thead>
              <tbody>
                {vlelist?.request.map((e) => (
                  <tr className={` border-b bg-[#fff]`} key={e?._id}>
                    <td className="py-[14px] max-w-[200px] pl-[40px]">
                      <p className="text-[#47505F] text-[14px] font-medium font-poppins">
                        {e?.name?.length > 16 ? (
                          <>{`${e?.name?.substring(0, 16)}...`}</>
                        ) : (
                          <>{e?.name}</>
                        )}
                      </p>
                    </td>
                    <td className="text-[#47505F] text-[14px] font-medium font-poppins py-[20px]">
                      <div>{e?.emailID}</div>
                    </td>
                    <td className="text-[#47505F] text-[14px] font-medium font-poppins py-[20px]">
                      <div>{e?.contact}</div>
                    </td>
                    <td className="text-[#47505F] font-medium font-poppins py-[20px]">
                      <Select
                        options={Contactstatusoption}
                        styles={customStyles}
                        isSearchable={false} 
                        defaultValue={
                          Contactstatusoption &&
                          Contactstatusoption.filter(
                            (obj) => obj.value === e.status
                          )
                        }
                        value={
                          Contactstatusoption &&
                          Contactstatusoption.filter(
                            (obj) => obj.value === e.status
                          )
                        }
                        menuPlacement="auto"
                        onChange={(event) => {
                          handlestatus(event?.value, e?._id);
                        }}
                      />
                    </td>
                    <td className="text-[#47505F] font-medium font-poppins py-[20px] cursor-pointer">
                      <div className=" flex items-center ml-4">
                        <svg
                          className={`${
                            e.status === "Connected" &&
                            `${" cursor-context-menu fill-black"}`
                          } `}
                          alt="new"
                          onClick={() => {
                            if (e.status !== "Connected") {
                              updatestatus(e?._id);
                            }
                          }}
                          width="24"
                          height="18"
                          viewBox="0 0 24 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            className={`${
                              e.status === "Connected" && `${" fill-black"}`
                            } `}
                            d="M21.8906 0.5625H2.10938C0.948656 0.5625 0 1.50698 0 2.67188V15.3281C0 16.4934 0.949266 17.4375 2.10938 17.4375H21.8906C23.0513 17.4375 24 16.493 24 15.3281V2.67188C24 1.5067 23.0509 0.5625 21.8906 0.5625ZM21.5667 1.96875L12.5449 11.02C12.27 11.2957 11.7301 11.2959 11.4551 11.02L2.43328 1.96875H21.5667ZM1.40625 15.0696V2.93039L7.45617 9L1.40625 15.0696ZM2.43328 16.0312L8.44894 9.996L10.4592 12.0128C11.283 12.8393 12.7174 12.8389 13.5409 12.0128L15.5511 9.99605L21.5667 16.0312H2.43328ZM22.5938 15.0696L16.5438 9L22.5938 2.93039V15.0696Z"
                            fill="#076AFF"
                          />
                        </svg>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className=" md:flex items-center justify-between w-full py-[20px] bg-[#fff] rounded-b-[10px] boxshadow px-[20px] sm:px-[40px] mb-[30px]">
            <div className=" flex items-center mb-5 sm:mb-0">
              <p className="text-[#47505F] font-medium font-[poppins] ">View</p>

              {/* ----------------------select page ------------------------ */}

              <Menu as="div" className="relative text-left">
                <div>
                  <Menu.Button className="flex px-[20px] ml-[24px] min-h-[40px] bg-[#F2F2F2] min-w-[85px] rounded-[8px] justify-between  text-sm font-poppins font-medium  items-center ">
                    <p className="text-[#47505F] font-medium font-[poppins]">
                      {page}
                    </p>
                    <img className="" src={arrowdown} alt="new" />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="bottom-full absolute right-0 z-10 mt-2 py-2 w-[80%] rounded-[12px] bg-white shadoe-sm focus:outline-none">
                    {allvalue?.map((e) => (
                      <div key={e?.id}>
                        <Menu.Item>
                          {({ active }) => (
                            <p
                              className={classNames(
                                active ? "bg-[#DAE9FF] text-[#076aff]" : "",
                                "block px-4 py-2 text-sm cursor-pointer"
                              )}
                              onClick={() => onselectedpage(e)}
                            >
                              {e?.value}
                            </p>
                          )}
                        </Menu.Item>
                      </div>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>

              {/* ----------------------End select page ------------------------ */}
            </div>

            {/* ------------------------- pagination -------------------------*/}
            <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={Itemperpage} // Replace with the total number of pages
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
          forcePage={selectpage}
            />

            {/* -------------------------End  pagination -------------------------*/}
          </div>
        </div>
      ) : (
        <div className=" flex items-center justify-center min-h-[300px] sm:text-[20px] text-[#161616] font-poppins font-medium">
          No VLE Found!!
        </div>
      )}
    </div>
  );
}

export default RequestedVLE;
