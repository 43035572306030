export const actionTypes = {
    ORDER_LIST: "ORDER_LIST",
    ORDER_DETAIL: "ORDER_DETAIL",
    RESET_ORDER_DETAIL: "RESET_ORDER_DETAIL",
    NEW_ORDER: "NEW_ORDER",
    RESET_ORDER: " RESET_ORDER",
    SET_PRE_LOADER : "SET_PRE_LOADER"
  };

  const INITIAL_STATE = {
    orderlist: [],
    neworder: [],
    orderdetail: [],
     Preloader: false,
  };


  export const OrderReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case actionTypes.ORDER_LIST:
            return {
                ...state,
               orderlist: action.orderlist,
            };
    case actionTypes.SET_PRE_LOADER:
                return {
                  ...state,
                  Preloader: action.Preloader,
        };
        case actionTypes.NEW_ORDER:
            return {
                ...state,
               neworder: action.neworder,
               Preloader: action.Preloader,
            };
        case actionTypes.ORDER_DETAIL:
            return {
                ...state,
               orderdetail: action.orderdetail,
            };
        case actionTypes.RESET_ORDER_DETAIL:
            return {
                ...state,
               orderdetail: action.INITIAL_STATE,
            };
        case actionTypes.RESET_ORDER:
            return {
                ...state,
               neworder: action.INITIAL_STATE,
            };
            default:
                return state;
    }
  }

