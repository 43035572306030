import React, { useEffect, useRef, useState } from "react";
import logo from "../images/Headerlogo.svg";
import mobilelogo from "../images/mobilelogo.png";
import heart1 from "../images/heart (1).png";
import cart from "../images/cart.svg";
import polygon from "../images/Polygon 1.svg";
import menu from "../images/menu.svg";
import { Link, useLocation } from "react-router-dom";
import UseOutsideClick from "../components/UseOutsideClick";
import "../App.css";
import {  useDispatch, useSelector } from "react-redux";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { ResetMovieDetail } from "../Redux/Action/MovieDetailAction";
import { actionTypes } from "../Redux/Reducer/MovieDetailReducer";

function Header({ setshow }) {
  const [edit, setedit] = useState(false);
  const [firstname, setfirstname] = useState("");
  const location = useLocation();
  const ref = useRef();
  UseOutsideClick(ref, () => {
    if (edit) {
      setedit(false);
    }
  });
 
  useEffect(() => {
  setfirstname(() => localStorage.getItem('firstname'));
},[])

  const mywishlist = useSelector((state) => state.mywishlist.wishlist?.data);
  const mycart = useSelector((state) => state?.cart?.cartlist?.data);
  const dispatch = useDispatch();

  return (
    <>
      <div className="w-full bg-[#fff] py-3 headershadow sticky top-0 z-[1001]">
        {/* <!----------------- Header starts --------------------------> */}

        <div className="flex main_container items-center justify-between">
          <div className=" lg:hidden">
            <img
              src={menu}
              alt="new"
              className=" cursor-pointer"
              onClick={() => setshow(true)}
            ></img>
          </div>
          <div className=" hidden lg:block">
            <Link to="/">
              <img
                src={logo}
                alt="new"
                className="w-auto h-auto"
              ></img>
            </Link>
          </div>
          <div className="lg:hidden">
            <Link to="/">
              <img
                src={mobilelogo}
                alt="new"
                className="ml-[20px]"
              ></img>
            </Link>
          </div>
          <ul className="items-center hidden lg:flex">
            <li
              className={`text-[18px] text-[#161616] font-medium font-poppins hover:text-[#076AFF] mx-[23px] cursor-pointer`}
            >
              <Link to="/">
                <span
                  className={`${
                    location?.pathname === "/" && `${"text-[#076AFF]"}`
                  }`}
                >
                  Home
                </span>
              </Link>
            </li>
            <li
              className={`text-[18px] text-[#161616] font-medium font-poppins hover:text-[#076AFF] mx-[23px] cursor-pointer`}
              onClick={() =>  dispatch(ResetMovieDetail(actionTypes.RESET_USER_MOVIE_LIST))}
            >
              <Link to="/all-movies">
                <span
                  className={`${
                    location?.pathname === "/all-movies" &&
                    `${"text-[#076AFF]"}`
                  }`}
                >
                  Movies
                </span>
              </Link>
            </li>
            <li
              className={`text-[18px] text-[#161616] font-medium font-poppins hover:text-[#076AFF] mx-[23px] cursor-pointer`}
            >
              <Link to="/about-us">
              <span
                  className={`${
                    location?.pathname === "/about-us" &&
                    `${"text-[#076AFF]"}`
                  }`}
                >
              About Us
              </span>
              </Link>
            </li>
            <li
              className={`text-[18px] text-[#161616] font-medium font-poppins hover:text-[#076AFF] mx-[23px] cursor-pointer ${
                location?.pathname === "/contact-us" && `${"text-[#076AFF]"}`
              }`}
            >
              <Link to='/contact-us'>
              <span
                  className={`${
                    location?.pathname === "/contact-us" &&
                    `${"text-[#076AFF]"}`
                  }`}
                >
                  Contact Us
                </span>
                </Link>
            </li>
          </ul>
          <div className="flex items-center">
            <div className=" relative mr-4 lg:mr-0">
              <Link to="/wishlist">
              <img src={heart1} id="Cart-title" className=" cursor-pointer" alt="new"></img>
              </Link>
              {mywishlist && mywishlist?.count !== 0 && <div className="bg-[#076AFF] text-[8px] flex justify-center items-center w-[14px] h-[14px] rounded-[50%] text-[#fff] absolute -top-[6px] -right-[7px]">
                <p>{mywishlist?.count}</p>
              </div>}
            </div>
            <ReactTooltip
        anchorId="WishList-title"
        place="bottom"
        content="WishList"
        className="  shadow-xl font-poppins font-medium"
        variant="dark"
      />
            <div className=" relative mx-0 lg:mx-7 mr-4">
              <Link to="/cart">
                <img src={cart} id="Cart-title" className=" cursor-pointer" alt="new"></img>
              </Link>
              {mycart && mycart[0]?.movies?.length > 0 && <div className="bg-[#076AFF] text-[8px] flex justify-center items-center w-[14px] h-[14px] rounded-[50%] text-[#fff] absolute -top-[5px] -right-[7px]">
                <p>{mycart && mycart[0]?.count}</p>
              </div>}
            </div>
      
          {localStorage.getItem('token') ?
           
            <div
              onClick={() => setedit(true)}
              className=" relative cursor-pointer hidden lg:block"
            >
             
              <div className=" bg-[#dfdfdf] w-[34px] h-[34px] rounded-[50%] mx-auto flex items-center justify-center">
                <p className="text-[14px] font-semibold  font-poppins">{firstname?.substring(0,1)?.toUpperCase()}</p>
              </div>
             
              <p className="text-[14px] font-normal text-center font-poppins">{firstname?.length > 10 ? <>{firstname.substring(0,10)}..</> : <>{firstname}</>}</p>
              

              {/*------------------------ Edit Profile pop up start ------------------------*/}

              {edit && (
                <div className="relative">
                  <div
                    className="bg-[#fff] shadow-lg rounded-[10px] absolute top-8 -right-2  p-3.5 z-10 min-w-[200px]"
                    ref={ref}
                  >
                    <img
                      src={polygon}
                      className=" absolute -top-[15px] right-4 z-20"
                      alt="new"
                    ></img>
                    <Link to='/myorder'>
                    <div
                      onClick={() => setedit(false)}
                      className="flex  items-center py-2 px-5 hover:bg-[#dae9ff] rounded-[8px] group"
                    >
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="group-hover:fill-[#076AFF]"
                      >
                        <path
                          className="group-hover:fill-[#076AFF]"
                          d="M8.994 18C8.93364 18 8.87418 17.9853 8.82075 17.9572L0.20175 13.4572C0.140973 13.4256 0.0900343 13.3779 0.054466 13.3193C0.0188977 13.2607 6.03138e-05 13.1935 0 13.125L0 4.68748C6.59328e-05 4.62363 0.0164315 4.56086 0.0475462 4.50511C0.0786609 4.44936 0.123494 4.40248 0.177799 4.3689C0.232104 4.33533 0.294081 4.31618 0.35786 4.31326C0.421639 4.31034 0.485106 4.32375 0.54225 4.35223L9.16125 8.66473C9.22346 8.6959 9.27577 8.74377 9.31233 8.80298C9.34888 8.86218 9.36825 8.9304 9.36825 8.99998V17.625C9.36825 17.7243 9.32884 17.8196 9.25868 17.8899C9.18852 17.9602 9.09333 17.9998 8.994 18ZM0.75 12.8977L8.619 17.0062V9.23173L0.75 5.29423V12.8977Z"
                          fill="#47505F"
                        />
                        <path
                          className="group-hover:fill-[#076AFF]"
                          d="M9.00586 18C8.95659 18.0001 8.90778 17.9905 8.86224 17.9716C8.8167 17.9528 8.77532 17.9252 8.74047 17.8904C8.70563 17.8555 8.67801 17.8142 8.6592 17.7686C8.64039 17.7231 8.63076 17.6743 8.63086 17.625V8.99999C8.63086 8.9304 8.65023 8.86219 8.68678 8.80299C8.72334 8.74378 8.77565 8.69591 8.83786 8.66474L17.4569 4.35224C17.514 4.32359 17.5776 4.31004 17.6415 4.31287C17.7054 4.3157 17.7675 4.33482 17.8219 4.36842C17.8763 4.40201 17.9212 4.44896 17.9524 4.50481C17.9835 4.56065 17.9999 4.62354 17.9999 4.68749V13.125C17.9998 13.1935 17.981 13.2607 17.9454 13.3193C17.9098 13.3779 17.8589 13.4256 17.7981 13.4572L9.17911 17.9572C9.12568 17.9853 9.06622 18 9.00586 18ZM9.38086 9.23174V17.0062L17.2499 12.8977V5.29424L9.38086 9.23174Z"
                          fill="#47505F"
                        />
                        <path
                          className="group-hover:fill-[#076AFF]"
                          d="M0.374975 5.06273C0.290532 5.06273 0.208562 5.03422 0.142337 4.98183C0.0761118 4.92944 0.0295078 4.85623 0.0100702 4.77405C-0.00936734 4.69188 -0.000500565 4.60555 0.035235 4.52904C0.0709706 4.45253 0.131483 4.39032 0.206975 4.35248L8.83798 0.0399841C8.89006 0.0140873 8.94743 0.000610352 9.0056 0.000610352C9.06377 0.000610352 9.12114 0.0140873 9.17323 0.0399841L17.7922 4.35248C17.8812 4.39694 17.9489 4.47494 17.9805 4.56932C18.012 4.66369 18.0047 4.76672 17.9602 4.85573C17.9158 4.94475 17.8378 5.01245 17.7434 5.04396C17.649 5.07547 17.546 5.06819 17.457 5.02373L9.00598 0.794484L0.542975 5.02298C0.490848 5.04917 0.433311 5.06279 0.374975 5.06273Z"
                          fill="#47505F"
                        />
                        <path
                          className="group-hover:fill-[#076AFF]"
                          d="M13.5002 11.25C13.4007 11.25 13.3053 11.2105 13.235 11.1401C13.1647 11.0698 13.1252 10.9744 13.1252 10.875V6.98172L4.71318 2.77272C4.66911 2.75065 4.62981 2.72013 4.59753 2.68288C4.56526 2.64563 4.54063 2.60239 4.52507 2.55562C4.5095 2.50885 4.5033 2.45948 4.50682 2.41032C4.51033 2.36116 4.5235 2.31317 4.54556 2.26909C4.56762 2.22502 4.59815 2.18572 4.6354 2.15344C4.67265 2.12116 4.71589 2.09654 4.76265 2.08097C4.80942 2.06541 4.85879 2.05921 4.90796 2.06272C4.95712 2.06624 5.00511 2.0794 5.04918 2.10147L13.6682 6.41397C13.7303 6.4454 13.7826 6.49343 13.8191 6.55274C13.8557 6.61205 13.8751 6.68031 13.8752 6.74997V10.875C13.8752 10.9744 13.8357 11.0698 13.7653 11.1401C13.695 11.2105 13.5996 11.25 13.5002 11.25Z"
                          fill="#47505F"
                        />
                      </svg>
                      <p className="pl-5 font-poppins group-hover:text-[#076AFF] text-[#47505F]">
                        My Orders
                      </p>
                    </div>
                    </Link>
                    <Link to="/login">
                      <div
                        onClick={() => {setedit(false)
                        localStorage.clear();
                        }}
                        className="flex  items-center py-2 hover:bg-[#dae9ff] rounded-[8px] px-5 group"
                      >
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="group-hover:fill-[#076AFF]"
                        >
                          <path
                            className="group-hover:fill-[#076AFF]"
                            d="M13.1818 0.000799158H7.20392C6.9921 0.000799158 6.78896 0.084992 6.63919 0.234856C6.48942 0.384721 6.40527 0.58798 6.40527 0.79992C6.40527 1.01186 6.48942 1.21512 6.63919 1.36498C6.78896 1.51485 6.9921 1.59904 7.20392 1.59904H13.1818C13.855 1.59904 14.4029 2.14484 14.4029 2.8161V13.1839C14.4029 13.8552 13.855 14.4018 13.1818 14.4018H7.20392C6.9921 14.4018 6.78896 14.486 6.63919 14.6358C6.48942 14.7857 6.40527 14.9889 6.40527 15.2009C6.40527 15.4128 6.48942 15.6161 6.63919 15.7659C6.78896 15.9158 6.9921 16 7.20392 16H13.1818C13.9285 15.9998 14.6446 15.7032 15.173 15.1752C15.7014 14.6472 15.9989 13.9311 16.0002 13.1839V2.8161C15.9989 2.06892 15.7014 1.35277 15.173 0.824808C14.6446 0.296847 13.9285 0.000210586 13.1818 0V0.000799158Z"
                            fill="#47505F"
                          />
                          <path
                            className="group-hover:fill-[#076AFF]"
                            d="M2.63312 8.8015H10.3696C10.5814 8.8015 10.7845 8.71731 10.9343 8.56744C11.0841 8.41758 11.1682 8.21432 11.1682 8.00238C11.1682 7.79044 11.0841 7.58718 10.9343 7.43731C10.7845 7.28745 10.5814 7.20326 10.3696 7.20326H2.6491L5.09454 4.59013C5.1694 4.51419 5.2283 4.42402 5.26778 4.32494C5.30726 4.22585 5.32652 4.11987 5.32442 4.01322C5.32232 3.90658 5.2989 3.80143 5.25554 3.70398C5.21219 3.60654 5.14978 3.51876 5.07198 3.44583C4.99419 3.3729 4.90259 3.31629 4.80259 3.27934C4.70259 3.24239 4.59621 3.22584 4.48971 3.23068C4.38322 3.23552 4.27877 3.26164 4.18253 3.3075C4.08628 3.35337 4.00019 3.41805 3.92932 3.49773L0.256364 7.42222C0.168811 7.50418 0.101075 7.60502 0.0583008 7.71709C0.0375362 7.76904 0.0343417 7.82417 0.0255566 7.87851C0.0183688 7.92087 0 7.95843 0 8.00238L0.00159726 8.00717C-0.000282543 8.21177 0.0766528 8.40923 0.216432 8.55857L3.93012 12.5254C4.00153 12.6022 4.08742 12.6642 4.18284 12.7077C4.27827 12.7512 4.38135 12.7754 4.48615 12.7789C4.59096 12.7824 4.69542 12.7651 4.79352 12.728C4.89163 12.691 4.98144 12.6349 5.0578 12.563C5.1344 12.4912 5.19611 12.405 5.23942 12.3094C5.28272 12.2138 5.30677 12.1105 5.31018 12.0056C5.31359 11.9007 5.2963 11.7961 5.2593 11.6978C5.2223 11.5996 5.16631 11.5096 5.09454 11.433L2.63312 8.8015Z"
                            fill="#47505F"
                          />
                        </svg>
                        <p className="pl-5 font-poppins group-hover:text-[#076AFF] text-[#47505F]">
                          Log out
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
              )}
              {/*----------------------- Edit Profile pop up End --------------------------*/}
            </div>
            : <Link to={'/login'}><button className="bg-[#076aff] text-white text-[14px] sm:text-[16px] rounded-[8px] py-1 px-2 sm:px-4 font-poppins">Login</button></Link>
          }
          </div>
        </div>
        {/* <!-- Header ends --> */}
      </div>
    </>
  );
}

export default Header;
