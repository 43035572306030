import axios from "axios";
import { Flip, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const actionTypes = {
  SET_LOGIN: "SET_LOGIN",
  SET_REGISTER: "SET_REGISTER",
  RESET_PIN : 'RESET_PIN',
  SET_PIN : 'SET_PIN',
  SET_NEW_PIN : 'SET_NEW_PIN',
  GENERATE_NEW_PIN : 'GENERATE_NEW_PIN',
  FORGOT_RESET_PIN : 'FORGOT_RESET_PIN',
  FIRST_PIN : 'FIRST_PIN',

};

// login
export const Userlogin = (contact, pin, history) => {
  const Data = {
    contact: contact,
    pin: pin,
  };
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/login`, Data, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.SET_LOGIN,
          login: response?.data,
        });
        if(response.data?.data?.roleID === 1 || response?.data?.data?.roleID === 2){
        history("/admin/vle");
        }else if(response?.data?.data?.roleID === 3){
        history("/admin/producer-movies");
        }else if(response?.data?.data?.roleID === 4){
          history("/");
        }
        localStorage.setItem("token",response?.data?.data?.token);
        localStorage.setItem("role",response?.data?.data?.role);
        localStorage.setItem("firstname",response?.data?.data?.firstname);
        toast.success(response?.data?.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
      })
      .catch((err) => toast.error(err?.response?.data?.error, {
        transition: Flip,
        theme: "colored",
        autoClose: 500,
      }));
  };
};

// register
export const UserRegister = (firstname, lastname, email, contact, role) => {
  const Data = {
    firstname: firstname,
    lastname: lastname,
    emailID: email,
    contact: contact,
    role: role,
  };
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/user/request`, Data, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.SET_REGISTER,
          register: response.data,
        });
      })
      .catch((err) =>  
      toast.error(err.response.data.error, {
        transition: Flip,
        theme: "colored",
        autoClose: 500,
      }));
  };
};


export const reset = (RESET) => {
  return {
    type: RESET,
  };
};



// Set Pin
export const SetPin = (data) => {
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/setpin`, data, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.SET_PIN,
          setpin: response.data,
        });
        toast.success(response?.data?.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 2000,
        })
      })
      .catch((err) =>  
      toast.error(err.response.data.error, {
        transition: Flip,
        theme: "colored",
        autoClose: 2000,
      }));
  };
};
export const SetNewPin = (data) => {
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/setpin`, data, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.SET_NEW_PIN,
          setnewpin: response.data,
        });
        toast.success(response?.data?.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 2000,
        })
      })
      .catch((err) =>  
      toast.error(err.response.data.error, {
        transition: Flip,
        theme: "colored",
        autoClose: 2000,
      }));
  };
};
export const SetGeneratePin = (data) => {
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/setpin`, data, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.GENERATE_NEW_PIN,
          generatenewpin: response.data,
        });
        toast.success(response?.data?.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 2000,
        })
      })
      .catch((err) =>  
      toast.error(err.response.data.error, {
        transition: Flip,
        theme: "colored",
        autoClose: 2000,
      }));
  };
};

// Reset Pin
export const ResetPin = (data) => {
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/resetpin`, data, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        toast.success(response?.data?.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 2000,
        })
        dispatch({
          type: actionTypes.FIRST_PIN,
          firstpin: response.data,
        });
      })
      .catch((err) =>  
      toast.error(err.response.data.error, {
        transition: Flip,
        theme: "colored",
        autoClose: 2000,
      }));
  };
};

// Reset Pin
export const SubmitResetPin = (data) => {
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/resetpin`, data, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.RESET_PIN,
          resetpin: response.data,
        });
        toast.success(response?.data?.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        })
      })
      .catch((err) =>  
      toast.error(err.response.data.error, {
        transition: Flip,
        theme: "colored",
        autoClose: 500,
      }));
  };
};
// Reset Pin
export const ConfirmResetPin = (data) => {
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/resetpin`, data, {
        headers: {
          Accept: "application/json",
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.FORGOT_RESET_PIN,
          forgotresetpin: response.data,
        });
        toast.success(response?.data?.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 2000,
        })
      })
      .catch((err) =>  
      toast.error(err.response.data.error, {
        transition: Flip,
        theme: "colored",
        autoClose: 2000,
      }));
  };
};

