import axios from "axios";
import Loginmodal from "../../Layout/Loginmodal";
import Swal from "sweetalert2";

export const actionTypes = {
  MOVIES_LIST: "MOVIES_LIST",
  MOVIES_ERROR: "MOVIES_ERROR",

};

//  MOVIES list

export const GetHomeMovielist = (history) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/home`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.MOVIES_LIST,
          movieslist: response.data,
        });
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
        }
      });
  };
};


export const resetERR = (RESET) => {
  return {
    type: RESET,
  };
};