import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import arrow from "../../images/rightarr.png";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { useDispatch, useSelector } from "react-redux";
import { GetProducerDetail } from "../../Redux/Action/ProducerAction";

function ProducerDetail() {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Address, setAddress] = useState("");
  const [Country, setCountry] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [ZipCode, setZipCode] = useState("");
  const [Aadharno, setAadharno] = useState("");
  const [Panno, setPanno] = useState("");
  const [gstno, setgstno] = useState("");
  const [smallimages, setsmallImages] = useState("");
  const [Panimages, setPanImages] = useState("");
  const [Gstimages, setGstImages] = useState("");
  const producerdetail = useSelector(
    (state) => state?.producer?.producerdetail?.data?.producer
  );

  useEffect(() => {
    setFirstName(producerdetail?.firstname);
    setLastName(producerdetail?.lastname);
    setEmail(producerdetail?.emailID);
    setMobile(producerdetail?.contact);
    setAddress(producerdetail?.address);
    setCountry(producerdetail?.country);
    setState(producerdetail?.state);
    setCity(producerdetail?.city);
    setZipCode(producerdetail?.pincode);
    setAadharno(producerdetail?.aadharcardno);
    setPanno(producerdetail?.pancardno);
    setgstno(producerdetail?.gstcertificateno);
    setsmallImages(
      `${`https://hooc.b-cdn.net/${
        producerdetail?.aadharcard
      }?t=${Date.now()}`}`
    );
    setPanImages(
      `${`https://hooc.b-cdn.net/${producerdetail?.pancard}?t=${Date.now()}`}`
    );
    setGstImages(
      `${`https://hooc.b-cdn.net/${
        producerdetail?.gstcertificate
      }?t=${Date.now()}`}`
    );
  }, [producerdetail]);

  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  useEffect(() => {
    dispatch(GetProducerDetail(id , history));
  }, []);
  return (
    <div>
      <div className="pt-[20px]">
        <div className="flex items-center pb-[7px]">
          <Link to="/admin/producer">
            <p className=" text-[#076aff] cursor-pointer text-[18px] font-medium font-poppins">
              PRODUCER
            </p>
          </Link>
          <img src={arrow} alt="new" className="mx-3"></img>
          <p className=" text-[#161616] text-[18px] font-medium font-poppins">
            Producer Details
          </p>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-x-4 lg:gap-x-7 mt-[20px] sm:mt-[30px]">
        <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
          <p className="text-base text-[#161616] font-medium font-poppins">
            First Name
          </p>
          <input
            className="h-[46px] rounded-[5px] border border-[#6D6E70]  bg-[#fff] pl-5 mt-3 w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
            value={FirstName}
            onChange={(e) => setFirstName(e.target.value)}
            disabled
          ></input>
        </div>
        <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
          <p className="text-base text-[#161616] font-medium font-poppins">
            Last Name
          </p>
          <input
            className="h-[46px] rounded-[5px] border border-[#6D6E70]  bg-[#fff] pl-5 mt-3 w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
            value={LastName}
            onChange={(e) => setLastName(e.target.value)}
            disabled
          ></input>
        </div>
        <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
          <p className="text-base text-[#161616] font-medium font-poppins">
            Email
          </p>
          <input
            className="h-[46px] rounded-[5px] border border-[#6D6E70]  pl-5 mt-3 bg-[#fff] w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
            disabled
          ></input>
        </div>
        <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
          <p className="text-base text-[#161616] font-medium font-poppins">
            Mobile No
          </p>
          <input
            className="h-[46px] rounded-[5px] pl-5 border border-[#6D6E70]  mt-3 w-full bg-[#fff] placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
            value={Mobile}
            onChange={(e) => setMobile(e.target.value)}
            disabled
          ></input>
        </div>
        <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
          <p className="text-base text-[#161616] font-medium font-poppins">
            Address
          </p>
          <input
            className="h-[46px] rounded-[5px] pl-5 border border-[#6D6E70]  mt-3 w-full bg-[#fff] placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
            value={Address}
            onChange={(e) => setAddress(e.target.value)}
            disabled
          ></input>
        </div>
        <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
          <p className="text-base text-[#161616] font-medium font-poppins">
            Country
          </p>
          <input
            className="h-[46px] rounded-[5px] pl-5 mt-3 border border-[#6D6E70]  w-full bg-[#fff] placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
            value={Country}
            onChange={(e) => setCountry(e.target.value)}
            disabled
          ></input>
        </div>
        <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
          <p className="text-base text-[#161616] font-medium font-poppins">
            State
          </p>
          <input
            className="h-[46px] rounded-[5px] pl-5 mt-3 border border-[#6D6E70]  w-full bg-[#fff] placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
            value={State}
            onChange={(e) => setState(e.target.value)}
            disabled
          ></input>
        </div>
        <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-0">
          <p className="text-base text-[#161616] font-medium font-poppins">
            City
          </p>
          <input
            className="h-[46px] rounded-[5px] pl-5 mt-3 border border-[#6D6E70]  w-full bg-[#fff] placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
            value={City}
            onChange={(e) => setCity(e.target.value)}
            disabled
          ></input>
        </div>
        <div className=" col-span-12 sm:col-span-4 ">
          <p className="text-base text-[#161616] font-medium font-poppins">
            Zip Code
          </p>
          <input
            className="h-[46px] rounded-[5px] pl-5 mt-3 border border-[#6D6E70]  w-full bg-[#fff] placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
            value={ZipCode}
            onChange={(e) => setZipCode(e.target.value)}
            disabled
          ></input>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-x-4 lg:gap-x-7 mt-[20px] sm:mt-[0px] pb-[30px] sm:pb-0">
        <Gallery>
          <div className="col-span-12 sm:col-span-4 ">
            <div className="  mb-[20px]  object-cover overflow-hidden  flex items-center justify-center cursor-pointer rounded-[10px]">
              <Item original={smallimages} width="700" height="400">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={smallimages}
                    alt="new"
                    className=" w-full h-[150px] xl:h-[180px]"
                  />
                )}
              </Item>
            </div>
            <div className=" col-span-12 mb-[20px] sm:mb-0 sm:col-span-4 ">
              <input
                className="h-[46px] rounded-[5px] border border-[#6D6E70]  bg-[#fff] pl-5  w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
                value={Aadharno}
                onChange={(e) => setAadharno(e.target.value)}
                disabled
              ></input>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-4 ">
            <div className=" mb-[20px]   object-cover overflow-hidden  flex items-center justify-center cursor-pointer rounded-[10px]">
              <Item original={Panimages} width="700" height="400">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={Panimages}
                    alt="new"
                    className=" w-full h-[150px] xl:h-[180px]"
                  />
                )}
              </Item>
            </div>
            <div className=" col-span-12 mb-[20px] sm:mb-0 sm:col-span-4 ">
              <input
                className="h-[46px] rounded-[5px] pl-5 border border-[#6D6E70]   bg-[#fff] w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
                value={Panno}
                onChange={(e) => setPanno(e.target.value)}
                disabled
              ></input>
            </div>
          </div>
          <div className="col-span-12 sm:col-span-4  ">
            <div className="object-cover overflow-hidden  flex items-center justify-center cursor-pointer rounded-[10px]">
              <Item original={Gstimages} width="700" height="400">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    src={Gstimages}
                    alt="new"
                    className=" w-full h-[150px] xl:h-[180px]"
                  />
                )}
              </Item>
            </div>
            <div className=" col-span-12  sm:col-span-4 mt-[20px]">
              <input
                className="h-[46px] rounded-[5px] pl-5 border border-[#6D6E70]   bg-[#fff] w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
                value={gstno}
                onChange={(e) => setgstno(e.target.value)}
                disabled
              ></input>
            </div>
          </div>
        </Gallery>
      </div>
    </div>
  );
}

export default ProducerDetail;
