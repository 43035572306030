import axios from "axios";
import { Flip, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";


export const actionTypes = {
  VLE_LIST: "VLE_LIST",
  ALL_VLE_LIST: "ALL_VLE_LIST",
  VLE_DETAIL: "VLE_DETAIL",
  DELETE_VLE_LIST: "DELETE_VLE_LIST",
  ADD_VLE: "ADD_VLE",

};

// requested vle list

export const GetRequestVlelist = (query , history) => {
  const token = localStorage.getItem('token')

  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/vle/request`,{
        params : { ...query },
        headers : {
          'Authorization' : token
        }
      })
      .then((response) => {
        dispatch({
          type: actionTypes.VLE_LIST,
          requestvlelist: response.data,
        });
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};

// Update request vle

export const UpdateVle = (requestID , newdata , history) => {
  const token = localStorage.getItem('token')

  return async (dispatch) => {
    await axios
      .patch(`${process.env.REACT_APP_API_BASE_URL}/vle/request/${requestID}`,'', {
        headers: {
          'Authorization' : token
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        dispatch(GetRequestVlelist(newdata));
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};

// vle list

export const GetVlelist = (query , history) => {
  const token = localStorage.getItem('token')
     
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/vle`, {
        params: { ...query },
        headers: {
          "Authorization": token,
        }
      },
       )
      .then((response) => {
        dispatch({
          type: actionTypes.ALL_VLE_LIST,
          vlelist: response.data,
        });
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};

// Create vle

export const CreateNewVle = (
  firstname,
  lastname,
  emailID,
  contact,
  address,
  country,
  state,
  city,
  pincode,
  file,
  file1,
  file2,
  file3,
  aadharcardno,
  pancardno,
  gstcertificateno,history
) => {
  var data = new FormData();
  var fileAadhar = new File([file && file[0]], file && file[0].name, {
    type: "application/json",
  });
  var filePan = new File([file1 && file1[0]], file1 && file1[0].name, {
    type: "application/json",
  });
  var fileGst = new File([file2 && file2[0]], file2 && file2[0].name, {
    type: "application/json",
  });
  var fileCerti = new File([file3 && file3[0]], file3 && file3[0].name, {
    type: "application/json",
  });

  data.append("firstname", firstname);
  data.append("lastname", lastname);
  data.append("emailID", emailID);
  data.append("contact", contact);
  data.append("address", address);
  data.append("country", country);
  data.append("state", state);
  data.append("city", city);
  data.append("pincode", pincode);
  data.append("aadharcard", fileAadhar);
  data.append("pancard", filePan);
  data.append("gst", fileGst);
  data.append("license", fileCerti);
  data.append("aadharcardno", aadharcardno);
  data.append("pancardno", pancardno);
  data.append("gstcertificateno", gstcertificateno);
  const token = localStorage.getItem('token')

  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/vle`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization' : token
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        dispatch({
          type: actionTypes.ADD_VLE,
          addvle: response.data,
        });
        history('/admin/vle')
        // dispatch(GetVlelist(''));
      })
      .catch((err) =>
        toast.error(err.response.data.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        })
      );
  };
};

// update vle list

export const UpdateVlelist = (data, id , newdata , history) => {
  // const data = {
  //   status: status,
  // };
  const token = localStorage.getItem('token')

  return async (dispatch) => {
    await axios
      .patch(`${process.env.REACT_APP_API_BASE_URL}/vle/${id}`, data, {
        headers: {
          Accept: "application/json",
          'Authorization' : token
        },
      })
      .then((response) => {
        dispatch(GetVlelist(newdata));
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};

// Delete vle

export const DeleteVle = (id , data , history) => {
  const token = localStorage.getItem('token')
  return async (dispatch) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/vle/${id}`, {
        headers: {
          Accept: "application/json",
          'Authorization' : token
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        dispatch(GetVlelist(data));
        dispatch({
          type: actionTypes.DELETE_VLE_LIST,
          deletevlelist: response.data,
        });
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};

// vle Detail

export const GetVleDetail = (id , history) => {
  const token = localStorage.getItem('token')

  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/vle/${id}` , {
        headers : {
          'Authorization' : token
        }
      })
      .then((response) => {
        dispatch({
          type: actionTypes.VLE_DETAIL,
          vledetail: response.data,
        });
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};
