import React, { useEffect, useState } from "react";
import upload from "../../images/upload.png";
import dates from "../../images/date.png";
import price from "../../images/ruppee.png";
import discount from "../../images/precentage.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import arrow from "../../images/rightarr.png";
import "../../App.css";
import { useDispatch, useSelector } from "react-redux";
import {
  EditProducersMovies,
  GetActorlist,
  GetGenrelist,
  GetLanguagelist,
  GetProducerMovieDetail,
  UpdateMovie,
  UpdateProducerMovie,
  UpdateProducersMovies,
} from "../../Redux/Action/MovieAction";

function EditProducerMovies() {
  const [Moviename, setMoviename] = useState("");
  const [show, setshow] = useState();
  const [Discount, setDiscount] = useState("");
  const [Price, setPrice] = useState("");
  const [Duration, setDuration] = useState("");
  const [Moviedesc, setMoviedesc] = useState("");
  const [trailerlink, settrailerlink] = useState("");
  const [Language, setLanguage] = useState("");
  const [Languageoption, setLanguageoption] = useState("");
  const [Genre, setGenre] = useState([]);
  const [Genreoption, setGenreoption] = useState("");
  const [Actor, setActor] = useState("");
  const [Actoroption, setActoroption] = useState("");
  const [isabanneredit, setisabanneredit] = useState(false);
  const [isposteredit, setisposteredit] = useState(false);
  const [isimageedit, setisimageedit] = useState(false);
  const [isimageedit1, setisimageedit1] = useState(false);
  const [isimageedit2, setisimageedit2] = useState(false);
  const [isimageedit3, setisimageedit3] = useState(false);
  const [Hours, setHours] = useState("");
  const [Minutes, setMinutes] = useState("");
  const [check, setcheck] = useState("");
  // Banner image
  const [images, setImages] = useState([]);
  const [imageURLs, setImagesURLs] = useState([]);

  const [imageBannerData, setImageBannerData] = useState([]);
  const [newbanner, setnewbanner] = useState([]);
  const [bannerErrorMessage, setBannerErrorMessage] = useState("");

  const [imageUploadData, setImageUploadData] = useState([]);
  const [newUpload, setNewUpload] = useState([]);
  const [uploadErrorMessage, setUploadErrorMessage] = useState("");

  const [imageUploadData1, setImageUploadData1] = useState([]);
  const [newUpload1, setNewUpload1] = useState([]);
  const [uploadErrorMessage1, setUploadErrorMessage1] = useState("");

  const [imageUploadData2, setImageUploadData2] = useState([]);
  const [newUpload2, setNewUpload2] = useState([]);
  const [uploadErrorMessage2, setUploadErrorMessage2] = useState("");

  const [imageUploadData3, setImageUploadData3] = useState([]);
  const [newUpload3, setNewUpload3] = useState([]);
  const [uploadErrorMessage3, setUploadErrorMessage3] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const width = image.width;
        const height = image.height;
        const size = file.size;

        if (width === 1440 && height === 500 && size <= 1000000) {
          // Image meets the conditions, proceed with handling the image data
          setImageBannerData(reader.result);
          setBannerErrorMessage("");
        } else {
          // Image does not meet the conditions, show an error message
          setnewbanner([]);
          setBannerErrorMessage(
            "Image dimensions must be 1440x500 and size less than 1MB."
          );
        }
      };
    };

    if (file) {
      reader.readAsDataURL(file);
    }
    // setImageBannerData(file);
    setnewbanner([...event.target.files]);
  };

  // poster image
  const [smallimages, setsmallImages] = useState([]);
  const [smallimageURLs, setsmallImagesURLs] = useState([]);

  const [imagePosterData, setImagePosterData] = useState([]);
  const [newPoster, setNewPoster] = useState([]);
  const [posterErrorMessage, setPosterErrorMessage] = useState("");

  const handlePosterChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const width = image.width;
        const height = image.height;
        const size = file.size;

        if (width === 300 && height === 320 && size <= 1000000) {
          setImagePosterData(reader.result);
          setPosterErrorMessage("");
        } else {
          setNewPoster([]);
          setPosterErrorMessage(
            "Image dimensions must be 300x320 and size less than 1MB."
          );
        }
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setNewPoster([...event.target.files]);
  };

  // upload image
  const [uploadimages, setuploadimages] = useState([]);
  const [uploadimagesURLs, setuploadimagesURLs] = useState([]);

  const [startDate, setStartDate] = useState("");
  // const [selectedOptions, setSelectedOptions] = useState([]);

  const handlecheck = () => {
    setcheck(!check);
  };

  useEffect(() => {
    if (newbanner && newbanner.length > 0) {
      setisabanneredit(true);
    } else {
      setisabanneredit(false);
    }
  }, [newbanner]);

  useEffect(() => {
    if (newPoster && newPoster.length > 0) {
      setisposteredit(true);
    } else {
      setisposteredit(false);
    }
  }, [newPoster]);

  useEffect(() => {
    if (newUpload && newUpload.length > 0) {
      setisimageedit(true);
    } else {
      setisimageedit(false);
    }
  }, [newUpload]);
  useEffect(() => {
    if (newUpload1 && newUpload1.length > 0) {
      setisimageedit1(true);
    } else {
      setisimageedit1(false);
    }
  }, [newUpload1]);
  useEffect(() => {
    if (newUpload2 && newUpload2.length > 0) {
      setisimageedit2(true);
    } else {
      setisimageedit2(false);
    }
  }, [newUpload2]);
  useEffect(() => {
    if (newUpload3 && newUpload3.length > 0) {
      setisimageedit3(true);
    } else {
      setisimageedit3(false);
    }
  }, [newUpload3]);

  //  console.log(images)
  //   check box for select
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "transparent";
    if (isActive) bg = "#DAE9FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input
          type="checkbox"
          defaultChecked={isSelected}
          className="mr-3 cursor-pointer w-[18px] h-[18px]"
          style={{ accentColor: "#028f3b" }}
        />
        {children}
      </components.Option>
    );
  };

  //------------------------ for Banner image----------------------------

  let errorsObj = { image: "", posterimage: "", uploadimage: "" };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    if (images?.length < 1) return;
    const newImageURLs = [];
    images.forEach((image) => newImageURLs.push(URL.createObjectURL(image)));
    setImagesURLs(newImageURLs);
  }, [images]);

  const onimagechange = (e) => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (e.target.files[0].size > 5000000) {
      errorObj.image = "Image Size must be less than 5MB.";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setImages([...e.target.files]);
  };

  //------------------------ for Poster image----------------------------

  useEffect(() => {
    if (smallimages?.length < 1) return;
    const newImageURLs = [];
    smallimages.forEach((image) =>
      newImageURLs.push(URL.createObjectURL(image))
    );
    setsmallImagesURLs(newImageURLs);
  }, [smallimages]);

  const onsmallimagechange = (e) => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (e?.target?.files[0]?.size > 5000000) {
      errorObj.posterimage = "Image Size must be less than 5MB.";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setsmallImages([...e.target.files]);
  };

  //-------------------- for upload images-------------------------------------

  useEffect(() => {
    if (uploadimages?.length < 1) return;
    const newImageURLs = [];
    uploadimages.forEach((image) =>
      newImageURLs.push(URL.createObjectURL(image))
    );
    setuploadimagesURLs(newImageURLs);
  }, [uploadimages]);

  const onuploadimagechange = (e) => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (e?.target?.files[0]?.size > 5000000) {
      errorObj.uploadimage = "Image Size must be less than 5MB.";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setuploadimages([...e.target.files]);
  };

  //--------------------style for select--------------------------------

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: "#6D6E70 !important",
      boxShadow: "none",
      minHeight: 60,
    }),
    menu: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      zIndex: 9999,
    }),
    menuList: (base) => ({
      ...base,
      height: "228px !important",
      "::-webkit-scrollbar": {
        width: "5px",
      },
      "::-webkit-scrollbar-track": {
        background: "#fff",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#DAE9FF",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#DAE9FF",
      },
    }),
    option: (provided) => ({
      ...provided,
      color: "#979797 !important",
      fontWeight: 500,
      cursor: "pointer",
      borderBottom: 1,
      paddingTop: 10,
      paddingBottom: 10,
      borderColor: "#DAE9FF",
      borderStyle: "solid",
      ":hover": {
        backgroundColor: "#DAE9FF !important",
        color: "#076AFF !important",
      },
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#DAE9FF",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#076AFF",
      fontWeight: 500,
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#47505F",
      ":hover": {
        backgroundColor: "#47505F",
        color: "white",
      },
    }),
  };

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const moviedetails = useSelector(
    (state) => state?.movie?.producermoviedetail?.data?.movie
  );
  const nwetime = moviedetails && new Date(moviedetails?.release);

  let unix_timestamp = Number(moviedetails?.duration) * Number(60);
  var date = new Date(unix_timestamp * 1000);

  var hours = date.getUTCHours();
  var minutes = date.getUTCMinutes();

  useEffect(() => {
    setDuration(Number(Hours * 60) + Number(Minutes));
  }, [Hours, Minutes]);

  const handleUploadChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const width = image.width;
        const height = image.height;
        const size = file.size;

        if (width === 280 && height === 240 && size <= 1000000) {
          setImageUploadData(reader.result);
          setUploadErrorMessage("");
        } else {
          setNewUpload([]);
          setUploadErrorMessage(
            "Image dimensions must be 280x240 and size less than 1MB."
          );
        }
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setNewUpload([...event.target.files]);
  };
  const handleUploadChange1 = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const width = image.width;
        const height = image.height;
        const size = file.size;

        if (width === 280 && height === 240 && size <= 1000000) {
          setImageUploadData1(reader.result);
          setUploadErrorMessage1("");
        } else {
          setNewUpload1([]);
          setUploadErrorMessage1(
            "Image dimensions must be 280x240 and size less than 1MB."
          );
        }
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setNewUpload1([...event.target.files]);
  };
  const handleUploadChange2 = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const width = image.width;
        const height = image.height;
        const size = file.size;

        if (width === 280 && height === 240 && size <= 1000000) {
          setImageUploadData2(reader.result);
          setUploadErrorMessage2("");
        } else {
          setNewUpload2([]);
          setUploadErrorMessage2(
            "Image dimensions must be 280x240 and size less than 1MB."
          );
        }
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setNewUpload2([...event.target.files]);
  };
  const handleUploadChange3 = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const width = image.width;
        const height = image.height;
        const size = file.size;

        if (width === 280 && height === 240 && size <= 1000000) {
          setImageUploadData3(reader.result);
          setUploadErrorMessage3("");
        } else {
          setNewUpload3([]);
          setUploadErrorMessage3(
            "Image dimensions must be 280x240 and size less than 1MB."
          );
        }
      };
    };
    if (file) {
      reader.readAsDataURL(file);
    }
    setNewUpload3([...event.target.files]);
  };

  useEffect(() => {
    setGenre(moviedetails?.genre);
    setMoviename(moviedetails?.name);
    setMoviedesc(moviedetails?.description);
    setshow(moviedetails?.minshow);
    settrailerlink(moviedetails?.trailer);
    setPrice(moviedetails?.price);
    setDiscount(moviedetails?.discount_);
    setStartDate(nwetime);
    setHours(hours);
    setMinutes(minutes);
    setLanguage(moviedetails?.language);
    setActor(moviedetails?.actor);
    setcheck(moviedetails?.ispopular);
    setImageBannerData(
      `${`https://hooc.b-cdn.net/${moviedetails?.banner_image}?t=${Date.now()}`}`
    );
    setImagePosterData(
      `${`https://hooc.b-cdn.net/${moviedetails?.poster_image}?t=${Date.now()}`}`
    );
    if (moviedetails?.image1) {
      setImageUploadData(`${`https://hooc.b-cdn.net/${moviedetails?.image1}?t=${Date.now()}`}`);
    }
    if (moviedetails?.image2) {
      setImageUploadData1(
        `${`https://hooc.b-cdn.net/${moviedetails?.image2}?t=${Date.now()}`}`
      );
    }
    if (moviedetails?.image3) {
      setImageUploadData2(
        `${`https://hooc.b-cdn.net/${moviedetails?.image3}?t=${Date.now()}`}`
      );
    }
    if (moviedetails?.image4) {
      setImageUploadData3(
        `${`https://hooc.b-cdn.net/${moviedetails?.image4}?t=${Date.now()}`}`
      );
    }
  }, [moviedetails]);

  const languagelist = useSelector((state) => state.movie.languagelist.data);
  const genrelist = useSelector((state) => state.movie.genrelist.data);
  const actorlist = useSelector((state) => state.movie.actorlist.data);

  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    dispatch(GetProducerMovieDetail(id, history));
  }, []);

  useEffect(() => {
    dispatch(GetLanguagelist());
  }, []);
  useEffect(() => {
    dispatch(GetGenrelist());
  }, []);
  useEffect(() => {
    dispatch(GetActorlist());
  }, []);

  // language list

  useEffect(() => {
    const con =
      languagelist && languagelist?.map((c) => ({ value: c, label: c }));
    setLanguageoption(con);
  }, [languagelist]);

  useEffect(() => {
    const con = genrelist && genrelist?.map((c) => ({ value: c, label: c }));
    setGenreoption(con);
  }, [genrelist]);

  useEffect(() => {
    const con =
      actorlist && actorlist?.map((c) => ({ value: c._id, label: c.actor }));
    setActoroption(con);
  }, [actorlist]);

  const newurl = uploadimagesURLs?.map((e) =>
    e?.replace("https://hooc.b-cdn.net/", "")
  );
 
  const updatnewmovie = (e) => {

    e?.preventDefault();
    if(role === "producer"){
      dispatch(
        EditProducersMovies(
  
          newbanner,
          newPoster,
          newUpload,
          newUpload1,
          newUpload2,
          newUpload3,
          Moviename,
          Moviedesc,
          Language,
          Actor,
          Genre,
          trailerlink,
          Math.floor(new Date(startDate).getTime()),
          Duration,
          isabanneredit,
          isposteredit,
          isimageedit,
          isimageedit1,
          isimageedit2,
          isimageedit3,
          id,
          history
        )
      );
    }else{
    dispatch(
      UpdateProducersMovies(

        newbanner,
        newPoster,
        newUpload,
        newUpload1,
        newUpload2,
        newUpload3,
        Moviename,
        Moviedesc,
        Language,
        Actor,
        Genre,
        trailerlink,
        Math.floor(new Date(startDate).getTime()),
        Duration,
        Price,
        Discount,
        show,
        isabanneredit,
        isposteredit,
        isimageedit,
        isimageedit1,
        isimageedit2,
        isimageedit3,
        id,
        history
      )
    );
  }
  };
  

  const role = localStorage.getItem("role");

  return (
    <div className="pt-[20px] pb-[50px]">
      {/* <ToastContainer /> */}
      <div className=" flex items-center pb-[7px]">
        <Link to={"/admin/producer-movies"}>
          <p className=" text-[#076aff] cursor-pointer text-[18px] font-medium font-poppins">
            MOVIES
          </p>
        </Link>
        <img src={arrow} alt="new" className="mx-3"></img>
        <p className=" text-[#161616] text-[18px] font-medium font-poppins">
          Edit Movie
        </p>
      </div>
      <div className="mt-[15px] sm:mt-[20px]">
        {/*---------------------- Movie Form --------------------------------------*/}

        <form
          className="grid grid-cols-12 gap-x-5 xl:gap-x-9 w-full"
          onSubmit={updatnewmovie}
        >
          <div className="w-full col-span-12 md:col-span-6">
            {/*---------------------- Upload Banner Image --------------------------------------*/}

            <label htmlFor="inputTag">
              <div className="bg-[#fff] h-[170px] border border-dashed border-[#DAE9FF] flex items-center justify-center cursor-pointer overflow-hidden  rounded-[10px]">
                <input
                  id="inputTag"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  // onChange={onimagechange}
                  onChange={handleImageChange}
                />
                {imageBannerData?.length > 0 ? (
                  <div className="w-full h-full relative group">
                    <img
                      src={imageBannerData}
                      className="w-full h-full"
                      alt="new"
                    ></img>
                    <div className=" absolute top-0 left-0 right-0 bottom-0 ">
                      <div className="hidden group-hover:block absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%]">
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className=" mx-auto"
                        >
                          <path
                            d="M26.5 15.25C26.1685 15.25 25.8505 15.3817 25.6161 15.6161C25.3817 15.8505 25.25 16.1685 25.25 16.5V21.7662C25.249 22.6899 24.8817 23.5754 24.2285 24.2285C23.5754 24.8817 22.6899 25.249 21.7662 25.25H6.23375C5.31011 25.249 4.42458 24.8817 3.77146 24.2285C3.11835 23.5754 2.75099 22.6899 2.75 21.7662V16.5C2.75 16.1685 2.6183 15.8505 2.38388 15.6161C2.14946 15.3817 1.83152 15.25 1.5 15.25C1.16848 15.25 0.850537 15.3817 0.616116 15.6161C0.381696 15.8505 0.25 16.1685 0.25 16.5V21.7662C0.251654 23.3527 0.882615 24.8738 2.00443 25.9956C3.12624 27.1174 4.64727 27.7483 6.23375 27.75H21.7662C23.3527 27.7483 24.8738 27.1174 25.9956 25.9956C27.1174 24.8738 27.7483 23.3527 27.75 21.7662V16.5C27.75 16.1685 27.6183 15.8505 27.3839 15.6161C27.1495 15.3817 26.8315 15.25 26.5 15.25Z"
                            fill="#fff"
                          />
                          <path
                            d="M7.38353 9.88373L12.7498 4.51748V20.25C12.7498 20.5815 12.8815 20.8994 13.1159 21.1339C13.3503 21.3683 13.6683 21.5 13.9998 21.5C14.3313 21.5 14.6492 21.3683 14.8837 21.1339C15.1181 20.8994 15.2498 20.5815 15.2498 20.25V4.51748L20.616 9.88373C20.8518 10.1114 21.1675 10.2374 21.4953 10.2346C21.823 10.2317 22.1365 10.1003 22.3683 9.8685C22.6001 9.63674 22.7315 9.32323 22.7344 8.99548C22.7372 8.66773 22.6112 8.35198 22.3835 8.11623L14.8835 0.616227C14.6491 0.381888 14.3312 0.250244 13.9998 0.250244C13.6683 0.250244 13.3504 0.381888 13.116 0.616227L5.61603 8.11623C5.38833 8.35198 5.26234 8.66773 5.26518 8.99548C5.26803 9.32323 5.39949 9.63674 5.63125 9.8685C5.86301 10.1003 6.17653 10.2317 6.50428 10.2346C6.83202 10.2374 7.14777 10.1114 7.38353 9.88373Z"
                            fill="#fff"
                          />
                        </svg>
                        <p className="text-[#fff] shadow-xl text-[14px] sm:text-[16px]  font-medium font-poppins mt-5">
                          Edit Banner Image
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className=" flex justify-center items-center h-full">
                    <div>
                      <img src={upload} className="mx-auto" alt="new"></img>
                      <p className="text-[#47505F] text-[14px] sm:text-[16px] md:text-[18px] font-medium font-poppins mt-5">
                        Upload Banner Image
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {bannerErrorMessage && (
                <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                  {bannerErrorMessage}
                </div>
              )}
            </label>

            {/*---------------------- Upload Poster Image --------------------------------------*/}

            <label htmlFor="inputTags" className="bg-[#fff] w-[160px] h-[170px] mx-auto sm:h-[240px] sm:w-[225px] mt-[20px] sm:mt-[30px] border border-dashed border-[#DAE9FF] overflow-hidden flex items-center justify-center cursor-pointer  rounded-[10px]">
              <div>
                <input
                  id="inputTags"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  onChange={handlePosterChange}
                />
                {imagePosterData?.length > 0 ? (
                  <div className="w-full h-full relative group">
                    <img
                      src={imagePosterData}
                      className="w-full h-full object-cover"
                      alt="new"
                    ></img>
                    <div className="absolute top-0 left-0 right-0 bottom-0 ">
                      <div className="hidden w-full group-hover:block absolute top-[50%] left-[50%] transform -translate-x-[50%] -translate-y-[50%]">
                        <svg
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className=" mx-auto"
                        >
                          <path
                            d="M26.5 15.25C26.1685 15.25 25.8505 15.3817 25.6161 15.6161C25.3817 15.8505 25.25 16.1685 25.25 16.5V21.7662C25.249 22.6899 24.8817 23.5754 24.2285 24.2285C23.5754 24.8817 22.6899 25.249 21.7662 25.25H6.23375C5.31011 25.249 4.42458 24.8817 3.77146 24.2285C3.11835 23.5754 2.75099 22.6899 2.75 21.7662V16.5C2.75 16.1685 2.6183 15.8505 2.38388 15.6161C2.14946 15.3817 1.83152 15.25 1.5 15.25C1.16848 15.25 0.850537 15.3817 0.616116 15.6161C0.381696 15.8505 0.25 16.1685 0.25 16.5V21.7662C0.251654 23.3527 0.882615 24.8738 2.00443 25.9956C3.12624 27.1174 4.64727 27.7483 6.23375 27.75H21.7662C23.3527 27.7483 24.8738 27.1174 25.9956 25.9956C27.1174 24.8738 27.7483 23.3527 27.75 21.7662V16.5C27.75 16.1685 27.6183 15.8505 27.3839 15.6161C27.1495 15.3817 26.8315 15.25 26.5 15.25Z"
                            fill="#fff"
                          />
                          <path
                            d="M7.38353 9.88373L12.7498 4.51748V20.25C12.7498 20.5815 12.8815 20.8994 13.1159 21.1339C13.3503 21.3683 13.6683 21.5 13.9998 21.5C14.3313 21.5 14.6492 21.3683 14.8837 21.1339C15.1181 20.8994 15.2498 20.5815 15.2498 20.25V4.51748L20.616 9.88373C20.8518 10.1114 21.1675 10.2374 21.4953 10.2346C21.823 10.2317 22.1365 10.1003 22.3683 9.8685C22.6001 9.63674 22.7315 9.32323 22.7344 8.99548C22.7372 8.66773 22.6112 8.35198 22.3835 8.11623L14.8835 0.616227C14.6491 0.381888 14.3312 0.250244 13.9998 0.250244C13.6683 0.250244 13.3504 0.381888 13.116 0.616227L5.61603 8.11623C5.38833 8.35198 5.26234 8.66773 5.26518 8.99548C5.26803 9.32323 5.39949 9.63674 5.63125 9.8685C5.86301 10.1003 6.17653 10.2317 6.50428 10.2346C6.83202 10.2374 7.14777 10.1114 7.38353 9.88373Z"
                            fill="#fff"
                          />
                        </svg>
                        <p className="text-[#fff] shadow-xl text-[14px] sm:text-[16px]  text-center font-medium font-poppins mt-5">
                          Edit Poster Image
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className=" flex justify-center items-center h-full">
                    <div>
                      <img src={upload} className="mx-auto" alt="new"></img>
                      <p className="text-[#47505F] text-[14px] sm:text-[16px] md:text-[18px] font-medium font-poppins mt-5">
                        Upload Poster Image
                      </p>
                    </div>
                  </div>
                )}
              </div>
           
            </label>
            {posterErrorMessage && (
                <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                  {posterErrorMessage}
                </div>
              )}
            {/*---------------------- Upload Image --------------------------------------*/}

            <div className=" grid grid-cols-12 mt-[20px] sm:mt-[30px] gap-x-3 xl:gap-x-6">
              <label htmlFor="inputTagsf" className=" col-span-6">
                <div className="bg-[#fff]  h-[170px] sm:h-[215px] overflow-hidden border border-dashed border-[#DAE9FF] flex items-center justify-center cursor-pointer  rounded-[10px]">
                  <input
                    id="inputTagsf"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleUploadChange}
                  />
                  {imageUploadData?.length > 0 ? (
                    <div className=" col-span-6 w-full   h-[170px] sm:h-[215px] rounded-[10px] ">
                      <img
                        src={imageUploadData}
                        className="w-full h-full object-cover rounded-[10px]"
                        alt="new"
                      ></img>
                    </div>
                  ) : (
                    <div className=" flex justify-center items-center h-full">
                      <div className=" text-center">
                        <img src={upload} className="mx-auto" alt="new"></img>
                        <p className="text-[#47505F] text-[14px] sm:text-[16px]  font-medium font-poppins mt-5">
                          Upload Image
                        </p>
                        <p className="text-[12px] text-[#47505F] mt-2">
                          (Img size : 280 x 240)
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {uploadErrorMessage && (
                  <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                    {uploadErrorMessage}
                  </div>
                )}
              </label>
              <label htmlFor="inputTags2" className=" col-span-6">
                <div className="bg-[#fff]  h-[170px] sm:h-[215px] overflow-hidden border border-dashed border-[#DAE9FF] flex items-center justify-center cursor-pointer  rounded-[10px]">
                  <input
                    id="inputTags2"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleUploadChange1}
                  />
                  {imageUploadData1?.length > 0 ? (
                    <div className=" col-span-6 w-full   h-[170px] sm:h-[215px] rounded-[10px] ">
                      <img
                        src={imageUploadData1}
                        className="w-full h-full object-cover rounded-[10px]"
                        alt="new"
                      ></img>
                    </div>
                  ) : (
                    <div className=" flex justify-center items-center h-full">
                      <div className=" text-center">
                        <img src={upload} className="mx-auto" alt="new"></img>
                        <p className="text-[#47505F] text-[14px] sm:text-[16px] font-medium font-poppins mt-5">
                          Upload Image
                        </p>
                        <p className="text-[12px] text-[#47505F] mt-2">
                          (Img size : 280 x 240)
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {uploadErrorMessage1 && (
                  <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                    {uploadErrorMessage1}
                  </div>
                )}
              </label>
              <label
                htmlFor="inputTags3"
                className=" col-span-6 mt-[20px] sm:mt-[30px]"
              >
                <div className="bg-[#fff]  h-[170px] sm:h-[215px] overflow-hidden border border-dashed border-[#DAE9FF] flex items-center justify-center cursor-pointer  rounded-[10px]">
                  <input
                    id="inputTags3"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleUploadChange2}
                  />
                  {imageUploadData2?.length > 0 ? (
                    <div className=" col-span-6 w-full   h-[170px] sm:h-[215px] rounded-[10px] ">
                      <img
                        src={imageUploadData2}
                        className="w-full h-full object-cover rounded-[10px]"
                        alt="new"
                      ></img>
                    </div>
                  ) : (
                    <div className=" flex justify-center items-center h-full">
                      <div className=" text-center">
                        <img src={upload} className="mx-auto" alt="new"></img>
                        <p className="text-[#47505F] text-[14px] sm:text-[16px]  font-medium font-poppins mt-5">
                          Upload Image
                        </p>
                        <p className="text-[12px] text-[#47505F] mt-2">
                          (Img size : 280 x 240)
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {uploadErrorMessage2 && (
                  <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                    {uploadErrorMessage2}
                  </div>
                )}
              </label>
              <label
                htmlFor="inputTagsnew"
                className=" col-span-6 mt-[20px] sm:mt-[30px]"
              >
                <div className="bg-[#fff]  h-[170px] sm:h-[215px] overflow-hidden border border-dashed border-[#DAE9FF] flex items-center justify-center cursor-pointer  rounded-[10px]">
                  <input
                    id="inputTagsnew"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleUploadChange3}
                  />
                  {imageUploadData3?.length > 0 ? (
                    <div className=" col-span-6 w-full   h-[170px] sm:h-[215px] rounded-[10px] ">
                      <img
                        src={imageUploadData3}
                        className="w-full h-full object-cover rounded-[10px]"
                        alt="new"
                      ></img>
                    </div>
                  ) : (
                    <div className=" flex justify-center items-center h-full">
                      <div className=" text-center">
                        <img src={upload} className="mx-auto" alt="new"></img>
                        <p className="text-[#47505F] text-[14px] sm:text-[16px]  font-medium font-poppins mt-5">
                          Upload Image
                        </p>
                        <p className="text-[12px] text-[#47505F] mt-2">
                          (Img size : 280 x 240)
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {uploadErrorMessage3 && (
                  <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                    {uploadErrorMessage3}
                  </div>
                )}
              </label>
            </div>
          </div>
          <div className="w-full col-span-12 md:col-span-6 mt-[20px] md:mt-0">
            <p className="text-base text-[#161616] font-medium font-poppins">
              Movie Name
            </p>
            <input
              className="h-[46px] rounded-[5px] border border-[#6D6E70] pl-5 mt-3 w-full text-[#979797] placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
              placeholder="Enter Movie Name"
              value={Moviename}
              required
              onChange={(e) => setMoviename(e.target.value)}
            ></input>
            <p className="text-base mt-[20px] text-[#161616] font-medium font-poppins">
              Movie Description
            </p>
            <textarea
              className="py-3.5 rounded-[5px] border border-[#6D6E70] pl-5 mt-3 min-h-[110px] text-[#979797] w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
              placeholder="Enter Movie Description..."
              value={Moviedesc}
              required
              onChange={(e) => setMoviedesc(e.target.value)}
              style={{ resize: "none" }}
            ></textarea>
            <div className="mt-[20px]">
              <p className="text-base mb-3 text-[#161616] font-medium font-poppins">
                Select Language
              </p>
              <Select
                defaultValue={Language}
                closeMenuOnSelect={false}
                isMulti
                required
                name="colors"
                options={Languageoption}
                value={
                  Languageoption &&
                  Languageoption.filter((obj) =>
                    Language?.some((item) => item === obj.value)
                  )
                }
                className="basic-multi-select"
                classNamePrefix="select"
                styles={customStyles}
                hideSelectedOptions={false}
                onChange={(options) => {
                  if (Array.isArray(options)) {
                    setLanguage(options?.map((opt) => opt.value));
                  }
                }}
                components={{
                  Option: InputOption,
                }}
              />
            </div>
            <div className="mt-[20px]">
              <p className="text-base mb-3  text-[#161616] font-medium font-poppins">
                Select Actor
              </p>
              <Select
                defaultValue={Actor}
                closeMenuOnSelect={false}
                isMulti
                required
                name="colors"
                options={Actoroption}
                value={
                  Actoroption &&
                  Actoroption.filter((obj) =>
                    Actor?.some((item) => item === obj.value)
                  )
                }
                className="basic-multi-select"
                classNamePrefix="select"
                styles={customStyles}
                hideSelectedOptions={false}
                onChange={(options) => {
                  if (Array.isArray(options)) {
                    setActor(options?.map((opt) => opt.value));
                  }
                }}
                components={{
                  Option: InputOption,
                }}
              />
            </div>
            <div className="mt-[20px]">
              <p className="text-base mb-3  text-[#161616] font-medium font-poppins">
                Select Genre
              </p>
              <Select
                closeMenuOnSelect={false}
                isMulti
                required
                name="colors"
                options={Genreoption}
                value={
                  Genreoption &&
                  Genreoption.filter((obj) =>
                    Genre?.some((item) => item === obj.value)
                  )
                }
                className="basic-multi-select"
                classNamePrefix="select"
                styles={customStyles}
                placeholder={"Select genre...."}
                hideSelectedOptions={false}
                onChange={(options) => {
                  if (Array.isArray(options)) {
                    setGenre(options?.map((opt) => opt.value));
                  }
                }}
                components={{
                  Option: InputOption,
                }}
              />
            </div>
            <div className=" mt-[20px]">
              <p className="text-base text-[#161616] font-medium font-poppins">
                Movie Trailer Link
              </p>
              <input
                className="h-[46px] border border-[#6D6E70] rounded-[5px] pl-5 mt-3 w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
                value={trailerlink}
                required
                type="url"
                onChange={(e) => settrailerlink(e.target.value)}
              ></input>
            </div>
            <div className="mt-[25px] gap-x-3 xl:gap-x-8 grid grid-cols-12">
              <div className=" col-span-6">
                <p className="text-base mb-[15px] text-[#161616] font-medium font-poppins">
                  Release Date
                </p>
                <div className="relative">
                  <div className="absolute right-5 z-50 flex items-center h-full cursor-pointer">
                    <img src={dates} alt="new"></img>
                  </div>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="bg-white border border-[#6D6E70] cursor-pointer w-full h-[46px] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                  />
                </div>
              </div>
              <div className=" col-span-6">
                <p className="text-base mb-[15px] text-[#161616] font-medium font-poppins">
                  Duration
                </p>
                <div className=" grid grid-cols-12 gap-3">
                  <div className="relative col-span-6">
                    <input
                      className="bg-white w-full border border-[#6D6E70]  h-[46px] flex items-center pl-3 xl:pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                      placeholder="Hours"
                      type="number"
                      onKeyDown={event => {
                        if (event.key === "e" || event.key === "E") {
                            event.preventDefault();
                        }
                        }}
                      value={Hours}
                      required
                      min={0}
                      onChange={(e) => {
                        e.target.value > 0
                          ? setHours(e.target.value)
                          : setHours("");
                      }}
                    />
                  </div>
                  <div className="relative col-span-6">
                    <input
                      className="bg-white w-full border border-[#6D6E70]  h-[46px] flex items-center pl-3 xl:pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                      placeholder="Mins"
                      type="number"
                      onKeyDown={event => {
                        if (event.key === "e" || event.key === "E") {
                            event.preventDefault();
                        }
                        }}
                      value={Minutes}
                      min={0}
                      onChange={(e) => {
                        e.target.value > 0
                          ? setMinutes(e.target.value)
                          : setshow("");
                      }}
                    />
                  </div>
                </div>
              </div>
              {role !== "producer" && (
                <div className=" col-span-6 mt-[25px]">
                  <p className="text-base mb-[15px] text-[#161616] font-medium font-poppins">
                    Movie Price
                  </p>
                  <div className="relative">
                    <div className="absolute right-5 flex items-center h-full cursor-pointer">
                      <img src={price} alt="new"></img>
                    </div>
                    <input
                      className="bg-white w-full border border-[#6D6E70] text-[#979797] font-medium h-[46px] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                      placeholder="Enter Price"
                      type="number"
                     
                      value={Price}
                      min={0}
                      onChange={(e) => {
                        e.target.value > 0
                          ? setPrice(e.target.value)
                          : setPrice("");
                      }}
                    />
                  </div>
                </div>
              )}
              {role !== "producer" && (
                <div className=" col-span-6 mt-[25px]">
                  <p className="text-base mb-[15px] text-[#161616] font-medium font-poppins">
                    Discount
                  </p>
                  <div className="relative">
                    <div className="absolute right-5 flex items-center h-full cursor-pointer">
                      <img src={discount} alt="new"></img>
                    </div>
                    <input
                      className="bg-white w-full border border-[#6D6E70] text-[#979797] font-medium h-[46px] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                      placeholder="Enter Discount"
                      // type="number"
                      value={Discount}

                      min={0}
                      onChange={(e) => {
                        e.target.value > 0
                          ? setDiscount(e.target.value)
                          : setDiscount("");
                      }}
                    />
                  </div>
                </div>
              )}
              {role !== "producer" && (
                <div className=" col-span-6 mt-[25px]">
                  <p className="text-base mb-[15px] text-[#161616] font-medium font-poppins">
                    Minimum Shows
                  </p>
                  <div className="relative">
                    <input
                      className="bg-white w-full border border-[#6D6E70] text-[#979797] font-medium h-[46px] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                      placeholder="Enter Show"
                      type="number"
                      value={show}
                     
                      min={0}
                      onChange={(e) => {
                        e.target.value > 0
                          ? setshow(e.target.value)
                          : setshow("");
                      }}
                    />
                  </div>
                </div>
              )}
              <div
                className={`col-span-6  flex items-end  ${
                  role === "producer" && `${" mt-5"}`
                }`}
              >
                <button
                  type="submit"
                  className="text-base font-poppins font-medium px-[3rem] xl:px-[5rem] min-h-[46px] rounded-[5px] text-white bg-[#076aff] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl"
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </form>

        {/*---------------------- End Movie Form --------------------------------------*/}
      </div>
    </div>
  );
}

export default EditProducerMovies;
