import React, { useEffect, useRef, useState } from "react";
import logo from "../images/LOGO 2.svg";
import "../App.css";
import { Link } from "react-router-dom";
import OTPInput from "otp-input-react";
import eyeclose from "../images/eye close.svg";
import eyeopen from "../images/view.png";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmResetPin, ResetPin, SubmitResetPin, Userlogin, reset } from "../Redux/Action/AuthAction";
import { ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom/dist";
import { actionTypes } from "../Redux/Reducer/AuthReducer";

const STATUS = {
  STARTED: "Started",
  STOPPED: "Stopped",
};

const INITIAL_COUNT = 120;

function Login() {
  const [open, setopen] = useState(false);
  const [Mobile, setMobile] = useState("");
  const [Phone, setPhone] = useState("");

  // for login
  const [OTP, setOTP] = useState("");

  // for forget pin
  const [Otp, setOtp] = useState("");
  const [resend, setresend] = useState(false);
  const [buttontext, setbuttontext] = useState("Get OTP");

  const [Tab, setTab] = useState("1");


  const history = useNavigate();
  let errorsObj = {
    passwordInput: "",
    passwordInputConfirm: "",
    otp: "",
    OTP: "",
  };
  const [errors, setErrors] = useState(errorsObj);

  //------------------------ on Login form-------------------------------------

  const dispatch = useDispatch();
  const onsubmit = (e) => {
    e?.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (OTP?.length === 0) {
      errorObj.OTP = "Please Enter Pin!!!";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(Userlogin(Mobile, OTP, history));
  };
  const allpage = [
    { value: "producer", label: "Producer", id: 1 },
    { value: "vle", label: "VLE", id: 2 },
  ];

  //------------------------ on Forget Pin----------------------------------

  const resetpin = useSelector(state => state.Auth?.resetpin)
  const onsubmitForgotpin = (e) => {
    e?.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (Otp?.length === 0) {
      errorObj.otp = "Please Enter Pin!!!";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    const data = {
      contact: Phone,
      otp: Otp,
    };
    dispatch(SubmitResetPin(data));
    setbuttontext("Get OTP");
    setOtp("");
  };


  useEffect(() => {
    if(resetpin && resetpin?.code === 200){
      handleReset();
    dispatch(reset(actionTypes.RESET_FIRST_PIN))
        setTab("2");
        }
  },[resetpin])

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [passwordTypeConfirm, setpasswordTypeConfirm] = useState("password");
  const [passwordInputConfirm, setpasswordInputConfirm] = useState("");

  //----------------------- Enter Confirm Pin---------------------------

  const handlePasswordChange = (e) => {
    setPasswordInput(e.target.value);
  };
  const togglePassword = (e) => {
    e?.preventDefault();
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  //----------------- Confirm Pin-----------------

  const handlePasswordChangeConfirm = (e) => {
    setpasswordInputConfirm(e.target.value);
  };
  const togglePasswordConfirm = (e) => {
    e?.preventDefault();
    if (passwordTypeConfirm === "password") {
      setpasswordTypeConfirm("text");
      return;
    }
    setpasswordTypeConfirm("password");
  };

  //------------------- on confirm pin form--------------------

  const forgotresetpin = useSelector(state => state.Auth?.forgotresetpin)

  const onsubmitform = (e) => {
    e?.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (passwordInput?.length === 0) {
      errorObj.passwordInput = "Please Enter Pin";
      error = true;
    }
    if (passwordInputConfirm?.length === 0) {
      errorObj.passwordInputConfirm = "Please Enter Confirm Pin";
      error = true;
    } else if (passwordInputConfirm !== passwordInput) {
      errorObj.passwordInputConfirm = "Pin and Confirm Pin Should be Same.";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    const data = {
      contact: Phone,
      pin: passwordInput,
    };
    dispatch(ConfirmResetPin(data));
  };


  useEffect(() => {
    if(forgotresetpin && forgotresetpin?.code === 200){
      dispatch(Userlogin(Phone, passwordInput, history))
      setPasswordInput("");
      setpasswordInputConfirm("");
      setPhone("");
      setopen(false);
    }
  },[forgotresetpin])

  // --------------------- resend otp Timer -----------------------------

  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
  const [status, setStatus] = useState(STATUS.STOPPED);
  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;

  const firstpin = useSelector(state => state.Auth?.firstpin)

  const handleStart = (e) => {
    e?.preventDefault();
    const data = {
      contact: Phone,
    };
    dispatch(ResetPin(data));
  };
  useEffect(() => {
    if(firstpin && firstpin?.code === 200){
      setStatus(STATUS.STARTED);
      setresend(true);
      }
  },[firstpin])

  const handleReset = () => {
    setStatus(STATUS.STOPPED);
    setSecondsRemaining(INITIAL_COUNT);
    setresend(false);
  };

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    //--------------- Remember the latest callback---------------------

    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      if (minutesToDisplay === 0 && secondsToDisplay === 0) {
        setresend(false);
        handleReset();
        setbuttontext("Resend OTP");
      }
    }, [minutesToDisplay, secondsToDisplay]);

    //---------------- Set up the interval-------------------------
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null
    // passing null stops the interval
  );

  useEffect(() => {
  localStorage.clear();
  },[])
  return (
    <div>
      {/* <!-------------------------------- Login Card starts ---------------------------------------> */}
      <ToastContainer />
      <div className="h-screen flex items-center mx-auto w-full loginback">
        <div
          className={`bg-[#fff] pt-[25px] sm:pt-[44px] pb-[25px] 2xl:py-14 mx-2 sm:mx-auto w-full sm:w-[520px] px-[40px] sm:px-[85px]  loginbox rounded-[20px] ${
            open && `${"hidden"}`
          }`}
        >
          <div className=" text-center">
            <img src={logo} alt="new" className=" mx-auto mb-5"></img>
          </div>
          <div className="  py-3 border-b">
           <p className="text-[#000] font-poppins font-medium text-[18px] sm:text-[22px]"> <Link to='/' onClick={() => window?.reload()}><span>Home</span></Link> / <span className=" text-[#076aff]">Login</span></p>
          </div>
          <form onSubmit={onsubmit} className=" pt-5">
            <p className="sm:text-[18px] text-[#000] font-medium pb-[10px] sm:pb-[20px] font-poppins">
              Mobile No
            </p>
            <input
              className=" focus:bg-white numberfield rounded-[5px] w-full py-2.5 border border-[#6D6E70] mb-[15px] sm:mb-[25px] placeholder:text-[#979797] pl-5 placeholder:font-medium"
              placeholder="Enter Mobile No"
              autoComplete="off"
              required
              type="number"
              onChange={(e) => setMobile(e.target.value)}
              value={Mobile}
            ></input>
            <p className="sm:text-[18px] text-[#000] font-medium pb-[10px] sm:pb-[20px] font-poppins">
              Enter Pin
            </p>
            <OTPInput
              value={OTP}
              onChange={setOTP}
              autoFocus={false}
              OTPLength={6}
              otpType="number"
              disabled={false}
              className=" justify-between"
              inputStyles={{
                border: 1,
                borderStyle: "solid",
                borderColor: "#979797",
                height: 42,
                width: 42,
                borderRadius: 5,
                margin: 0,
              }}
              secure
            />
            {errors.OTP && (
              <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                {errors.OTP}
              </div>
            )}
            <div className="text-center mt-5">
              <button
                type="submit"
                className="bg-[#076AFF] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium w-full rounded-[5px] min-h-[46px] text-white"
              >
                Login
              </button>
            </div>
            <div className="flex items-center justify-end pt-[15px]">
              <p
                className="text-[14px] text-[#076AFF] font-medium font-poppins cursor-pointer"
                onClick={() => {
                  setopen(true);
                  setTab("1");
                }}
              >
                Forgot Pin?
              </p>
            </div>
            <div>
              <p className="text-[12px] sm:text-[14px] text-[#979797] font-medium font-poppins text-center mt-[15px] sm:mt-[25px]">
                Do you want to be like connect with Hooc ?
              </p>
              <div className="mt-[15px] flex items-center justify-between">
                {allpage?.map((e) => (
                  <Link to={`/register/${e.value}`} key={e?.id}>
                    <button className="hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl text-[14px] text-[#030303]  mb-3 sm:mb-0 min-w-[120px] sm:min-w-[150px] rounded-[5px] min-h-[32px] font-medium font-poppins buttonback">
                      {e.label}
                    </button>
                  </Link>
                ))}
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* <!---------------------------------- Login Card  end -------------------------- --> */}

      {/*---------------------------------Forget Pin Modal ------------------------- */}

      {open && (
        <div className="backcolor absolute z-[50] top-0 bottom-0 right-0 left-0">
          <div className="absolute top-[50%] left-[50%] opacity-100 transform -translate-x-[50%] -translate-y-[50%]">
            <div
              className="bg-[#fff] py-[30px] sm:py-[60px] mx-2 sm:mx-auto px-[30px] sm:px-[65px] w-[350px] sm:w-[470px] loginbox rounded-[20px]"
              //  ref={ref}
            >
              {/*------------------------------- Forget Pin -------------------------------------------*/}

              {Tab === "1" && (
                <div>
                  <div>
                    <img src={logo} alt="new"></img>
                    <div className="py-[15px] sm:py-[20px] ">
                      <p className=" text-[#161616] text-[20px] sm:text-[24px] font-medium font-poppins">
                        Forgot PIN?
                      </p>
                    </div>
                  </div>
                  <form onSubmit={handleStart}>
                    <div className="flex justify-between items-end ">
                      <div className="">
                        <p className="sm:text-[18px] text-[#000] font-medium pb-[10px] font-poppins">
                          Registered Phone No.
                        </p>
                        <input
                          className=" focus:bg-white rounded-[5px] w-[180px] mr-2 sm:w-[225px] py-2.5 border border-[#6D6E70] placeholder:text-[#979797] pl-5 placeholder:font-medium"
                          placeholder="Enter Phone No."
                          autoComplete="off"
                          required
                          type="number"
                          // maxLength={10}
                          onChange={(e) => setPhone(e.target.value)}
                          value={Phone}
                          // pattern='\b[0-9]{12}\b'
                          // title='value must be Number!!'
                        ></input>
                      </div>
                      <div>
                        <button
                          type="submit"
                          disabled={status === 'Started'}
                          className={`${status === 'Started' ? `${' cursor-context-menu'}` : `${'cursor-pointer'}` } text-[#076aff] bg-[#DAE9FF] min-h-[46px] flex items-center justify-center  rounded-[5px] font-poppins text-[12px] sm:text-[16px] font-medium w-[80px] sm:w-[100px] ${
                            buttontext === "Resend OTP" &&
                            `${"min-w-[110px] text-[14px]"}`
                          }`}
                        >
                          {buttontext}
                        </button>
                      </div>
                    </div>
                  </form>
                  <form onSubmit={onsubmitForgotpin}>
                    <div className=" col-span-12 mt-[25px] mb-[20px]">
                      <OTPInput
                        value={Otp}
                        onChange={setOtp}
                        autoFocus
                        OTPLength={6}
                        otpType="number"
                        disabled={false}
                        className=" justify-between"
                        inputStyles={{
                          border: 1,
                          borderStyle: "solid",
                          borderColor: "#979797",
                          height: 42,
                          width: 42,
                          borderRadius: 5,
                          margin: 0,
                        }}
                        secure
                      />
                      {errors.otp && (
                        <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                          {errors.otp}
                        </div>
                      )}
                      <div className=" text-right">
                        {resend && (
                          <p className="text-[#979797] text-sm font-poppins font-medium mt-[15px]">
                            Resend otp in{" "}
                            {minutesToDisplay < 10
                              ? `0${minutesToDisplay}`
                              : minutesToDisplay}{" "}
                            :{" "}
                            {secondsToDisplay < 10
                              ? `0${secondsToDisplay}`
                              : secondsToDisplay}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className=" flex items-center">
                      <button
                        className=" border border-[#979797] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl mr-4 font-poppins font-medium w-full rounded-[5px] min-h-[46px] text-[#161616]"
                        onClick={() => {
                          setopen(false);
                          setPhone("");
                          setOtp("");
                          setErrors("");
                          handleReset();
                          setbuttontext("Get OTP");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-[#076AFF] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium w-full rounded-[5px] min-h-[46px] text-white"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              )}

              {/*-------------------------------- Generate Pin-------------------------------------------- */}

              {Tab === "2" && (
                <div>
                  <div>
                    <img src={logo} alt="new"></img>
                    <div className="py-[20px] ">
                      <p className=" text-[#161616] text-[24px] font-medium font-poppins">
                        Generate PIN
                      </p>
                    </div>
                  </div>
                  <form onSubmit={onsubmitform}>
                    <div className="grid grid-cols-12">
                      <div className=" col-span-12 mb-[20px]">
                        <p className="text-[18px] text-[#000] font-medium pb-[10px] font-poppins">
                          Phone No
                        </p>
                        <input
                          className=" focus:bg-white rounded-[5px] w-full py-2.5 border border-[#6D6E70] placeholder:text-[#979797] pl-5 placeholder:font-medium"
                          placeholder="+91 9087343434"
                          autoComplete="off"
                          disabled={true}
                          required
                          type="text"
                          onChange={(e) => setPhone(e.target.value)}
                          value={Phone}
                        ></input>
                      </div>
                      <div className=" col-span-12 mb-[30px]">
                        <p className="text-[18px] text-[#000] font-medium pb-[10px] font-poppins">
                          Create Pin
                        </p>
                        <div className="relative">
                          <input
                            type={passwordType}
                            onChange={handlePasswordChange}
                            value={passwordInput}
                            name="pin"
                            className=" focus:bg-white rounded-[5px] w-full py-2.5 border border-[#6D6E70] placeholder:text-[#979797] pl-5 placeholder:font-medium"
                            placeholder="Enter Pin"
                            autoComplete="off"
                            maxLength={6}
                            minLength={6}
                          ></input>
                          <div className="absolute right-4 top-[13px]">
                            <button onClick={togglePassword}>
                              {passwordType === "password" ? (
                                <img src={eyeopen} alt="new"></img>
                              ) : (
                                <img src={eyeclose} alt="new"></img>
                              )}
                            </button>
                          </div>
                        </div>
                        {errors.passwordInput && (
                          <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                            {errors.passwordInput}
                          </div>
                        )}
                      </div>
                      <div className=" col-span-12 mb-[30px]">
                        <p className="text-[18px] text-[#000] font-medium pb-[10px] font-poppins">
                          Confirm Pin
                        </p>
                        <div className="relative">
                          <input
                            type={passwordTypeConfirm}
                            onChange={handlePasswordChangeConfirm}
                            value={passwordInputConfirm}
                            name="confirm-pin"
                            className=" focus:bg-white rounded-[5px] w-full py-2.5 border border-[#6D6E70] placeholder:text-[#979797] pl-5 placeholder:font-medium"
                            placeholder="Enter Confirm Pin"
                            autoComplete="off"
                            maxLength={6}
                            minLength={6}
                          ></input>
                          <div className="absolute right-4 top-[13px]">
                            <button onClick={togglePasswordConfirm}>
                              {passwordTypeConfirm === "password" ? (
                                <img src={eyeopen} alt="new"></img>
                              ) : (
                                <img src={eyeclose} alt="new"></img>
                              )}
                            </button>
                          </div>
                          {errors.passwordInputConfirm && (
                            <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                              {errors.passwordInputConfirm}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className=" flex items-center">
                      <button
                        className=" border border-[#979797] mr-4 font-poppins font-medium w-full rounded-[5px] min-h-[46px] text-[#161616]"
                        onClick={() => {
                          setopen(false);
                          setPhone("");
                          setPasswordInput("");
                          setpasswordInputConfirm("");
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-[#076AFF] font-poppins font-medium w-full rounded-[5px] min-h-[46px] text-white"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
