export const actionTypes = {
  SET_LOGIN: "SET_LOGIN",
  SET_REGISTER: "SET_REGISTER",
  RESET_REGISTER: "RESET_REGISTER",
  RESET_PIN : 'RESET_PIN',
  FIRST_PIN : 'FIRST_PIN',
  RESET_FIRST_PIN : 'RESET_FIRST_PIN',
  SET_PIN : 'SET_PIN',
  SET_NEW_PIN : 'SET_NEW_PIN',
  GENERATE_NEW_PIN : 'GENERATE_NEW_PIN',
  FORGOT_RESET_PIN : 'FORGOT_RESET_PIN',
  RESET_FORGOT_RESET_PIN : 'RESET_FORGOT_RESET_PIN',

};

const INITIAL_STATE = {
  login: [],
  register: [],
  resetpin : [],
  setpin : [],
  firstpin : [],
  setnewpin : [],
  generatenewpin : [],
  forgotresetpin : [],

};

const AuthReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.SET_LOGIN:
      return {
        ...state,
        login: action.login,
      };
    case actionTypes.SET_REGISTER:
      return {
        ...state,
        register: action.register,
      };
    case actionTypes.RESET_REGISTER:
      return {
        ...state,
        register: INITIAL_STATE.register,
      };
      case actionTypes.RESET_PIN:
        return {
          ...state,
          resetpin: action.resetpin,
        };
      case actionTypes.SET_PIN:
        return {
          ...state,
          setpin: action.setpin,
        };
      case actionTypes.SET_NEW_PIN:
        return {
          ...state,
          setnewpin: action.setnewpin,
        };
      case actionTypes.GENERATE_NEW_PIN:
        return {
          ...state,
          generatenewpin: action.generatenewpin,
        };
      case actionTypes.FORGOT_RESET_PIN:
        return {
          ...state,
          forgotresetpin: action.forgotresetpin,
        };
      case actionTypes.RESET_FORGOT_RESET_PIN:
        return {
          ...state,
          forgotresetpin: action.INITIAL_STATE,
        };
      case actionTypes.FIRST_PIN:
        return {
          ...state,
          firstpin: action.firstpin,
        };
      case actionTypes.RESET_FIRST_PIN:
        return {
          ...state,
          firstpin: action.INITIAL_STATE,
        };
    default:
      return state;
  }
};

export default AuthReducer;
