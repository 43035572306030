export const actionTypes = {
    TRANSACTION_LIST: "TRANSACTION_LIST",
    INCOME_LIST: "INCOME_LIST",
    TOTAL_VLE_LIST: "TOTAL_VLE_LIST",
    TOTAL_PRODUCER_LIST: "TOTAL_PRODUCER_LIST",
    VLE_TRANSACTION_DETAIL: "VLE_TRANSACTION_DETAIL",
    PRODUCER_TRANSACTION_DETAIL: "PRODUCER_TRANSACTION_DETAIL",
    MOVIE_REPORT_LIST: "MOVIE_REPORT_LIST",
}

const INITIAL_STATE = {
    Transactionlist : [],
    Incomelist : [],
    Totalvlelist : [],
    Totalproducerlist : [],
    Vletransactiondetail : [],
    Producertransactiondetail : [],
    Moviereportlist : []
}

const TransactionReducer = (state = INITIAL_STATE, action) => {
 
    switch(action.type){
        case actionTypes.TRANSACTION_LIST:
            return{
                ...state,
                Transactionlist: action.Transactionlist
            }
        case actionTypes.INCOME_LIST:
            return{
                ...state,
                Incomelist: action.Incomelist
            }
        case actionTypes.TOTAL_VLE_LIST:
            return{
                ...state,
                Totalvlelist: action.Totalvlelist
            }
        case actionTypes.TOTAL_PRODUCER_LIST:
            return{
                ...state,
                Totalproducerlist: action.Totalproducerlist
            }
        case actionTypes.VLE_TRANSACTION_DETAIL:
            return{
                ...state,
                Vletransactiondetail: action.Vletransactiondetail
            }
        case actionTypes.PRODUCER_TRANSACTION_DETAIL:
            return{
                ...state,
                Producertransactiondetail: action.Producertransactiondetail
            }
        case actionTypes.MOVIE_REPORT_LIST:
            return{
                ...state,
                Moviereportlist: action.Moviereportlist
            }
            default: return state;
    }
};

export default TransactionReducer;