import React, { useEffect } from "react";
import arrow from "../images/rightarr.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { OrderDetails } from "../Redux/Action/OrderAction";
import { ResetMovie } from "../Redux/Action/MovieAction";
import { actionTypes } from "../Redux/Reducer/OrderReducer";


function OrderDetail() {


  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const dispatch = useDispatch();
  const history = useNavigate();
  const {id} = useParams();

  useEffect(() =>{
   dispatch(OrderDetails(id,history))
  },[])

  const orderdetails = useSelector((state) => state?.order?.orderdetail?.data);
  return (
    <div className="main_container sm:min-h-[500px]">
      <div className="flex items-center mt-[16px] sm:mt-[30px] mb-[16px]">
          <Link to="/myorder">
            <p onClick={() => dispatch(ResetMovie(actionTypes.RESET_ORDER_DETAIL))} className=" text-[#076aff] cursor-pointer text-[18px] font-medium font-poppins">
              My Order
            </p>
          </Link>
          <img src={arrow} alt="new" className="mx-3"></img>
          <p className=" text-[#161616] text-[18px] font-medium font-poppins">
            Order Details
          </p>
        </div>
     

      {/*---------------------------------Desktop  orderdetail Table ------------------------------ */}
      <div className="grid grid-cols-12 gap-x-3 lg:gap-x-7">
        <table className="w-full border border-[#DAE9FF] mb-[30px] hidden lg:inline-table  lg:col-span-9 xl:col-span-8  2xl:max-w-full">
          <thead>
            <tr className="bg-[#DAE9FF]">
              <td className="py-[15px] pl-[30px]  text-[#161616] text-[18px] font-medium font-poppins">
                Movies
              </td>
              <td className="py-[15px] text-[#161616] text-[18px] font-medium font-poppins">
                Price
              </td>
              <td className="py-[15px] text-[#161616] text-[18px] font-medium font-poppins text-center">
                Discount
              </td>
              <td className="py-[15px] text-[#161616] text-[18px] font-medium font-poppins text-center">
               Show Quantity
              </td>
              <td className="py-[15px] text-[#161616] text-[18px] font-medium font-poppins text-center min-w-[90px]">
                Total
              </td>
            </tr>
          </thead>
          <tbody>
            {orderdetails?.order?.movies?.map((e , i) => (
              <tr key={i} className="border-b border-[#DAE9FF]">
                <td className=" pl-[30px] ">
                  <div className="flex items-center min-h-[100px]">
                    <img  src={`${`https://hooc.b-cdn.net/${
                    e?.poster_image
                  }?t=${Date.now()}`}`}
                  className=" w-[50px] h-[50px] rounded-[5px]"
                  alt="new"></img>
                  <p className="text-[#47505F] ml-3 text-[16px] font-medium font-poppins">
                    {e?.name?.length > 16 ? (
                      <>{`${e?.name?.substring(0, 16)}...`}</>
                    ) : (
                      <>{e?.name}</>
                    )}
                  </p>
                  </div>
                  
                </td>
                <td className="min-h-[120px]">
                  <p className="text-[#47505F] text-[16px] font-medium font-poppins">
                  {e?.price?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    })}
                  </p>
                </td>
                <td className="min-h-[120px]">
                  <p className="text-[#47505F] text-[16px] font-medium font-poppins text-center">
                    {e?.discount ?  <span>{e?.discount}%</span> : ''}
                  </p>
                </td>
                <td className="min-h-[120px]">
                  <p className="text-[#076aff] text-[16px] font-medium font-poppins text-center">
                    {e?.quantity}
                  </p>
                </td>
                <td className="">
                  <p className="text-[#47505F] text-[16px] font-medium font-poppins text-center">
                  {e?.total?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    })}
                  </p>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/*---------------------------------End of orderdetail Table ------------------------------ */}

        {/*-------------------------------- mobile view for orderdetail------------------------------ */}

        <div className="col-span-12 md:col-span-6 lg:hidden">
          {orderdetails?.order?.movies?.map((e , i) => (
            <div
              className=" bg-[#fff] rounded-[10px] boxshadow p-5 mb-5"
              key={i}
            >
              <div className=" flex items-start justify-between">
                <div className=" flex items-start">
                  <img  src={`${`https://hooc.b-cdn.net/${
                    e?.poster_image
                  }?t=${Date.now()}`}`}  
                  className=" w-[50px] h-[50px] rounded-[5px]"
                  alt="new"></img>
                  <div className="ml-3">
                    <p className="text-[#000]  text-[16px]  font-poppins ">
                      {e?.name}
                    </p>
                    <p className="text-[#000] text-[12px] sm:text-[18px] my-2 font-medium font-poppins">
                    {e?.price?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    })}
                     {e?.discount && <span className="text-[#028F3B] ml-3 text-[10px] sm:text-[12px] font-medium font-poppins bg-[#d9eee2] px-3 py-1.5 rounded-[20px]">
                      {e?.discount}% off
                      </span>}
                    </p>
                    <div className=" my-[10px]">
                      <p className="text-[#000] text-[14px] sm:text-[16px]  font-poppins">
                      Show Quantity :
                        <span className="ml-2  text-[#47505F] text-[14px] sm:text-[16px] font-poppins">
                          {e.quantity}
                        </span>
                      </p>
                    </div>
                    <p className="text-[#47505F] text-[14px] sm:text-[16px]  font-poppins">
                      Total Price =
                      <span className="ml-2  text-[#076aff] text-[14px] sm:text-[16px]  font-poppins">
                      {e?.total?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    })}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/*--------------------------------End mobile view for orderdetail------------------------------ */}

        {/*--------------------------------- Payment Detail ------------------------------ */}

        <div className=" bg-[#fff] boxshadow rounded-[10px] px-[20px] xl:px-[30px] py-[30px] col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-4 max-h-[350px]">
          <p className="text-[#161616] text-[16px] xl:text-[20px] font-medium font-poppins">
            Payment Details
          </p>
          <div className=" flex items-center justify-between py-[20px]">
            <p className="text-[#47505F] text-[14px] xl:text-[16px] font-medium font-poppins">
              Sub Total
            </p>
            <p className="text-[#47505F] text-[14px] xl:text-[16px] font-medium font-poppins">
              {orderdetails?.order?.subtotal?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    })}
              
            </p>
          </div>
        {orderdetails?.order?.texts?.cgst &&  <div className=" flex items-center justify-between pb-[20px]">
            <p className="text-[#47505F] text-[14px] xl:text-[16px] font-medium font-poppins">
              CGST(9%)
            </p>
            <p className="text-[#47505F] text-[14px] xl:text-[16px] font-medium font-poppins">
            {orderdetails?.order?.texts?.cgst?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    })}
            </p>
          </div>}
          {orderdetails?.order?.texts?.sgst &&  <div className=" flex items-center justify-between pb-[20px] border-b border-[#DAE9FF]">
            <p className="text-[#47505F] text-[14px] xl:text-[16px] font-medium font-poppins">
              SGST(9%)
            </p>
            <p className="text-[#47505F] text-[14px] xl:text-[16px] font-medium font-poppins">
            {orderdetails?.order?.texts?.sgst?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    })}
            </p>.
          </div>}
          {orderdetails?.order?.texts?.igst &&  <div className=" flex items-center justify-between pb-[20px] border-b border-[#DAE9FF]">
            <p className="text-[#47505F] text-[14px] xl:text-[16px] font-medium font-poppins">
              IGST(18%)
            </p>
            <p className="text-[#47505F] text-[14px] xl:text-[16px] font-medium font-poppins">
            {orderdetails?.order?.texts?.igst?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    })}
            </p>
          </div>}
          <div className=" flex items-center justify-between py-[20px]">
            <p className="text-[#161616] text-[14px] xl:text-[16px] font-medium font-poppins">
              Total Amount
            </p>
            <p className="text-[#161616] text-[14px] xl:text-[16px] font-medium font-poppins">
              {orderdetails?.order?.total?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    })}
            </p>
          </div>
        </div>

        {/*---------------------------------End of Payment Detail ------------------------------ */}
      </div>
    </div>
  );
}

export default OrderDetail;
