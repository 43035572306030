export const actionTypes = {
  MANAGER_LIST: "MANAGER_LIST",
  ADD_MANAGER: "ADD_MANAGER",
  DELETE_MANAGER_LIST: "DELETE_MANAGER_LIST",
  MANAGER_DETAIL: "MANAGER_DETAIL",
};

const INITIAL_STATE = {
  managerlist: [],
  addmanager: [],
  deletemanagerlist: [],
  managerdetail: [],
};

const ManagerReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.MANAGER_LIST:
      return {
        ...state,
        managerlist: action.managerlist,
      };
    case actionTypes.MANAGER_DETAIL:
      return {
        ...state,
        managerdetail: action.managerdetail,
      };
    case actionTypes.DELETE_MANAGER_LIST:
      return {
        ...state,
        deletemanagerlist: action.deletemanagerlist,
      };
    case actionTypes.ADD_MANAGER:
      return {
        ...state,
        addmanager: action.addmanager,
      };

    default:
      return state;
  }
};

export default ManagerReducer;
