import React from "react";
import "./App.css";
import MainLayout from "./Layout/MainLayout";
import Aboutus from "./pages/Aboutus";
import AllMovies from "./pages/AllMovies";
import Homepage from "./pages/Homepage";
import Login from "./pages/Login";
import MovieDetail from "./pages/MovieDetail";
import Wishlist from "./pages/Wishlist";
import Cart from "./pages/Cart";
import AdminLayout from "./Admin Layout/AdminLayout";
import VleList from "./Admin pages/Vle Pages/VleList";
import Movielist from "./Admin pages/Movie pages/Movielist";
import AddMovies from "./Admin pages/Movie pages/AddMovies";
import TransactionList from "./Admin pages/Transaction pages/TransactionList";
import Producerlist from "./Admin pages/Producer pages/Producerlist";
import ManagerList from "./Admin pages/Manager pages/ManagerList";
import Register from "./pages/Register";
import VleDetail from "./Admin pages/Vle Pages/VleDetail";
import GenratePin from "./pages/GenratePin";
import ConfirmPin from "./pages/ConfirmPin";
import EditMovies from "../src/Admin pages/Movie pages/EditMovies";
import ProducerDetail from "./Admin pages/Producer pages/ProducerDetail";
import ManagerDetail from "./Admin pages/Manager pages/ManagerDetail";
import AddActor from "./Admin pages/AddActor";
import CreateVle from "./Admin pages/Vle Pages/CreateVle";
import HomepageBanner from "./Admin pages/HomepageBanner";
import CreateProducer from "./Admin pages/Producer pages/CreateProducer";
import CreateManager from "./Admin pages/Manager pages/CreateManager";
import Contactus from "./pages/Contactus";
import RequestedProducer from "./Admin pages/Requested pages/RequestedProducer";
import RequestedVLE from "./Admin pages/Requested pages/RequestedVLE";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import MyOrder from "./pages/MyOrder";
import OrderDetail from "./pages/Orderdetail";
import MovieView from "./Admin pages/Movie pages/MovieView";
import PrivateRoute from "../src/components/PrivateRoute";
import AdminRoute from "../src/Admin Layout/AdminRoute";
import { Navigate, Route, Routes } from "react-router";
import ContactList from "./Admin pages/ContactList";
import ProducerMovie from "./Admin pages/ProducerMovie pages/ProducerMovie";
import EditProducerMovies from "./Admin pages/ProducerMovie pages/EditProducerMovie";
import MovieViewProducer from "./Admin pages/ProducerMovie pages/ViewProducerMovie";
import AddProducerMovies from "./Admin pages/ProducerMovie pages/AddProducerMovie";
import Termservice from "./pages/Termservice";
import PageNotFound from "./PageNotFound";
import UserRoute from "./Layout/UserRoute";
import VleTransaction from "./Admin pages/Transaction pages/vle/VleTransaction";
import Producertransaction from "./Admin pages/Transaction pages/producer/Producertransaction";
import Vletransactionlist from "./Admin pages/Transaction pages/vle/vletransactionlist";
import Producertransactionlist from "./Admin pages/Transaction pages/producer/Producertransactionlist";
import MovieReport from "./Admin pages/Transaction pages/MovieReport";
import VleTransactionData from "./pages/TransactionList";

function Routers() {
  return (
    <div>
      <Routes>
        {/*-------------- User Layout------------------- */}

        <Route
          path="/"
          element={
            <UserRoute>
            <MainLayout>
              <Homepage />
            </MainLayout>
            </UserRoute>
          }
        ></Route>
        <Route
          path="/about-us"
          element={
            <UserRoute>
            <MainLayout>
              <Aboutus />
            </MainLayout>
            </UserRoute>
          }
        ></Route>
        <Route
          path="/all-movies"
          element={
            <UserRoute>
            <MainLayout>
              <AllMovies />
            </MainLayout>
            </UserRoute>
          }
        ></Route>
        <Route
          path="/movie-detail/:id"
          element={
            <UserRoute>
            <MainLayout>
              <MovieDetail />
            </MainLayout>
            </UserRoute>
          }
        ></Route>
        <Route
          path="/wishlist"
          element={
            <UserRoute>
            <MainLayout>
              <Wishlist />
            </MainLayout>
            </UserRoute>
          }
        ></Route>
        <Route
          path="/cart"
          element={
            <UserRoute>
            <MainLayout>
              <Cart />
            </MainLayout>
            </UserRoute>
          }
        ></Route>
        <Route
          path="/myorder"
          element={
            <PrivateRoute>
              <MainLayout>
                <MyOrder />
              </MainLayout>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/transaction-list"
          element={
            <PrivateRoute>
              <MainLayout>
                <VleTransactionData/>
              </MainLayout>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/order-detail/:id"
          element={
            <PrivateRoute>
              <MainLayout>
                <OrderDetail />
              </MainLayout>
            </PrivateRoute>
          }
        ></Route>
        <Route
          path="/contact-us"
          element={
            <UserRoute>
            <MainLayout>
              <Contactus />
            </MainLayout>
            </UserRoute>
          }
        ></Route>
        <Route
          path="/privacy-policy"
          element={
            <UserRoute>
            <MainLayout>
              <PrivacyPolicy />
            </MainLayout>
            </UserRoute>
          }
        ></Route>
        <Route
          path="/terms-of-conditions"
          element={
            <UserRoute>
            <MainLayout>
              <Termservice />
            </MainLayout>
            </UserRoute>
          }
        ></Route>

        {/*---------------- Admin Layout------------ */}

        {/*---------------- vle section--------------- */}

        <Route
          path="/admin/vle"
          element={
            <AdminRoute>
              <AdminLayout>
                <VleList />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/create-vle"
          element={
            <AdminRoute>
              <AdminLayout>
                <CreateVle />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/vle-detail/:id"
          element={
            <AdminRoute>
              <AdminLayout>
                <VleDetail />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>

        {/*---------------- Movie section--------------- */}

        <Route
          path="/admin/movies"
          element={
            <AdminRoute>
              <AdminLayout>
                <Movielist />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/producer-movies"
          element={
            <AdminRoute>
              <AdminLayout>
                <ProducerMovie />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/add-movie"
          element={
            <AdminRoute>
              <AdminLayout>
                <AddMovies />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/addproducer-movie"
          element={
            <AdminRoute>
              <AdminLayout>
                <AddProducerMovies />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/view-movie/:id"
          element={
            <AdminRoute>
              <AdminLayout>
                <MovieView />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/viewproducer-movie/:id"
          element={
            <AdminRoute>
              <AdminLayout>
                <MovieViewProducer />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/edit-movie/:id"
          element={
            <AdminRoute>
              <AdminLayout>
                <EditMovies />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/editproducer-movie/:id"
          element={
            <AdminRoute>
              <AdminLayout>
                <EditProducerMovies />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>

        {/*---------------- Producer section--------------- */}

        <Route
          path="/admin/producer"
          element={
            <AdminRoute>
              <AdminLayout>
                <Producerlist />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/create-producer"
          element={
            <AdminRoute>
              <AdminLayout>
                <CreateProducer />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/producer-detail/:id"
          element={
            <AdminRoute>
              <AdminLayout>
                <ProducerDetail />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>

        {/*---------------- Manager section--------------- */}

        <Route
          path="/admin/manager"
          element={
            <AdminRoute>
              <AdminLayout>
                <ManagerList />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/manager-detail/:id"
          element={
            <AdminRoute>
              <AdminLayout>
                <ManagerDetail />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/create-manager"
          element={
            <AdminRoute>
              <AdminLayout>
                <CreateManager />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>

        {/*---------------- Transaction section--------------- */}

        <Route
          path="/admin/transaction"
          element={
            <AdminRoute>
              <AdminLayout>
                <TransactionList />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>

        <Route
          path="/admin/vle-transactiondetail/:id"
          element={
            <AdminRoute>
              <AdminLayout>
                <VleTransaction/>
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/vle-transactionlist"
          element={
            <AdminRoute>
              <AdminLayout>
                <Vletransactionlist/>
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/producer-transactiondetail/:id"
          element={
            <AdminRoute>
              <AdminLayout>
                <Producertransaction/>
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/producer-transactionlist"
          element={
            <AdminRoute>
              <AdminLayout>
               <Producertransactionlist/>
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/movie_report"
          element={
            <AdminRoute>
              <AdminLayout>
               <MovieReport/>
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>

        {/*---------------- Actor section--------------- */}

        <Route
          path="/admin/actors"
          element={
            <AdminRoute>
              <AdminLayout>
                <AddActor />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>

        {/*---------------- Homepage banner section--------------- */}

        <Route
          path="/admin/home-banner"
          element={
            <AdminRoute>
              <AdminLayout>
                <HomepageBanner />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        {/*---------------- contact list section--------------- */}

        <Route
          path="/admin/contact"
          element={
            <AdminRoute>
              <AdminLayout>
                <ContactList />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>

        {/*---------------- Requested section--------------- */}

        <Route
          path="/admin/request-producer"
          element={
            <AdminRoute>
              <AdminLayout>
                <RequestedProducer />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>
        <Route
          path="/admin/request-vle"
          element={
            <AdminRoute>
              <AdminLayout>
                <RequestedVLE />
              </AdminLayout>
            </AdminRoute>
          }
        ></Route>

        {/*---------------- Login section--------------- */}

        <Route path="/login" element={<Login />}></Route>
        <Route path="/register/:id" element={<Register />}></Route>
        <Route path="/generate-pin" element={<GenratePin />}></Route>
        <Route path="/confirm-pin" element={<ConfirmPin />}></Route> 
        <Route path="/404" element={<PageNotFound />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </div>
  );
}

export default Routers;
