import React from "react";
import Slider from "../components/Slider";
import UpcomingMovies from "../components/UpcomingMovies";
import PopularMovies from "../components/PopularMovies";
import AllMoviesHome from "../components/AllMoviesHome";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetHomeMovielist } from "../Redux/Action/HomePageAction";
import HomeVideo from "../components/HomeVideo";
import { useNavigate } from "react-router";

function Homepage() {
  const dispatch = useDispatch();
  const history = useNavigate();
  
  useEffect(() => {
    dispatch(GetHomeMovielist(history));
  }, []);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const movieslist = useSelector((state) => state.homemovies.movieslist?.data);

  return (
    <div>
      {movieslist?.feature.length > 0 && <Slider movieslist={movieslist?.feature} />}
      {movieslist?.popular.length > 0 && <PopularMovies movieslist={movieslist?.popular} />}
      {movieslist?.upcoming.length > 0 && <UpcomingMovies movieslist={movieslist?.upcoming} />}
      {movieslist?.movies.length > 0 && <AllMoviesHome movieslist={movieslist?.movies} />}
      <HomeVideo />
    </div>
  );
}

export default Homepage;
