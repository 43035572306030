export const actionTypes = {
    WISH_LIST: "WISH_LIST",
   
  };
  
  const INITIAL_STATE = {
    wishlist: [],
   
  };
  
  const GetWishListReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case actionTypes.WISH_LIST:
        return {
          ...state,
          wishlist: action.wishlist,
        };
     
  
      default:
        return state;
    }
  };
  
  export default GetWishListReducer;