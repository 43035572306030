import React, { useRef, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import close from "../images/close.svg";
import UseOutsideClick from "../components/UseOutsideClick";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GetWishList } from "../Redux/Action/AddWishListAction";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { getCart } from "../Redux/Action/cartAction";
import { reset } from "../Redux/Action/AuthAction";
import { actionTypes } from "../Redux/Reducer/AuthReducer";
import Loader from "../components/Loader";

function MainLayout(props) {
  const [show, setshow] = useState(false);
  const ref = useRef();
  UseOutsideClick(ref, () => {
    if (show) {
      setshow(false);
    }
  });

  const location = useLocation();
  const mywishlist = useSelector((state) => state.mywishlist.wishlist?.data);
 const mycart = useSelector((state) => (state?.cart?.cartlist?.data));

 
 const history = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if(!mywishlist) {
      if(localStorage.getItem('token')){
        dispatch(GetWishList());
      }
      
    }
  }, [mywishlist]);

  useEffect(() => {
    if(!mycart){
      if(localStorage.getItem('token')){
        dispatch(getCart());
      }
      
    }
  }, [mycart]);

  
  const logout = () => {
    localStorage.clear();
    history('/login')
  }

  const firstname = localStorage.getItem('firstname')

useEffect(() => {
  dispatch(reset(actionTypes.RESET_FORGOT_RESET_PIN))
},[])

const Preloader = useSelector(state => state?.order?.Preloader);


  return (
    <div className=" relative">
        {Preloader && <div className="backcolor absolute z-[50] top-0 bottom-0 right-0 left-0"><Loader/></div>}
      <Header setshow={setshow} show={show} />
      <div className="w-full">{props.children}</div>
      <Footer />

      {/*----------------------------- sidebar -------------------------------------*/}

      {/* {show && ( */}
      <div
        className={`${
          show &&
          `${"backcolor absolute z-[1002] top-0 bottom-0 right-0 left-0"}`
        } lg:hidden`}
      >
        <div
          className={`${
            show
              ? "translate-x-0 h-full max-w-[335px] fixed bottom-0 px-[16px] pt-[25px] pb-[15px]"
              : "-translate-x-full"
          } transform  ease-in-out transition duration-500 bg-[#F6F7FE] flex flex-col justify-between lg:hidden`}
          ref={ref}
        >
          {show && (
            <div>
              <div>
                <img
                  src={close}
                  className={`ml-auto ${!localStorage.getItem('token') && `${'mb-5'}`}`}
                  alt="new"
                  onClick={() => setshow(false)}
                ></img>
              </div>
            {localStorage.getItem('token') &&  <div className=" text-center border-b border-[#B3A1D3] pb-[10px] mb-[20px]">
                <div className=" bg-[#dfdfdf] w-[50px] h-[50px] rounded-[50%] mx-auto flex justify-center items-center">
                <p className="text-[18px] font-semibold  font-poppins">{firstname?.substring(0,1)?.toUpperCase()}</p>
                </div>
                <p className="text-[14px] font-normal font-poppins text-[#000000] pt-[8px]">
                  {firstname}
                </p>
              </div>}
              <ul>
                <Link to="/">
                  <li
                    className={`${
                      location?.pathname === "/"
                        ? `${"bg-[#DAE9FF] text-[#076aff]"}`
                        : `${" bg-[#fff] text-[#000000]"}`
                    } cursor-pointer flex items-center pl-[28px] rounded-[10px]  mb-[18px] py-[11px] font-normal font-poppins `}
                    onClick={() => setshow(false)}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      className={`${
                        location?.pathname === "/" && `${" stroke-[#076aff]"}`
                      } mr-[18px] `}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 17.99V14.99M9.02 2.84001L3.63 7.04001C2.73 7.74001 2 9.23001 2 10.36V17.77C2 20.09 3.89 21.99 6.21 21.99H17.79C20.11 21.99 22 20.09 22 17.78V10.5C22 9.29001 21.19 7.74001 20.2 7.05001L14.02 2.72001C12.62 1.74001 10.37 1.79001 9.02 2.84001Z"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className={`${
                          location?.pathname === "/" && `${" stroke-[#076aff]"}`
                        }`}
                      />
                    </svg>
                    Home
                  </li>
                </Link>
                <Link to="/all-movies">
                  <li
                    className={`${
                      location?.pathname === "/all-movies"
                        ? `${"bg-[#DAE9FF] text-[#076aff]"}`
                        : `${" bg-[#fff] text-[#000000]"}`
                    } cursor-pointer flex items-center pl-[28px] rounded-[10px]   mb-[18px] py-[11px] font-normal font-poppins `}
                    onClick={() => setshow(false)}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      className={`${
                        location?.pathname === "/all-movies" &&
                        `${" fill-[#076aff]"}`
                      } mr-[18px] `}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.7815 2.65393C9.07066 2.65393 7.67884 4.04574 7.67884 5.75654C7.67884 7.46729 9.07066 8.8591 10.7815 8.8591C12.4922 8.8591 13.8841 7.46729 13.8841 5.75654C13.8841 4.04574 12.4922 2.65393 10.7815 2.65393ZM10.7815 7.45281C9.84606 7.45281 9.08509 6.69184 9.08509 5.75649C9.08509 4.82115 9.84606 4.06013 10.7815 4.06013C11.7168 4.06013 12.4778 4.8211 12.4778 5.75649C12.4778 6.69184 11.7168 7.45281 10.7815 7.45281ZM5.75495 7.6787C4.04416 7.6787 2.65234 9.07051 2.65234 10.7813C2.65234 12.492 4.04416 13.8838 5.75491 13.8838C7.46566 13.8838 8.85756 12.492 8.85756 10.7813C8.85756 9.07051 7.46575 7.6787 5.75495 7.6787ZM5.75495 12.4776C4.81956 12.4776 4.05864 11.7166 4.05864 10.7813C4.05864 9.84592 4.81961 9.08495 5.75495 9.08495C6.6903 9.08495 7.45131 9.84592 7.45131 10.7813C7.45131 11.7166 6.6903 12.4776 5.75495 12.4776ZM10.7815 12.7034C9.07066 12.7034 7.67884 14.0952 7.67884 15.806C7.67884 17.5167 9.07066 18.9085 10.7815 18.9085C12.4922 18.9085 13.8841 17.5167 13.8841 15.806C13.8841 14.0952 12.4922 12.7034 10.7815 12.7034ZM10.7815 17.5023C9.84606 17.5023 9.08509 16.7413 9.08509 15.806C9.08509 14.8706 9.84606 14.1097 10.7815 14.1097C11.7168 14.1097 12.4778 14.8706 12.4778 15.806C12.4778 16.7413 11.7168 17.5023 10.7815 17.5023ZM15.808 7.6787C14.0972 7.6787 12.7053 9.07051 12.7053 10.7813C12.7053 12.492 14.0972 13.8838 15.808 13.8838C17.5187 13.8838 18.9105 12.492 18.9105 10.7813C18.9105 9.07051 17.5187 7.6787 15.808 7.6787ZM15.808 12.4776C14.8726 12.4776 14.1116 11.7166 14.1116 10.7813C14.1116 9.84592 14.8726 9.08495 15.808 9.08495C16.7433 9.08495 17.5043 9.84592 17.5043 10.7813C17.5043 11.7166 16.7433 12.4776 15.808 12.4776ZM10.7815 9.72038C10.1965 9.72038 9.72058 10.1963 9.72058 10.7813C9.72058 11.3662 10.1965 11.8421 10.7815 11.8421C11.3665 11.8421 11.8423 11.3662 11.8423 10.7813C11.8423 10.1963 11.3665 9.72038 10.7815 9.72038Z"
                        fill="black"
                        className={`${
                          location?.pathname === "/all-movies" &&
                          `${" fill-[#076aff]"}`
                        } `}
                      />
                      <path
                        d="M20.8594 20.1562H16.1142C19.3866 18.2879 21.5625 14.7881 21.5625 10.7812C21.5625 4.82241 16.7406 0 10.7812 0C4.82241 0 0 4.82194 0 10.7812C0 16.7401 4.82194 21.5625 10.7812 21.5625H20.8594C21.8157 21.5625 22.5938 22.3405 22.5938 23.2969C22.5938 23.6852 22.9086 24 23.2969 24C23.6852 24 24 23.6852 24 23.2969C24 21.5651 22.5911 20.1562 20.8594 20.1562ZM1.40625 10.7812C1.40625 5.61188 5.61188 1.40625 10.7812 1.40625C15.9506 1.40625 20.1562 5.61188 20.1562 10.7812C20.1562 15.9506 15.9506 20.1562 10.7812 20.1562C5.61188 20.1562 1.40625 15.9506 1.40625 10.7812Z"
                        fill="black"
                        className={`${
                          location?.pathname === "/all-movies" &&
                          `${" fill-[#076aff]"}`
                        } `}
                      />
                    </svg>
                    Movies
                  </li>
                </Link>
                {localStorage.getItem('token') && <Link to="/myorder">
                  <li
                    onClick={() => setshow(false)}
                    className={`${
                      location?.pathname === "/myorder"
                        ? `${"bg-[#DAE9FF] text-[#076aff]"}`
                        : `${" bg-[#fff] text-[#000000]"}`
                    } cursor-pointer flex items-center pl-[28px] rounded-[10px] mb-[18px] py-[11px] font-normal font-poppins`}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      className={`${
                        location?.pathname === "/myorder" &&
                        `${" stroke-[#076aff]"}`
                      } mr-[18px] `}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 2H3.74C4.82 2 5.67 2.93 5.58 4L4.75 13.96C4.71759 14.3459 4.76569 14.7342 4.89123 15.1005C5.01678 15.4669 5.21705 15.8031 5.47934 16.0879C5.74163 16.3728 6.06023 16.6001 6.41495 16.7553C6.76967 16.9106 7.15278 16.9905 7.54 16.99H18.19C19.63 16.99 20.89 15.81 21 14.38L21.54 6.88C21.66 5.22 20.4 3.87 18.73 3.87H5.82M9 8H21M16.25 22C16.5815 22 16.8995 21.8683 17.1339 21.6339C17.3683 21.3995 17.5 21.0815 17.5 20.75C17.5 20.4185 17.3683 20.1005 17.1339 19.8661C16.8995 19.6317 16.5815 19.5 16.25 19.5C15.9185 19.5 15.6005 19.6317 15.3661 19.8661C15.1317 20.1005 15 20.4185 15 20.75C15 21.0815 15.1317 21.3995 15.3661 21.6339C15.6005 21.8683 15.9185 22 16.25 22ZM8.25 22C8.58152 22 8.89946 21.8683 9.13388 21.6339C9.3683 21.3995 9.5 21.0815 9.5 20.75C9.5 20.4185 9.3683 20.1005 9.13388 19.8661C8.89946 19.6317 8.58152 19.5 8.25 19.5C7.91848 19.5 7.60054 19.6317 7.36612 19.8661C7.1317 20.1005 7 20.4185 7 20.75C7 21.0815 7.1317 21.3995 7.36612 21.6339C7.60054 21.8683 7.91848 22 8.25 22Z"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className={`${
                          location?.pathname === "/myorder" &&
                          `${" stroke-[#076aff]"}`
                        }`}
                      />
                    </svg>{" "}
                    My Order
                  </li>
                </Link>}
                <Link to="/about-us">
                  <li
                    onClick={() => setshow(false)}
                    className={`${
                      location?.pathname === "/about-us"
                        ? `${"bg-[#DAE9FF] text-[#076aff]"}`
                        : `${" bg-[#fff] text-[#000000]"}`
                    } cursor-pointer flex items-center pl-[28px] rounded-[10px] mb-[18px] py-[11px] font-normal font-poppins`}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      className={`${
                        location?.pathname === "/about-us" &&
                        `${" stroke-[#076aff]"}`
                      } mr-[18px] `}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.4107 4.00001C18.3507 4.00001 19.9107 5.57001 19.9107 7.50001C19.9107 9.39001 18.4107 10.93 16.5407 11C16.4543 10.99 16.3671 10.99 16.2807 11M18.3407 20C19.0607 19.85 19.7407 19.56 20.3007 19.13C21.8607 17.96 21.8607 16.03 20.3007 14.86C19.7507 14.44 19.0807 14.16 18.3707 14M9.1607 10.87C9.0607 10.86 8.9407 10.86 8.8307 10.87C7.68291 10.831 6.59535 10.3468 5.79833 9.51996C5.00132 8.69309 4.55744 7.58846 4.5607 6.44001C4.5607 3.99001 6.5407 2.00001 9.0007 2.00001C10.1769 1.97879 11.3134 2.4257 12.1602 3.24242C13.0069 4.05914 13.4945 5.17877 13.5157 6.35501C13.5369 7.53124 13.09 8.66773 12.2733 9.51446C11.4566 10.3612 10.3369 10.8488 9.1607 10.87ZM4.1607 14.56C1.7407 16.18 1.7407 18.82 4.1607 20.43C6.9107 22.27 11.4207 22.27 14.1707 20.43C16.5907 18.81 16.5907 16.17 14.1707 14.56C11.4307 12.73 6.9207 12.73 4.1607 14.56Z"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className={`${
                          location?.pathname === "/about-us" &&
                          `${" stroke-[#076aff]"}`
                        } mr-[18px] `}
                      />
                    </svg>
                    About us
                  </li>
                </Link>
                <Link to="/contact-us">
                  <li
                    onClick={() => setshow(false)}
                    className={`${
                      location?.pathname === "/contact-us"
                        ? `${"bg-[#DAE9FF] text-[#076aff]"}`
                        : `${" bg-[#fff] text-[#000000]"}`
                    } cursor-pointer flex items-center pl-[28px] rounded-[10px] mb-[18px] py-[11px] font-normal font-poppins text-[#000000]`}
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      className={`${
                        location?.pathname === "/contact-us" &&
                        `${" stroke-[#076aff]"}`
                      } mr-[18px] `}
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.97 18.33C21.97 18.69 21.89 19.06 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C18.8 21.87 18.15 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C9.58811 18.4401 8.49169 17.5041 7.47 16.49C6.45877 15.472 5.5261 14.3789 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.27 13.75 11.79 14.24 12.32 14.69C12.84 15.13 13.27 15.43 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeMiterlimit="10"
                        className={`${
                          location?.pathname === "/contact-us" &&
                          `${" stroke-[#076aff]"}`
                        } mr-[18px] `}
                      />
                    </svg>
                    Contact us
                  </li>
                </Link>
                
                {localStorage.getItem('token') &&   <li
                    onClick={() => {setshow(false)
                    logout()
                    }}
                    className="cursor-pointer flex items-center pl-[28px] rounded-[10px] bg-[#fff]  mb-[18px] py-[11px] font-normal font-poppins text-[#000000]"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      className="mr-[18px]"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.9 7.56C9.21 3.96 11.06 2.49 15.11 2.49H15.24C19.71 2.49 21.5 4.28 21.5 8.75V15.27C21.5 19.74 19.71 21.53 15.24 21.53H15.11C11.09 21.53 9.24 20.08 8.91 16.54M15 12H3.62M5.85 8.65L2.5 12L5.85 15.35"
                        stroke="black"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    Log out
                  </li>}
                
              </ul>
            </div>
          )}
          {show && (
            <div>
              <p className="text-[12px] py-[10px] px-[5px] font-normal font-poppins text-[#060606]">
                Copyright © 2023 Hooc Store. All Rights Reserved.
              </p>
            </div>
          )}
        </div>
      </div>
      {/* )} */}
      <ToastContainer/>
    </div>
  );
}

export default MainLayout;
