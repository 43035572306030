import React from "react";
import logo from "../images/LOGO WHITE 1.svg";
import logo1 from "../images/Frame tiwtter.svg";
import logo2 from "../images/Frame facebook.svg";
import logo3 from "../images/Frame youtube.svg";
import logo4 from "../images/Frame instagram (1).svg";
import logo5 from "../images/Frame (1).svg";
import { Link } from "react-router-dom";

function Footer() {
  const date = new Date();
  return (
    <div className="footerback mt-[20px]">
      <div className="main_container">
        <div className="pt-10 pb-3 flex flex-col md:flex-row  justify-between items-start">
          <div className="w-full lg:w-[65%] flex flex-col justify-between min-h-[120px] sm:min-h-[185px]">
            <div>
            <img src={logo} alt="new"></img>
            <div className="sm:w-[75%]">
            {window?.location?.pathname === '/contact-us' ? <p className="text-[14px] sm:text-[18px] pt-5 xl:pt-7 mb-3 xl:mb-0 font-normal font-poppins text-[#fff]">
            30-B, Amita Building, 3rd Road, Jai Bharat Society, Khar (West), Mumbai City, Maharashtra, India, 400052.
              </p> : <p className="text-[14px] sm:text-[20px] pt-5 sm:pt-7 font-medium font-poppins text-[#fff]">
                Escape into the magic of the movies with us...!
              </p>}
            </div>
            </div>
            <div className=" flex items-center">
            <a href="https://twitter.com/hooc_social" target="_blank" rel="noopener noreferrer"><img src={logo1} alt="new" className=" mr-2"></img></a>
            <img src={logo2} alt="new" className=" mr-2 cursor-pointer"></img>
            <a href="https://www.youtube.com/@houseofoc" target="_blank" rel="noopener noreferrer"><img src={logo3} alt="new" className=" mr-2"></img></a>
            <img src={logo4} alt="new" className=" mr-2 cursor-pointer"></img>
            <a href="https://www.linkedin.com/in/house-of-october-cinemas-45042825b/" target="_blank" rel="noopener noreferrer"><img src={logo5} alt="new" className=" cursor-pointer"></img></a>
            </div>
          </div>
          <div className=" flex items-start justify-around md:justify-between w-full mt-7 md:mt-0 lg:w-[30%]">
          <ul className="text-[#fff] font-poppins ">
            <li className="text-[14px] sm:text-[20px] font-medium">Quick Links</li>
            <li className="pt-5 sm:pt-7 text-[12px] sm:text-[18px] font-normal"><Link to='/all-movies'>Movies</Link></li>
            <li className="py-4 sm:py-6 text-[12px] sm:text-[18px] font-normal cursor-pointer"><Link to='/about-us'>About Us</Link></li>
            <li className="text-[12px] sm:text-[18px] font-normal cursor-pointer"><Link to='/contact-us'>Contact Us</Link></li>
          </ul>
          <div className=" min-h-[120px] border-r border-[#ABA2D8] md:hidden"></div>
          <ul className="text-[#fff] font-poppins ">
            <li className="text-[14px] sm:text-[20px] font-medium">My Account</li>
            <li className="pt-5 sm:pt-7 text-[12px] sm:text-[18px] font-normal"><Link to='/cart'>View Cart</Link></li>
            <li className="py-4 sm:py-6 text-[12px] sm:text-[18px] font-normal"><Link to='/wishlist'>My Wishlist</Link></li>
            <li className="text-[12px] sm:text-[18px] font-normal cursor-pointer"><Link to='/privacy-policy'>Privacy Policy</Link></li>
          </ul>
          </div>
        </div>
        <div className="font-poppins md:flex  text-[#fff] border-t border-[#fff] py-3 sm:py-7  items-center justify-between">
          <p className="lg:text-[20px] font-normal hidden md:flex">
            Copyright © {date.getFullYear()} Hooc Store. All Rights Reserved.
          </p>
         <Link to='/terms-of-conditions'><p className="text-[12px] sm:text-[16px] lg:text-[20px]  text-center md:text-auto font-normal">Terms Of Conditions</p></Link> 
        </div>
      </div>
    </div>
  );
}

export default Footer;