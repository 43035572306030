import axios from "axios";
import { Flip, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

export const actionTypes = {
  MOVIE_LIST: "MOVIE_LIST",
  MOVIE_DETAIL: "MOVIE_DETAIL",
  PRODUCER_MOVIE_LIST: "PRODUCER_MOVIE_LIST",
  LANGUAGE_LIST: "LANGUAGE_LIST",
  GENRE_LIST: "GENRE_LIST",
  ACTOR_LIST: "ACTOR_LIST",
  PRODUCER_MOVIE_DETAIL: "PRODUCER_MOVIE_DETAIL",
  PRODUCER_MOVIE_NEW_LIST: "PRODUCER_MOVIE_NEW_LIST",
  DELETE_MOVIE_LIST: "DELETE_MOVIE_LIST",
  ADD_MOVIE: "ADD_MOVIE",
};

//  Movie list

export const GetMovielist = (query, history) => {
  const token = localStorage.getItem("token");

  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/movie`, {
        params: { ...query },
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.MOVIE_LIST,
          movielist: response.data,
        });
      })
      .catch((err) => {
        if (err?.response?.data?.code === 409) {
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history("/login");
            }
          });
        }
      });
  };
};

// Producer Movie list

export const GetProducerMovielist = (query, history) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/movie/draft`, {
        params: { ...query },
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.PRODUCER_MOVIE_LIST,
          producermovielist: response.data,
        });
      })
      .catch((err) => {
        if (err?.response?.data?.code === 409) {
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history("/login");
            }
          });
        }
      });
  };
};

// PRODUCER Movie Detail New

export const GetProducerMovieNewList = (query, history) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/movie/producer`, {
        params: { ...query },
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.PRODUCER_MOVIE_NEW_LIST,
          producermovienewlist: response.data,
        });
      })
      .catch((err) => {
        if (err?.response?.data?.code === 409) {
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history("/login");
            }
          });
        }
        toast.error(err.response.data.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
      });
  };
};

// Create Movie

export const CreateNewMovie = (
  file,
  file1,
  file2,
  file3,
  file4,
  file5,
  name,
  description,
  language,
  actor,
  genre,
  trailer,
  release,
  duration,
  price,
  discount_,
  minshow,
  check,
  history
) => {
  var data = new FormData();
  var filebanner = new File([file && file[0]], file && file[0]?.name, {
    type: "application/json",
  });
  var fileposter = new File([file1 && file1[0]], file1 && file1[0]?.name, {
    type: "application/json",
  });
  var fileimage1 = new File([file2 && file2[0]], file2 && file2[0]?.name, {
    type: "application/json",
  });
  var fileimage2 = new File([file3 && file3[0]], file3 && file3[0]?.name, {
    type: "application/json",
  });
  var fileimage3 = new File([file4 && file4[0]], file4 && file4[0]?.name, {
    type: "application/json",
  });
  var fileimage4 = new File([file5 && file5[0]], file5 && file5[0]?.name, {
    type: "application/json",
  });
  data.append("name", name);
  data.append("description", description);
  data.append("trailer", trailer);
  data.append("release", release);
  data.append("duration", duration);
  data.append("price", price);
  if (discount_) {
    data.append("discount", discount_);
  }
  data.append("minshow", minshow);
  if (check) {
    data.append("ispopular", check);
  }
  data.append("banner", filebanner);
  data.append("poster", fileposter);
  if (file2.length > 0) {
    data.append("image1", fileimage1);
  }
  if (file3.length > 0) {
    data.append("image2", fileimage2);
  }
  if (file4.length > 0) {
    data.append("image3", fileimage3);
  }
  if (file5.length > 0) {
    data.append("image4", fileimage4);
  }
  actor.forEach((e) => {
    data.append("actor", e);
  });
  language?.forEach((e) => {
    data.append("language", e);
  });
  genre?.forEach((e) => {
    data.append("genre", e);
  });
  // file2?.forEach((image) => {
  //   data.append("image", image);
  // });
  const token = localStorage.getItem("token");

  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/movie`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      })
      .then((response) => {
        history("/admin/movies");
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        dispatch({
          type: actionTypes.ADD_MOVIE,
          addmovie: response.data,
        });
        // dispatch(GetMovielist());
      })
      .catch((err) =>{
        toast.error(err.response.data.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        })
        if (err?.response?.data?.code === 409) {

        Swal.fire({
          title: "Alert!",
          text: "Someone used your login credential now, Please check it or else update your password.",
          icon: "warning",
          confirmButtonColor: "#ff0101",
          confirmButtonText: "Logout",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage?.clear();
            history("/login");
          }
        });
      }

      }
      );
  };
};

// Update Movie

export const UpdateMovie = (
  file,
  file1,
  file2,
  file3,
  file4,
  file5,
  name,
  description,
  language,
  actor,
  genre,
  trailer,
  release,
  duration,
  price,
  discount_,
  minshow,
  isbanneredit,
  isposteredit,
  isimage1edit,
  isimage2edit,
  isimage3edit,
  isimage4edit,
  requestID,
  ispopular,history
) => {
  var data = new FormData();
  var filebanner = new File([file && file[0]], file[0]?.name, {
    type: "application/json",
  });
  var fileposter = new File([file1 && file1[0]], file1[0]?.name, {
    type: "application/json",
  });
  var fileimage1 = new File([file2 && file2[0]], file2[0]?.name, {
    type: "application/json",
  });
  var fileimage2 = new File([file3 && file3[0]], file3[0]?.name, {
    type: "application/json",
  });
  var fileimage3 = new File([file4 && file4[0]], file4[0]?.name, {
    type: "application/json",
  });
  var fileimage4 = new File([file5 && file5[0]], file5[0]?.name, {
    type: "application/json",
  });
  data.append("name", name);
  data.append("description", description);
  data.append("trailer", trailer);
  data.append("release", release);
  data.append("duration", duration);
  if (price) {
    data.append("price", price);
  }
  if (discount_ >= 1 || discount_?.length > 1) {
    data.append("discount", discount_);
  }
  if (minshow) {
    data.append("minshow", minshow);
  }
 
    data.append("ispopular", ispopular);
  
  data.append("isbanneredit", isbanneredit);
  data.append("isposteredit", isposteredit);
  data.append("isimage1edit", isimage1edit);
  data.append("isimage2edit", isimage2edit);
  data.append("isimage3edit", isimage3edit);
  data.append("isimage4edit", isimage4edit);
  data.append("banner", filebanner);
  data.append("poster", fileposter);
  data.append("image1", fileimage1);
  data.append("image2", fileimage2);
  data.append("image3", fileimage3);
  data.append("image4", fileimage4);
  actor.forEach((e) => {
    data.append("actor", e);
  });
  language.forEach((e) => {
    data.append("language", e);
  });
  genre.forEach((e) => {
    data.append("genre", e);
  });
  // file2.forEach((image) => {
  //   data.append("image", image);
  // });

  const token = localStorage.getItem("token");

  return async (dispatch) => {
    await axios
      .patch(`${process.env.REACT_APP_API_BASE_URL}/movie/${requestID}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        // dispatch(GetMovielist());
      })
      .catch((err) =>{
        toast.error(err.response.data.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        })
        if (err?.response?.data?.code === 409) {
        Swal.fire({
          title: "Alert!",
          text: "Someone used your login credential now, Please check it or else update your password.",
          icon: "warning",
          confirmButtonColor: "#ff0101",
          confirmButtonText: "Logout",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage?.clear();
            history("/login");
          }
        });

      }
      }
      );
  };
};
export const UpdateProducersMovies = (
  file,
  file1,
  file2,
  file3,
  file4,
  file5,
  name,
  description,
  language,
  actor,
  genre,
  trailer,
  release,
  duration,
  price,
  discount_,
  minshow,
  isbanneredit,
  isposteredit,
  isimage1edit,
  isimage2edit,
  isimage3edit,
  isimage4edit,
  requestID,
  history
) => {
  var data = new FormData();
  var filebanner = new File([file && file[0]], file[0]?.name, {
    type: "application/json",
  });
  var fileposter = new File([file1 && file1[0]], file1[0]?.name, {
    type: "application/json",
  });
  var fileimage1 = new File([file2 && file2[0]], file2[0]?.name, {
    type: "application/json",
  });
  var fileimage2 = new File([file3 && file3[0]], file3[0]?.name, {
    type: "application/json",
  });
  var fileimage3 = new File([file4 && file4[0]], file4[0]?.name, {
    type: "application/json",
  });
  var fileimage4 = new File([file5 && file5[0]], file5[0]?.name, {
    type: "application/json",
  });
  data.append("name", name);
  data.append("description", description);
  data.append("trailer", trailer);
  data.append("release", release);
  data.append("duration", duration);
  if (price) {
    data.append("price", price);
  }
  if (discount_ >= 1 || discount_?.length > 1) {
    data.append("discount", discount_);
  }
  if (minshow) {
    data.append("minshow", minshow);
  }

  
  data.append("isbanneredit", isbanneredit);
  data.append("isposteredit", isposteredit);
  data.append("isimage1edit", isimage1edit);
  data.append("isimage2edit", isimage2edit);
  data.append("isimage3edit", isimage3edit);
  data.append("isimage4edit", isimage4edit);
  data.append("banner", filebanner);
  data.append("poster", fileposter);
  data.append("image1", fileimage1);
  data.append("image2", fileimage2);
  data.append("image3", fileimage3);
  data.append("image4", fileimage4);
  actor.forEach((e) => {
    data.append("actor", e);
  });
  language.forEach((e) => {
    data.append("language", e);
  });
  genre.forEach((e) => {
    data.append("genre", e);
  });
  // file2.forEach((image) => {
  //   data.append("image", image);
  // });

  const token = localStorage.getItem("token");

  return async (dispatch) => {
    await axios
      .patch(`${process.env.REACT_APP_API_BASE_URL}/movie/${requestID}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        // dispatch(GetMovielist());
      })
      .catch((err) =>{
        toast.error(err.response.data.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        })
        if (err?.response?.data?.code === 409) {
        Swal.fire({
          title: "Alert!",
          text: "Someone used your login credential now, Please check it or else update your password.",
          icon: "warning",
          confirmButtonColor: "#ff0101",
          confirmButtonText: "Logout",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage?.clear();
            history("/login");
          }
        });

      }

      }
      );
  };
};
export const EditProducersMovies = (
  file,
  file1,
  file2,
  file3,
  file4,
  file5,
  name,
  description,
  language,
  actor,
  genre,
  trailer,
  release,
  duration,
  isbanneredit,
  isposteredit,
  isimage1edit,
  isimage2edit,
  isimage3edit,
  isimage4edit,
  requestID,
  history
) => {
  var data = new FormData();
  var filebanner = new File([file && file[0]], file[0]?.name, {
    type: "application/json",
  });
  var fileposter = new File([file1 && file1[0]], file1[0]?.name, {
    type: "application/json",
  });
  var fileimage1 = new File([file2 && file2[0]], file2[0]?.name, {
    type: "application/json",
  });
  var fileimage2 = new File([file3 && file3[0]], file3[0]?.name, {
    type: "application/json",
  });
  var fileimage3 = new File([file4 && file4[0]], file4[0]?.name, {
    type: "application/json",
  });
  var fileimage4 = new File([file5 && file5[0]], file5[0]?.name, {
    type: "application/json",
  });
  data.append("name", name);
  data.append("description", description);
  data.append("trailer", trailer);
  data.append("release", release);
  data.append("duration", duration);
 

  
  data.append("isbanneredit", isbanneredit);
  data.append("isposteredit", isposteredit);
  data.append("isimage1edit", isimage1edit);
  data.append("isimage2edit", isimage2edit);
  data.append("isimage3edit", isimage3edit);
  data.append("isimage4edit", isimage4edit);
  data.append("banner", filebanner);
  data.append("poster", fileposter);
  data.append("image1", fileimage1);
  data.append("image2", fileimage2);
  data.append("image3", fileimage3);
  data.append("image4", fileimage4);
  actor.forEach((e) => {
    data.append("actor", e);
  });
  language.forEach((e) => {
    data.append("language", e);
  });
  genre.forEach((e) => {
    data.append("genre", e);
  });
  // file2.forEach((image) => {
  //   data.append("image", image);
  // });

  const token = localStorage.getItem("token");

  return async (dispatch) => {
    await axios
      .patch(`${process.env.REACT_APP_API_BASE_URL}/movie/${requestID}`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        // dispatch(GetMovielist());
      })
      .catch((err) =>{
        toast.error(err.response.data.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        })
        if (err?.response?.data?.code === 409) {
        Swal.fire({
          title: "Alert!",
          text: "Someone used your login credential now, Please check it or else update your password.",
          icon: "warning",
          confirmButtonColor: "#ff0101",
          confirmButtonText: "Logout",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            localStorage?.clear();
            history("/login");
          }
        });

      }

      }
      );
  };
};
export const UpdateMoviestatus = (data, requestID, newdata, history) => {
  const token = localStorage.getItem("token");

  return async (dispatch) => {
    await axios
      .patch(`${process.env.REACT_APP_API_BASE_URL}/movie/${requestID}`, data, {
        headers: {
          Accept: "application/json",
          Authorization: token,
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        dispatch(GetMovielist(newdata));
      })
      .catch((err) => {
        toast.error(err.response.data.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        if (err?.response?.data?.code === 409) {
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history("/login");
            }
          });
        }
      });
  };
};

// Delete Movie

export const DeleteMovie = (id, data, history) => {
  const token = localStorage.getItem("token");

  return async (dispatch) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/movie/${id}`, {
        headers: {
          Accept: "application/json",
          Authorization: token,
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        dispatch({
          type: actionTypes.DELETE_MOVIE_LIST,
          deletemovielist: response.data,
        });
        dispatch(GetMovielist(data));
      })
      .catch((err) => {
        if (err?.response?.data?.code === 409) {
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history("/login");
            }
          });
        }
      });
  };
};

export const DeleteProducerMovie = (id, data, history) => {
  const token = localStorage.getItem("token");

  return async (dispatch) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/movie/${id}`, {
        headers: {
          Accept: "application/json",
          Authorization: token,
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        dispatch({
          type: actionTypes.DELETE_MOVIE_LIST,
          deletemovielist: response.data,
        });
        dispatch(GetProducerMovielist(data));
      })
      .catch((err) => {
        if (err?.response?.data?.code === 409) {
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history("/login");
            }
          });
        }
      });
  };
};

// Movie Detail

export const GetMovieDetail = (id, history) => {
  const token = localStorage.getItem("token");

  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/movie/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.MOVIE_DETAIL,
          moviedetail: response.data,
        });
      })
      .catch((err) => {
        if (err?.response?.data?.code === 409) {
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history("/login");
            }
          });
        }
      });
  };
};
// PRODUCER Movie Detail

export const GetProducerMovieDetail = (id, history) => {
  const token = localStorage.getItem("token");

  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/movie/draft/${id}`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.PRODUCER_MOVIE_DETAIL,
          producermoviedetail: response.data,
        });
      })
      .catch((err) => {
        if (err?.response?.data?.code === 409) {
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history("/login");
            }
          });
        }
      });
  };
};

// Create Movie

export const CreateProducerMovie = (
  file,
  file1,
  file2,
  file3,
  file4,
  file5,
  name,
  description,
  language,
  actor,
  genre,
  trailer,
  release,
  duration,
  history
) => {
  var data = new FormData();
  var filebanner = new File([file && file[0]], file && file[0]?.name, {
    type: "application/json",
  });
  var fileposter = new File([file1 && file1[0]], file1 && file1[0]?.name, {
    type: "application/json",
  });
  var fileimage1 = new File([file2 && file2[0]], file2[0]?.name, {
    type: "application/json",
  });
  var fileimage2 = new File([file3 && file3[0]], file3[0]?.name, {
    type: "application/json",
  });
  var fileimage3 = new File([file4 && file4[0]], file4[0]?.name, {
    type: "application/json",
  });
  var fileimage4 = new File([file5 && file5[0]], file5[0]?.name, {
    type: "application/json",
  });
  data.append("name", name);
  data.append("description", description);
  data.append("trailer", trailer);
  data.append("release", release);
  data.append("duration", duration);
  data.append("banner", filebanner);
  data.append("poster", fileposter);
  if (file2.length > 0) {
    data.append("image1", fileimage1);
  }
  if (file3.length > 0) {
    data.append("image2", fileimage2);
  }
  if (file4.length > 0) {
    data.append("image3", fileimage3);
  }
  if (file5.length > 0) {
    data.append("image4", fileimage4);
  }
  actor.forEach((e) => {
    data.append("actor", e);
  });
  language?.forEach((e) => {
    data.append("language", e);
  });
  genre?.forEach((e) => {
    data.append("genre", e);
  });

  // file2?.forEach((image) => {
  //   data.append("image", image);
  // });
  const token = localStorage.getItem("token");

  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/movie`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token,
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        history("/admin/producer-movies");
        // dispatch(GetMovielist());
        dispatch({
          type: actionTypes.ADD_MOVIE,
          addmovie: response.data,
        });
      })
      .catch((err) =>
        toast.error(err.response.data.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        })
      );
  };
};

// lANGUAGE list

export const GetLanguagelist = () => {
  const token = localStorage.getItem("token");

  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/movielanguage`, {
        headers: {
          // 'Authorization' : token,
          Accept: "application/json",
          Authorization: token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.LANGUAGE_LIST,
          languagelist: response.data,
        });
      })
      .catch((err) => console.log(err));
  };
};

// Genre list

export const GetGenrelist = () => {
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/moviegenre`, {
        headers: {
          // 'Authorization' : token,
          Accept: "application/json",
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.GENRE_LIST,
          genrelist: response.data,
        });
      })
      .catch((err) => console.log(err));
  };
};

// Actor list

export const GetActorlist = () => {
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/movieactor`, {
        headers: {
          // 'Authorization' : token,
          Accept: "application/json",
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.ACTOR_LIST,
          actorlist: response.data,
        });
      })
      .catch((err) => console.log(err));
  };
};

export const ResetMovie = (RESET) => {
  return {
    type: RESET,
  };
};
