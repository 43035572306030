import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import arrow from "../../images/rightarr.png";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { GetManagerDetail } from "../../Redux/Action/ManagerAction";

function ManagerDetail() {
  const [Name, setName] = useState("");
  const [Lastname, setLastname] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const managerdetail = useSelector(state => state?.manager?.managerdetail?.data?.manager)
  useEffect(() => {
     setName(managerdetail?.firstname)
     setLastname(managerdetail?.lastname)
     setEmail(managerdetail?.emailID)
     setMobile(managerdetail?.contact)
  },[managerdetail])

     
  const {id} = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  useEffect(() => {
    dispatch(GetManagerDetail(id , history))
  },[])
  return (
    <div>
      <div className="pt-[20px]">
        <div className="flex items-center pb-[7px]">
          <Link to="/admin/manager">
            <p className=" text-[#076aff] cursor-pointer text-[18px] font-medium font-poppins">
              MANAGER
            </p>
          </Link>
          <img src={arrow} alt="new" className="mx-3"></img>
          <p className=" text-[#161616] text-[18px] font-medium font-poppins">
            Manager Details
          </p>
        </div>
      </div>
      <div className="mt-[20px] grid grid-cols-12 gap-x-4 lg:gap-x-7 sm:mt-[30px]">
        <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-0">
          <p className="text-base text-[#161616] font-medium font-poppins">
          First Name
          </p>
          <input
            className="h-[46px] rounded-[5px] border border-[#6D6E70]  bg-[#fff] pl-5 mt-3 w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
            value={Name}
            onChange={(e) => setName(e.target.value)}
            disabled
          ></input>
        </div>
        <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-0">
          <p className="text-base text-[#161616] font-medium font-poppins">
          Last Name
          </p>
          <input
            className="h-[46px] rounded-[5px] border border-[#6D6E70]  bg-[#fff] pl-5 mt-3 w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
            value={Lastname}
            onChange={(e) => setLastname(e.target.value)}
            disabled
          ></input>
        </div>
        <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-0">
          <p className="text-base text-[#161616] font-medium font-poppins">
            Email
          </p>
          <input
            className="h-[46px] rounded-[5px] pl-5 mt-3 border border-[#6D6E70]  bg-[#fff] w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
            disabled
          ></input>
        </div>
        <div className=" col-span-12 sm:col-span-4 sm:mt-[20px]">
          <p className="text-base text-[#161616] font-medium font-poppins">
            Mobile No
          </p>
          <input
            className="h-[46px] rounded-[5px] pl-5 mt-3 w-full border border-[#6D6E70]  bg-[#fff] placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
            value={Mobile}
            onChange={(e) => setMobile(e.target.value)}
            disabled
          ></input>
        </div>
      </div>
    </div>
  );
}

export default ManagerDetail;
