export const actionTypes = {
    BANNER_LIST: "BANNER_LIST",
    BANNER_MOVIE_LIST: "BANNER_MOVIE_LIST",
    RESET_BANNER_MOVIE_LIST: "RESET_BANNER_MOVIE_LIST",
  };
  
  const INITIAL_STATE = {
    bannerlist: [],
    bannermovielist: [],
  };
  
  const HomeBannerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case actionTypes.BANNER_LIST:
        return {
          ...state,
          bannerlist: action.bannerlist,
        };
        case actionTypes.BANNER_MOVIE_LIST:
          return {
            ...state,
            bannermovielist: action.bannermovielist,
          };
        case actionTypes.RESET_BANNER_MOVIE_LIST:
          return {
            ...state,
            bannermovielist: action.INITIAL_STATE,
          };
    
      default:
        return state;
    }
  };
  
  export default HomeBannerReducer;