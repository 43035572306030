export const actionTypes = {
  MOVIE_LIST: "MOVIE_LIST",
  DELETE_MOVIE_LIST: "DELETE_MOVIE_LIST",
  ADD_MOVIE: "ADD_MOVIE",
  PRODUCER_MOVIE_LIST: "PRODUCER_MOVIE_LIST",
  MOVIE_DETAIL: "MOVIE_DETAIL",
  RESET_MOVIE_DETAIL: "RESET_MOVIE_DETAIL",
  PRODUCER_MOVIE_DETAIL: "PRODUCER_MOVIE_DETAIL",
  RESET_PRODUCER_MOVIE_DETAIL: "RESET_PRODUCER_MOVIE_DETAIL",
  LANGUAGE_LIST: "LANGUAGE_LIST",
  GENRE_LIST: "GENRE_LIST",
  ACTOR_LIST: "ACTOR_LIST",
  PRODUCER_MOVIE_NEW_LIST: "PRODUCER_MOVIE_NEW_LIST",
};

const INITIAL_STATE = {
  movielist: [],
  deletemovielist: [],
  addmovie: [],
  producermovielist: [],
  moviedetail: [],
  producermoviedetail: [],
  languagelist: [],
  genrelist: [],
  actorlist: [],
  producermovienewlist: [],

};

const MovieReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.MOVIE_LIST:
      return {
        ...state,
        movielist: action.movielist,
      };
    case actionTypes.ADD_MOVIE:
      return {
        ...state,
        addmovie: action.addmovie,
      };
    case actionTypes.MOVIE_DETAIL:
      return {
        ...state,
        moviedetail: action.moviedetail,
      };
    case actionTypes.RESET_MOVIE_DETAIL:
      return {
        ...state,
        moviedetail: action.INITIAL_STATE,
      };
    case actionTypes.LANGUAGE_LIST:
      return {
        ...state,
        languagelist: action.languagelist,
      };
    case actionTypes.GENRE_LIST:
      return {
        ...state,
        genrelist: action.genrelist,
      };
      case actionTypes.ACTOR_LIST:
      return {
        ...state,
        actorlist: action.actorlist,
      };
      case actionTypes.PRODUCER_MOVIE_LIST:
      return {
        ...state,
        producermovielist: action.producermovielist,
      };
      case actionTypes.PRODUCER_MOVIE_DETAIL:
      return {
        ...state,
        producermoviedetail: action.producermoviedetail,
      };
      case actionTypes.RESET_PRODUCER_MOVIE_DETAIL:
      return {
        ...state,
        producermoviedetail: action.INITIAL_STATE,
      };
      case actionTypes.PRODUCER_MOVIE_NEW_LIST:
      return {
       ...state,
       producermovienewlist : action.producermovienewlist,
      };
      case actionTypes.DELETE_MOVIE_LIST:
      return {
       ...state,
       deletemovielist : action.deletemovielist,
      };
    default:
      return state;
  }
};

export default MovieReducer;
