import React from "react";
import arrow from "../../images/rightarr.png";
import calendar from "../../images/calendar.png";
import IncomeGraph from "./IncomeGraph";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { GetTransactionData } from "../../Redux/Action/TransactionAction";

function TransactionList() {
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const history = useNavigate();
  const handleSelection = (range) => {
    setSelectionRange(range.selection);
  };

  const handleSend = () => {
    const { startDate, endDate } = selectionRange;
    const data = {
      start: new Date(startDate).valueOf(),
      end: new Date(endDate).valueOf(),
    };
    dispatch(GetTransactionData(data));
  };

  const ClearDateSend = () => {
    setSelectionRange({ startDate: new Date(), endDate: new Date() , key: "selection",});
    dispatch(GetTransactionData(""));
  };

  const [open, setopen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetTransactionData(""));
  }, []);

  const transactiondatalist = useSelector(
    (state) => state.Transactiondata.Transactionlist.data
  );
  
  return (
    <div className="pt-[15px] h-full">
      <div className=" flex items-center relative justify-between">
        <p className="text-[22px] text-[#161616] font-poppins font-medium">
          TRANSACTION
        </p>
        <button
          onClick={() => setopen(!open)}
          className=" bg-[#076aff] text-[#fff]  flex items-center min-h-[40px] min-w-[100px] rounded-[5px] justify-center"
        >
          <img src={calendar} alt="new"></img>
          <span className=" font-poppins pl-3 text-[14px]">Date</span>
        </button>
        {open && (
          <div className=" absolute right-0 top-10  d-inline-block z-[100] shadow-xl">
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelection}
              rangeColors={["#076aff"]}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              popperProps={{
                strategy: "fixed", // use this to make the popper position: fixed
              }}
              direction="horizontal"
            />
            <div className="text-right position-relative  rdr-buttons-position pb-2 bg-[#fff]">
              <button
                className=" bg-[#076aff] py-1 text-[12px] text-[#fff] rounded-[5px] px-4 mr-2"
                onClick={() => {
                  handleSend();
                  setopen(false);
                }}
              >
                Apply
              </button>
              <button
                onClick={() => {
                  ClearDateSend();
                  setopen(false);
                }}
                className=" border border-[#076aff] text-[#076aff] py-1 text-[12px]  rounded-[5px] px-4 mr-2"
              >
                Clear
              </button>
            </div>
          </div>
        )}
      </div>
      <div className=" grid grid-cols-12 gap-x-5 2xl:gap-x-9 gap-y-5 sm:gap-y-9  mt-5">
        <div className=" bg-[#fff] boxshadow  col-span-12 sm:col-span-6 md:col-span-4 py-6 rounded-[10px] text-center ">
          <p className=" text-[18px] font-poppins pb-2.5">Total Movie</p>
          <p className=" text-[30px] font-poppins text-[#161616] font-medium">
            {transactiondatalist?.movies && transactiondatalist?.movies}
          </p>
        </div>
       <Link to='/admin/movie_report' className="col-span-12 sm:col-span-6 md:col-span-4"> <div className=" bg-[#fff] boxshadow   py-6 rounded-[10px] text-center">
          <p className=" text-[18px] font-poppins pb-2.5 flex items-center justify-center">
            Live & Pending Movie
            <span>
                <img src={arrow} alt="new" className=" ml-3"></img>
              </span>
          </p>
          <p className=" text-[30px] font-poppins text-[#161616] font-medium">
            {transactiondatalist && (
              <span>
                {transactiondatalist?.live} / {transactiondatalist?.pending}
              </span>
            )}
          </p>
        </div></Link>
        <div className=" bg-[#fff] boxshadow  col-span-12 sm:col-span-6 md:col-span-4 py-6 rounded-[10px] text-center">
          <p className=" text-[18px] font-poppins pb-2.5">Total Income</p>
          <p className=" text-[30px] font-poppins text-[#161616] font-medium">
            {transactiondatalist?.totalIncome &&
              transactiondatalist?.totalIncome?.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
              })}
          </p>
        </div>
        <Link
          to="/admin/vle-transactionlist"
          className=" col-span-12 sm:col-span-6 md:col-span-4"
        >
          <div className=" bg-[#fff] boxshadow  py-6 rounded-[10px] text-center">
            <p className=" text-[18px] font-poppins pb-2.5  flex items-center justify-center">
              Total VLE{" "}
              <span>
                <img src={arrow} alt="new" className=" ml-3"></img>
              </span>
            </p>
            <p className=" text-[30px] font-poppins text-[#161616] font-medium">
              {transactiondatalist?.vle && transactiondatalist?.vle}
            </p>
          </div>
        </Link>
        <Link
          to="/admin/producer-transactionlist"
          className=" col-span-12 sm:col-span-6 md:col-span-4"
        >
          <div className=" bg-[#fff] boxshadow  py-6 rounded-[10px] text-center">
            <p className=" text-[18px] font-poppins pb-2.5 flex items-center justify-center">
              Total Producer
              <span>
                <img src={arrow} className=" ml-3" alt="new"></img>
              </span>
            </p>
            <p className=" text-[30px] font-poppins text-[#161616] font-medium">
              {transactiondatalist?.producer && transactiondatalist?.producer}
            </p>
          </div>
        </Link>
        <div className=" bg-[#fff] boxshadow  py-6 rounded-[10px] text-center col-span-12 sm:col-span-6 md:col-span-4">
            <p className=" text-[18px] font-poppins pb-2.5 flex items-center justify-center">
              Total Orders
            </p>
            <p className=" text-[30px] font-poppins text-[#161616] font-medium">
              {transactiondatalist?.order && transactiondatalist?.order}
            </p>
          </div>
      </div>
      <div className="py-9 ">
        <IncomeGraph />
      </div>
    </div>
  );
}

export default TransactionList;
