import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import arrow from "../../../images/rightarr.png";
import calendar from "../../../images/calendar.png";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useDispatch, useSelector } from "react-redux";
import { Getvletransactiondetail } from "../../../Redux/Action/TransactionAction";
import ReactPaginate from "react-paginate";

function VleTransaction() {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
    },
  ]);

  const [open, setopen] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    dispatch(Getvletransactiondetail(id, history));
  }, []);

  const Vletransactiondetail = useSelector(
    (state) => state.Transactiondata.Vletransactiondetail.data
  );

  return (
    <div className="pt-[20px] pb-[50px]">
      <div className=" sm:flex items-center justify-between relative pb-[7px]">
        <div className=" flex items-center">
          <Link to={"/admin/transaction"}>
            <p className=" text-[#076aff] cursor-pointer text-[18px] font-medium font-poppins">
              TRANSACTION
            </p>
          </Link>
          <img src={arrow} alt="new" className="mx-3"></img>
          <Link to={"/admin/vle-transactionlist"}>
            <p className=" text-[#076aff] cursor-pointer text-[18px] font-medium font-poppins">
              Total VLE
            </p>
          </Link>
          <img src={arrow} alt="new" className="mx-3"></img>
          <p className=" text-[#161616] text-[18px] font-medium font-poppins">
            Transaction Details
          </p>
        </div>
        <button
          onClick={() => setopen(!open)}
          className=" bg-[#076aff] text-[#fff]  flex items-center min-h-[40px] min-w-[100px] rounded-[5px] justify-center"
        >
          <img src={calendar} alt="new"></img>
          <span className=" font-poppins pl-3 text-[14px]">Date</span>
        </button>
        {open && (
          <div className=" absolute right-0 top-10">
            <DateRangePicker
              onChange={(item) => {
                setState([item.range1]);
              }}
              ranges={state}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              showDateDisplay={true}
            />
          </div>
        )}
      </div>

      <div className=" grid grid-cols-12 gap-5 2xl:gap-9 mt-5">
        <div className=" bg-[#fff] boxshadow col-span-12 sm:col-span-6 md:col-span-4 py-6 rounded-[10px] text-center ">
          <p className=" text-[18px] font-poppins pb-2.5">Purchase Movies</p>
          <p className=" text-[30px] font-poppins text-[#161616] font-medium">
            {Vletransactiondetail?.vle?.movie &&
              Vletransactiondetail?.vle?.movie}
          </p>
        </div>
        <div className=" bg-[#fff] boxshadow col-span-12 sm:col-span-6 md:col-span-4 py-6 rounded-[10px] text-center">
          <p className=" text-[18px] font-poppins pb-2.5">Purchase Shows</p>
          <p className=" text-[30px] font-poppins text-[#161616] font-medium">
            {Vletransactiondetail?.vle?.shows &&
              Vletransactiondetail?.vle?.shows}
          </p>
        </div>
        <div className=" bg-[#fff] boxshadow col-span-12 sm:col-span-6 md:col-span-4 py-6 rounded-[10px] text-center">
          <p className=" text-[18px] font-poppins pb-2.5">Total Income</p>
          <p className=" text-[30px] font-poppins text-[#161616] font-medium">
            {Vletransactiondetail?.vle?.income &&
              Vletransactiondetail?.vle?.income?.toLocaleString("en-US", {
                style: "currency",
                currency: "INR",
                minimumFractionDigits: 0,
              })}
          </p>
        </div>
      </div>

      {/*------------------------- Table ---------------------------- */}
      {Vletransactiondetail?.vle?.movies?.length > 0 ? (
        <div>
          <div className="tableFixHead mt-5">
            <table className="w-full listtableshadow overflow-auto">
              <thead className="bg-[#F9FAFB]" style={{ minHeight: 60 }}>
                <tr className="text-[#161616] font-medium font-poppins">
                  <td className="py-4 pl-[40px] min-w-[100px]">ID</td>
                  <td className="py-4 min-w-[320px]">
                    <div className=" flex items-center">Movie</div>
                  </td>
                  <td className="py-4 min-w-[320px]">
                    <div className=" flex items-center">Shows</div>
                  </td>
                  <td className="py-4 min-w-[180px]">
                    <div className=" flex items-center">Income</div>
                  </td>
                </tr>
              </thead>
              <tbody>
                {Vletransactiondetail?.vle?.movies?.map((m, index) => (
                  <tr className={`border-b bg-[#fff]`} key={index}>
                    <td className="text-[#47505F] text-[14px] font-medium font-poppins pl-[40px] py-[20px]">
                      {index + 1}
                    </td>
                    <td className="py-[14px] max-w-[200px]">
                      <div className=" flex items-center">
                        <img
                          src={`${`https://hooc.b-cdn.net/${
                            m?.poster_image
                          }?t=${Date.now()}`}`}
                          className=" w-[50px] h-[50px] rounded-[5px]"
                          alt="new"
                        ></img>
                        <div className=" pl-[16px]">
                          <p className="text-[#47505F] text-[14px] font-medium font-poppins">
                            {m?.name?.length > 25 ? (
                              <>{`${m?.name?.substring(0, 25)}...`}</>
                            ) : (
                              <>{m?.name}</>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="text-[#47505F] text-[14px] font-medium font-poppins py-[20px]">
                      <div className=" ml-2">{m?.movieshows}</div>
                    </td>
                    <td className="text-[#47505F] text-[14px] font-medium font-poppins py-[20px]">
                      <div>
                        {m?.movieIncome?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "INR",
                          minimumFractionDigits: 0,
                        })}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* <div className=" md:flex items-center justify-between w-full py-[20px] bg-[#fff] rounded-b-[10px] boxshadow px-[20px] sm:px-[40px] mb-[30px]">
            <div className=" flex items-center mb-5 sm:mb-0">
              <p className="text-[#47505F] font-medium font-[poppins] ">View</p>

              {/* ----------------------select page ------------------------ */}

          {/* <Menu as="div" className="relative text-left">
            <div>
              <Menu.Button className="flex px-[20px] ml-[24px] min-h-[40px] bg-[#F2F2F2] min-w-[85px] rounded-[8px] justify-between  text-sm font-poppins font-medium  items-center ">
                <p className="text-[#47505F] font-medium font-[poppins]">
                  {page}
                </p>
                <img className="" src={arrowdown} alt="new" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="bottom-full absolute right-0 z-10 mt-2 py-2 w-[80%] rounded-[12px] bg-white shadoe-sm focus:outline-none">
                {allvalue?.map((e) => (
                  <div key={e?.id}>
                    <Menu.Item>
                      {({ active }) => (
                        <p
                          className={classNames(
                            active ? "bg-[#DAE9FF] text-[#076aff]" : "",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                          onClick={() => onselectedpage(e)}
                        >
                          {e?.value}
                        </p>
                      )}
                    </Menu.Item>
                  </div>
                ))}
              </Menu.Items>
            </Transition>
          </Menu> */}

          {/* ----------------------End select page ------------------------ */}
          {/* </div> */}

          {/* ------------------------- pagination -------------------------*/}
          {/* <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              // pageCount={Itemperpage} // Replace with the total number of pages
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              // onPageChange={handlePageChange}
              containerClassName={"pagination"}
              previousLinkClassName={"pagination__link"}
              nextLinkClassName={"pagination__link"}
              disabledClassName={"pagination__link--disabled"}
              activeClassName={"pagination__link--active"}
              // forcePage={selectpage}
            /> */}
          {/* -------------------------End  pagination -------------------------*/}
          {/* // </div> */}
        </div>
      ) : (
        <div className=" flex items-center justify-center min-h-[300px] sm:text-[20px] text-[#161616] font-poppins font-medium">
          No Transaction Detail Found!!
        </div>
      )}
    </div>
  );
}

export default VleTransaction;
