import React from 'react'
import { useNavigate } from 'react-router';
import Swal from 'sweetalert2';

function Loginmodal() {
    const history = useNavigate();
    const Newmodal = () => {
    Swal.fire({
    title: "Alert!",
    text: "Someone used your login credential now, Please check it or else update your password.",
    icon: "warning",
    confirmButtonColor: "#ff0101",
    confirmButtonText: "Logout",
    allowOutsideClick: false
  }).then((result) => {
    if (result.isConfirmed) {
      localStorage?.clear();
      history('/login');
    }
  })
  
  ;}

  return (
    <div>
      {Newmodal()}
    </div>
  )
}

export default Loginmodal