import React, { useState } from "react";
import search from "../images/search.png";
import reject from "../images/reject.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  AddBanner,
  DeleteBanner,
  GetBannerlist,
  GetBannermovielist,
} from "../Redux/Action/HomebannerAction";
import Swal from "sweetalert2";
import { useNavigate } from "react-router";

function HomepageBanner() {
 

  const bannerlist = useSelector((state) => state?.banner?.bannerlist?.data);
  const bannermovielist = useSelector(
    (state) => state.banner.bannermovielist.data
  );



  const dispatch = useDispatch();
  const history = useNavigate();
  useEffect(() => {
    dispatch(GetBannerlist(history));
  }, []);
  

  //--------------------Banner delete modal------------------------------

  const modal = (e, id) => {
    e?.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to remove this Banner!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#076aff",
      cancelButtonColor: "#ff0101",
      confirmButtonText: "Yes, Remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(DeleteBanner(id , history));
      }
    });
  };

  const [filteredResults, setFilteredResults] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [delayedSearchTerm, setDelayedSearchTerm] = React.useState("");
  const [movielist, setmovielist] = React.useState(false);
  const [movieid, setmovieid] = React.useState("");

  const onselectedvalue = (item) => {
    setSearchInput(item.name);
    setmovielist(false);
    setDelayedSearchTerm("");
    setmovieid(item._id);
  };

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchInput.length > 0) {
        dispatch(GetBannermovielist({ search: searchInput } , history));
      }
      setDelayedSearchTerm(searchInput);
    }, 500);

    return () => clearTimeout(delayDebounceFn);
  }, [searchInput]);

  const AddBannermovie = (e) => {
    e?.preventDefault();
    if (searchInput?.length > 0) {
      const data = {
        movieID: movieid,
      };
      dispatch(AddBanner(data , history));
      setSearchInput("");
      setmovielist("");
    }
  };

  const searchItems = (searchValue) => {
    setSearchInput(searchValue);
    if (searchValue?.length > 0) {
      setmovielist(true);
    } else {
      setmovielist(false);
    }

    if (searchInput !== searchValue) {
      if (searchInput !== "") {
        const filteredData = bannermovielist?.filter((item) => {
          return Object.values(item)
            .join("")
            .toLowerCase()
            .includes(searchInput.toLowerCase());
        });
        setFilteredResults(filteredData);
        if (filteredData?.length === 0) {
          setmovielist(false);
        }
      } else {
        setFilteredResults(bannermovielist);
      }
    }
  };
  return (
    <div className="pt-[20px] h-full">
      {/* <ToastContainer /> */}
      <div className="flex items-center justify-between">
        <p className="sm:text-[22px] text-[#161616] font-poppins font-medium">
          Home Banner
        </p>
      </div>

      {/*-------------------------- search bar -----------------------------------  */}

      <div className="mt-[15px] mb-[20px] sm:mb-[30px] p-5 bg-[#fff] rounded-[10px] lg:flex  listtableshadow lg:max-h-[80px]">
        <div className="relative">
          <div className="h-[40px]">
            <input
              className="h-full bg-[#F2F2F2] rounded-[8px] pl-[50px] min-w-full lg:min-w-[400px] placeholder:text-[#47505F]  placeholder:text-[14px] placeholder:font-medium"
              placeholder="Search..."
              value={searchInput}
              onChange={(e) => searchItems(e.target.value)}
            ></input>
            <img src={search} className="-mt-7 ml-[20px]" alt="new"></img>
         
          </div>
          {movielist && (
              <div
                style={{ height: 200, overflow: "auto" }}
                className=" min-w-full lg:max-w-[400px] position-absolute top-36 bg-white shadow-lg p-3 mb-5 bg-body"
              >
                {delayedSearchTerm.length > 0 ? (
                  filteredResults?.map((item) => {
                    return (
                      <div key={item._id} onClick={() => onselectedvalue(item)} className="py-2 flex items-center border-b cursor-pointer">
                        <img className=" w-[50px] h-[50px] rounded-[10px]" src={`${`https://hooc.b-cdn.net/${item?.poster_image}`}`}></img>
                        <p className="cursor-pointer border-bottom pb-2 ml-5 border-[#F2F2F2] text-[#161616] text-[12px] sm:text-[14px] 2xl:text-[16px] font-medium font-poppins">
                          {item.name}
                        </p>
                      </div>
                    );
                  })
                ) : (
                  <div>
                    <p className="d-flex justify-content-center align-items-center">
                      No Movie Found!
                    </p>
                  </div>
                )}
              </div>
            )}
        </div>
        <button
          onClick={() => AddBannermovie()}
          disabled={!searchInput}
          className={`${
            searchInput.length === 0 && `${" opacity-50"}`
          } bg-[#076AFF] lg:ml-5 mt-3 lg:mt-0 flex items-center min-w-[100px] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium rounded-[5px] h-[40px] text-white text-[14px]`}
        >
          <span className="mx-[16px]">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.14174 1.85721C9.14174 1.22507 8.63103 0.714355 7.99888 0.714355C7.36674 0.714355 6.85603 1.22507 6.85603 1.85721V7.00007H1.71317C1.08103 7.00007 0.570312 7.51078 0.570312 8.14293C0.570312 8.77507 1.08103 9.28578 1.71317 9.28578H6.85603V14.4286C6.85603 15.0608 7.36674 15.5715 7.99888 15.5715C8.63103 15.5715 9.14174 15.0608 9.14174 14.4286V9.28578H14.2846C14.9167 9.28578 15.4275 8.77507 15.4275 8.14293C15.4275 7.51078 14.9167 7.00007 14.2846 7.00007H9.14174V1.85721Z"
                fill="white"
              />
            </svg>
          </span>
          Add
        </button>
      </div>

      {/*--------------------------- Movie list ---------------------------------- */}

      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-x-3 lg:gap-x-6">
        {bannerlist && bannerlist?.length > 0 && bannerlist?.map((m) => {
          return (
            <div
              className="bg-[#fff] rounded-[10px] boxshadow mb-[30px]"
              key={m?._id}
            >
              <img
                src={`${`https://hooc.b-cdn.net/${
                  m?.banner_image
                }?t=${Date.now()}`}`}
                alt="new"
                className="w-full h-[96px] sm:h-[86px] rounded-t-[10px] 2xl:h-[109px]"
              ></img>
              <div className="px-3 2xl:px-5 py-[10px] sm:flex items-center justify-between">
                <p className="text-[#161616] text-[12px] sm:text-[14px] 2xl:text-[16px] pt-[3px] font-medium font-poppins truncate">
                  {m?.name}
                </p>
                <button
                  onClick={(e) => modal(e, m._id)}
                  className="bg-[#f7d2d5] px-3 mt-2 sm:mt-0  max-w-[100px] text-[12px] xl:text-[14px] flex items-center justify-center w-full  hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium rounded-[5px] h-[30px] text-[#FF0101]"
                >
                  <img
                    src={reject}
                    className="mr-[5px] w-[14px] h-[14px]"
                    alt="new"
                  ></img>
                  Remove
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default HomepageBanner;
