import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import arrow from "../../images/rightarr.png";
import { useDispatch, useSelector } from "react-redux";
import { CreateNewManager } from "../../Redux/Action/ManagerAction";
import { useState } from "react";

function CreateManager() {
  const [Name, setName] = useState("");
  const [Lastname, setLastname] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");

  const dispatch = useDispatch();

  const history = useNavigate();

  const onSubmitform = (e) => {
    e?.preventDefault();
    const data = {
      "firstname": Name,
      "lastname": Lastname,
      "emailID": Email,
      "contact": Mobile
    }
    dispatch(CreateNewManager(data , history))
  }

  const addmanager = useSelector((state) => state.manager.addmanager);

  useEffect(() => {
    if(addmanager.code === 200){
      setName('')
      setLastname('')
      setEmail('')
      setMobile('')
      }
  },[addmanager])
  return (
    <div>
      <div className="pt-[20px]">
        <div className="flex items-center pb-[7px]">
          <Link to="/admin/manager">
            <p className=" text-[#076aff] cursor-pointer text-[18px] font-medium font-poppins">
              MANAGER
            </p>
          </Link>
          <img src={arrow} alt="new" className="mx-3"></img>
          <p className=" text-[#161616] text-[18px] font-medium font-poppins">
            Create Manager
          </p>
        </div>
      </div>
      <form onSubmit={onSubmitform}>
        <div className="mt-[20px] grid grid-cols-12 gap-x-4 lg:gap-x-7 sm:mt-[30px]">
          <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-0">
            <p className="text-base text-[#161616] font-medium font-poppins">
            First  Name
            </p>
            <input
              className="h-[46px] rounded-[5px] border border-[#6D6E70]  bg-[#fff] pl-5 mt-3 w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
              placeholder="Enter First name"
              type="text"
              value={Name}
              onChange={(e) => setName(e.target.value)}
              required
            ></input>
          </div>
          <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-0">
            <p className="text-base text-[#161616] font-medium font-poppins">
              Last Name
            </p>
            <input
              className="h-[46px] rounded-[5px] border border-[#6D6E70]  bg-[#fff] pl-5 mt-3 w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
              placeholder="Enter Last name"
              type="text"
              value={Lastname}
              onChange={(e) => setLastname(e.target.value)}
              required
            ></input>
          </div>
          <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-0">
            <p className="text-base text-[#161616] font-medium font-poppins">
              Email
            </p>
            <input
              className="h-[46px] rounded-[5px] pl-5 mt-3 border border-[#6D6E70]  bg-[#fff] w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
              placeholder="Enter Email"
              type="email"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              required
            ></input>
          </div>
          <div className=" col-span-12 sm:col-span-4 sm:mt-[20px] mt-0">
            <p className="text-base text-[#161616] font-medium font-poppins">
              Mobile No
            </p>
            <input
              className="h-[46px] rounded-[5px] pl-5 mt-3 w-full border border-[#6D6E70]  bg-[#fff] placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
              placeholder="Enter Mobile No"
              value={Mobile}
              onChange={(e) => setMobile(e.target.value)}
              type="number"
              required
            ></input>
          </div>
        </div>
        <div className="my-[20px] sm:my-[30px] flex items-center">
          <div className="min-w-[165px]">
            <button
              type="submit"
              className="bg-[#076AFF] flex items-center justify-center w-full  hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium rounded-[5px] h-[46px] text-white"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateManager;
