import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App'
import { Provider } from 'react-redux';
import { store } from './Redux/store';
import { BrowserRouter } from "react-router-dom";


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
      <BrowserRouter>
      <App />  
    </BrowserRouter>
    </Provider>
);


// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/service-worker.js')
//     .then(function(registration) {
//       console.log('Service Worker registered with scope:', registration.scope);
//     })
//     .catch(function(error) {
//       console.error('Service Worker registration failed:', error);
//     });
// }
