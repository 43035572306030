import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { OrderList } from "../Redux/Action/OrderAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import ReactPaginate from "react-paginate";
import { Menu, Transition } from "@headlessui/react";
import arrowdown from "../images/arrow-down.svg";
import emptyorder from "../images/emptyorder.svg";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function MyOrder() {
 const dispatch = useDispatch();
 const history = useNavigate();
 const [searchParams, setSearchParams] = useSearchParams();
 
  useEffect(() =>{
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    dispatch(OrderList({...object},history));
  },[])
  
  const orderlist = useSelector((state) => state.order.orderlist.data);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const convertReleaseTime = (duration) => {
    const d = new Date(duration);
    var formatted = moment(d).format("DD/MM/YYYY");
    return (
      <span className=" sm:ml-2">
        {formatted}
      </span>
    );
  };

  const location = useLocation();

  const [selectpage, setselectpage] = useState('');
  const [page, setpage] = useState("");

  const allvalue = [
    { value: "10", label: "10", id: 1 },
    { value: "25", label: "25", id: 2 },
    { value: "50", label: "50", id: 3 },
  ];

  useEffect(() => {
    setpage(allvalue[0]?.value);
  }, []);


  const handlePageChange = (selected) => {
    setselectpage(selected.selected);
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      history({
        pathname: "/myorder",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            page: selected.selected + 1,
          }).toString(),
      });
    } else {
      history({
        pathname: "/myorder",
        search: `?page=${selected.selected + 1}`,
      });
    }
    dispatch(OrderList({ ...object, page: selected.selected + 1 } , history));
  };

  const [Itemperpage, setItemperpage] = useState("");

  const pageCount = Math.ceil(Number(orderlist?.counts) / Number(page));

  useEffect(() => {
    if (pageCount && pageCount !== Infinity) {
      setItemperpage(pageCount);
    }
  }, [orderlist]);

  const onselectedpage = (item) => {
    setpage(item.value);

    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      setpage(item.value);
      history({
        pathname: "/myorder",
        search:
          "?" +
          new URLSearchParams({ ...object, limit: item.value }).toString(),
      });
    } else {
      history({
        pathname: "/myorder",
        search: `?limit=${item.value}`,
      });
    }
    dispatch(OrderList({ ...object, limit: item.value } , history));
  };

  useEffect(() => {
    const newlimit = searchParams.get("limit");
    if(newlimit?.length > 0){
      setpage(newlimit)
    }
  },[])

  useEffect(() => {
    const newpage = searchParams.get("page");
    if(newpage?.length > 0 && newpage !== null){
      setselectpage(newpage - 1)
    }
  },[])
  return (
    <div className="main_container  sm:min-h-[500px]">
      {orderlist && orderlist?.orderss.length > 0 && <div className="mt-[20px] flex items-center justify-between sm:mt-[30px] mb-[20px]">
        <p className="text-[#161616] text-[18px] sm:text-[20px] font-medium font-poppins">
          My Order
        </p>
       <Link to='/transaction-list'> <button className=" bg-[#076aff] text-[#fff] py-2 px-5 rounded-[8px] font-poppins hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl">My Show</button>
      </Link></div>}

      {/*--------------------------------- Wishlist Table For desktop ------------------------------ */}

     {orderlist && orderlist?.orderss.length > 0 ? <div>
      <table className="w-full border border-[#DAE9FF]  hidden md:inline-table">
        <thead>
          <tr className="bg-[#DAE9FF]">
            <td className="py-[15px] pl-[20px] min-w-[100px] xl:min-w-[120px] text-[#161616] lg:text-[18px] font-medium font-poppins">
              Order Id
            </td>
            <td className="py-[15px] min-w-[120px] xl:min-w-[150px] text-[#161616] lg:text-[18px] font-medium font-poppins">
              Order Date
            </td>
            <td className="py-[15px] min-w-[70px]  xl:min-w-[120px] text-[#161616] lg:text-[18px] font-medium font-poppins">
              Price
            </td>
            <td className="py-[15px] min-w-[80px] xl:min-w-[120px] text-[#161616] lg:text-[18px] font-medium font-poppins">
              Quantity
            </td>
            <td className="py-[15px]  min-w-[80px] xl:min-w-[120px] text-[#161616] lg:text-[18px] font-medium font-poppins">
              Total
            </td>
            <td className="py-[15px] text-[#161616] lg:text-[18px] text-center font-medium font-poppins">
              Actions
            </td>
          </tr>
        </thead>
        <tbody>
          {orderlist?.orderss?.map((e , index) => (
            <tr key={index} className="border-b border-[#DAE9FF]">
              <td className="flex items-center pl-[15px] sm:pl-[20px] min-h-[80px] lg:min-h-[120px]">
                <p className="text-[#47505F] text-[12px] lg:text-[16px] font-medium font-poppins">
                  #{e?.orderID}
                </p>
              </td>
              <td>
                <p className="text-[#47505F] text-[12px] lg:text-[16px] font-medium font-poppins">
                  {convertReleaseTime(e?.date)}
                </p>
              </td>
              <td>
                <p className="text-[#47505F] text-[12px] lg:text-[16px] font-medium font-poppins">
                {e?.subtotal?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    })}
                </p>
              </td>
              <td>
                <p className="text-[#47505F] text-[12px] lg:text-[16px] ml-6 font-medium font-poppins">
                  {e?.count}
                </p>
              </td>
              <td>
                <p className="text-[#076aff] text-[12px] lg:text-[16px] font-medium font-poppins">
                {e?.total?.toLocaleString("en-US", {style: "currency", currency: "INR",minimumFractionDigits: 0})}
                </p>
              </td>
              <td className="min-h-[120px]">
                <div className=" flex items-center justify-center">
                  <Link to={`/order-detail/${e?.orderID}`}>
                    <button   className="text-[#47505F] border border-[#979797] min-h-[40px] min-w-[100px] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl lg:min-w-[140px] rounded-[5px] text-[12px] lg:text-[14px] font-medium font-poppins">
                      Quick View
                    </button>
                  </Link>
                  <a href={`https://hooc.b-cdn.net/${e?.link}`} target="_blank"  rel="noreferrer"  className="text-[#076aff] flex items-center justify-center bg-[#DAE9FF] min-h-[40px] min-w-[100px] lg:min-w-[140px] ml-[10px] lg:ml-[20px] rounded-[5px] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl text-[12px] lg:text-[14px] font-medium font-poppins">
                    Get Invoice
                  </a>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className=" hidden md:flex items-center justify-between w-full py-[20px] bg-[#fff] rounded-b-[10px] boxshadow px-[20px] sm:px-[40px] mb-[30px]">
          <div className=" flex items-center">
            <p className="text-[#47505F] font-medium font-[poppins] ">View</p>

            {/* ----------------------select page ------------------------ */}

            <Menu as="div" className="relative text-left">
              <div>
                <Menu.Button className="flex px-[20px] ml-[24px] min-h-[40px] bg-[#F2F2F2] min-w-[85px] rounded-[8px] justify-between  text-sm font-poppins font-medium  items-center ">
                  <p className="text-[#47505F] font-medium font-[poppins]">
                    {page}
                  </p>
                  <img className="" src={arrowdown} alt="new" />
                </Menu.Button> 
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="bottom-full absolute right-0 z-10 mt-2 py-2 w-[80%] rounded-[12px] bg-white shadoe-sm focus:outline-none">
                  {allvalue?.map((e) => (
                    <div key={e?.id}>
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            className={classNames(
                              active ? "bg-[#DAE9FF] text-[#076aff]" : "",
                              "block px-4 py-2 text-sm cursor-pointer"
                            )}
                            onClick={() => onselectedpage(e)}
                          >
                            {e?.value}
                          </p>
                        )}
                      </Menu.Item>
                    </div>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>

            {/* ----------------------End select page ------------------------ */}
          </div>

          {/* ------------------------- pagination -------------------------*/}
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={Itemperpage} // Replace with the total number of pages
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
          />
          {/* -------------------------End  pagination -------------------------*/}
        </div>
      {/*--------------------------------- Order For mobile ------------------------------ */}

      <div className="grid  md:hidden">
        {orderlist?.orderss?.map((e , index) => {
          return (
            <div
              className="bg-[#fff] p-[15px]  rounded-[10px] boxshadow relative mb-[18px]"
              key={index}
            >
              <p className="text-[#000] pb-2 text-[14px] sm:text-[16px] font-medium font-poppins">
                Order ID : <span className=" text-[#47505F] font-normal">#{e.orderID}</span>
              </p>
              <p className="text-[#000] pb-2 text-[14px] sm:text-[16px] font-medium font-poppins">
                Order Date :
                <span className=" text-[#47505F] font-normal"> {convertReleaseTime(e?.date)}</span>
              </p>
              <p className="text-[#000] pb-2 text-[14px] sm:text-[16px] font-medium font-poppins">
                Quantity : <span className=" text-[#47505F] font-normal"> {e.count}</span>
              </p>
              <p className="text-[#000] pb-2 text-[14px] sm:text-[16px] font-medium font-poppins">
                Price : <span className=" text-[#47505F] font-normal"> {e?.subtotal?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    })}</span>
              </p>
              <p className="text-[#47505F] pb-2 text-[14px] sm:text-[16px] font-medium font-poppins">
                Total = <span className=" text-[#076aff] font-normal"> {e?.total?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    })}</span>
              </p>
              <div className=" flex items-center">
                <Link to={`/order-detail/${e?.orderID}`}>
                  <button className="text-[#47505F] border border-[#979797] min-h-[30px] min-w-[140px] rounded-[5px] text-[12px] font-medium font-poppins">
                    Quick View
                  </button>
                </Link>
                <a href={`https://hooc.b-cdn.net/${e?.link}`} target="_blank"  rel="noreferrer"  className="text-[#076aff] flex items-center justify-center bg-[#DAE9FF] min-h-[30px] min-w-[140px] ml-[20px] rounded-[5px] text-[12px] font-medium font-poppins">
                  Get Invoice
                </a>
              </div>
            </div>
          );
        })}
      </div>
      <div className=" md:hidden   w-full py-[20px] bg-[#fff] rounded-b-[10px] boxshadow px-[20px] sm:px-[40px] mb-[30px]">
          <div className=" flex items-center mb-5">
            <p className="text-[#47505F] font-medium font-[poppins] ">View</p>

            {/* ----------------------select page ------------------------ */}

            <Menu as="div" className="relative text-left">
              <div>
                <Menu.Button className="flex px-[20px] ml-[24px] min-h-[40px] bg-[#F2F2F2] min-w-[85px] rounded-[8px] justify-between  text-sm font-poppins font-medium  items-center ">
                  <p className="text-[#47505F] font-medium font-[poppins]">
                    {page}
                  </p>
                  <img className="" src={arrowdown} alt="new" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="bottom-full absolute right-0 z-10 mt-2 py-2 w-[80%] rounded-[12px] bg-white shadoe-sm focus:outline-none">
                  {allvalue?.map((e) => (
                    <div key={e?.id}>
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            className={classNames(
                              active ? "bg-[#DAE9FF] text-[#076aff]" : "",
                              "block px-4 py-2 text-sm cursor-pointer"
                            )}
                            onClick={() => onselectedpage(e)}
                          >
                            {e?.value}
                          </p>
                        )}
                      </Menu.Item>
                    </div>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>

            {/* ----------------------End select page ------------------------ */}
          </div>

          {/* ------------------------- pagination -------------------------*/}
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            pageCount={Itemperpage} // Replace with the total number of pages
            marginPagesDisplayed={2}
            pageRangeDisplayed={1}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            previousLinkClassName={"pagination__link"}
            nextLinkClassName={"pagination__link"}
            disabledClassName={"pagination__link--disabled"}
            activeClassName={"pagination__link--active"}
            forcePage={selectpage}
          />
          {/* -------------------------End  pagination -------------------------*/}
        </div>
</div>
 : (
          <div className=" min-h-[400px]  sm:min-h-[500px]  flex flex-col items-center justify-center">
          <img src={emptyorder} className=" w-[300px] mt-5" alt="new"></img>
          <div className="text-[18px] sm:text-[24px] mt-5 font-medium logoheading font-poppins">
            Your Order Is Empty!!
          </div>
          <div className="mt-5">
              <Link to='/'> <button
                  className="bg-[#076AFF] min-w-[140px] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium  rounded-[5px] text-[14px]  min-h-[35px]  text-white"
                >
                 Back to Home
                </button></Link> 
              </div>
          </div>
        )} 

    </div>
  );
}

export default MyOrder;
