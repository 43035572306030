import React, { useEffect, useState } from "react";
import upload from "../../images/upload.png";
import dates from "../../images/date.png";
import price from "../../images/ruppee.png";
import discount from "../../images/precentage.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import arrow from "../../images/rightarr.png";
import "../../App.css";
import { useDispatch, useSelector } from "react-redux";
import {
  GetActorlist,
  GetGenrelist,
  GetLanguagelist,
  GetMovieDetail,
} from "../../Redux/Action/MovieAction";


function MovieView() {
  const [Moviename, setMoviename] = useState("");
  const [show, setshow] = useState();
  const [Discount, setDiscount] = useState("");
  const [Price, setPrice] = useState("");
  // const [Duration, setDuration] = useState("");
  const [Moviedesc, setMoviedesc] = useState("");
  const [trailerlink, settrailerlink] = useState("");
  const [Language, setLanguage] = useState("");
  const [Languageoption, setLanguageoption] = useState("");
  const [Genre, setGenre] = useState([]);
  const [Genreoption, setGenreoption] = useState("");
  const [Actor, setActor] = useState("");
  const [Actoroption, setActoroption] = useState("");
  const [isabanneredit, setisabanneredit] = useState(false);
  const [isposteredit, setisposteredit] = useState(false);
  const [isimageedit, setisimageedit] = useState(false);
  const [Hours, setHours] = useState("");
  const [Minutes, setMinutes] = useState("");
  // Banner image
  const [images, setImages] = useState([]);
  const [imageURLs, setImagesURLs] = useState([]);

  // poster image
  const [smallimages, setsmallImages] = useState([]);
  const [smallimageURLs, setsmallImagesURLs] = useState([]);

  // upload image
  const [uploadimages, setuploadimages] = useState([]);
  const [uploadimagesURLs, setuploadimagesURLs] = useState([]);

  const [startDate, setStartDate] = useState("");
  // const [selectedOptions, setSelectedOptions] = useState([]);

  const [imageUploadData, setImageUploadData] = useState([]);
  const [imageUploadData1, setImageUploadData1] = useState([]);
  const [imageUploadData2, setImageUploadData2] = useState([]);
  const [imageUploadData3, setImageUploadData3] = useState([]);

  useEffect(() => {
    if(images && images.length > 0){
      setisabanneredit(true)
    }else{
      setisabanneredit(false)
    }
  }, [images])

  useEffect(() => {
    if(smallimages && smallimages.length > 0){
      setisposteredit(true)
    }else{
      setisposteredit(false)
    }
  }, [smallimages])

  useEffect(() => {
    if(uploadimages && uploadimages.length > 0){
      setisimageedit(true)
    }else{
      setisimageedit(false)
    }
  }, [uploadimages])
  
//  console.log(images)
  //   check box for select
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "transparent";
    if (isActive) bg = "#DAE9FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input
          type="checkbox"
          defaultChecked={isSelected}
          className="mr-3 cursor-pointer w-[18px] h-[18px]"
          style={{ accentColor: "#028f3b" }}
        />
        {children}
      </components.Option>
    );
  };

  //------------------------ for Banner image----------------------------

  let errorsObj = { image: "", posterimage: "", uploadimage: "" };
  const [errors, setErrors] = useState(errorsObj);

  useEffect(() => {
    if (images?.length < 1) return;
    const newImageURLs = [];
    images.forEach((image) => newImageURLs.push(URL.createObjectURL(image)));
    setImagesURLs(newImageURLs);
  }, [images]);



  //------------------------ for Poster image----------------------------

  useEffect(() => {
    if (smallimages?.length < 1) return;
    const newImageURLs = [];
    smallimages.forEach((image) =>
      newImageURLs.push(URL.createObjectURL(image))
    );
    setsmallImagesURLs(newImageURLs);
  }, [smallimages]);



  //-------------------- for upload images-------------------------------------

  useEffect(() => {
    if (uploadimages?.length < 1) return;
    const newImageURLs = [];
    uploadimages.forEach((image) =>
      newImageURLs.push(URL.createObjectURL(image))
    );
    setuploadimagesURLs(newImageURLs);
  }, [uploadimages]);



  //--------------------style for select--------------------------------

  const customStyles = {
    control: (base) => ({
      ...base,
      borderColor: "#6D6E70 !important",
      boxShadow: "none",
      minHeight: 60,
    }),
    menu: (provided) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
      zIndex: 9999,
    }),
    menuList: (base) => ({
      ...base,
      height: "228px !important",
      "::-webkit-scrollbar": {
        width: "5px",
      },
      "::-webkit-scrollbar-track": {
        background: "#fff",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#DAE9FF",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#DAE9FF",
      },
    }),
    option: (provided) => ({
      ...provided,
      color: "#979797 !important",
      fontWeight: 500,
      cursor: "pointer",
      borderBottom: 1,
      paddingTop: 10,
      paddingBottom: 10,
      borderColor: "#DAE9FF",
      borderStyle: "solid",
      ":hover": {
        backgroundColor: "#DAE9FF !important",
        color: "#076AFF !important",
      },
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#DAE9FF",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#076AFF",
      fontWeight: 500,
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#47505F",
      ":hover": {
        backgroundColor: "#47505F",
        color: "white",
      },
    }),
  };

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const moviedetails = useSelector((state) => state?.movie?.moviedetail?.data?.movie);

  let unix_timestamp = Number(moviedetails?.duration) * Number(60);
  var date = new Date(unix_timestamp * 1000);

  var hours = date.getUTCHours();
  var minutes = date.getUTCMinutes();

  useEffect(() => {
    setGenre(moviedetails?.genre);
    setMoviename(moviedetails?.name);
    setMoviedesc(moviedetails?.description);
    setshow(moviedetails?.minshow);
    settrailerlink(moviedetails?.trailer);
    setPrice(moviedetails?.price);
    setDiscount(moviedetails?.discount_);
    setStartDate(moviedetails && moviedetails?.release);
    setHours(hours)
    setMinutes(minutes)
    setLanguage(moviedetails?.language);
    setActor(moviedetails?.actor);
    setImagesURLs(`${`https://hooc.b-cdn.net/${moviedetails?.banner_image}?t=${Date.now()}`}`);
    setsmallImagesURLs(`${`https://hooc.b-cdn.net/${moviedetails?.poster_image}?t=${Date.now()}`}`);
    if (moviedetails?.image1) {
      setImageUploadData(`${`https://hooc.b-cdn.net/${moviedetails?.image1}?t=${Date.now()}`}`);
    }
    if (moviedetails?.image2) {
      setImageUploadData1(
        `${`https://hooc.b-cdn.net/${moviedetails?.image2}?t=${Date.now()}`}`
      );
    }
    if (moviedetails?.image3) {
      setImageUploadData2(
        `${`https://hooc.b-cdn.net/${moviedetails?.image3}?t=${Date.now()}`}`
      );
    }
    if (moviedetails?.image4) {
      setImageUploadData3(
        `${`https://hooc.b-cdn.net/${moviedetails?.image4}?t=${Date.now()}`}`
      );
    }
  }, [moviedetails]);


  const languagelist = useSelector((state) => state.movie.languagelist.data);
  const genrelist = useSelector((state) => state.movie.genrelist.data);
  const actorlist = useSelector((state) => state.movie.actorlist.data);

  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();

  useEffect(() => {
    dispatch(GetMovieDetail(id , history));
  }, []);

  useEffect(() => {
    dispatch(GetLanguagelist());
  }, []);
  useEffect(() => {
    dispatch(GetGenrelist());
  }, []);
  useEffect(() => {
    dispatch(GetActorlist());
  }, []);

  // language list

  useEffect(() => {
    const con =
      languagelist && languagelist?.map((c) => ({ value: c, label: c }));
    setLanguageoption(con);
  }, [languagelist]);

  useEffect(() => {
    const con = genrelist && genrelist?.map((c) => ({ value: c, label: c }));
    setGenreoption(con);
  }, [genrelist]);

  useEffect(() => {
    const con =
      actorlist && actorlist?.map((c) => ({ value: c._id, label: c.actor }));
    setActoroption(con);
  }, [actorlist]);


  const role = localStorage.getItem("role");
 
  return (
    <div className="pt-[20px] pb-[50px]">
      {/* <ToastContainer /> */}
      <div className=" flex items-center pb-[7px]">
        <Link to={`${role === 'producer' ? `${'/admin/producer-movies'}` : `${'/admin/movies'}`}`}>
          <p className=" text-[#076aff] cursor-pointer text-[18px] font-medium font-poppins">
            MOVIES
          </p>
        </Link>
        <img src={arrow} alt="new" className="mx-3"></img>
        <p className=" text-[#161616] text-[18px] font-medium font-poppins">
          View Movie
        </p>
      </div>
      <div className="mt-[15px] sm:mt-[20px]">
      
          {/*---------------------- Movie Form --------------------------------------*/}

          <form
            className="grid grid-cols-12 gap-x-5 xl:gap-x-9 w-full"
          >
            <div className="w-full col-span-12 md:col-span-6">
            {/*---------------------- Upload Banner Image --------------------------------------*/}

            <label htmlFor="inputTag">
              <div className="bg-[#fff] h-[170px]  border border-dashed border-[#DAE9FF] flex items-center justify-center  overflow-hidden  rounded-[10px]">
        
                {imageURLs?.length > 0 ? (
                  <div className="w-full h-full relative group">
                    <img
                      src={imageURLs}
                      className="w-full h-full "
                      alt="new"
                    ></img>
                  </div>
                ) : (
                  <div className=" flex justify-center items-center h-full">
                    <div>
                      <img src={upload} className="mx-auto" alt="new"></img>
                      <p className="text-[#47505F] text-[14px] sm:text-[16px] md:text-[18px] font-medium font-poppins mt-5">
                        Upload Banner Image
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {errors.image && (
                <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                  {errors.image}
                </div>
              )}
            </label>

            {/*---------------------- Upload Poster Image --------------------------------------*/}

            <label htmlFor="inputTags">
              <div className="bg-[#fff] w-[160px] h-[170px] mx-auto sm:h-[240px] sm:w-[225px] mt-[20px] sm:mt-[30px] border border-dashed border-[#DAE9FF] overflow-hidden flex items-center justify-center   rounded-[10px]">
                {smallimageURLs?.length > 0 ? (
                  <div className="w-full h-full relative group">
                    <img
                      // src={m}
                      src={`${smallimageURLs}`}
                      className="w-full h-full object-cover"
                      alt="new"
                    ></img>
                  </div>
                ) : (
                  <div className=" flex justify-center items-center h-full">
                    <div>
                      <img src={upload} className="mx-auto" alt="new"></img>
                      <p className="text-[#47505F] text-[14px] sm:text-[16px] md:text-[18px] font-medium font-poppins mt-5">
                        Upload Poster Image
                      </p>
                    </div>
                  </div>
                )}
              </div>
              {errors.posterimage && (
                <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                  {errors.posterimage}
                </div>
              )}
            </label>

            {/*---------------------- Upload Image --------------------------------------*/}

            <div className=" grid grid-cols-12 mt-[20px] sm:mt-[30px] gap-x-3 xl:gap-x-6">
            <label htmlFor="inputTagsf" className=" col-span-6">
                <div className="bg-[#fff]  h-[170px] sm:h-[215px] overflow-hidden border border-dashed border-[#DAE9FF] flex items-center justify-center rounded-[10px]">
                  {imageUploadData?.length > 0 ? (
                    <div className=" col-span-6 w-full   h-[170px] sm:h-[215px] rounded-[10px] ">
                      <img
                        src={imageUploadData}
                        className="w-full h-full object-cover rounded-[10px]"
                        alt="new"
                      ></img>
                    </div>
                  ) : (
                    <div className="mt-[20px] sm:mt-[30px] col-span-6">
                  <div className="bg-[#fff] w-full  border-[#DAE9FF]  h-[170px] sm:h-[215px] overflow-hidden flex items-center justify-center cursor-pointer  rounded-[10px]"></div>
                </div>
                  )}
                </div>
              </label>
              <label htmlFor="inputTags2" className=" col-span-6">
                <div className="bg-[#fff]  h-[170px] sm:h-[215px] overflow-hidden border border-dashed border-[#DAE9FF] flex items-center justify-center  rounded-[10px]">
                  {imageUploadData1?.length > 0 ? (
                    <div className=" col-span-6 w-full   h-[170px] sm:h-[215px] rounded-[10px] ">
                      <img
                        src={imageUploadData1}
                        className="w-full h-full object-cover rounded-[10px]"
                        alt="new"
                      ></img>
                    </div>
                  ) : (
                    <div className="mt-[20px] sm:mt-[30px] col-span-6">
                  <div className="bg-[#fff] w-full  border-[#DAE9FF]  h-[170px] sm:h-[215px] overflow-hidden flex items-center justify-center cursor-pointer  rounded-[10px]"></div>
                </div>
                  )}
                </div>
              </label>
              <label
                htmlFor="inputTags3"
                className=" col-span-6 mt-[20px] sm:mt-[30px]"
              >
                <div className="bg-[#fff]  h-[170px] sm:h-[215px] overflow-hidden border border-dashed border-[#DAE9FF] flex items-center justify-center   rounded-[10px]">

                  {imageUploadData2?.length > 0 ? (
                    <div className=" col-span-6 w-full   h-[170px] sm:h-[215px] rounded-[10px] ">
                      <img
                        src={imageUploadData2}
                        className="w-full h-full object-cover rounded-[10px]"
                        alt="new"
                      ></img>
                    </div>
                  ) : (
                    <div className="mt-[20px] sm:mt-[30px] col-span-6">
                  <div className="bg-[#fff] w-full  border-[#DAE9FF]  h-[170px] sm:h-[215px] overflow-hidden flex items-center justify-center cursor-pointer  rounded-[10px]"></div>
                </div>
                  )}
                </div>
              </label>
              <label
                htmlFor="inputTagsnew"
                className=" col-span-6 mt-[20px] sm:mt-[30px]"
              >
                <div className="bg-[#fff]  h-[170px] sm:h-[215px] overflow-hidden border border-dashed border-[#DAE9FF] flex items-center justify-center   rounded-[10px]">
                  {imageUploadData3?.length > 0 ? (
                    <div className=" col-span-6 w-full   h-[170px] sm:h-[215px] rounded-[10px] ">
                      <img
                        src={imageUploadData3}
                        className="w-full h-full object-cover rounded-[10px]"
                        alt="new"
                      ></img>
                    </div>
                  ) : (
                    <div className="mt-[20px] sm:mt-[30px] col-span-6">
                    <div className="bg-[#fff] w-full  border-[#DAE9FF]  h-[170px] sm:h-[215px] overflow-hidden flex items-center justify-center cursor-pointer  rounded-[10px]"></div>
                  </div>
                  )}
                </div>
              </label>
            </div>
          </div>
            <div className="w-full col-span-12 md:col-span-6 mt-[20px] md:mt-0">
            <p className="text-base text-[#161616] font-medium font-poppins">
              Movie Name
            </p>
            <input
              className="h-[46px] rounded-[5px] border border-[#6D6E70] pl-5 mt-3 w-full text-[#979797] placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
              placeholder="Enter Movie Name"
              value={Moviename}
              onChange={(e) => setMoviename(e.target.value)}
              disabled
            ></input>
            <p className="text-base mt-[20px] text-[#161616] font-medium font-poppins">
              Movie Description
            </p>
            <textarea
              className="py-3.5 rounded-[5px] border border-[#6D6E70] pl-5 mt-3 min-h-[110px] text-[#979797] w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
              placeholder="Enter Movie Description..."
              value={Moviedesc}
              onChange={(e) => setMoviedesc(e.target.value)}
              disabled
              style={{ resize: "none" }}
            ></textarea>
            <div className="mt-[20px]">
              <p className="text-base mb-3 text-[#161616] font-medium font-poppins">
                Select Language
              </p>
              <Select
                defaultValue={Language}
                closeMenuOnSelect={false}
                isMulti
                isDisabled
                name="colors"
                options={Languageoption}
                value={
                  Languageoption &&
                  Languageoption.filter((obj) =>
                    Language?.some((item) => item === obj.value)
                  )
                }
                className="basic-multi-select"
                classNamePrefix="select"
                styles={customStyles}
                hideSelectedOptions={false}
                onChange={(options) => {
                  if (Array.isArray(options)) {
                    setLanguage(options?.map((opt) => opt.value));
                  }
                }}
                components={{
                  Option: InputOption,
                }}
              />
            </div>
            <div className="mt-[20px]">
              <p className="text-base mb-3  text-[#161616] font-medium font-poppins">
                Select Actor
              </p>
              <Select
                defaultValue={Actor}
                closeMenuOnSelect={false}
                isMulti
                name="colors"
                isDisabled
                options={Actoroption}
                value={
                  Actoroption &&
                  Actoroption.filter((obj) =>
                    Actor?.some((item) => item === obj.value)
                  )
                }
                className="basic-multi-select"
                classNamePrefix="select"
                styles={customStyles}
                hideSelectedOptions={false}
                onChange={(options) => {
                  if (Array.isArray(options)) {
                    setActor(options?.map((opt) => opt.value));
                  }
                }}
                components={{
                  Option: InputOption,
                }}
              />
            </div>
            <div className="mt-[20px]">
              <p className="text-base mb-3  text-[#161616] font-medium font-poppins">
                Select Genre
              </p>
              <Select
                // defaultValue={Genre}
                closeMenuOnSelect={false}
                isMulti
                name="colors"
                isDisabled
                options={Genreoption}
                value={
                  Genreoption &&
                  Genreoption.filter((obj) =>
                    Genre?.some((item) => item === obj.value)
                  )
                }
                className="basic-multi-select"
                classNamePrefix="select"
                styles={customStyles}
                placeholder={"Select genre...."}
                hideSelectedOptions={false}
                onChange={(options) => {
                  if (Array.isArray(options)) {
                    setGenre(options?.map((opt) => opt.value));
                  }
                }}
                components={{
                  Option: InputOption,
                }}
              />
            </div>
            <div className=" mt-[20px]">
              <p className="text-base text-[#161616] font-medium font-poppins">
                Movie Trailer Link
              </p>
              <input
                className="h-[46px] border border-[#6D6E70] rounded-[5px] pl-5 mt-3 w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
                // placeholder="https://www.youtube.com/embed/RBumgq5yVrA"
                value={trailerlink}
                onChange={(e) => settrailerlink(e.target.value)}
                disabled
              ></input>
            </div>
            {role !== 'producer' && <div className=" mt-[20px] flex items-center">
              <label className="text-base text-[#161616] font-medium font-poppins">Popular :</label>
              <input
                className="h-[20px] w-[20px] rounded-[5px] border border-[#6D6E70] ml-2"
                type="checkbox"
                name="popular"
                disabled
                // placeholder="Is This Movie Popular"
                // value={trailerlink}
                
                // onChange={(e) => settrailerlink(e.target.value)}
              ></input>
            </div>}
            <div className="mt-[25px] gap-x-3 xl:gap-x-8 grid grid-cols-12">
              <div className=" col-span-6">
                <p className="text-base mb-[15px] text-[#161616] font-medium font-poppins">
                  Release Date
                </p>
                <div className="relative">
                  <div className="absolute right-5 z-50 flex items-center h-full cursor-pointer">
                    <img src={dates} alt="new"></img>
                  </div>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    disabled
                    className="bg-white border border-[#6D6E70] cursor-pointer w-full h-[46px] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                  />
                </div>
              </div>
              <div className=" col-span-6">
                <p className="text-base mb-[15px] text-[#161616] font-medium font-poppins">
                  Duration
                </p>
                <div className=" grid grid-cols-12 gap-3">
                  <div className="relative col-span-6">
                    <input
                      className="bg-white w-full border border-[#6D6E70]  h-[46px] flex items-center pl-3 xl:pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                      placeholder="Hours"
                      value={Hours}
                      disabled
                      required
                      onChange={(e) => setHours(e.target.value)}
                    />
                  </div>
                  <div className="relative col-span-6">
                    <input
                      className="bg-white w-full border border-[#6D6E70]  h-[46px] flex items-center pl-3 xl:pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                      placeholder="Mins"
                      value={Minutes}
                      disabled
                      required
                      onChange={(e) => setMinutes(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              {role !== 'producer' &&<div className=" col-span-6 mt-[25px]">
                <p className="text-base mb-[15px] text-[#161616] font-medium font-poppins">
                  Movie Price
                </p>
                <div className="relative">
                  <div className="absolute right-5 flex items-center h-full cursor-pointer">
                    <img src={price} alt="new"></img>
                  </div>
                  <input
                    className="bg-white w-full border border-[#6D6E70] text-[#979797] font-medium h-[46px] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                    placeholder="Enter Price"
                    value={Price}
                    disabled
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </div>
              </div>}
              {role !== 'producer' && <div className=" col-span-6 mt-[25px]">
                <p className="text-base mb-[15px] text-[#161616] font-medium font-poppins">
                  Discount
                </p>
                <div className="relative">
                  <div className="absolute right-5 flex items-center h-full cursor-pointer">
                    <img src={discount} alt="new"></img>
                  </div>
                  <input
                    className="bg-white w-full border border-[#6D6E70] text-[#979797] font-medium h-[46px] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                    placeholder="Enter Discount"
                    value={Discount}
                    disabled
                    onChange={(e) => setDiscount(e.target.value)}
                  />
                </div>
              </div>}
              {role !== 'producer' &&<div className=" col-span-6 mt-[25px]">
                <p className="text-base mb-[15px] text-[#161616] font-medium font-poppins">
                  Minimum Shows
                </p>
                <div className="relative">
                  <input
                    className="bg-white w-full border border-[#6D6E70] text-[#979797] font-medium h-[46px] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                    placeholder="Enter Show"
                    value={show}
                    disabled
                    onChange={(e) => setshow(e.target.value)}
                  />
                </div>
              </div>}
            </div>
            </div>

          </form>

          {/*---------------------- End Movie Form --------------------------------------*/}
        
      </div>
    </div>
  );
}

export default MovieView;
