import axios from "axios";
import { Flip, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";


export const actionTypes = {
    ACTOR_LIST: "ACTOR_LIST",
  };
  

  // Actor list

export const GetActorlist = (query , history) => {
  const token = localStorage.getItem('token')
    return async (dispatch) => {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/actor`, {
          params: { ...query },
          headers : {
            'Authorization' : token
          }
        })
        .then((response) => {
          dispatch({
            type: actionTypes.ACTOR_LIST,
            actorlist: response.data,
          });
        })
        .catch((err) => {
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
        });
    };
  };

  // Create Actor

export const CreateNewActor = (file,name,history) => {
var data = new FormData();
var fileDate = new File([file && file[0]], file && file[0].name, {type:'application/json'});
data.append('image', fileDate);
data.append('actor', name);

const token = localStorage.getItem('token')

    return async (dispatch) => {
      await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/actor`, data, {
          headers: {
            Accept: "application/json",
            'Authorization' : token
          },
        })
        .then((response) => {
          toast.success(response.data.message, {
            transition: Flip,
            theme: "colored",
            autoClose: 500,
          });
          dispatch(GetActorlist());
        })
        .catch((err) => {
        toast.error(err.response.data.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        })
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
        
      }
        );
    };
  };

  // Delete Actor

export const DeleteActor = (id , history) => {
  const token = localStorage.getItem('token')

    return async (dispatch) => {
      await axios
        .delete(`${process.env.REACT_APP_API_BASE_URL}/actor/${id}`, {
          headers: {
            Accept: "application/json",
            'Authorization' : token
          },
        })
        .then((response) => {
          toast.success(response.data.message, {
            transition: Flip,
            theme: "colored",
            autoClose: 500,
          });
          dispatch(GetActorlist());
        })
        .catch((err) => {
          toast.error(err.response.data.error, {
            transition: Flip,
            theme: "colored",
            autoClose: 500,
          });
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
        });
    };
  };