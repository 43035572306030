import axios from "axios";

export const actionTypes = {
    COUNTRY_LIST: "COUNTRY_LIST",
    STATE_LIST: "STATE_LIST",
    CITY_LIST: "CITY_LIST",
  };


// Country list

export const GetCountrylist = () => {
    return async (dispatch) => {
      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/country`,
          {
            headers: {
              // 'Authorization' : token,
              'Accept': "application/json",
            },
          }
        )
        .then((response) => {
          dispatch({
            type: actionTypes.COUNTRY_LIST,
            countrylist: response.data,
          });
        })
        .catch((err) => console.log(err));
    };
  };

  // State list

export const GetStatelist = (con) => {
    return async (dispatch) => {
      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/state/${con}`,
          {
            headers: {
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          dispatch({
            type: actionTypes.STATE_LIST,
            statelist: response.data,
          });
        })
        .catch((err) => console.log(err));
    };
  };


    // City list

export const GetCitylist = (con,state) => {
    return async (dispatch) => {
      await axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/city/${con}/${state}`,
          {
            headers: {
              Accept: "application/json",
            },
          }
        )
        .then((response) => {
          dispatch({
            type: actionTypes.CITY_LIST,
            citylist: response.data,
          });
        })
        .catch((err) => console.log(err));
    };
  };