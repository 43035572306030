import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import arrow from "../../../images/rightarr.png";
import calendar from "../../../images/calendar.png";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { GetProducertransactiondetail } from "../../../Redux/Action/TransactionAction";

function Producertransaction() {
  const rupee = 600000;
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
    },
  ]);

  const [open, setopen] = useState(false);

  const options = [
    { value: "Amaan", label: "Amaan" },
    { value: "Parth", label: "Parth" },
    { value: "Kausik", label: "Kausik" },
    { value: "Axat", label: "Axat" },
    { value: "Nikunj", label: "Nikunj" },
    { value: "Rajiv", label: "Rajiv" },
  ];
  const [selectedOption, setSelectedOption] = useState(options[1]);

  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useNavigate();
  useEffect(() => {
    dispatch(GetProducertransactiondetail(id, history));
  }, []);

  return (
    <div className="pt-[20px] pb-[50px]">
      <div className=" flex items-center justify-between relative pb-[7px]">
        <div className=" flex items-center">
          <Link to={"/admin/transaction"}>
            <p className=" text-[#076aff] cursor-pointer text-[18px] font-medium font-poppins">
              TRANSACTION
            </p>
          </Link>
          <img src={arrow} alt="new" className="mx-3"></img>
          <Link to={"/admin/producer-transactionlist"}>
            <p className=" text-[#076aff] cursor-pointer text-[18px] font-medium font-poppins">
              Total PRODUCER
            </p>
          </Link>
          <img src={arrow} alt="new" className="mx-3"></img>
          <p className=" text-[#161616] text-[18px] font-medium font-poppins">
            Transaction Details
          </p>
        </div>
        <button
          onClick={() => setopen(!open)}
          className=" bg-[#076aff] text-[#fff]  flex items-center min-h-[40px] min-w-[100px] rounded-[5px] justify-center"
        >
          <img src={calendar} alt="new"></img>
          <span className=" font-poppins pl-3 text-[14px]">Date</span>
        </button>
        {open && (
          <div className=" absolute right-0 top-10">
            <DateRangePicker
              onChange={(item) => {
                setState([item.range1]);
              }}
              ranges={state}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              showDateDisplay={true}
            />
          </div>
        )}
      </div>

      <div className=" grid grid-cols-12 gap-5 2xl:gap-9 mt-5">
        <div className=" bg-[#fff] boxshadow col-span-12 sm:col-span-6 md:col-span-4 py-6 rounded-[10px] text-center ">
          <p className=" text-[18px] font-poppins pb-2.5">Add Movies</p>
          <p className=" text-[30px] font-poppins text-[#161616] font-medium">
            90
          </p>
        </div>
        <div className=" bg-[#fff] boxshadow col-span-12 sm:col-span-6 md:col-span-4 py-6 rounded-[10px] text-center">
          <p className=" text-[18px] font-poppins pb-2.5">Sold Movies</p>
          <p className=" text-[30px] font-poppins text-[#161616] font-medium">
            50
          </p>
        </div>
        <div className=" bg-[#fff] boxshadow col-span-12 sm:col-span-6 md:col-span-4 py-6 rounded-[10px] text-center">
          <p className=" text-[18px] font-poppins pb-2.5">Total Income</p>
          <p className=" text-[30px] font-poppins text-[#161616] font-medium">
            {" "}
            {rupee?.toLocaleString("en-US", {
              style: "currency",
              currency: "INR",
              minimumFractionDigits: 0,
            })}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Producertransaction;
