import axios from "axios";
import { Flip, toast } from "react-toastify";
import Swal from "sweetalert2";


export const actionTypes = {
  ADD_CART: "ADD_CART",
  MOVIES_ERROR: "MOVIES_ERROR",
};

export const addToCart = (data , history) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/cart`, data, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        dispatch({
          type: actionTypes.ADD_CART,
          cartlist: response.data,
        });
      })
      .catch((err) => {
        toast.error(err.response.data.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
      });
  };
};

export const getCart = () => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/cart`, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_CART,
          cartlist: response.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: actionTypes.MOVIES_ERROR,
          movieserr: err.response.data,
        });
      });
  };
};

export const deletecart = (query, id , history) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/cart/${id}`, {
        headers: {
          Authorization: token,
        },
        params: { ...query },
      })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        dispatch({
          type: actionTypes.ADD_CART,
          cartlist: response.data,
        });
      })
      .catch((err) => {
        toast.error(err.response.data.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};

export const updateCart = (id, data) => {
  const token = localStorage.getItem("token");
  return async (dispatch) => {
    await axios
      .patch(`${process.env.REACT_APP_API_BASE_URL}/cart/${id}`, data, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_CART,
          cartlist: response.data,
        });
      })
      .catch((err) => {
        toast.error(err.response.data.error, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
      });
  };
};
