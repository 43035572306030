import React, { useEffect } from 'react'

function Aboutus() {
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div>
      <div className="py-[25px]  privacyheading">
        <p className=" text-white  main_container text-[18px] sm:text-[22px] font-medium font-poppins">
          About Us
        </p>
      </div>
      <div className=" main_container min-h-[400px]">
        <div className="mt-[20px] sm:mt-[30px]">
          <p className="text-[#161616] text-[14px] sm:text-[16px] font-poppins">
          House of October Cinemas (HOOC) is on a mission to democratize the access to mainstream & regional films & content to the masses at the very same time that it is available to the citizens in Urban, Metro, Tier 1 & Tier 2 regions of India for a cinematic experience.
          </p>
        </div>
        <div className="mt-[20px] sm:mt-[30px]">
          <p className="text-[#161616] text-[14px] sm:text-[16px] font-poppins">
          The aim of HOOC is to roll out 1000s of screens in villages of India and help in spreading happiness via family entertainment, community building,  controlling piracy and much more.
          </p>
        </div>
       
      </div>
    </div>
  )
}

export default Aboutus