import axios from "axios";
import Swal from "sweetalert2";

export const actionTypes = {
  TRANSACTION_LIST: "TRANSACTION_LIST",
  INCOME_LIST: "INCOME_LIST",
  TOTAL_VLE_LIST: "TOTAL_VLE_LIST",
  TOTAL_PRODUCER_LIST: "TOTAL_PRODUCER_LIST",
  VLE_TRANSACTION_DETAIL: "VLE_TRANSACTION_DETAIL",
  PRODUCER_TRANSACTION_DETAIL: "PRODUCER_TRANSACTION_DETAIL",
  MOVIE_REPORT_LIST: "MOVIE_REPORT_LIST",

  
};

export const GetTransactionData = (data) => {
  const token = localStorage.getItem("token");

  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/transaction`, data, {
        headers: {
          Accept: "application/json",
          Authorization: token,
        },
      })
      .then((response) => {
        dispatch({
          type: actionTypes.TRANSACTION_LIST,
          Transactionlist: response.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};



export const GetIncomelist = (query , history) => {
    const token = localStorage.getItem('token')
  
    return async (dispatch) => {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/income`,{
          params : { ...query },
          headers : {
            'Authorization' : token
          }
        })
        .then((response) => {
          dispatch({
            type: actionTypes.INCOME_LIST,
            Incomelist: response.data,
          });
        })
        .catch((err) => {
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
        });
    };
  };
  

  export const GetTotalvlelist = (query , history) => {
    const token = localStorage.getItem('token')
  
    return async (dispatch) => {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/totalvle`,{
          params : { ...query },
          headers : {
            'Authorization' : token
          }
        })
        .then((response) => {
          dispatch({
            type: actionTypes.TOTAL_VLE_LIST,
            Totalvlelist: response.data,
          });
        })
        .catch((err) => {
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
        });
    };
  };



  export const GetTotalproducerlist = (query , history) => {
    const token = localStorage.getItem('token')
  
    return async (dispatch) => {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/totalproducer`,{
          params : { ...query },
          headers : {
            'Authorization' : token
          }
        })
        .then((response) => {
          dispatch({
            type: actionTypes.TOTAL_PRODUCER_LIST,
            Totalproducerlist: response.data,
          });
        })
        .catch((err) => {
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
        });
    };
  };


  // vle transaction detail

  export const Getvletransactiondetail = (id, history) => {
    const token = localStorage.getItem('token')
  
    return async (dispatch) => {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/totalvle/${id}`,{
          headers : {
            'Authorization' : token
          }
        })
        .then((response) => {
          dispatch({
            type: actionTypes.VLE_TRANSACTION_DETAIL,
            Vletransactiondetail: response.data,
          });
        })
        .catch((err) => {
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
        });
    };
  };


  // producer transaction detail

  export const GetProducertransactiondetail = (id, history) => {
    const token = localStorage.getItem('token')
  
    return async (dispatch) => {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/totalproducer/${id}`,{
          headers : {
            'Authorization' : token
          }
        })
        .then((response) => {
          dispatch({
            type: actionTypes.PRODUCER_TRANSACTION_DETAIL,
            Producertransactiondetail: response.data,
          });
        })
        .catch((err) => {
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
        });
    };
  };


  export const GetMovieReportlist = (query , history) => {
    const token = localStorage.getItem('token')
  
    return async (dispatch) => {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/report`,{
          params : { ...query },
          headers : {
            'Authorization' : token
          }
        })
        .then((response) => {
          dispatch({
            type: actionTypes.MOVIE_REPORT_LIST,
            Moviereportlist: response.data,
          });
        })
        .catch((err) => {
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
        });
    };
  };