import React, { Fragment, useState, useEffect } from "react";
import search from "../../images/search.png";
import del from "../../images/newdelete.png";
import arrowdown from "../../images/arrow-down.svg";
import arrowd from "../../images/rightarr.png";
import eye from "../../images/eye.png";
import Swal from "sweetalert2";
import "../../App.css";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DeleteVle,
  GetVlelist,
  UpdateVlelist,
} from "../../Redux/Action/VleAction";
import { useLocation, useSearchParams } from "react-router-dom/dist";
import { useNavigate } from "react-router-dom/dist";
import ReactPaginate from "react-paginate";
import { reset } from "../../Redux/Action/AuthAction";
import { actionTypes } from "../../Redux/Reducer/VleReducer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function VleList() {
  const [sort, setsort] = useState("desc");
  const [sortmob, setsortmob] = useState("desc");
  const [sortstatus, setsortstatus] = useState("desc");
  const [Search, setSearch] = useState("");

  // for table content
  const [checkvalue, setcheckvalue] = useState("");
  // for select page value
  const allvalue = [
    { value: "10", label: "10", id: 1 },
    { value: "25", label: "25", id: 2 },
    { value: "50", label: "50", id: 3 },
  ];


  const [page, setpage] = useState("");
  useEffect(() => {
    setpage(allvalue[0]?.value);
  }, []);

 

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const [selectpage, setselectpage] = useState("");

  //--------------- Modal for delete vle---------------------

  // const deletevlelist = useSelector((state) => state.Vle.deletevlelist);
  
  // console.log(deletevlelist)

  const modal = (e, id) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Vle!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#076aff",
      cancelButtonColor: "#ff0101",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const sds = location.search.slice(1).split("&");
      let object = {};
      if (location.search.includes("?")) {
        sds.map((item) => {
          let newItem = item.split("=");
          object[newItem[0]] = newItem[1];
        });
      }
        dispatch(DeleteVle(id , {...object} , history));
      }
    });
  };


  const dispatch = useDispatch();
  useEffect(() => {
    searchParams.delete('search');
    setSearchParams(searchParams)
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    delete object.search;
    dispatch(GetVlelist({...object} , history));
  }, []);

  const vlelist = useSelector((state) => state.Vle.vlelist.data);

  const ordermethod = (order_by) => {
    switch (order_by) {
      case "name":
        const t = sort === "desc" ? "asc" : "desc";
        setsort(t);
        break;

      case "status":
        const te = sortstatus === "desc" ? "asc" : "desc";
        setsortstatus(te);
        break;

      case "contact":
        const tr = sortmob === "desc" ? "asc" : "desc";
        setsortmob(tr);
        break;

      default:
    }
  };

  const location = useLocation();
  const history = useNavigate();

  //----------------- for field and order filter------------------------

  const onApply = (order_by, sort) => {
    const order_typedata = ordermethod(order_by);
    const sds =  location.search.slice(1).split("&")
    let object = {}
    if(location.search.includes('?')){

    sds.map(item => {
      let newItem = item.split("=")
      object[newItem[0]] = newItem[1]
    })
    history({
      pathname: '/admin/vle',
      search: "?" + new URLSearchParams({...object,"field": order_by,'order' : sort}).toString()

    });
  }else{
    history({
      pathname: '/admin/vle',
      search: `?field=${order_by}&order=${sort}`
    });
  }
    dispatch(GetVlelist({ ...object,field: order_by,order : sort} , history));
  };

    //----------------- for limit filter------------------------

    const onselectedpage = (item) => {
      setpage(item.value);
    
          const sds = location.search.slice(1).split("&")
          let object = {}
        if(location.search.includes('?')){
        sds.map(item => {
          let newItem = item.split("=")
          object[newItem[0]] = newItem[1]
        })
    
        setpage(item.value);
        history({
          pathname: '/admin/vle',
          search: "?" + new URLSearchParams({...object,"limit": item.value}).toString()
        });
        }else
        {
          history({
            pathname: '/admin/vle',
            search: `?limit=${item.value}`
          });
        }
        dispatch(GetVlelist({ ...object,limit: item.value} , history));
      };


       //----------------- for search filter------------------------

  const submitsearch = (e) => {
    e?.preventDefault();
    const sds =  location.search.slice(1).split("&")

    let object = {}
    if(location.search.includes('?')){
    sds.map(item => {
      let newItem = item.split("=")
      object[newItem[0]] = newItem[1]
    })
    history({
      pathname: '/admin/vle',
      search: "?" + new URLSearchParams({...object,"search": Search}).toString()

    });
  }else{
    history({
      pathname: '/admin/vle',
      search: `?search=${Search}`
    });
  }
    dispatch(GetVlelist({ ...object,search: Search} , history));

  };

   //----------------- for pagination filter------------------------

   const handlePageChange = (selected) => {
    setselectpage(selected.selected);
    const sds =  location.search.slice(1).split("&")

    let object = {}
    if(location.search.includes('?')){
      sds.map(item => {
        let newItem = item.split("=")
        object[newItem[0]] = newItem[1]
      })
  
      history({
        pathname: '/admin/vle',
        search: "?" + new URLSearchParams({...object,"page": selected.selected + 1}).toString()
      });
      }else
      {
        history({
          pathname: '/admin/vle',
          search: `?page=${selected.selected + 1}`
        });
      }
      dispatch(GetVlelist({ ...object,page: selected.selected + 1} , history));
  };


  const [searchParams, setSearchParams] = useSearchParams();

  const removeQueryParams = () => {
    const param = searchParams.get('search');

    if (param) {
      searchParams.delete('search');
      setSearchParams(searchParams);
      const sds =  location.search.slice(1).split("&")
      let object = {}
      if(location.search.includes('?')){
        sds.map(item => {
          let newItem = item.split("=")
          object[newItem[0]] = newItem[1]
        })
      }
      delete object.search;
      dispatch(GetVlelist( {...object} , history));

    }
  };
  
  

  const [Itemperpage, setItemperpage] = useState("");


  const pageCount = Math.ceil(Number(vlelist?.count) / Number(page));

  useEffect(() => {
    if(pageCount && pageCount !== Infinity){
      setItemperpage(pageCount)
    }
    },[vlelist])

  const updatestatus = (sdata, id) => {
      const sds = location.search.slice(1).split("&");
        let object = {};
        if (location.search.includes("?")) {
          sds.map((item) => {
            let newItem = item.split("=");
            object[newItem[0]] = newItem[1];
          });
        }
        const data = {
          status: sdata,
        };
        dispatch(UpdateVlelist(data, id , {...object} , history));
      };

  const newrole = localStorage.getItem('role')
  useEffect(() => {
    const newlimit = searchParams.get("limit");
    if(newlimit?.length > 0){
      setpage(newlimit)
    }
  },[])

  useEffect(() => {
    const newpage = searchParams.get("page");
    if(newpage?.length > 0 && newpage !== null){
      setselectpage(newpage - 1)
    }
  },[])
  return (
    <div className="pt-[20px] h-full pb-[20px] lg:pb-0">
      {/* <ToastContainer /> */}
      <p className=" sm:text-[22px] text-[#161616] font-poppins font-medium">
        VLE
      </p>
      {/* search bar  */}
      <div className="mt-[15px] mb-[20px] sm:mb-[30px] p-5 lg:flex items-center justify-between bg-[#fff] rounded-[10px]  listtableshadow">
        <div>
          <form
            className="h-[40px] relative"
            onSubmit={(e) => {
              e?.preventDefault();
              if (Search) {
                submitsearch(e)
              }
            }}
          >
            <input
              className="h-full bg-[#F2F2F2] rounded-[8px] pl-[50px] min-w-full lg:min-w-[400px] placeholder:text-[#47505F]  placeholder:text-[14px] placeholder:font-medium"
              placeholder="Search by email..."
              value={Search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value === "") {
                  removeQueryParams()
                }
              }}
            ></input>
            <img src={search} className="-mt-7 ml-[20px]" alt="new"></img>
          </form>
        </div>
        <Link to="/admin/create-vle">
          <button className="bg-[#076AFF] mt-3 lg:mt-0 flex items-center  min-w-[114px] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium rounded-[5px] h-[40px] text-white text-[14px]">
            <span className="mx-[16px]">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.14174 1.85721C9.14174 1.22507 8.63103 0.714355 7.99888 0.714355C7.36674 0.714355 6.85603 1.22507 6.85603 1.85721V7.00007H1.71317C1.08103 7.00007 0.570312 7.51078 0.570312 8.14293C0.570312 8.77507 1.08103 9.28578 1.71317 9.28578H6.85603V14.4286C6.85603 15.0608 7.36674 15.5715 7.99888 15.5715C8.63103 15.5715 9.14174 15.0608 9.14174 14.4286V9.28578H14.2846C14.9167 9.28578 15.4275 8.77507 15.4275 8.14293C15.4275 7.51078 14.9167 7.00007 14.2846 7.00007H9.14174V1.85721Z"
                  fill="white"
                />
              </svg>
            </span>
            Create
          </button>
        </Link>
      </div>
      {/*End search bar  */}

      {/*------------------------- Table ---------------------------- */}
      {vlelist?.vle?.length > 0 ? 
      <div>
      <div className="tableFixHead">
        <table className="w-full listtableshadow overflow-auto">
          <thead className="bg-[#F9FAFB]" style={{ minHeight: 60 }}>
            <tr className="text-[#161616] font-medium font-poppins">
              <td className="py-4 pl-[40px] min-w-[100px]">ID</td>
              <td className="py-4 min-w-[320px]">
                <div className=" flex items-center">
                  Name{" "}
                  <span>
                    <img
                      src={arrowd}
                      alt="new"
                      onClick={() => onApply("name", sort)}
                      className={`${
                        sort === "asc" ? `${"-rotate-90"}` : `${" rotate-90"}`
                      } ml-2 cursor-pointer`}
                    ></img>
                  </span>
                </div>
              </td>
              <td className="py-4 min-w-[180px]">
                <div className=" flex items-center">
                  Mobile No{" "}
                  <span>
                    <img
                      src={arrowd}
                      alt="new"
                      onClick={() => onApply("contact", sortmob)}
                      className={`${
                        sortmob === "asc"
                          ? `${"-rotate-90"}`
                          : `${" rotate-90"}`
                      } ml-2 cursor-pointer`}
                    ></img>
                  </span>
                </div>
              </td>
              <td className="py-4 min-w-[150px]">
                <div className=" flex items-center">
                  Status{" "}
                  <span>
                    <img
                      src={arrowd}
                      alt="new"
                      onClick={() => onApply("status", sortstatus)}
                      className={`${
                        sortstatus === "asc"
                          ? `${"-rotate-90"}`
                          : `${" rotate-90"}`
                      } ml-2 cursor-pointer`}
                    ></img>
                  </span>
                </div>
              </td>
              <td className="py-4 min-w-[120px]">Action</td>
            </tr>
          </thead>
          <tbody>
            {vlelist?.vle?.map((m, index) => (
              <tr className={`border-b bg-[#fff]`} key={m?._id}>
                <td className="text-[#47505F] text-[14px] font-medium font-poppins pl-[40px] py-[20px]">
                  {index + 1}
                </td>
                <td className="py-[14px] max-w-[200px]">
                  <div className=" flex items-center">
                    {/* <img src={a1} alt="new"></img> */}
                    <div className="flex items-center justify-center bg-[#dfdfdf] min-w-[46px] min-h-[46px] rounded-[50%]">
                    <p className="text-[14px] font-semibold  font-poppins">{m?.name?.substring(0,1)?.toUpperCase()}</p>
              </div>
                    <div className=" pl-[16px]">
                      <p className="text-[#47505F] text-[14px] font-medium font-poppins">
                        {m?.name?.length > 16 ? (
                          <>{`${m?.name?.substring(0, 16)}...`}</>
                        ) : (
                          <>{m?.name}</>
                        )}
                      </p>
                      <p className="text-[#47505F] text-[14px] font-medium font-poppins">
                        {m?.emailID}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="text-[#47505F] text-[14px] font-medium font-poppins py-[20px]">
                  <div>{m?.contact}</div>
                </td>
                <td className="text-[#47505F] font-medium font-poppins py-[20px]">
                  <div className=" flex items-center">
                    <div className=" flex flex-col items-center">
                      {/* Code block starts */}
                      <div className="cursor-pointer rounded-full bg-gray-200 relative shadow-sm">
                        <input
                          type="checkbox"
                          name="toggle"
                          id={m?._id}
                          defaultChecked={m.status === true}
                          onChange={(e) => {
                            setcheckvalue(e.target.checked);
                            updatestatus(e.target.checked, m?._id)
                          }}
                          value={checkvalue}
                          className="focus:outline-none checkbox w-[22px] h-[22px] mx-[3px] rounded-full bg-white absolute shadow-sm appearance-none cursor-pointer border border-transparent top-0 bottom-0 m-auto"
                        />
                        <label
                          htmlFor={m?._id}
                          className="toggle-label dark:bg-gray-700 block w-[50px] r-2 h-[26px] overflow-hidden rounded-full bg-[#FF0101] cursor-pointer"
                        />
                      </div>
                    </div>
                    {/* Code block ends */}
                    <span className="ml-[17px] text-[14px]">{m?.status}</span>
                  </div>
                </td>
                <td className="text-[#47505F] font-medium font-poppins py-[20px] cursor-pointer">
                  <div className=" flex items-center">
                    <Link to={`/admin/vle-detail/${m?._id}`}>
                      <img
                        src={eye}
                        className=""
                        alt="new"
                        onClick={() => dispatch(reset(actionTypes.RESET_VLE_DETAIL))}
                      ></img>
                    </Link>
                  {newrole === 'admin' &&  <img
                      src={del}
                      className="ml-[15px]"
                      alt="new"
                      onClick={(e) => modal(e, m?._id)}
                    ></img>}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className=" md:flex items-center justify-between w-full py-[20px] bg-[#fff] rounded-b-[10px] boxshadow px-[20px] sm:px-[40px] mb-[30px]">
        <div className=" flex items-center mb-5 sm:mb-0">
          <p className="text-[#47505F] font-medium font-[poppins] ">View</p>

          {/* ----------------------select page ------------------------ */}

          <Menu as="div" className="relative text-left">
            <div>
              <Menu.Button className="flex px-[20px] ml-[24px] min-h-[40px] bg-[#F2F2F2] min-w-[85px] rounded-[8px] justify-between  text-sm font-poppins font-medium  items-center ">
                <p className="text-[#47505F] font-medium font-[poppins]">
                  {page}
                </p>
                <img className="" src={arrowdown} alt="new" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="bottom-full absolute right-0 z-10 mt-2 py-2 w-[80%] rounded-[12px] bg-white shadoe-sm focus:outline-none">
                {allvalue?.map((e) => (
                  <div key={e?.id}>
                    <Menu.Item>
                      {({ active }) => (
                        <p
                          className={classNames(
                            active ? "bg-[#DAE9FF] text-[#076aff]" : "",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                          onClick={() => onselectedpage(e)}
                        >
                          {e?.value}
                        </p>
                      )}
                    </Menu.Item>
                  </div>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>

          {/* ----------------------End select page ------------------------ */}
        </div>

        {/* ------------------------- pagination -------------------------*/}
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={Itemperpage} // Replace with the total number of pages
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
          forcePage={selectpage}
        />
        {/* -------------------------End  pagination -------------------------*/}
      </div>
</div> : 
<div className=" flex items-center justify-center min-h-[300px] sm:text-[20px] text-[#161616] font-poppins font-medium">No VLE Found!!</div>
}
      
    </div>
  );
}

export default VleList;
