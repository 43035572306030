import React, { Fragment, useState, useEffect } from "react";
import search from "../../images/search.png";
import del from "../../images/newdelete.png";
import edit from "../../images/editmovie.png";
import eye from "../../images/eye.png";
import arrowdown from "../../images/arrow-down.svg";
import arrowd from "../../images/rightarr.png";
import "../../App.css";
import { Menu, Transition } from "@headlessui/react";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";

import {
  UpdateMoviestatus,
  GetProducerMovieNewList,
  GetProducerMovielist,
  DeleteProducerMovie,
  ResetMovie,
} from "../../Redux/Action/MovieAction";
import { useLocation } from "react-router-dom/dist";
import { useNavigate } from "react-router-dom/dist";
import { useSearchParams } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { actionTypes } from "../../Redux/Reducer/Moviereducer";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ProducerMovie() {
  const [checkvalue, setcheckvalue] = useState("");

  // for select page value

  const allvalue = [
    { value: "10", label: "10", id: 1 },
    { value: "25", label: "25", id: 2 },
    { value: "50", label: "50", id: 3 },
  ];

  const [page, setpage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();

  const removeQueryParams = () => {
    const param = searchParams.get("search");

    if (param) {
      searchParams.delete("search");
      setSearchParams(searchParams);
    }
  };

  useEffect(() => {
    setpage(allvalue[0]?.value);
  }, []);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const [selectpage, setselectpage] = useState("");

  //--------------------movie delete modal------------------------------

  const modal = (e, id) => {
    e?.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Movie!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#076aff",
      cancelButtonColor: "#ff0101",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const sds = location.search.slice(1).split("&");
        let object = {};
        if (location.search.includes("?")) {
          sds.map((item) => {
            let newItem = item.split("=");
            object[newItem[0]] = newItem[1];
          });
        }
        dispatch(DeleteProducerMovie(id, { ...object }, history));
      }
    });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    searchParams.delete('search');
    setSearchParams(searchParams)
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    delete object.search;
    if (role === "producer") {
      dispatch(GetProducerMovieNewList({...object}, history));
    } else {
      dispatch(GetProducerMovielist({...object}, history));
    }
  }, []);

  const movielists = useSelector(
    (state) => state.movie.producermovienewlist.data
  );
  const producermovielist = useSelector(
    (state) => state.movie.producermovielist.data
  );

  const [sort, setsort] = useState("desc");
  const [sortprice, setsortprice] = useState("desc");
  const [sortstatus, setsortstatus] = useState("desc");
  const [Search, setSearch] = useState("");
  const ordermethod = (order_by) => {
    switch (order_by) {
      case "name":
        const t = sort === "desc" ? "asc" : "desc";
        setsort(t);
        break;

      case "status":
        const te = sortstatus === "desc" ? "asc" : "desc";
        setsortstatus(te);
        break;

      case "price":
        const tr = sortprice === "desc" ? "asc" : "desc";
        setsortprice(tr);
        break;

      default:
    }
  };

  const location = useLocation();
  const history = useNavigate();

  const updatestatus = (sdata, id) => {
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    const data = {
      status: sdata,
    };
    dispatch(UpdateMoviestatus(data, id, " ", history));
    if (role === "producer") {
      dispatch(GetProducerMovieNewList({ ...object }, history));
    } else {
      dispatch(GetProducerMovielist({ ...object }, history));
    }
  };

  //----------------- for field and order filter------------------------

  const onApply = (order_by, sort) => {
    const order_typedata = ordermethod(order_by);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/producer-movies",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            field: order_by,
            order: sort,
          }).toString(),
      });
    } else {
      history({
        pathname: "/admin/producer-movies",
        search: `?field=${order_by}&order=${sort}`,
      });
    }
    if (role === "producer") {
      dispatch(
        GetProducerMovieNewList(
          { ...object, field: order_by, order: sort },
          history
        )
      );
    } else {
      dispatch(
        GetProducerMovielist(
          { ...object, field: order_by, order: sort },
          history
        )
      );
    }
  };

  //----------------- for limit filter------------------------

  const onselectedpage = (item) => {
    setpage(item.value);

    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      setpage(item.value);
      history({
        pathname: "/admin/producer-movies",
        search:
          "?" +
          new URLSearchParams({ ...object, limit: item.value }).toString(),
      });
    } else {
      history({
        pathname: "/admin/producer-movies",
        search: `?limit=${item.value}`,
      });
    }
    if (role === "producer") {
      dispatch(
        GetProducerMovieNewList({ ...object, limit: item.value }, history)
      );
    } else {
      dispatch(GetProducerMovielist({ ...object, limit: item.value }, history));
    }
  };

  //----------------- for search filter------------------------

  const submitsearch = (e) => {
    e?.preventDefault();
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/producer-movies",
        search:
          "?" + new URLSearchParams({ ...object, search: Search }).toString(),
      });
    } else {
      history({
        pathname: "/admin/producer-movies",
        search: `?search=${Search}`,
      });
    }
    if (role === "producer") {
      dispatch(GetProducerMovieNewList({ ...object, search: Search }, history));
    } else {
      dispatch(GetProducerMovielist({ ...object, search: Search }, history));
    }
  };

  //----------------- for pagination filter------------------------

  const handlePageChange = (selected) => {
    setselectpage(selected.selected);
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      history({
        pathname: "/admin/producer-movies",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            page: selected.selected + 1,
          }).toString(),
      });
    } else {
      history({
        pathname: "/admin/producer-movies",
        search: `?page=${selected.selected + 1}`,
      });
    }
    if (role === "producer") {
      dispatch(
        GetProducerMovieNewList(
          { ...object, page: selected.selected + 1 },
          history
        )
      );
    } else {
      dispatch(
        GetProducerMovielist(
          { ...object, page: selected.selected + 1 },
          history
        )
      );
    }
  };


  const [Itemperpage, setItemperpage] = useState("");

  const pageCount = Math.ceil(Number(movielists?.count) / Number(page));
  const pageCounts = Math.ceil(Number(producermovielist?.count) / Number(page));

  useEffect(() => {
    if (role === "producer") {
      if (pageCount && pageCount !== Infinity) {
        setItemperpage(pageCount);
      }
    } else {
      if (pageCounts && pageCounts !== Infinity) {
        setItemperpage(pageCounts);
      }
    }
  }, [movielists, producermovielist]);

  const role = localStorage.getItem("role");
  useEffect(() => {
    const newlimit = searchParams.get("limit");
    if(newlimit?.length > 0){
      setpage(newlimit)
    }
  },[])

  useEffect(() => {
    const newpage = searchParams.get("page");
    if(newpage?.length > 0 && newpage !== null){
      setselectpage(newpage - 1)
    }
  },[])

  return (
    <div className="pt-[20px] pb-[20px] lg:pb-0 h-full">
      {/* <ToastContainer /> */}
      <div>
        <p className=" sm:text-[22px] text-[#161616] font-poppins font-medium">
          PRODUCER MOVIES
        </p>
        {/* search bar  */}
        <div className="mt-[15px] mb-[20px] sm:mb-[30px] p-5 bg-[#fff] rounded-[10px] lg:flex justify-between listtableshadow">
          <div>
            <form
              className="h-[40px] relative"
              onSubmit={(e) => {
                e?.preventDefault();
                if (Search) {
                  submitsearch(e);
                }
              }}
            >
              <input
                className="h-full bg-[#F2F2F2] rounded-[8px] pl-[50px] min-w-full lg:min-w-[400px] placeholder:text-[#47505F]  placeholder:text-[14px] placeholder:font-medium"
                placeholder="Search..."
                value={Search}
                onChange={(e) => {
                  setSearch(e.target.value);
                  if (e.target.value === "") {
                    if (role === "producer") {
                      dispatch(GetProducerMovieNewList("", history));
                    } else {
                      dispatch(GetProducerMovielist("", history));
                    }
                    removeQueryParams();
                  }
                }}
              ></input>
              <img src={search} className="-mt-7 ml-[20px]" alt="new"></img>
            </form>
          </div>
          {role === "producer" && (
            <div className="w-[114px]">
              <Link to="/admin/addproducer-movie">
                <button className="bg-[#076AFF] mt-3 lg:mt-0 flex items-center  min-w-[114px] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium rounded-[5px] h-[40px] text-white text-[14px]">
                  <span className="mx-[16px]">
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.14174 1.85721C9.14174 1.22507 8.63103 0.714355 7.99888 0.714355C7.36674 0.714355 6.85603 1.22507 6.85603 1.85721V7.00007H1.71317C1.08103 7.00007 0.570312 7.51078 0.570312 8.14293C0.570312 8.77507 1.08103 9.28578 1.71317 9.28578H6.85603V14.4286C6.85603 15.0608 7.36674 15.5715 7.99888 15.5715C8.63103 15.5715 9.14174 15.0608 9.14174 14.4286V9.28578H14.2846C14.9167 9.28578 15.4275 8.77507 15.4275 8.14293C15.4275 7.51078 14.9167 7.00007 14.2846 7.00007H9.14174V1.85721Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  Add
                </button>
              </Link>
            </div>
          )}
        </div>
        {/*End search bar  */}

        {/*------------------------- Table ---------------------------- */}
        <div className="tableFixHead">
          <table className="w-full listtableshadow">
            <thead className="bg-[#F9FAFB]" style={{ minHeight: 60 }}>
              <tr className="text-[#161616] font-medium font-poppins">
                <td className="py-4 pl-[40px] min-w-[100px]">ID</td>
                <td className="py-4  min-w-[220px]">
                  <div className=" flex items-center">
                    Movie{" "}
                    <span>
                      <img
                        src={arrowd}
                        alt="new"
                        onClick={() => onApply("name", sort)}
                        className={`${
                          sort === "asc" ? `${"-rotate-90"}` : `${" rotate-90"}`
                        } ml-2 cursor-pointer`}
                      ></img>
                    </span>
                  </div>
                </td>
                {role === "producer" && (
                  <td className="py-4 min-w-[100px]">
                    <div className=" flex items-center">
                      Price{" "}
                      <span>
                        <img
                          src={arrowd}
                          alt="new"
                          onClick={() => onApply("price", sortprice)}
                          className={`${
                            sortprice === "asc"
                              ? `${"-rotate-90"}`
                              : `${" rotate-90"}`
                          } ml-2 cursor-pointer`}
                        ></img>
                      </span>
                    </div>
                  </td>
                )}
                {role === "producer" && (
                  <td className="py-4  min-w-[170px]">
                    <div className=" flex items-center">
                      Status{" "}
                      <span>
                        <img
                          src={arrowd}
                          alt="new"
                          onClick={() => onApply("status", sortstatus)}
                          className={`${
                            sortstatus === "asc"
                              ? `${"-rotate-90"}`
                              : `${" rotate-90"}`
                          } ml-2 cursor-pointer`}
                        ></img>
                      </span>
                    </div>
                  </td>
                )}
                <td className="py-4  min-w-[120px]">Action</td>
              </tr>
            </thead>
            <tbody>
              {role === "producer"
                ? movielists?.movie?.map((m, index) => (
                    <tr className={`border-b bg-[#fff]`} key={m?._id}>
                      <td className="text-[#47505F] text-[14px] font-medium font-poppins pl-[40px] py-[20px]">
                        {index + 1}
                      </td>
                      <td className="py-[12px] ">
                        <div className=" flex items-center">
                          <img
                            src={`${`https://hooc.b-cdn.net/${
                              m?.poster_image
                            }?t=${Date.now()}`}`}
                            className=" w-[50px] h-[50px] rounded-[5px]"
                            alt="new"
                          ></img>
                          <div className=" pl-[16px]">
                            <p className="text-[#47505F] text-[14px] font-medium font-poppins">
                              {m?.name?.length > 16 ? (
                                <>{`${m?.name?.substring(0, 16)}...`}</>
                              ) : (
                                <>{m?.name}</>
                              )}
                            </p>
                          </div>
                        </div>
                      </td>
                      {role === "producer" && (
                        <td className="text-[#47505F] text-[14px] font-medium font-poppins py-[20px]">
                          <div>
                            {m?.price?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "INR",
                              minimumFractionDigits: 0,
                            })}
                          </div>
                        </td>
                      )}
                      {role === "producer" && (
                        <td className="text-[#47505F] font-medium font-poppins py-[20px]">
                          <span
                            className={`${
                              m?.status === true
                                ? `${"text-[#028F3B]"}`
                                : `${"text-[#ff0101]"}`
                            }  text-[14px]`}
                          >
                            {m?.status === true ? "Active" : "InActive"}
                          </span>
                        </td>
                      )}
                      <td className="text-[#47505F] font-medium font-poppins flex  items-center py-[20px] cursor-pointer">
                        <Link to={`/admin/editproducer-movie/${m?._id}`}>
                          <img
                            src={edit}
                            className=" cursor-pointer"
                            alt="new"
                            onClick={() => dispatch(ResetMovie(actionTypes.RESET_PRODUCER_MOVIE_DETAIL))}
                          ></img>
                        </Link>
                        <Link to={`/admin/viewproducer-movie/${m?._id}`}>
                          <img
                            src={eye}
                            className="mx-[20px] cursor-pointer"
                            alt="new"
                            onClick={() => dispatch(ResetMovie(actionTypes.RESET_PRODUCER_MOVIE_DETAIL))}
                          ></img>
                        </Link>
                        {role === "admin" && (
                          <img
                            src={del}
                            className=" cursor-pointer"
                            alt="new"
                            onClick={(e) => modal(e, m._id)}
                          ></img>
                        )}
                      </td>
                    </tr>
                  ))
                : producermovielist?.movie?.map((m, index) => (
                    <tr className={`border-b bg-[#fff]`} key={m?._id}>
                      <td className="text-[#47505F] text-[14px] font-medium font-poppins pl-[40px] py-[20px]">
                        {index + 1}
                      </td>
                      <td className="py-[12px] ">
                        <div className=" flex items-center">
                          <img
                            src={`${`https://hooc.b-cdn.net/${
                              m?.poster_image
                            }?t=${Date.now()}`}`}
                            className=" w-[50px] h-[50px] rounded-[5px]"
                            alt="new"
                          ></img>
                          <div className=" pl-[16px]">
                            <p className="text-[#47505F] text-[14px] font-medium font-poppins">
                              {m?.name?.length > 16 ? (
                                <>{`${m?.name?.substring(0, 16)}...`}</>
                              ) : (
                                <>{m?.name}</>
                              )}
                            </p>
                          </div>
                        </div>
                      </td>
                      {role === "producer" && (
                        <td className="text-[#47505F] text-[14px] font-medium font-poppins py-[20px]">
                          <div>
                            {m?.price?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "INR",
                              minimumFractionDigits: 0,
                            })}
                          </div>
                        </td>
                      )}
                      {role === "producer" && (
                        <td className="text-[#47505F] font-medium font-poppins py-[20px]">
                          <span
                            className={`${
                              m?.status === true
                                ? `${"text-[#028F3B]"}`
                                : `${"text-[#ff0101]"}`
                            }  text-[14px]`}
                          >
                            {m?.status === true ? "Active" : "InActive"}
                          </span>
                        </td>
                      )}
                      <td className="text-[#47505F] font-medium font-poppins flex  items-center py-[20px] cursor-pointer">
                        <Link to={`/admin/editproducer-movie/${m?._id}`}>
                          <img
                            src={edit}
                            className=" cursor-pointer"
                            alt="new"
                            // onClick={() => dispatch(GetMovieDetail(m?._id))}
                            onClick={() => dispatch(ResetMovie(actionTypes.RESET_PRODUCER_MOVIE_DETAIL))}
                          ></img>
                        </Link>
                        <Link to={`/admin/viewproducer-movie/${m?._id}`}>
                          <img
                            src={eye}
                            className="mx-[20px] cursor-pointer"
                            alt="new"
                            onClick={() => dispatch(ResetMovie(actionTypes.RESET_PRODUCER_MOVIE_DETAIL))}
                          ></img>
                        </Link>
                        {role === "admin" && (
                          <img
                            src={del}
                            className=" cursor-pointer"
                            alt="new"
                            onClick={(e) => modal(e, m._id)}
                          ></img>
                        )}
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
        {/*-------------------------End Table ---------------------------- */}

        <div className=" md:flex items-center justify-between w-full py-[20px] bg-[#fff] rounded-b-[10px] boxshadow px-[20px] sm:px-[40px] mb-[30px]">
          <div className=" flex items-center mb-5 sm:mb-0">
            <p className="text-[#47505F] font-medium font-[poppins] ">View</p>

            {/* ----------------------select page ------------------------ */}

            <Menu as="div" className="relative text-left">
              <div>
                <Menu.Button className="flex px-[20px] ml-[24px] min-h-[40px] bg-[#F2F2F2] min-w-[85px] rounded-[8px] justify-between  text-sm font-poppins font-medium  items-center ">
                  <p className="text-[#47505F] font-medium font-[poppins]">
                    {page}
                  </p>
                  <img className="" src={arrowdown} alt="new" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="bottom-full absolute right-0 z-10 mt-2 py-2 w-[80%] rounded-[12px] bg-white shadoe-sm focus:outline-none">
                  {allvalue?.map((e) => (
                    <div key={e?.id}>
                      <Menu.Item>
                        {({ active }) => (
                          <p
                            className={classNames(
                              active ? "bg-[#DAE9FF] text-[#076aff]" : "",
                              "block px-4 py-2 text-sm cursor-pointer"
                            )}
                            onClick={() => onselectedpage(e)}
                          >
                            {e?.value}
                          </p>
                        )}
                      </Menu.Item>
                    </div>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>

            {/* ----------------------End select page ------------------------ */}
          </div>

          {/* ------------------------- pagination -------------------------*/}
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            pageCount={Itemperpage} // Replace with the total number of pages
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
          forcePage={selectpage}
          />
          {/* -------------------------End  pagination -------------------------*/}
        </div>
      </div>
    </div>
  );
}

export default ProducerMovie;
