import React, { useEffect } from "react";
import arrow from "../../images/rightarr.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import "photoswipe/dist/photoswipe.css";
import { Gallery, Item } from "react-photoswipe-gallery";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { GetVleDetail } from "../../Redux/Action/VleAction";

function VleDetail() {
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [Email, setEmail] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Address, setAddress] = useState("");
  const [Country, setCountry] = useState("");
  const [City, setCity] = useState("");
  const [State, setState] = useState("");
  const [ZipCode, setZipCode] = useState("");
  const [Aadharno, setAadharno] = useState("");
  const [Panno, setPanno] = useState("");
  const [gstno, setgstno] = useState("");
  const [smallimages, setsmallImages] = useState("");
  const [Panimages, setPanImages] = useState("");
  const [Gstimages, setGstImages] = useState("");
  const [Certiimages, setCertiimages] = useState("");
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const vledetail = useSelector((state) => state.Vle?.vledetail?.data?.vle);
  useEffect(() => {
    setFirstName(vledetail?.firstname);
    setLastName(vledetail?.lastname);
    setEmail(vledetail?.emailID);
    setMobile(vledetail?.contact);
    setAddress(vledetail?.address);
    setCountry(vledetail?.country);
    setState(vledetail?.state);
    setCity(vledetail?.city);
    setZipCode(vledetail?.pincode);
    setAadharno(vledetail?.aadharcardno);
    setPanno(vledetail?.pancardno);
    setgstno(vledetail?.gstcertificateno);
    setsmallImages(
      `${`https://hooc.b-cdn.net/${vledetail?.aadharcard}?t=${Date.now()}`}`
    );
    setPanImages(
      `${`https://hooc.b-cdn.net/${vledetail?.pancard}?t=${Date.now()}`}`
    );
    setGstImages(
      `${`https://hooc.b-cdn.net/${vledetail?.gstcertificate}?t=${Date.now()}`}`
    );
    setCertiimages(
      `${`https://hooc.b-cdn.net/${
        vledetail?.ownercertificate
      }?t=${Date.now()}`}`
    );
  }, [vledetail]);


  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useNavigate();
  useEffect(() => {
    dispatch(GetVleDetail(id , history));
  }, []);
  return (
    <div>
      <div className="pt-[20px]">
        <div className=" flex items-center pb-[7px]">
          <Link to="/admin/vle">
            <p className=" text-[#076aff] cursor-pointer text-[18px] font-medium font-poppins">
              VLE
            </p>
          </Link>
          <img src={arrow} alt="new" className="mx-3"></img>
          <p className=" text-[#161616] text-[18px] font-medium font-poppins">
            VLE Details
          </p>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-x-4 lg:gap-x-7 mt-[20px] sm:mt-[30px]">
        <div className="col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
          <p className="sm:text-[18px] mb-[15px] text-[#161616] font-medium font-poppins">
            First Name
          </p>
          <div className="relative">
            <input
              className="bg-white w-full border border-[#6D6E70] h-[46px] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
              value={FirstName}
              onChange={(e) => setFirstName(e.target.value)}
              disabled
            />
          </div>
        </div>
        <div className="col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
          <p className="sm:text-[18px] mb-[15px] text-[#161616] font-medium font-poppins">
            Last Name
          </p>
          <div className="relative">
            <input
              className="bg-white w-full border border-[#6D6E70] h-[46px] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
              value={LastName}
              onChange={(e) => setLastName(e.target.value)}
              disabled
            />
          </div>
        </div>
        <div className="col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
          <p className="sm:text-[18px] mb-[15px] text-[#161616] font-medium font-poppins">
            Email
          </p>
          <div className="relative">
            <input
              className="bg-white w-full h-[46px] border border-[#6D6E70] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
              value={Email}
              onChange={(e) => setEmail(e.target.value)}
              disabled
            />
          </div>
        </div>
        <div className="col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
          <p className="sm:text-[18px] mb-[15px] text-[#161616] font-medium font-poppins">
            Mobile No
          </p>
          <div className="relative">
            <input
              className="bg-white w-full border border-[#6D6E70] h-[46px] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
              value={Mobile}
              onChange={(e) => setMobile(e.target.value)}
              disabled
            />
          </div>
        </div>
        <div className="col-span-12 sm:col-span-4 mb-[20px] sm:mb-[30px]">
          <p className="sm:text-[18px] mb-[15px] text-[#161616] font-medium font-poppins">
            Address
          </p>
          <div className="relative">
            <input
              className="bg-white w-full h-[46px] border border-[#6D6E70] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
              value={Address}
              onChange={(e) => setAddress(e.target.value)}
              disabled
            />
          </div>
        </div>
        <div className="col-span-12 sm:col-span-4 mb-[20px] sm:mb-[0px]">
          <p className="sm:text-[18px] mb-[15px] text-[#161616] font-medium font-poppins">
            Country
          </p>
          <div className="relative">
            <input
              className="bg-white w-full h-[46px] border border-[#6D6E70] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
              value={Country}
              onChange={(e) => setCountry(e.target.value)}
              disabled
            />
          </div>
        </div>
        <div className="col-span-12 sm:col-span-4 mb-[20px] sm:mb-[0px]">
          <p className="sm:text-[18px] mb-[15px] text-[#161616] font-medium font-poppins">
            State
          </p>
          <div className="relative">
            <input
              className="bg-white w-full border border-[#6D6E70] h-[46px] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
              value={State}
              onChange={(e) => setState(e.target.value)}
              disabled
            />
          </div>
        </div>
        <div className="col-span-12 sm:col-span-4 mb-[20px] sm:mb-[0px]">
          <p className="sm:text-[18px] mb-[15px] text-[#161616] font-medium font-poppins">
            City
          </p>
          <div className="relative">
            <input
              className="bg-white w-full h-[46px] border border-[#6D6E70] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
              value={City}
              onChange={(e) => setCity(e.target.value)}
              disabled
            />
          </div>
        </div>
        <div className="col-span-12 sm:col-span-4">
          <p className="sm:text-[18px] mb-[15px] text-[#161616] font-medium font-poppins">
            Zip Code
          </p>
          <div className="relative">
            <input
              className="bg-white w-full h-[46px] border border-[#6D6E70] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
              value={ZipCode}
              onChange={(e) => setZipCode(e.target.value)}
              disabled
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-x-4 lg:gap-x-7 py-[30px] ">
        <Gallery>
          <div className="col-span-12 sm:col-span-6 xl:col-span-3 md:col-span-4">
            <div className=" mb-[20px]  h-[160px] object-cover overflow-hidden w-full  flex items-center justify-center cursor-pointer rounded-[10px]">
              <Item original={smallimages} width="700" height="400">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    className="h-[160px] w-full object-cover rounded-[10px]"
                    src={smallimages}
                    alt="new"
                  />
                )}
              </Item>
            </div>
            <div className=" col-span-12 sm:col-span-4 mb-[20px] sm:mb-0">
              <input
                className="bg-white w-full border border-[#6D6E70] h-[46px] flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                value={Aadharno}
                onChange={(e) => setAadharno(e.target.value)}
                disabled
              />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-6 xl:col-span-3 md:col-span-4">
            <div className=" mb-[20px]  h-[160px] object-cover overflow-hidden w-full  flex items-center justify-center cursor-pointer rounded-[10px]">
              <Item original={Panimages} width="700" height="400">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    className="h-[160px] w-full object-cover rounded-[10px]"
                    src={Panimages}
                    alt="new"
                  />
                )}
              </Item>
            </div>
            <div className="col-span-12 sm:col-span-4 mb-[20px] sm:mb-0">
              <input
                className="bg-white w-full border border-[#6D6E70] h-[46px]  flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                value={Panno}
                onChange={(e) => setPanno(e.target.value)}
                disabled
              />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-6 xl:col-span-3 md:col-span-4 sm:mt-[20px] md:mt-0">
            <div className=" mb-[20px] h-[160px] object-cover overflow-hidden w-full flex items-center justify-center cursor-pointer rounded-[10px]">
              <Item original={Gstimages} width="700" height="400">
                {({ ref, open }) => (
                  <img
                    ref={ref}
                    onClick={open}
                    className="h-[160px] w-full object-cover rounded-[10px]"
                    src={Gstimages}
                    alt="new"
                  />
                )}
              </Item>
            </div>
            <div className="col-span-12 sm:col-span-4 mb-[20px] xl:mb-0">
              <input
                className="bg-white w-full h-[46px] border border-[#6D6E70]  flex items-center pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                value={gstno}
                onChange={(e) => setgstno(e.target.value)}
                disabled
              />
            </div>
          </div>
          <div className="col-span-12 sm:col-span-6 xl:col-span-3 md:col-span-4 sm:mt-[20px] md:mt-0  h-[160px] object-cover overflow-hidden w-full flex items-center justify-center cursor-pointer rounded-[10px]">
            <Item original={Certiimages} width="700" height="400">
              {({ ref, open }) => (
                <img
                  ref={ref}
                  onClick={open}
                  className="h-[160px] w-full object-cover rounded-[10px]"
                  src={Certiimages}
                  alt="new"
                />
              )}
            </Item>
          </div>
        </Gallery>
      </div>
    </div>
  );
}

export default VleDetail;
