import React from "react";
import { useState } from "react";
import { DateRangePicker } from "react-date-range";
import calendar from "../../images/calendar.png";
import arrow from "../../images/rightarr.png";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { Link, useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import arrowdown from "../../images/arrow-down.svg";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetMovieReportlist } from "../../Redux/Action/TransactionAction";


function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

function MovieReport() {
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const [open, setopen] = useState(false);
  const [page, setpage] = useState("");
  
  useEffect(() => {
    setpage(allvalue[0]?.value);
  }, []);


  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  //   const history = useNavigate();
  const handleSelection = (range) => {
    setSelectionRange(range.selection);
  };

  const handleSend = () => {
    const { startDate, endDate } = selectionRange;
    const data = {
      start: new Date(startDate).valueOf(),
      end: new Date(endDate).valueOf(),
    };
    // dispatch(GetTransactionData(data));
    // console.log({ start: startDate, end: endDate });
  };

  const ClearDateSend = () => {
    setSelectionRange({
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    });
    // dispatch(GetTransactionData(""));
  };

  const movielist = [
    {
      name: "Dream Girl 2",
      show: "100",
      discount: "50%",
      income: 120000,
      id: 1,
    },
    {
      name: "Bushirt T-shirt",
      show: "10",
      discount: "15%",
      income: 100000,
      id: 2,
    },
    { name: "Shehzada", show: "50", discount: "25%", income: 15000, id: 3 },
    { name: "10000 BC", show: "10", discount: "35%", income: 20000, id: 4 },
    { name: "Interstellar", show: "60", discount: "5%", income: 250000, id: 5 },
    {
      name: "Indiana Jones",
      show: "80",
      discount: "30%",
      income: 30000,
      id: 6,
    },
    { name: "Jawan", show: "35", discount: "10%", income: 56000, id: 7 },
    { name: "Inception", show: "25", discount: "15%", income: 78000, id: 8 },
  ];
  const allvalue = [
    { value: "10", label: "10", id: 1 },
    { value: "25", label: "25", id: 2 },
    { value: "50", label: "50", id: 3 },
  ];


  const dispatch = useDispatch();
  
  const history = useNavigate();
   
  useEffect(() => {
   dispatch(GetMovieReportlist('' , history))
  },[])

  const Moviereportlist = useSelector(state => state.Transactiondata.Moviereportlist.data)

  console.log(Moviereportlist)

  return (
    <div className=" pt-[15px] h-full">
      <div className=" flex items-center relative justify-between">
        <div className=" flex items-center">
          <Link to={"/admin/transaction"}>
            <p className=" text-[#076aff] cursor-pointer text-[18px] font-medium font-poppins">
              TRANSACTION
            </p>
          </Link>
          <img src={arrow} alt="new" className="mx-3"></img>
          <p className=" text-[#000] cursor-pointer text-[18px] font-medium font-poppins">
            MOVIE'S REPORT
          </p>
        </div>
        <button
          onClick={() => setopen(!open)}
          className=" bg-[#076aff] text-[#fff]  flex items-center min-h-[40px] min-w-[100px] rounded-[5px] justify-center"
        >
          <img src={calendar} alt="new"></img>
          <span className=" font-poppins pl-3 text-[14px]">Date</span>
        </button>
        {open && (
          <div className=" absolute right-0 top-10  d-inline-block z-[100] shadow-xl">
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelection}
              rangeColors={["#076aff"]}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              popperProps={{
                strategy: "fixed", // use this to make the popper position: fixed
              }}
              direction="horizontal"
            />
            <div className="text-right position-relative  rdr-buttons-position pb-2 bg-[#fff]">
              <button
                className=" bg-[#076aff] py-1 text-[12px] text-[#fff] rounded-[5px] px-4 mr-2"
                onClick={() => {
                  handleSend();
                  setopen(false);
                }}
              >
                Apply
              </button>
              <button
                onClick={() => {
                  ClearDateSend();
                  setopen(false);
                }}
                className=" border border-[#076aff] text-[#076aff] py-1 text-[12px]  rounded-[5px] px-4 mr-2"
              >
                Clear
              </button>
            </div>
          </div>
        )}
      </div>
      {movielist?.length > 0 ? (
        <div>
          <div className="tableFixHead mt-5">
            <table className="w-full listtableshadow overflow-auto">
              <thead className="bg-[#F9FAFB]" style={{ minHeight: 60 }}>
                <tr className="text-[#161616] font-medium font-poppins">
                  <td className="py-4 pl-[40px] min-w-[100px]">ID</td>
                  <td className="py-4 min-w-[320px]">
                    <div className=" flex items-center">Movie Name</div>
                  </td>
                  <td className="py-4 min-w-[180px]">
                    <div className=" flex items-center">Discount</div>
                  </td>
                  <td className="py-4 min-w-[160px]">Purchase Show</td>
                  <td className="py-4 min-w-[160px]">Total Income</td>
                </tr>
              </thead>
              <tbody>
                {movielist?.map((m, index) => (
                  <tr className={`border-b bg-[#fff]`} key={m?.id}>
                    <td className="text-[#47505F] text-[14px] font-medium font-poppins pl-[40px] py-[20px]">
                      {index + 1}
                    </td>
                    <td className="py-[14px] max-w-[200px]">
                      <div className=" flex items-center">
                        {/* <img src={a1} alt="new"></img> */}
                        <div className="flex items-center justify-center bg-[#dfdfdf] min-w-[46px] min-h-[46px] rounded-[50%]">
                          <p className="text-[14px] font-semibold  font-poppins">
                            {m?.name?.substring(0, 1)?.toUpperCase()}
                          </p>
                        </div>
                        <div className=" pl-[16px]">
                          <p className="text-[#47505F] text-[14px] font-medium font-poppins">
                            {m?.name?.length > 16 ? (
                              <>{`${m?.name?.substring(0, 16)}...`}</>
                            ) : (
                              <>{m?.name}</>
                            )}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td className="text-[#47505F] font-medium font-poppins py-[20px] cursor-pointer">
                      {m?.discount}
                    </td>
                    <td className="text-[#47505F] text-[14px] font-medium font-poppins py-[20px]">
                      {m?.show}
                    </td>
                    <td className="text-[#47505F] text-[14px] font-medium font-poppins py-[20px]">
                      {m?.income?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "INR",
                        minimumFractionDigits: 0,
                      })}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className=" md:flex items-center justify-between w-full py-[20px] bg-[#fff] rounded-b-[10px] boxshadow px-[20px] sm:px-[40px] mb-[30px]">
          <div className=" flex items-center mb-5 sm:mb-0">
          <p className="text-[#47505F] font-medium font-[poppins] ">View</p>

          {/* ----------------------select page ------------------------ */}

          <Menu as="div" className="relative text-left">
            <div>
              <Menu.Button className="flex px-[20px] ml-[24px] min-h-[40px] bg-[#F2F2F2] min-w-[85px] rounded-[8px] justify-between  text-sm font-poppins font-medium  items-center ">
                <p className="text-[#47505F] font-medium font-[poppins]">
                  {page}
                </p>
                <img className="" src={arrowdown} alt="new" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="bottom-full absolute right-0 z-10 mt-2 py-2 w-[80%] rounded-[12px] bg-white shadoe-sm focus:outline-none">
                {allvalue?.map((e) => (
                  <div key={e?.id}>
                    <Menu.Item>
                      {({ active }) => (
                        <p
                          className={classNames(
                            active ? "bg-[#DAE9FF] text-[#076aff]" : "",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                        //   onClick={() => onselectedpage(e)}
                        >
                          {e?.value}
                        </p>
                      )}
                    </Menu.Item>
                  </div>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>

          {/* ----------------------End select page ------------------------ */}
          </div>

          {/* ------------------------- pagination -------------------------*/}
          <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
        //   pageCount={Itemperpage} // Replace with the total number of pages
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
        //   onPageChange={handlePageChange}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        //   forcePage={selectpage}
        />
          {/* -------------------------End  pagination -------------------------*/}
          </div>
        </div>
      ) : (
        <div className=" flex items-center justify-center min-h-[300px] sm:text-[20px] text-[#161616] font-poppins font-medium">
          No VLE Found!!
        </div>
      )}
    </div>
  );
}

export default MovieReport;
