import axios from "axios";
import { Flip, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";


export const actionTypes = {
  PRODUCER_LIST: "PRODUCER_LIST",
  MAIN_PRODUCER_LIST: "MAIN_PRODUCER_LIST",
  PRODUCER_DETAIL: "PRODUCER_DETAIL",
  DELETE_PRODUCER_LIST: "DELETE_PRODUCER_LIST",
  ADD_PRODUCER: "ADD_PRODUCER",
  PRODUCER_STATUS: "PRODUCER_STATUS",

};


// requested Producer list



export const GetRequestProducerlist = (query , history) => {
  const token = localStorage.getItem('token')

  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/producer/request` , {
        params : {...query},
        headers : {
          'Authorization' : token
        }
      })
      .then((response) => {
        dispatch({
          type: actionTypes.PRODUCER_LIST,
          requestproducerlist: response.data,
        });
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};

// Update Producer

export const UpdateProducer = (requestID , newdata , history) => {
  const token = localStorage.getItem('token')
  return async (dispatch) => {
    await axios
      .patch(
        `${process.env.REACT_APP_API_BASE_URL}/producer/request/${requestID}`,'',
        {
          headers: {
            Accept: "application/json",
            'Authorization' : token
            
          },
        }
      )
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        dispatch(GetRequestProducerlist(newdata));
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};

// Producer list

export const GetProducerlist = (query , history) => {
  const token = localStorage.getItem('token')

  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/producer`, {
        params: { ...query },
        headers : {
          'Authorization' : token
        }
      })
      .then((response) => {
        dispatch({
          type: actionTypes.MAIN_PRODUCER_LIST,
          producerlist: response.data,
        });
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};

// Create Producer

export const CreateNewProducer = (firstname,lastname,emailID,contact,address,country,state,city,pincode,file,file1,file2,aadharcardno,pancardno,gstcertificateno , history) => {
  var data = new FormData();
  var fileAadhar = new File([file && file[0]], file && file[0]?.name, {
    type: "application/json",
  });
  var filePan = new File([file1 && file1[0]], file1 && file1[0]?.name, {
    type: "application/json",
  });
  var fileGst = new File([file2 && file2[0]], file2 && file2[0]?.name, {
    type: "application/json",
  });

  data.append("firstname", firstname);
  data.append("lastname", lastname);
  data.append("emailID", emailID);
  data.append("contact", contact);
  data.append("address", address);
  data.append("country", country);
  data.append("state", state);
  data.append("city", city);
  data.append("pincode", pincode);
  data.append("aadharcard", fileAadhar);
  data.append("pancard", filePan);
  data.append("gst", fileGst);
  data.append("aadharcardno", aadharcardno);
  data.append("pancardno", pancardno);
  data.append("gstcertificateno", gstcertificateno);

  const token = localStorage.getItem('token')

  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/producer`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          'Authorization' : token
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        dispatch({
          type: actionTypes.ADD_PRODUCER,
          addproducer: response.data,
        });
        history('/admin/producer')
        // dispatch(GetProducerlist());
      })
      .catch((err) =>  toast.error(err.response.data.error, {
        transition: Flip,
        theme: "colored",
        autoClose: 500,
      }));
  };
};

// update Producer list

export const UpdateProducerlist = (data, id , newdata , history) => {

  const token = localStorage.getItem('token')

  return async (dispatch) => {
    await axios
      .patch(`${process.env.REACT_APP_API_BASE_URL}/producer/${id}`, data, {
        headers: {
          Accept: "application/json",
          'Authorization' : token
        },
      })
      .then((response) => {
        dispatch(GetProducerlist(newdata));
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};

// Delete Producer

export const DeleteProducer = (id , data , history) => {
  const token = localStorage.getItem('token')

  return async (dispatch) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/producer/${id}`, {
        headers: {
          Accept: "application/json",
          'Authorization' : token
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        dispatch({
          type: actionTypes.DELETE_PRODUCER_LIST,
          deleteproducerlist: response.data,
        });
        dispatch(GetProducerlist(data));
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};

// Producer Detail

export const GetProducerDetail = (id , history) => {
  const token = localStorage.getItem('token')
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/producer/${id}` , {
        headers : {
          'Authorization' : token
        }
       })
      .then((response) => {
        dispatch({
          type: actionTypes.PRODUCER_DETAIL,
          producerdetail: response.data,
        });
      })
      .catch((err) => { 
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};


// Update Producer status

export const UpdateProducerStatus = (data ,id , history) => {
  const token = localStorage.getItem('token')
  return async (dispatch) => {
    await axios
      .patch(`${process.env.REACT_APP_API_BASE_URL}/status/${id}` , data, {
        headers : {
          'Authorization' : token
        }
       })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        dispatch({
          type: actionTypes.PRODUCER_STATUS,
          producerstatus: response.data,
        });
      })
      .catch((err) => { 
        toast.error(err?.response?.data?.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};
