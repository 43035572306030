import React, { useEffect, useState } from "react";
import plus from "../images/plus1.svg";
import minus from "../images/minus1.svg";
import payment from "../images/payment.svg";
import emptycart from "../images/emptycart.svg";
import del from "../images/newdelete.png";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { deletecart, getCart, updateCart } from "../Redux/Action/cartAction";
import { useNavigate } from "react-router";
import { CreateOrder, OrderPreloader } from "../Redux/Action/OrderAction";
import { Link } from "react-router-dom";
import '../App.css'
import { actionTypes } from "../Redux/Reducer/OrderReducer";
import { ResetMovie } from "../Redux/Action/MovieAction";
import Loader from "../components/Loader";

function Cart() {
  const [count, setcount] = useState();
  const [msgbox, setmsgbox] = useState(false);
  const [orderId, setOrderId] = useState([]);



  const date = new Date();
  const newdate = Math.floor(new Date(date).getTime());
  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const dispatch = useDispatch();

  const mycart = useSelector(state => state?.cart?.cartlist?.data);

  const neworder = useSelector(state => state?.order?.neworder);

  useEffect(() => {
   if(neworder?.code === 200){
    setmsgbox(true)
   } 
  },[neworder])


  //----------- Modal for delete cart item------------------

  useEffect(() => {
    const newcart = mycart && mycart?.map((e) => e.quantity);

    setcount(newcart);
  }, []);

  useEffect(()=>{
    setOrderId(mycart && mycart[0]?.movies?.map((e) => e.movieID))
    // console.log(orderId);
  },[])

  const history = useNavigate();
  const modal = (e, id) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this movie!!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#076aff",
      cancelButtonColor: "#ff0101",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deletecart({ type: "movie" }, id , history));
      }
    });
  };
  const UpdateInCart = (id, show) => {
    const data = {
      show: show,
    };
    dispatch(updateCart(id, data));
  };

  return (
    <div className="main_container  sm:min-h-[500px]">
        {mycart && mycart?.length > 0 && ( 
        <div className="flex justify-between items-center mt-[30px] mb-[16px]">
        <p className="text-[#161616] text-[18px] sm:text-[20px] font-medium font-poppins">
          My Cart
        </p>
          <button
            onClick={() =>
              dispatch(
                deletecart({ type: "cart" }, mycart && mycart[0]?.cartID , history)
              )
            }
            className="text-[#47505F] border border-[#979797] min-h-[30px] sm:min-h-[40px] min-w-[100px] sm:min-w-[140px] rounded-[5px] text-[12px] sm:text-[14px] font-medium font-poppins"
          >
            Clear Cart
          </button> 
          </div>
        )}
     

      {/*---------------------------------Desktop  Cart Table ------------------------------ */}
      {mycart && mycart.length > 0 ? (
        <div className="grid grid-cols-12 gap-x-3 lg:gap-x-7">
          <table className="w-full border border-[#DAE9FF] mb-[30px] hidden lg:inline-table  lg:col-span-9 xl:col-span-8  2xl:max-w-full">
            <thead>
              <tr className="bg-[#DAE9FF]">
                <td className="py-[15px] pl-[30px]  text-[#161616] text-[18px] font-medium font-poppins">
                  Movies
                </td>
                <td className="py-[15px] text-[#161616] text-[18px] font-medium font-poppins">
                  Price
                </td>
                <td className="py-[15px] text-[#161616] text-[18px] font-medium font-poppins text-center">
                  Discount
                </td>
                <td className="py-[15px] text-[#161616] text-[18px] font-medium font-poppins text-center">
                  Quantity
                </td>
                <td className="py-[15px] text-[#161616] text-[18px] font-medium font-poppins text-center min-w-[90px]">
                  Total
                </td>
                <td className="py-[15px] text-[#161616] text-[18px] font-medium font-poppins text-center min-w-[80px]">
                  Action
                </td>
              </tr>
            </thead>
            <tbody>
              {mycart &&
                mycart[0]?.movies?.map((m) => (
                  <tr key={m?._id} className="border-b border-[#DAE9FF]">
                    <td className=" pl-[30px] py-3">
                      <div className="flex items-center">
                        <img
                          src={`${`https://hooc.b-cdn.net/${m?.poster_image}`}`}
                          alt="new"
                          className="sm:h-[80px] sm:w-[80px] rounded-[10px]"
                        ></img>

                        <p className="text-[#47505F] ml-3 text-[16px] font-medium font-poppins">
                          {m?.name?.length > 16 ? (
                            <>{`${m?.name?.substring(0, 16)}...`}</>
                          ) : (
                            <>{m?.name}</>
                          )}
                        </p>
                      </div>
                    </td>
                    <td className="">
                      <p className="text-[#47505F] text-[16px] font-medium font-poppins">
                        {m.price?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "INR",
                          minimumFractionDigits: 0,
                        })}
                      </p>
                    </td>
                    <td className="">
                      <p className="text-[#47505F] text-[16px] font-medium font-poppins text-center">
                        {m.discount?.length > 0 && `${m?.discount}%`}
                      </p>
                    </td>
                    <td className="">
                      <div className=" flex justify-center">
                        <div className="flex items-center h-[35px] ">
                          <button
                            className="text-[#076aff] bg-[#DAE9FF] w-[35px] flex justify-center items-center rounded-l-[5px] h-full"
                            onClick={() => {
                              if (m.minshow < m?.quantity) {
                                // setcount(count - 1);
                                UpdateInCart(m?.movieID, m?.quantity - 1);
                              }
                            }}
                          >
                            <img src={minus} alt="new"></img>
                          </button>
                          <p className="w-[35px] text-[16px] border-t border-b border-[#D9EEE2] text-[#076aff] h-full flex justify-center items-center font-poppins">
                            {m?.quantity}
                          </p>
                          <button
                            className="text-[#076aff] bg-[#DAE9FF]  w-[35px] flex justify-center items-center rounded-r-[5px] h-full"
                            onClick={() => {
                              //   setcount(count + 1);
                              UpdateInCart(m?.movieID, m?.quantity + 1);
                            }}
                          >
                            <img src={plus} alt="new"></img>
                          </button>
                        </div>
                      </div>
                    </td>
                    <td className="">
                      <p className="text-[#47505F] text-[16px] font-medium font-poppins text-center">
                        {m.total?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "INR",
                          minimumFractionDigits: 0,
                        })}
                      </p>
                    </td>
                    <td>
                      <img
                        src={del}
                        alt="new"
                        className=" mx-auto cursor-pointer"
                        onClick={(e) => modal(e, m?.movieID)}
                      ></img>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {/*---------------------------------End of Cart Table ------------------------------ */}

          {/*-------------------------------- mobile view for cart------------------------------ */}

          <div className="col-span-12 md:col-span-6 lg:hidden">
            {mycart &&
              mycart[0]?.movies.map((m) => (
                <div
                  className=" bg-[#fff] rounded-[10px] boxshadow p-5 mb-5"
                  key={m._id}
                >
                  <div className=" flex items-start justify-between">
                    <div className=" flex items-start">
                      <img
                        src={`${`https://hooc.b-cdn.net/${m?.poster_image}`}`}
                        alt="new"
                        className="h-[80px] w-[80px] rounded-[10px]"
                      ></img>
                      <div className="ml-3">
                        <p className="text-[#47505F]  text-[16px] font-medium font-poppins">
                          {m?.name?.length > 16 ? (
                            <>{`${m?.name?.substring(0, 16)}...`}</>
                          ) : (
                            <>{m?.name}</>
                          )}
                        </p>
                        <p className="text-[#47505F] text-[12px] sm:text-[18px] my-2 font-medium font-poppins">
                          {m.price?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "INR",
                            minimumFractionDigits: 0,
                          })}
                          {m?.discount && (
                            <span className="text-[#028F3B] ml-3 text-[10px] sm:text-[12px] font-medium font-poppins bg-[#d9eee2] px-3 py-1.5 rounded-[20px]">
                              {m.discount}% off
                            </span>
                          )}
                        </p>
                        <div className="my-[10px]">
                          <div className="flex items-center h-[25px] ">
                            <button
                              className="text-[#076aff] bg-[#DAE9FF] w-[25px] flex justify-center items-center rounded-l-[5px] h-full"
                              onClick={() => {
                                if (count < m?.quantity) {
                                  UpdateInCart(m?.movieID, m?.quantity - 1);
                                }
                              }}
                            >
                              <img src={minus} alt="new"></img>
                            </button>
                            <p className="w-[25px] text-[16px] border-t border-b border-[#D9EEE2] text-[#076aff] h-full flex justify-center items-center font-poppins">
                              {m?.quantity}
                            </p>
                            <button
                              className="text-[#076aff] bg-[#DAE9FF]  w-[25px] flex justify-center items-center rounded-r-[5px] h-full"
                              onClick={() => {
                                UpdateInCart(m?.movieID, m?.quantity + 1);
                              }}
                            >
                              <img src={plus} alt="new"></img>
                            </button>
                          </div>
                        </div>
                        <p className="text-[#47505F] text-[14px] sm:text-[16px]  font-poppins">
                          Total Price =
                          <span className="ml-2  text-[#000] text-[14px] sm:text-[16px]  font-poppins">
                            {m.total?.toLocaleString("en-US", {
                              style: "currency",
                              currency: "INR",
                              minimumFractionDigits: 0,
                            })}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div
                      className=" bg-[#f2f2f2] px-[6px] py-[5px] rounded-[50%]"
                      onClick={(e) => modal(e, m?.movieID)}
                    >
                      <svg
                        width="14"
                        height="16"
                        viewBox="0 0 14 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13.1175 4.23529C13.1175 3.86086 12.9687 3.50178 12.704 3.23702C12.4392 2.97226 12.0801 2.82352 11.7057 2.82352H9.35278C9.35278 2.19948 9.10488 1.601 8.66362 1.15974C8.22236 0.718479 7.62388 0.470581 6.99984 0.470581C6.3758 0.470581 5.77732 0.718479 5.33606 1.15974C4.89479 1.601 4.6469 2.19948 4.6469 2.82352H2.29395C1.96133 2.82289 1.63922 2.94 1.38466 3.15411C1.13011 3.36822 0.959549 3.66551 0.903184 3.99332C0.84682 4.32114 0.908292 4.65832 1.07671 4.94515C1.24513 5.23199 1.50963 5.44996 1.82337 5.56046V13.3007C1.82399 13.8915 2.05893 14.458 2.47666 14.8758C2.89439 15.2936 3.46078 15.5287 4.0516 15.5294H9.94807C10.5389 15.5287 11.1053 15.2936 11.523 14.8758C11.9407 14.458 12.1757 13.8915 12.1763 13.3007V5.56046C12.4506 5.46348 12.6883 5.28412 12.8567 5.04691C13.0252 4.8097 13.1163 4.52623 13.1175 4.23529ZM6.99984 1.41176C7.37426 1.41176 7.73335 1.5605 7.99811 1.82525C8.26286 2.09001 8.4116 2.4491 8.4116 2.82352H5.58807C5.58807 2.4491 5.73681 2.09001 6.00157 1.82525C6.26633 1.5605 6.62541 1.41176 6.99984 1.41176ZM11.2351 13.3007C11.2348 13.642 11.0991 13.9692 10.8578 14.2105C10.6165 14.4519 10.2894 14.5877 9.94807 14.5882H4.0516C3.71032 14.5877 3.38317 14.4519 3.1419 14.2105C2.90062 13.9692 2.76492 13.642 2.76454 13.3007V5.64705H11.2351V13.3007ZM11.7057 4.70588H2.29395C2.16915 4.70588 2.04945 4.6563 1.9612 4.56804C1.87295 4.47979 1.82337 4.36009 1.82337 4.23529C1.82337 4.11048 1.87295 3.99078 1.9612 3.90253C2.04945 3.81428 2.16915 3.7647 2.29395 3.7647H11.7057C11.8305 3.7647 11.9502 3.81428 12.0385 3.90253C12.1267 3.99078 12.1763 4.11048 12.1763 4.23529C12.1763 4.36009 12.1267 4.47979 12.0385 4.56804C11.9502 4.6563 11.8305 4.70588 11.7057 4.70588Z"
                          fill="#076AFF"
                        />
                        <path
                          d="M6.99943 13.1765C7.12423 13.1765 7.24393 13.1269 7.33218 13.0386C7.42043 12.9504 7.47001 12.8307 7.47001 12.7059V7.5294C7.47001 7.40459 7.42043 7.28489 7.33218 7.19664C7.24393 7.10839 7.12423 7.05881 6.99943 7.05881C6.87462 7.05881 6.75492 7.10839 6.66667 7.19664C6.57842 7.28489 6.52884 7.40459 6.52884 7.5294V12.7059C6.52884 12.8307 6.57842 12.9504 6.66667 13.0386C6.75492 13.1269 6.87462 13.1765 6.99943 13.1765ZM8.88178 12.7059C9.00659 12.7059 9.12628 12.6563 9.21454 12.568C9.30279 12.4798 9.35237 12.3601 9.35237 12.2353V7.99998C9.35237 7.87518 9.30279 7.75548 9.21454 7.66723C9.12628 7.57897 9.00659 7.5294 8.88178 7.5294C8.75697 7.5294 8.63727 7.57897 8.54902 7.66723C8.46077 7.75548 8.41119 7.87518 8.41119 7.99998V12.2353C8.41119 12.3601 8.46077 12.4798 8.54902 12.568C8.63727 12.6563 8.75697 12.7059 8.88178 12.7059ZM5.11707 12.7059C5.24188 12.7059 5.36158 12.6563 5.44983 12.568C5.53808 12.4798 5.58766 12.3601 5.58766 12.2353V7.99998C5.58766 7.87518 5.53808 7.75548 5.44983 7.66723C5.36158 7.57897 5.24188 7.5294 5.11707 7.5294C4.99226 7.5294 4.87257 7.57897 4.78432 7.66723C4.69606 7.75548 4.64648 7.87518 4.64648 7.99998V12.2353C4.64648 12.3601 4.69606 12.4798 4.78432 12.568C4.87257 12.6563 4.99226 12.7059 5.11707 12.7059Z"
                          fill="#076AFF"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/*--------------------------------End mobile view for cart------------------------------ */}

          {/*--------------------------------- Payment Detail ------------------------------ */}

          <div className=" bg-[#fff] boxshadow rounded-[10px] px-[20px] xl:px-[30px] py-[30px] col-span-12 md:col-span-6 lg:col-span-3 xl:col-span-4 max-h-[350px]">
            <p className="text-[#161616] text-[16px] xl:text-[20px] font-medium font-poppins">
              Payment Details
            </p>
            <div className=" flex items-center justify-between py-[20px]">
              <p className="text-[#47505F] text-[14px] xl:text-[16px] font-medium font-poppins">
                Sub Total
              </p>
              <p className="text-[#47505F] text-[14px] xl:text-[16px] font-medium font-poppins">
                {mycart &&
                  mycart[0]?.summery?.subtotal?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  })}
              </p>
            </div>
            {mycart &&
                  mycart[0]?.summery?.igst && <div className=" flex items-center justify-between pb-[20px]">
              <p className="text-[#47505F] text-[14px] xl:text-[16px] font-medium font-poppins">
                IGST (18%)
              </p>
              <p className="text-[#47505F] text-[14px] xl:text-[16px] font-medium font-poppins">
                {mycart &&
                  mycart[0]?.summery?.igst?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  })}
              </p>
            </div>}
            {mycart &&
                  mycart[0]?.summery?.cgst && <div className=" flex items-center justify-between pb-[20px]">
              <p className="text-[#47505F] text-[14px] xl:text-[16px] font-medium font-poppins">
                CGST (9%)
              </p>
              <p className="text-[#47505F] text-[14px] xl:text-[16px] font-medium font-poppins">
                {mycart &&
                  mycart[0]?.summery?.cgst?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  })}
              </p>
            </div>}
            {mycart &&
                  mycart[0]?.summery?.sgst && <div className=" flex items-center justify-between pb-[20px] border-b border-[#DAE9FF]">
              <p className="text-[#47505F] text-[14px] xl:text-[16px] font-medium font-poppins">
                SGST (9%)
              </p>
              <p className="text-[#47505F] text-[14px] xl:text-[16px] font-medium font-poppins">
                {mycart &&
                  mycart[0]?.summery?.sgst?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  })}
              </p>
            </div>}
            <div className=" flex items-center justify-between py-[20px]">
              <p className="text-[#161616] text-[14px] xl:text-[16px] font-medium font-poppins">
                Total Amount
              </p>
              <p className="text-[#161616] text-[14px] xl:text-[16px] font-medium font-poppins">
                {mycart &&
                  mycart[0]?.summery?.total?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 2,
                  })}
              </p>
            </div>
            <button 
            className="bg-[#076AFF] active:translate-y-4 hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl w-full font-poppins font-medium rounded-[8px] min-h-[40px] text-white text-[14px]"
            onClick={() => {dispatch(CreateOrder(mycart[0]?.cartID, history))
            dispatch(OrderPreloader())
            }}
            >
              Pay Now
            </button>
          </div>

          {/*---------------------------------End of Payment Detail ------------------------------ */}
        </div>
      ) : (
        <div className=" min-h-[400px] sm:min-h-[505px] flex flex-col items-center justify-center">
          <img src={emptycart} className=" w-[300px]  mt-5" alt="new"></img>
        <div className="text-[18px] sm:text-[24px] mt-5 font-medium logoheading font-poppins">
          Your Cart Is Empty!!
        </div>
        <div className="mt-5">
              <Link to='/'> <button
                  className="bg-[#076AFF] min-w-[140px] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium  rounded-[5px] text-[14px]  min-h-[35px]  text-white"
                >
                 Back to Home
                </button></Link> 
              </div>
        </div>
      )}

{msgbox && (
        <div className="backcolor absolute z-[10000] top-0 bottom-0 right-0 left-0">
          <div className="fixed  top-[30%] sm:top-[50%] left-[50%] opacity-100 transform -translate-x-[50%] -translate-y-[50%]">
            <div className="bg-[#fff] py-[30px] sm:py-[60px] mx-2 sm:mx-auto px-[30px] sm:px-[65px] w-[350px] sm:w-[600px]  rounded-[20px]">
              <div className=" text-center">
                <img src={payment} alt="new" className=" mx-auto"></img>
                <p className="py-[5px] sm:pt-[20px]">
                  <span className="text-[18px] sm:text-[24px] font-medium logoheading font-poppins">
                  Your Payment is Successfull!
                  </span>
                </p>
                <p className="text-[#000] font-medium font-poppins">
                Thank you for your payment.
                </p>
                <p className="text-[#000] font-medium font-poppins">
                An automated payment receipt will be sent to your registered email.
                </p>
              </div>
              <div className=" flex items-center justify-center">
              <button
                onClick={() => {
                  setmsgbox(false);
                  dispatch(ResetMovie(actionTypes.RESET_ORDER))
                  dispatch(getCart())
                  history('/')
                }}
                className="bg-[#076aff] min-w-[140px] mt-5 text-[14px] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium rounded-[10px] min-h-[35px]  text-white"
              >
                Back to Home
              </button>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>
  );
}

export default Cart;
