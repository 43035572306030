import axios from "axios";
import { Flip, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";


export const actionTypes = {
  MANAGER_LIST: "MANAGER_LIST",
  DELETE_MANAGER_LIST: "DELETE_MANAGER_LIST",
  MANAGER_DETAIL: "MANAGER_DETAIL",
  ADD_MANAGER : 'ADD_MANAGER'
};

// Manager list



export const GetManagerlist = (query , history) => {
  const token = localStorage.getItem('token')
  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/manager`, {
        params: { ...query },
        headers : {
          'Authorization' : token
        }
      })
      .then((response) => {
        dispatch({
          type: actionTypes.MANAGER_LIST,
          managerlist: response.data,
        });
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};

// Create Manager

export const CreateNewManager = (data , history) => {
  const token = localStorage.getItem('token')

  return async (dispatch) => {
    await axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/manager`, data, {
        headers: {
          Accept: "application/json",
          'Authorization' : token
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        dispatch({
          type: actionTypes.ADD_MANAGER,
          addmanager: response.data,
        });
        history('/admin/manager')
        // dispatch(GetManagerlist());
      })
      .catch((err) => 
      toast.error(err.response.data.error, {
        transition: Flip,
        theme: "colored",
        autoClose: 500,
      }));
  };
};

// update manager

export const UpdateManager = (data, id , newdata , history) => {

  const token = localStorage.getItem('token')

  return async (dispatch) => {
    await axios
      .patch(`${process.env.REACT_APP_API_BASE_URL}/manager/${id}`, data, {
        headers: {
          Accept: "application/json",
          'Authorization' : token
        },
      })
      .then((response) => {
        dispatch(GetManagerlist(newdata));
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};

// Manager Detail

export const GetManagerDetail = (id , history) => {
  const token = localStorage.getItem('token')

  return async (dispatch) => {
    await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/manager/${id}` , { 
        headers  : {
          'Authorization' : token
        }
      })
      .then((response) => {
        dispatch({
          type: actionTypes.MANAGER_DETAIL,
          managerdetail: response.data,
        });
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};

// Delete Manager

export const DeleteManager = (id , data , history) => {
  const token = localStorage.getItem('token')

  return async (dispatch) => {
    await axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}/manager/${id}`, {
        headers: {
          Accept: "application/json",
          'Authorization' : token
        },
      })
      .then((response) => {
        toast.success(response.data.message, {
          transition: Flip,
          theme: "colored",
          autoClose: 500,
        });
        dispatch({
          type: actionTypes.DELETE_MANAGER_LIST,
          deletemanagerlist: response.data,
        });
        dispatch(GetManagerlist(data));
      })
      .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
      });
  };
};
