export const actionTypes = {
    MOVIES_LIST: "MOVIES_LIST",
    
    

  };
  
  const INITIAL_STATE = {
        movieslist: [],
  };

  const HomePageReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case actionTypes.MOVIES_LIST:
        return {
          ...state,
          movieslist: action.movieslist,

        };
   
  
      default:
        return state;
    }
  };
  
  export default HomePageReducer;
  