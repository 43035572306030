import React, { useEffect, useRef, useState } from "react";
import logo from "../images/LOGO 2.svg";
import OTPInput from "otp-input-react";
import { useNavigate } from "react-router-dom/dist";
import { useDispatch, useSelector } from "react-redux";
import { SetNewPin, SetPin } from "../Redux/Action/AuthAction";
import { ToastContainer } from "react-toastify";

const STATUS = {
  STARTED: "Started",
  STOPPED: "Stopped",
};

const INITIAL_COUNT = 120;

function GenratePin() {
  const [Phone, setPhone] = useState("");
  const [Otp, setOtp] = useState("");
  const [resend, setresend] = useState(false);
  const [buttontext, setbuttontext] = useState("Get OTP");
  let errorsObj = { otp: "" };
  const [errors, setErrors] = useState(errorsObj);

  const [secondsRemaining, setSecondsRemaining] = useState(INITIAL_COUNT);
  const [status, setStatus] = useState(STATUS.STOPPED);
  const secondsToDisplay = secondsRemaining % 60;
  const minutesRemaining = (secondsRemaining - secondsToDisplay) / 60;
  const minutesToDisplay = minutesRemaining % 60;

  const handleStart = (e) => {
    e?.preventDefault();
    const data = {
      contact: Phone,
    }
    dispatch(SetPin(data))
  };

  const newdata = useSelector(state => state.Auth?.setpin)
  
  useEffect(() => {
  if(newdata.code === 200){
    setStatus(STATUS.STARTED);
    setresend(true);
  }
  },[newdata])
  const handleReset = () => {
    setStatus(STATUS.STOPPED);
    setSecondsRemaining(INITIAL_COUNT);
    setresend(false);
  };

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
      if (minutesToDisplay === 0 && secondsToDisplay === 0) {
        setresend(false);
        handleReset();
        setbuttontext("Resend OTP");
      }
    }, [minutesToDisplay, secondsToDisplay]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }
  useInterval(
    () => {
      if (secondsRemaining > 0) {
        setSecondsRemaining(secondsRemaining - 1);
      } else {
        setStatus(STATUS.STOPPED);
      }
    },
    status === STATUS.STARTED ? 1000 : null
    // passing null stops the interval
  );

  const history = useNavigate();

  const dispatch = useDispatch();

  const newpindata = useSelector(state => state.Auth?.setnewpin)
  

  const onsubmitpin = (e) => {
    e?.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (Otp?.length === 0) {
      errorObj.otp = "Please Enter Pin!!!";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    const data = {
      contact: Phone,
      otp: Otp,
    }
    
    setbuttontext("Get OTP");
    setOtp("");
    dispatch(SetNewPin(data))
  };

  useEffect(() => {
    if(newpindata.success === true){
      history("/confirm-pin");
      handleReset();
    }
  },[newpindata])
  return (
    <div className="loginback">
      <ToastContainer/>
      <div className="h-screen flex items-center">
        <div className="bg-[#fff] py-[30px] sm:py-[60px] sm:mx-auto px-[30px] sm:px-[65px] w-full sm:w-[470px] loginbox rounded-[20px]">
          <div>
            <img src={logo} alt="new"></img>
            <div className="py-[15px] sm:py-[20px] ">
              <p className=" text-[#161616] text-[18px] sm:text-[24px] font-medium font-poppins">
                Generate PIN
              </p>
            </div>
          </div>
          <form onSubmit={handleStart}>
            <div className="flex justify-between items-end ">
              <div className="">
                <p className="sm:text-[18px] text-[#000] font-medium pb-[10px] font-poppins">
                  Registered Phone No.
                </p>
                <input
                  className=" focus:bg-white rounded-[5px] min-w-[225px] mr-2 py-2.5 border border-[#6D6E70] placeholder:text-[#979797] pl-5 placeholder:font-medium"
                  placeholder="Enter Phone No."
                  autoComplete="off"
                  required
                  type="number"
                  onChange={(e) => setPhone(e.target.value)}
                  value={Phone}
                ></input>
              </div>
              <div>
                <button
                  type="submit"
                  disabled={status === 'Started'}
                  className={`${status === 'Started' ? `${' cursor-context-menu'}` : `${'cursor-pointer'}` }  text-[#076aff] bg-[#DAE9FF] min-h-[46px] flex items-center justify-center  rounded-[5px] font-poppins  text-[12px] sm:text-[16px] font-medium w-[80px] sm:w-[100px] ${
                    buttontext === "Resend OTP" &&
                    `${"min-w-[110px] text-[14px]"}`
                  }`}
                >
                  {buttontext}
                </button>
              </div>
            </div>
          </form>
          <form onSubmit={onsubmitpin}>
            <div className=" col-span-12 mt-[25px] mb-[20px]">
              <OTPInput
                value={Otp}
                onChange={setOtp}
                autoFocus
                OTPLength={6}
                otpType="number"
                disabled={false}
                className=" justify-between"
                inputStyles={{
                  border: 1,
                  borderStyle: "solid",
                  borderColor: "#979797",
                  height: 42,
                  width: 42,
                  borderRadius: 5,
                  margin: 0,
                }}
                secure
              />
              {errors.otp && (
                <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                  {errors.otp}
                </div>
              )}
              <div className=" text-right">
                {resend && (
                  <p className="text-[#979797] text-sm font-poppins font-medium mt-[15px]">
                    Resend otp in{" "}
                    {minutesToDisplay < 10
                      ? `0${minutesToDisplay}`
                      : minutesToDisplay}{" "}
                    :{" "}
                    {secondsToDisplay < 10
                      ? `0${secondsToDisplay}`
                      : secondsToDisplay}
                  </p>
                )}
              </div>
            </div>
            <div className=" flex items-center">
              <button
                type="submit"
                className="bg-[#076AFF] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium w-full rounded-[5px] min-h-[46px] text-white"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default GenratePin;
