import React, { Fragment, useEffect, useState } from "react";
import search from "../images/search.png";
import arrowdown from "../images/arrow-down.svg";
import del from "../images/newdelete.png";
import arrowd from "../images/rightarr.png";
import Swal from "sweetalert2";
import { Menu, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom/dist";
import { useNavigate } from "react-router-dom/dist";
import ReactPaginate from "react-paginate";
import { DeleteContact, GetContactlist, UpdateContact } from "../Redux/Action/ContactusAction";
import Select from 'react-select'

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function ContactList() {
  const [sort, setsort] = useState("desc");
  const [sortmob, setsortmob] = useState("desc");
  const [sortstatus, setsortstatus] = useState("desc");
  const [sortemail, setsortemail] = useState("desc");
  const [Search, setSearch] = useState("");

  const allvalue = [
    { value: "10", label: "10", id: 1 },
    { value: "25", label: "25", id: 2 },
    { value: "50", label: "50", id: 3 },
  ];

  const Contactstatusoption = [
    { value: "Pending", label: "Pending" },
    { value: "Connected", label: "Connected" },
  ];

  const customStyles = {
    control: (base) => ({
      ...base,
      boxShadow: "none",
      borderRadius: 5,
      fontSize : '14px',
      // border : 'none'
      width : '140px',
      borderColor: "#6D6E70 !important",
    }),
    menu: (provided) => ({
      ...provided,
      border: 1,
      borderColor: "#6D6E70 !important",
      borderStyle: "solid",
      boxShadow: "none",
      width : '140px',
    }),
    menuList: (base) => ({
      ...base,
      "::-webkit-scrollbar": {
        width: "5px",
      },
      "::-webkit-scrollbar-track": {
        background: "#fff",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#DAE9FF",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#DAE9FF",
      },
    }),
    option: (provided) => ({
      ...provided,
      cursor: "pointer",
      borderBottom: 1,
      paddingTop: 10,
      paddingBottom: 10,
      zIndex : 100,
      fontSize : '14px',
      borderColor: "#DAE9FF",
      borderStyle: "solid",
      ":hover": {
        backgroundColor: "#DAE9FF !important",
        color: "#076AFF !important",
      },
    }),
  };

  const [page, setpage] = useState("");
  const [selectpage, setselectpage] = useState("");
  const [checkvalue, setcheckvalue] = useState("");

  useEffect(() => {
    setpage(allvalue[0]?.value);
  }, []);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  //-------------------- delete Manager modal------------------------------

  const modal = (e, id) => {
    e?.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You want to delete this Contact!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#076aff",
      cancelButtonColor: "#ff0101",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const sds = location.search.slice(1).split("&");
        let object = {};
        if (location.search.includes("?")) {
          sds.map((item) => {
            let newItem = item.split("=");
            object[newItem[0]] = newItem[1];
          });
        }
        dispatch(DeleteContact(id ,{...object} , history));
      }
    });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    searchParams.delete('search');
    setSearchParams(searchParams)
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
    }
    delete object.search;
    dispatch(GetContactlist({...object} , history));
  }, []);

  const contactlist = useSelector((state) => state.contact.contactlist.data);

  const ordermethod = (order_by) => {
    switch (order_by) {
      case "name":
        const t = sort === "desc" ? "asc" : "desc";
        setsort(t);
        break;

      case "status":
        const te = sortstatus === "desc" ? "asc" : "desc";
        setsortstatus(te);
        break;

      case "emailID":
        const td = sortemail === "desc" ? "asc" : "desc";
        setsortemail(td);
        break;

      case "contact":
        const tr = sortmob === "desc" ? "asc" : "desc";
        setsortmob(tr);
        break;

      default:
    }
  };

  const location = useLocation();
  const history = useNavigate();

  //----------------- for field and order filter------------------------

  const onApply = (order_by, sort) => {
    const order_typedata = ordermethod(order_by);
    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/contact",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            field: order_by,
            order: sort,
          }).toString(),
      });
    } else {
      history({
        pathname: "/admin/contact",
        search: `?field=${order_by}&order=${sort}`,
      });
    }
    dispatch(GetContactlist({ ...object, field: order_by, order: sort } , history));
  };

  //----------------- for limit filter------------------------

  const onselectedpage = (item) => {
    setpage(item.value);

    const sds = location.search.slice(1).split("&");
    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      setpage(item.value);
      history({
        pathname: "/admin/contact",
        search:
          "?" +
          new URLSearchParams({ ...object, limit: item.value }).toString(),
      });
    } else {
      history({
        pathname: "/admin/contact",
        search: `?limit=${item.value}`,
      });
    }
    dispatch(GetContactlist({ ...object, limit: item.value } , history));
  };

  //----------------- for search filter------------------------

  const submitsearch = (e) => {
    e?.preventDefault();
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });
      history({
        pathname: "/admin/contact",
        search:
          "?" + new URLSearchParams({ ...object, search: Search }).toString(),
      });
    } else {
      history({
        pathname: "/admin/contact",
        search: `?search=${Search}`,
      });
    }
    dispatch(GetContactlist({ ...object, search: Search } , history));
  };

  //----------------- for pagination filter------------------------

  const handlePageChange = (selected) => {
    setselectpage(selected.selected);
    const sds = location.search.slice(1).split("&");

    let object = {};
    if (location.search.includes("?")) {
      sds.map((item) => {
        let newItem = item.split("=");
        object[newItem[0]] = newItem[1];
      });

      history({
        pathname: "/admin/contact",
        search:
          "?" +
          new URLSearchParams({
            ...object,
            page: selected.selected + 1,
          }).toString(),
      });
    } else {
      history({
        pathname: "/admin/contact",
        search: `?page=${selected.selected + 1}`,
      });
    }
    dispatch(GetContactlist({ ...object, page: selected.selected + 1 } , history));
  };
  
  // useEffect(() => {
  //   const queryParams = "";
  //   history({
  //     search: queryParams,
  //   });
  // }, []);

  const [searchParams, setSearchParams] = useSearchParams();

  const removeQueryParams = () => {
    const param = searchParams.get("search");

    if (param) {
      searchParams.delete("search");
      setSearchParams(searchParams);
      const sds =  location.search.slice(1).split("&")
      let object = {}
      if(location.search.includes('?')){
        sds.map(item => {
          let newItem = item.split("=")
          object[newItem[0]] = newItem[1]
        })
      }
      delete object.search;
      dispatch(GetContactlist( {...object} , history));
    }
  };

  const [Itemperpage, setItemperpage] = useState("");

  const pageCount = Math.ceil(Number(contactlist?.count) / Number(page));

  useEffect(() => {
    if (pageCount && pageCount !== Infinity) {
      setItemperpage(pageCount);
    }
  }, [contactlist]);

   const [contact,setcontact] = useState('')

  //  update contact

  const handlestatus = (value , id) => { 
    setcontact(value)
    contactlist?.contact?.map((e) => {
      if(e._id === id) {
        const data = {
          status : value
         }
         const sds = location.search.slice(1).split("&");
         let object = {};
         if (location.search.includes("?")) {
           sds.map((item) => {
             let newItem = item.split("=");
             object[newItem[0]] = newItem[1];
           });
         }
        dispatch(UpdateContact(data , id , {...object} , history))
      }
    })

  }

  const newrole = localStorage.getItem('role')

  useEffect(() => {
    const newlimit = searchParams.get("limit");
    if(newlimit?.length > 0){
      setpage(newlimit)
    }
  },[])

  useEffect(() => {
    const newpage = searchParams.get("page");
    if(newpage?.length > 0 && newpage !== null){
      setselectpage(newpage - 1)
    }
  },[])
  return (
    <div className="pt-[20px] pb-[20px] lg:pb-[0px] h-full">
      {/* <ToastContainer /> */}
      <div>
        <p className=" sm:text-[22px] text-[#161616] font-poppins font-medium">
          CUSTOMER TYPE
        </p>
      </div>
      {/* search bar  */}
      <div className="mt-[15px] mb-[20px] sm:mb-[30px] p-5 lg:flex items-center justify-between bg-[#fff] rounded-[10px]  listtableshadow">
        <div>
          <form
            className="h-[40px] relative"
            onSubmit={(e) => {
              e?.preventDefault();
              if (Search) {
                submitsearch(e);
              }
            }}
          >
            <input
              className="h-full bg-[#F2F2F2] rounded-[8px] pl-[50px] min-w-full lg:min-w-[400px] placeholder:text-[#47505F]  placeholder:text-[14px] placeholder:font-medium"
              placeholder="Search by email..."
              value={Search}
              onChange={(e) => {
                setSearch(e.target.value);
                if (e.target.value === "") {
                  // dispatch(GetContactlist('' , history));
                  removeQueryParams();
                }
              }}
            ></input>
            <img src={search} className="-mt-7 ml-[20px]" alt="new"></img>
          </form>
        </div>
      </div>
      {/*End search bar  */}
      {/*------------------------- Table ---------------------------- */}
{ contactlist?.contact?.length > 0 ? 
      <div>
      <div className="tableFixHead">
        <table className="w-full listtableshadow">
          <thead className="bg-[#F9FAFB]" style={{ minHeight: 60 }}>
            <tr className="text-[#161616] font-medium font-poppins">
              <td className="py-4 pl-[40px] min-w-[100px]">ID</td>
              <td className="py-4 min-w-[110px]">
                <div className=" flex items-center">
                  Name{" "}
                  <span>
                    <img
                      src={arrowd}
                      alt="new"
                      onClick={() => onApply("name", sort)}
                      className={`${
                        sort === "asc" ? `${"-rotate-90"}` : `${" rotate-90"}`
                      } ml-2 cursor-pointer`}
                    ></img>
                  </span>
                </div>
              </td>
              <td className="py-4 min-w-[200px]">
                <div className=" flex items-center">
                  Email{" "}
                  <span>
                    <img
                      src={arrowd}
                      alt="new"
                      onClick={() => onApply("emailID", sortemail)}
                      className={`${
                        sortemail === "asc"
                          ? `${"-rotate-90"}`
                          : `${" rotate-90"}`
                      } ml-2 cursor-pointer`}
                    ></img>
                  </span>
                </div>
              </td>
              <td className="py-4 min-w-[130px]">
                <div className=" flex items-center">
                  Mobile No{" "}
                  <span>
                    <img
                      src={arrowd}
                      alt="new"
                      onClick={() => onApply("contact", sortmob)}
                      className={`${
                        sortmob === "asc"
                          ? `${"-rotate-90"}`
                          : `${" rotate-90"}`
                      } ml-2 cursor-pointer`}
                    ></img>
                  </span>
                </div>
              </td>
              <td className="py-4 min-w-[150px]">
                <div className=" flex items-center">
                  Status{" "}
                  <span>
                    <img
                      src={arrowd}
                      alt="new"
                      onClick={() => onApply("status", sortstatus)}
                      className={`${
                        sortstatus === "asc"
                          ? `${"-rotate-90"}`
                          : `${" rotate-90"}`
                      } ml-2 cursor-pointer`}
                    ></img>
                  </span>
                </div>
              </td>
              <td className="py-4 min-w-[150px]">
                <div className=" flex items-center">
                  Customer Type
                  {/* <span>
                    <img
                      src={arrowd}
                      alt="new"
                      onClick={() => onApply("contact", sortmob)}
                      className={`${
                        sortmob === "asc"
                          ? `${"-rotate-90"}`
                          : `${" rotate-90"}`
                      } ml-2 cursor-pointer`}
                    ></img>
                  </span> */}
                </div>
              </td>
              {newrole === 'admin' && <td className="py-4 min-w-[100px] text-center">Action</td>}
            </tr>
          </thead>
          <tbody>
            {contactlist && contactlist?.contact?.map((m, index) => (
              <tr className={`border-b bg-[#fff]`} key={m?._id}>
                <td className="text-[#47505F] text-[14px] font-medium font-poppins pl-[40px] py-[20px]">
                  {index + 1}
                </td>
                <td className="py-[14px] max-w-[150px]">
                  <p className="text-[#47505F] text-[14px] font-medium font-poppins">
                    {m?.name?.length > 16 ? (
                      <>{`${m?.name?.substring(0, 16)}...`}</>
                    ) : (
                      <>{m?.name}</>
                    )}
                  </p>
                </td>
                <td className="text-[#47505F] text-[14px] font-medium font-poppins py-[20px]">
                  <div>{m?.emailID}</div>
                </td>
                <td className="text-[#47505F] text-[14px] font-medium font-poppins py-[20px]">
                  <div>{m?.contact}</div>
                </td>
                <td className="text-[#47505F] font-medium font-poppins py-[20px]">
                 <Select
                 options={Contactstatusoption}
                 isSearchable={false} 
                 styles={customStyles}
                 defaultValue={Contactstatusoption && Contactstatusoption.filter(obj => obj.value === m.status)}
                 value={Contactstatusoption && Contactstatusoption.filter(obj => obj.value === m.status)}
                 menuPlacement="auto"
                 onChange={(e) => {handlestatus(e?.value , m?._id)}}
                 />
                </td>
                <td className="text-[#47505F] text-[14px] font-medium font-poppins py-[20px]">
                  <div>{m?.type}</div>
                </td>
                <td className="4py-[20px] cursor-pointer">
                  <div className=" flex justify-center w-full">
                    {newrole === 'admin' && <img
                      src={del}
                      className=""
                      alt="new"
                      onClick={(e) => modal(e, m._id)}
                    ></img>}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className=" md:flex items-center justify-between w-full py-[20px] bg-[#fff] rounded-b-[10px] boxshadow px-[20px] sm:px-[40px] mb-[30px]">
        <div className=" flex items-center mb-5 sm:mb-0">
          <p className="text-[#47505F] font-medium font-[poppins] ">View</p>

          {/* ----------------------select page ------------------------ */}

          <Menu as="div" className="relative text-left">
            <div>
              <Menu.Button className="flex px-[20px] ml-[24px] min-h-[40px] bg-[#F2F2F2] min-w-[85px] rounded-[8px] justify-between  text-sm font-poppins font-medium  items-center ">
                <p className="text-[#47505F] font-medium font-[poppins]">
                  {page}
                </p>
                <img className="" src={arrowdown} alt="new" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="bottom-full absolute right-0 z-10 mt-2 py-2 w-[80%] rounded-[12px] bg-white shadoe-sm focus:outline-none">
                {allvalue?.map((e) => (
                  <div key={e?.id}>
                    <Menu.Item>
                      {({ active }) => (
                        <p
                          className={classNames(
                            active ? "bg-[#DAE9FF] text-[#076aff]" : "",
                            "block px-4 py-2 text-sm cursor-pointer"
                          )}
                          onClick={() => onselectedpage(e)}
                        >
                          {e?.value}
                        </p>
                      )}
                    </Menu.Item>
                  </div>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>

          {/* ----------------------End select page ------------------------ */}
        </div>

        {/* ------------------------- pagination -------------------------*/}
        <ReactPaginate
          previousLabel={"Previous"}
          nextLabel={"Next"}
          breakLabel={"..."}
          pageCount={Itemperpage} // Replace with the total number of pages
          marginPagesDisplayed={2}
          pageRangeDisplayed={3}
          onPageChange={handlePageChange}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
          forcePage={selectpage}
        />
        {/* -------------------------End  pagination -------------------------*/}
      </div>
</div>
 :
<div className=" flex items-center justify-center min-h-[300px] sm:text-[20px] text-[#161616] font-poppins font-medium">No Contact Found!!</div>
}



    </div>
  );
}

export default ContactList;
