import React, { useEffect, useRef, useState } from "react";
import "react-multi-carousel/lib/styles.css";
import play from "../images/play.svg";
import videoplay from "../images/videoplay.png";
import time from "../images/time.svg";
import arrow from "../images/car_arrow.png";
import "../App.css";
import Carousel from "react-multi-carousel";
import UseOutsideClick from "../components/UseOutsideClick";
import { useDispatch, useSelector } from "react-redux";
import {  GetMovieDetail } from "../Redux/Action/MovieDetailAction";
import { useNavigate, useParams } from "react-router-dom/dist";
import moment from "moment";
import {
  AddToWishlist,
  DeleteWishList,
} from "../Redux/Action/AddWishListAction";
import { addToCart } from "../Redux/Action/cartAction";

function MovieDetail()  {
  const [count, setcount] = useState();
  const [Tab, setTab] = useState("1");
  const [playvideo, setplayvideo] = useState(false);

  // -------------- Carousel responsive -------------//
  const responsive = {
    desktop: {
      breakpoint: { max: 5000, min: 1024 },
      items: 5,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 750 },
      items: 4,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 750, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };

  //-----------  Custom button for Slider  ----------------

  const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
    const {
      carouselState: { currentSlide },
    } = rest;
    return (
      <div className="carousel-button-group ">
        <button
          onClick={() => previous()}
          className="hidden lg:block absolute -left-7 top-24"
        >
          <img src={arrow} alt="new"></img>
        </button>
        <button
          onClick={() => next()}
          className="hidden lg:block absolute -right-7 top-24"
        >
          <img src={arrow} alt="new" className=" transform rotate-180"></img>
        </button>
      </div>
    );
  };

  const ref = useRef();
  UseOutsideClick(ref, () => {
    if (playvideo) {
      setplayvideo(false);
    }
  });

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);


  const dispatch = useDispatch();
  const history = useNavigate();

  const { id } = useParams();
  useEffect(() => {
    dispatch(GetMovieDetail(id , history));
  }, []);

  const [wishlistLocal, setwishlistLocal] = useState([]);

  const moviedetail = useSelector( (state) => state.moviedetails?.moviedetail?.data);

  useEffect(() => {
    setcount(wishlistLocal && wishlistLocal[0]?.minshow);
  }, [wishlistLocal]);

  useEffect(() => {
    if (moviedetail) {
      setwishlistLocal(moviedetail);
    }
  }, [moviedetail]);
 
  //------------------ add to wishlist --------------------

  const addtowishlist = (id) => {
    var getwishlist = JSON.parse(JSON.stringify(wishlistLocal));
    for (const key in getwishlist) {
      if (Object.hasOwnProperty.call(getwishlist, key)) {
        const element = getwishlist[key];
        if (element._id === id) {
          element.iswishlist = !element.iswishlist;
        }
      }
    }
    setwishlistLocal(getwishlist);

    const data = {
      movieID: id,
    };
    dispatch(AddToWishlist(data , history));
  };

   //------------------ delete from wishlist --------------------

  const deletefromwishlist = (id) => {
    var getwishlist = JSON.parse(JSON.stringify(wishlistLocal));
    for (const key in getwishlist) {
      if (Object.hasOwnProperty.call(getwishlist, key)) {
        const element = getwishlist[key];
        if (element._id === id) {
          element.iswishlist = !element.iswishlist;
        }
      }
    }
    setwishlistLocal(getwishlist);

    dispatch(DeleteWishList(id , history));
  };

  useEffect(() => {
    const newcount = wishlistLocal[0]?.minshow;
    setcount(newcount);
  }, []);

   //------------------ add to cart ----------------------

  const Addtocart = (id) => {
    var getwishlist = JSON.parse(JSON.stringify(wishlistLocal));
    for (const key in getwishlist) {
      if (Object.hasOwnProperty.call(getwishlist, key)) {
        const element = getwishlist[key];
        if (element._id === id) {
          element.iscart = !element.iscart;
        }
      }
    }
    setwishlistLocal(getwishlist);
    const data = {
      movieID: id,
    };
    dispatch(addToCart(data , history));
  };

  

   //------------------ convert time --------------------

  const convertIntoTime = () => {
    const time = wishlistLocal && wishlistLocal[0]?.duration * 60;
    const timestamp = new Date(time * 1000);
    const hours = timestamp.getUTCHours();
    const minutes = timestamp.getUTCMinutes();
    return (
      <p>
        {hours} <span> hr</span> {minutes} <span> mins</span>
      </p>
    );
  };

   //------------------ convert release time --------------------

  const convertReleaseTime = () => {
    const d = new Date(wishlistLocal && wishlistLocal[0]?.release * 1000);
    const date_month = moment(
      wishlistLocal && wishlistLocal[0]?.release
    ).format("YYYY");
    const date_date = moment(d).format("DD MMM");
    return (
      <span className=" ml-2">
        {date_date} , {date_month}{" "}
      </span>
    );
  };

  return (
    <div className="">
      {/*-------------------------- Hero section ------------------------------*/}
      <div className="relative">
        <div className=" absolute top-0 bottom-0 right-0  z-10 left-0 banner-shadow"></div>
        <img
          src={`${`https://hooc.b-cdn.net/${
            wishlistLocal && wishlistLocal[0]?.banner_image
          }?t=${Date.now()}`}`}
          className=" w-full h-[150px] sm:h-[300px] lg:h-[400px] xl:h-[530px]"
          alt="new"
        ></img>
        <div className="main_container">
          <div className=" relative cursor-pointer">
            <div className=" absolute top-0 bottom-0 right-0  z-10 left-0 banner-shadow"></div>
            <img
              src={`${`https://hooc.b-cdn.net/${
                wishlistLocal && wishlistLocal[0]?.poster_image
              }?t=${Date.now()}`}`}
              alt="new"
              className="absolute -mt-[90px] z-30 sm:-mt-[120px] lg:-mt-[162px] sm:left-[3%] xl:left-[11%] 2xl:left-[15%] w-[120px] h-[130px] sm:h-[215px] lg:h-[320px] sm:w-[200px] lg:w-[300px] rounded-[10px]"
            ></img>
            <img
              src={videoplay}
              alt="new"
              className=" absolute -top-10 z-30 lg:left-[15%] xl:left-[21%] 2xl:left-[23.5%] w-[30px] sm:w-[40px] lg:w-auto playbutton"
              onClick={() => setplayvideo(true)}
            ></img>
            <p className="text-[#fff] z-30  sm:ml-[17px] text-[10px] font-medium sm:text-[16px] lg:text-[20px] font-poppins absolute top-10 left-[5%] lg:top-24 lg:left-[8%] xl:left-[15%] 2xl:left-[18%]">
              {/* {moviedetail[0]?.name} */}
            </p>
          </div>

          <div className={`${wishlistLocal[0]?.name?.length > 30 ? '-bottom-[50px]' : `-bottom-[30px]`} mobile_view absolute z-30 w-[200px] left-[64.5%] md:w-[400px] lg:w-[500px]  md:left-[65%] lg:left-[61.5%] xl:left-[59.5%] 2xl:left-[55.5%]  transform -translate-x-[50%] -translate-y-[50%] `}>
            <p className="text-[#fff] font-medium sm:text-[20px] lg:text-[30px] font-poppins">
              {wishlistLocal && wishlistLocal[0]?.name}
            </p>
            <p className="text-[#fff] z-30 font-normal pt-1 pb-2 sm:pb-4 text-[12px] md:text-[16px] lg:text-[18px] font-poppins">
              {wishlistLocal &&
                wishlistLocal[0]?.language?.join(' , ')}
            </p>
            <div className="flex items-center ">
              <div className="flex items-center  sm:mr-[25px]">
                <img
                  src={play}
                  alt="new"
                  className=" w-[14px] z-30 h-[14px] sm:w-auto sm:h-auto"
                ></img>
                <p className="text-[#fff] z-30 min-w-[95px] ml-[5px] sm:ml-[17px] font-normal text-[12px] md:text-[16px] lg:text-[18px] font-poppins">
                  {convertReleaseTime()}
                </p>
              </div>
              <div className="flex items-center">
                <img
                  src={time}
                  alt="new"
                  className=" w-[14px] h-[14px] sm:w-auto sm:h-auto"
                ></img>
                <p className="text-[#fff]  min-w-[80px] ml-[4px] sm:ml-[17px] font-normal text-[12px] md:text-[16px] lg:text-[18px] font-poppins">
                  {convertIntoTime()}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*---------------------- End of section --------------------------*/}

      {/*----------------- number of show section -----------------------*/}

      <div className="bg-[#F1F1F2] pt-[10px] pb-[20px] sm:pb-[30px]">
        <div className="main_container ">
          <div className="w-[61%] sm:w-[63%] xl:w-[60%] ml-auto">
            <div className="pb-[5px] sm:pb-[10px]">
              <span className="text-[14px] sm:text-[16px] lg:text-[20px] detailtext font-poppins">
                {wishlistLocal &&
                  wishlistLocal[0]?.final_price?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "INR",
                    minimumFractionDigits: 0,
                  })}
              </span>

              <button
                onClick={() => {
                  if (wishlistLocal && wishlistLocal[0].iswishlist) {
                    deletefromwishlist(wishlistLocal && wishlistLocal[0]?._id);
                  } else {
                    if (localStorage.getItem("token")) {
                      addtowishlist(wishlistLocal && wishlistLocal[0]?._id);
                    } else {
                      history("/login");
                    }
                  }
                }}
                className={`${
                  wishlistLocal && wishlistLocal[0]?.iswishlist
                    ? `${"bg-[#076aff]"}`
                    : `${"bg-transparent"}`
                } border border-[#076aff] rounded-[50%] ml-3 p-1.5 hover:bg-[#076aff] sm:p-2 group cursor-pointer`}
              >
                <svg
                 
                  viewBox="0 0 26 22"
                  fill={`${
                    wishlistLocal && wishlistLocal[0]?.iswishlist
                      ? "#fff"
                      : "#076aff"
                  }`}
                  stroke={`${
                    wishlistLocal && !wishlistLocal[0]?.iswishlist
                      ? "#076aff"
                      : "#fff"
                  }`}
                  xmlns="http://www.w3.org/2000/svg"
                  className="group-hover:fill-[#fff] group-hover:stroke-[#fff] w-[12px] h-[12px] sm:w-[15px] sm:h-[15px]"
                >
                  <path
                    d="M24.8759 3.13397C24.1474 1.97887 23.0806 1.07646 21.8207 0.54971C20.3931 -0.0495088 18.7897 -0.157368 17.1839 0.238573C15.7123 0.601456 14.279 1.38851 13 2.53028C11.7208 1.38841 10.2873 0.601304 8.81558 0.238472C7.20973 -0.157622 5.6062 -0.049661 4.17864 0.549964C2.91871 1.07687 1.8519 1.97947 1.12359 3.13473C0.360857 4.34048 -0.0271624 5.80679 0.00147824 7.37496C0.128634 14.3465 10.5135 20.6858 12.594 21.886C12.7175 21.9572 12.8575 21.9946 13 21.9946C13.1425 21.9946 13.2825 21.9572 13.406 21.886C15.4867 20.6857 25.8724 14.3452 25.9985 7.37374C26.0268 5.80567 25.6386 4.33951 24.8759 3.13397ZM24.3738 7.34455C24.3332 9.59862 22.8305 12.2266 20.0288 14.9442C17.4341 17.4608 14.4828 19.3577 13 20.2403C11.5171 19.3579 8.56624 17.4612 5.97183 14.9445C3.16992 12.2271 1.66731 9.59933 1.62617 7.34531C1.58128 4.88409 2.77073 2.90388 4.80792 2.04816C5.47558 1.7693 6.19235 1.62705 6.9159 1.62983C8.7844 1.62983 10.7609 2.53434 12.4206 4.22201C12.4962 4.29889 12.5864 4.35995 12.6858 4.40164C12.7853 4.44332 12.892 4.46479 12.9999 4.46479C13.1077 4.46479 13.2145 4.44332 13.3139 4.40164C13.4134 4.35995 13.5036 4.29889 13.5792 4.22201C15.8849 1.87744 18.802 1.04447 21.1913 2.04801C23.2287 2.90342 24.4183 4.88333 24.3738 7.34439V7.34455Z"
                    fill={`${
                      wishlistLocal && wishlistLocal[0]?.iswishlist
                        ? "#fff"
                        : "#076aff"
                    }`}
                    stroke={`${
                      wishlistLocal && !wishlistLocal[0]?.iswishlist
                        ? "#076aff"
                        : "#fff"
                    }`}
                    className="group-hover:fill-[#fff] group-hover:stroke-[#fff] w-[12px] h-[12px] sm:w-[15px] sm:h-[15px]"
                  />
                </svg>
              </button>
            </div>
            {wishlistLocal && wishlistLocal[0]?.discount_ && (
              <div className="mb-1">
                <span className="text-[14px] sm:text-[16px] lg:text-[20px] line-through  font-poppins">
                  {wishlistLocal &&
                    wishlistLocal[0]?.price?.toLocaleString("en-US", {
                      style: "currency",
                      currency: "INR",
                      minimumFractionDigits: 0,
                    })}
                </span>
                {wishlistLocal && wishlistLocal[0]?.discount_ && (
                  <span className="text-[14px] sm:text-[16px] lg:text-[20px]  ml-3 font-poppins">
                    ({wishlistLocal && wishlistLocal[0]?.discount_}% off)
                  </span>
                )}
              </div>
            )}
            <p className=" text-[#47505F] hidden sm:block text-[14px] lg:text-[16px] font-medium font-poppins">
              {wishlistLocal && wishlistLocal[0]?.description.substring(0, 250)}
              {wishlistLocal && wishlistLocal[0]?.description?.length > 250 && <span><a href="#desc" onClick={() => window.scroll({ top: 0, behavior: "smooth" })} className=" bg-[#dfdfdf] px-2 py-1 rounded-[5px] text-[14px]">Read More</a></span>}
            </p>
            <div className="sm:pt-[10px] ">
              <p className="text-[#000000]   text-[13px] sm:text-[14px] font-poppins pb-[10px]">
                <span className="font-bold"> Note:</span> Minimum{" "}
                <span className="font-bold text-[15px] ">
                  {" "}
                  {wishlistLocal[0]?.minshow}{" "}
                </span>{" "}
                shows required*
              </p>
              <div className="flex items-center sm:mt-[10px]">
                <button
                  className="bg-[#076AFF] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl px-[27px] sm:px-[30px] mr-[10px] font-poppins font-medium rounded-[8px] min-h-[30px] sm:min-h-[40px] text-white text-[12px] sm:text-[14px]"
                  onClick={() => {
                    if (localStorage.getItem("token")) {
                    
                      Addtocart(wishlistLocal && wishlistLocal[0]?._id);
                      
                    } else {
                      history("/login");
                    }
                  }}
                >
                  Add To Cart
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*---------------- end of number of show section -------------------------------*/}

      {/*---------------- Overview section -------------------------------*/}

      <div className="main_container">
        <p className="font-poppins font-medium text-[18px] sm:text-[24px] text-[#161616] mt-[15px] mb-[15px] sm:mb-[20px]">
          Overview
        </p>
        <div className=" grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
               {wishlistLocal[0]?.image1 && <div>
                  <img
                    src={`${`https://hooc.b-cdn.net/${wishlistLocal[0]?.image1}?t=${Date.now()}`}`}
                    alt="new"
                    className=" col-span-4 rounded-[10px] "
                  ></img>
                </div>}
               {wishlistLocal[0]?.image2 && <div>
                  <img
                    src={`${`https://hooc.b-cdn.net/${wishlistLocal[0]?.image2}?t=${Date.now()}`}`}
                    alt="new"
                    className="col-span-4 rounded-[10px] "
                  ></img>
                </div>}
                {wishlistLocal[0]?.image3 && <div>
                  <img
                    src={`${`https://hooc.b-cdn.net/${wishlistLocal[0]?.image3}?t=${Date.now()}`}`}
                    alt="new"
                    className="col-span-4 rounded-[10px] "
                  ></img>
                </div>}
               {wishlistLocal[0]?.image4 &&  <div>
                  <img
                    src={`${`https://hooc.b-cdn.net/${wishlistLocal[0]?.image4}?t=${Date.now()}`}`}
                    alt="new"
                    className="col-span-4 rounded-[10px] "
                  ></img>
                </div>}
        </div>
      </div>

      {/*----------------End of Overview section -------------------------------*/}

      {/*---------------- Tab section -------------------------------*/}

      <div className="main_container ">
        <div className="flex items-center border-t border-b border-[#979797] mt-[20px] ">
          <p
            className={`font-poppins font-medium text-[18px] text-justify text-[#161616] cursor-pointer py-[10px] ${
              Tab === "1" &&
              `${"border-t-[3px] border-b-[3px] border-[#076aff]"}`
            }`}
            onClick={() => setTab("1")}
          >
            <span  className={`${Tab === "1" && `${"text-[#076aff]"}`}`}>
              SUMMARY
            </span>
          </p>
          <p
            className={`font-poppins font-medium text-[18px] text-[#161616] ml-[30px] cursor-pointer py-[10px] ${
              Tab === "2" &&
              `${"border-t-[3px] border-b-[3px] border-[#076aff]"}`
            }`}
            onClick={() => setTab("2")}
          >
            <span className={`${Tab === "2" && `${"text-[#076aff]"}`}`}>
              CAST
            </span>
          </p>
        </div>

        {/*------------------------ for movie description ---------------------------------------*/}

        {Tab === "1" && (
          <div id="desc">
            <p className="font-poppins font-medium text-[18px] text-justify sm:text-[20px] text-[#161616] py-[15px] sm:py-[25px]">
              {wishlistLocal && wishlistLocal[0]?.name}
            </p>
            <p className="font-poppins font-medium text-[12px] text-justify sm:text-[16px] text-[#47505F] pb-[10px]">
              {wishlistLocal && wishlistLocal[0]?.description}
            </p>
          </div>
        )}

        {/*------------------------ for movie cast ---------------------------------------*/}

        {Tab === "2" && (
          <div className="w-full my-[25px] sm:my-[35px] relative">
            <Carousel
              swipeable={true}
              // showDots={["mobile"]}
              responsive={responsive}
              ssr={true}
              infinite={true}
              arrows={false}
              renderButtonGroupOutside={true}
              autoPlaySpeed={2500}
              keyBoardControl={true}
              customTransition="all 1.5s"
              transitionDuration={500}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              dotListClass="custom-dot-list-style"
              itemClass="carousel-item-padding-40-px"
              customButtonGroup={<ButtonGroup />}
            >
              {wishlistLocal &&
                wishlistLocal[0]?.cast.map((m) => (
                  <div className=" text-center mb-6" key={m?._id}>
                    <div className="module p-[10px] sm:p-[15px]">
                      <img
                        src={`${`https://hooc.b-cdn.net/${m.image}`}`}
                        alt="new"
                        className="w-[90px] h-[105px] sm:w-[120px] sm:h-[140px] rounded-[50%] module"
                      ></img>
                    </div>
                    <div className="text-center mt-[7px] sm:mt-[10px]">
                      <p className="font-poppins font-medium text-[#161616]">
                        {m?.actor}
                      </p>
                      <p className="font-poppins font-medium text-[#47505F]">
                        {m?.roleas}
                      </p>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        )}
      </div>

      {/*----------------End of Tab section -------------------------------*/}

      {/*-------------------------- Video Player ------------------------*/}

      {playvideo && (
        <div className="backcolor absolute z-[50] top-0 bottom-0 right-0 left-0">
          <div className="fixed top-[50%] left-[50%] opacity-100 transform -translate-x-[50%] -translate-y-[50%]">
            <div ref={ref}>
              <iframe
                className=" w-[300px] h-[200px] md:w-[650px] md:h-[350px]"
                src={wishlistLocal && wishlistLocal[0]?.trailer}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      )}

      {/*--------------------------End  Video Player ------------------------*/}
      {/* <ToastContainer /> */}
    </div>
  )
}
export default MovieDetail;
