import axios from "axios";
import Swal from "sweetalert2";


export const actionTypes = {
    USER_MOVIE_LIST: "USER_MOVIE_LIST",
  };



//  Movie list

export const GetUserMovielist = (query , history) => {
    const token = localStorage.getItem('token')
  
    return async (dispatch) => {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/movies`, {
          params: { ...query },
          headers: {
            "Authorization": token,
          }
        })
        .then((response) => {
          dispatch({
            type: actionTypes.USER_MOVIE_LIST,
            usermovielist: response.data,
          });
        })
        .catch((err) => { 
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
      }
        );
    };
  };