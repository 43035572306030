export const actionTypes = {
  COUNTRY_LIST: "COUNTRY_LIST",
  STATE_LIST: "STATE_LIST",
  CITY_LIST: "CITY_LIST",
};

const INITIAL_STATE = {
  countrylist: [],
  statelist: [],
  citylist: [],
};

const AllListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.COUNTRY_LIST:
      return {
        ...state,
        countrylist: action.countrylist,
      };
    case actionTypes.STATE_LIST:
      return {
        ...state,
        statelist: action.statelist,
      };
    case actionTypes.CITY_LIST:
      return {
        ...state,
        citylist: action.citylist,
      };

    default:
      return state;
  }
};

export default AllListReducer;
