export const actionTypes = {
  ADD_CART: "ADD_CART",
  MOVIES_ERROR: "MOVIES_ERROR",
  RESET_ERROR: "RESET_ERROR",
};

const INITIAL_STATE = {
  cartlist: [],
  movieserr: [],
};

const getCartListReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.ADD_CART:
      return {
        ...state,
        cartlist: action.cartlist,
      };
    case actionTypes.MOVIES_ERROR:
      return {
        ...state,
        movieserr: action.movieserr,
      };
    case actionTypes.RESET_ERROR:
      return {
        ...state,
        movieserr: action.INITIAL_STATE,
      };

    default:
      return state;
  }
};

export default getCartListReducer;
