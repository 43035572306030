import axios from "axios";
import { Flip, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";


export const actionTypes = {
    ORDER_LIST: "ORDER_LIST",
    NEW_ORDER: "NEW_ORDER",
    ORDER_DETAIL: "ORDER_DETAIL",
    SET_PRE_LOADER : "SET_PRE_LOADER"

};

export const OrderPreloader = () => {
  return {
    type: actionTypes.SET_PRE_LOADER,
    Preloader: true,
  };
};

export const CreateOrder = (cartID, history) =>{
    const orderObj = {
      cartID: cartID,
      paymentID : "#HC01", 
    }
    const token = localStorage.getItem("token");
    return async  (dispatch) =>{
        await axios
        .post(`${process.env.REACT_APP_API_BASE_URL}/paynow`,orderObj,{
            headers : {
              'Authorization' : token
            }
          }).then((response) =>{
              dispatch({
                type: actionTypes.NEW_ORDER,
                neworder: response.data,
                Preloader: false,
              });
          }).catch((error) =>{
            if(error)
            {
              dispatch({
              type: actionTypes.SET_PRE_LOADER,
              Preloader: false,
            });
            }
            toast.error(error.response.data.error, {
                transition: Flip,
                theme: "colored",
                autoClose: 500,
              });
              if (error?.response?.data?.code === 409) {
                Swal.fire({
                  title: "Alert!",
                  text: "Someone used your login credential now, Please check it or else update your password.",
                  icon: "warning",
                  confirmButtonColor: "#ff0101",
                  confirmButtonText: "Logout",
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    localStorage?.clear();
                    history("/login");
                  }
                });
        
              }
          })
    }
}

export const OrderList = (query , history) =>{
    
    const token = localStorage.getItem("token");
    return async  (dispatch) =>{
        await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/order`,{
        params: { ...query },
            headers : {
              'Authorization' : token
            }
          }).then((response) =>{
            dispatch({
                type: actionTypes.ORDER_LIST,
                orderlist: response.data,
              });

          }).catch((error) =>{
            toast.error(error.response.data.error, {
                transition: Flip,
                theme: "colored",
                autoClose: 500,
              });
              if (error?.response?.data?.code === 409) {
                Swal.fire({
                  title: "Alert!",
                  text: "Someone used your login credential now, Please check it or else update your password.",
                  icon: "warning",
                  confirmButtonColor: "#ff0101",
                  confirmButtonText: "Logout",
                  allowOutsideClick: false,
                }).then((result) => {
                  if (result.isConfirmed) {
                    localStorage?.clear();
                    history("/login");
                  }
                });
        
              }
          })
    }
}


export const OrderDetails = (id, history) =>{
  const token = localStorage.getItem("token");
  return async  (dispatch) =>{
      await axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/order/details/${id}`, {
          headers : {
            'Authorization' : token
          }
        }).then((response) =>{
          dispatch({
              type: actionTypes.ORDER_DETAIL,
              orderdetail: response.data,
            });

        }).catch((error) =>{
          toast.error(error.response.data.error, {
              transition: Flip,
              theme: "colored",
              autoClose: 500,
            });
            if (error?.response?.data?.code === 409) {
              Swal.fire({
                title: "Alert!",
                text: "Someone used your login credential now, Please check it or else update your password.",
                icon: "warning",
                confirmButtonColor: "#ff0101",
                confirmButtonText: "Logout",
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  localStorage?.clear();
                  history("/login");
                }
              });
      
            }
        })
  }
}

