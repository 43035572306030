import axios from "axios";
import { Flip, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

export const actionTypes = {
  ADD_CONTACT: "ADD_CONTACT",
  CONTACT_LIST: "CONTACT_LIST",
};


export const GetContactlist = (query , history) => {
    const token = localStorage.getItem('token')
    return async (dispatch) => {
      await axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/contact`, {
          params: { ...query },
          headers : {
            'Authorization' : token
          }
        })
        .then((response) => {
          dispatch({
            type: actionTypes.CONTACT_LIST,
            contactlist: response.data,
          });
        })
        .catch((err) => {
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
        });
    };
  };


export const AddContact = (data , history) =>{
    const token = localStorage.getItem('token');
       return async (dispatch) => {
           await axios
           .post(`${process.env.REACT_APP_API_BASE_URL}/contact`,data, {
               headers:{
                   Authorization : token,
               }
           })
       .then((response) => {
           toast.success(response.data.message, {
               transition: Flip ,
               theme: 'colored',
               autoClose: 500
           })
       })
       .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
           toast.error(err.response.data.error,{
               transition: Flip ,
               theme: 'colored',
               autoClose: 500
           })
       })
   }
   }


   export const DeleteContact = (id , data , history) =>{
    const token = localStorage.getItem('token');
       return async (dispatch) => {
           await axios
           .delete(`${process.env.REACT_APP_API_BASE_URL}/contact/${id}`, {
               headers:{
                   Authorization : token,
               }
           })
       .then((response) => {
         dispatch(GetContactlist(data))
           toast.success(response.data.message, {
               transition: Flip ,
               theme: 'colored',
               autoClose: 500
           })
       })
       .catch((err) => {
        if(err?.response?.data?.code === 409){
          Swal.fire({
            title: "Alert!",
            text: "Someone used your login credential now, Please check it or else update your password.",
            icon: "warning",
            confirmButtonColor: "#ff0101",
            confirmButtonText: "Logout",
            allowOutsideClick: false
          }).then((result) => {
            if (result.isConfirmed) {
              localStorage?.clear();
              history('/login');
            }
          })
      }
           toast.error(err.response.data.error,{
               transition: Flip ,
               theme: 'colored',
               autoClose: 500
           })
       })
   }
   }


   // update manager

export const UpdateContact = (data, id , newdata , history) => {

    const token = localStorage.getItem('token')
  
    return async (dispatch) => {
      await axios
        .patch(`${process.env.REACT_APP_API_BASE_URL}/contact/${id}`, data, {
          headers: {
            Accept: "application/json",
            'Authorization' : token
          },
        })
        .then((response) => {
          dispatch(GetContactlist(newdata));
          toast.success(response.data.message, {
            transition: Flip,
            theme: "colored",
            autoClose: 500,
          });
        })
        .catch((err) =>  {
          if(err?.response?.data?.code === 409){
            Swal.fire({
              title: "Alert!",
              text: "Someone used your login credential now, Please check it or else update your password.",
              icon: "warning",
              confirmButtonColor: "#ff0101",
              confirmButtonText: "Logout",
              allowOutsideClick: false
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage?.clear();
                history('/login');
              }
            })
        }
        toast.error(err.response.data.error,{
          transition: Flip ,
          theme: 'colored',
          autoClose: 500
      })
      
    }
      
      );


    };
  };