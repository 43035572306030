import React, { useEffect, useState } from 'react'
import logo from "../images/LOGO 2.svg";
import eyeclose from "../images/eye close.svg";
import eyeopen from "../images/view.png";
import { useNavigate } from 'react-router-dom/dist';
import { useDispatch, useSelector } from 'react-redux';
import { SetGeneratePin, Userlogin } from '../Redux/Action/AuthAction';
import { ToastContainer } from 'react-toastify';

function ConfirmPin() {
    const [Phone, setPhone] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const [passwordTypeConfirm, setpasswordTypeConfirm] = useState("password");
    const [passwordInputConfirm, setpasswordInputConfirm] = useState("");

    let errorsObj = {passwordInput : '',passwordInputConfirm : ''};
    const [errors, setErrors] = useState(errorsObj);

    //------------------------ Enter Pin ---------------------------------
    
    const handlePasswordChange = (e) => {
      setPasswordInput(e.target.value);
    };
    const togglePassword = (e) => {
      e?.preventDefault();
      if (passwordType === "password") {
        setPasswordType("text");
        return;
      }
      setPasswordType("password");
    };

    // Confirm Pin
    const handlePasswordChangeConfirm = (e) => {
        setpasswordInputConfirm(e.target.value);
      };
      const togglePasswordConfirm = (e) => {
        e?.preventDefault();
        if (passwordTypeConfirm === "password") {
          setpasswordTypeConfirm("text");
          return;
        }
        setpasswordTypeConfirm("password");
      };

    // ---------------------- on Submit Form --------------------------

    const history = useNavigate();
  const newdata = useSelector(state => state.Auth?.setnewpin)
  const generatenewpin = useSelector(state => state.Auth?.generatenewpin)
  const dispatch = useDispatch()

  useEffect(() => {
  setPhone(newdata.data)
  },[newdata])

      const onsubmitform = (e) => {
       e?.preventDefault();
       let error = false;
       const errorObj = { ...errorsObj };
       if (passwordInput?.length === 0)
        {
         errorObj.passwordInput = "Please Enter Pin";
         error = true;
       }
       if(passwordInputConfirm?.length === 0)
       {
        errorObj.passwordInputConfirm = "Please Enter Confirm Pin";
        error = true;
       }else if(passwordInputConfirm !== passwordInput){
        errorObj.passwordInputConfirm = "Pin and Confirm Pin Should be Same.";
        error = true;
       }
       setErrors(errorObj);
       if (error) {
         return;
       }
  
       const data = {
        contact: Phone,
        pin: passwordInput,
      };
    dispatch(SetGeneratePin(data))
   
  }

  useEffect(() => {
    if(generatenewpin.success === true){
      dispatch(Userlogin(Phone, passwordInput, history))
      }
  },[generatenewpin])
    
  return (
    <div className='loginback'>
      <ToastContainer/>
         <div className='h-screen flex items-center'>
       <div className="bg-[#fff] py-[30px] sm:py-[50px] sm:mx-auto mx-3 w-full sm:w-[500px] px-[30px] sm:px-[70px] loginbox rounded-[20px]">
          <div >
            <img src={logo} alt="new"></img>
            <div className='py-[15px] sm:py-[20px] '>
            <p className=' text-[#161616] text-[20px] sm:text-[24px] font-medium font-poppins'>Generate PIN</p>
          </div>
          </div>
          <form onSubmit={onsubmitform}>
            <div className='grid grid-cols-12'>
            <div className=' col-span-12 mb-[20px]'>
               <p className="sm:text-[18px] text-[#000] font-medium pb-[10px] font-poppins">
              Phone No
            </p>
            <input
              className=" focus:bg-white rounded-[5px] w-full py-2.5 border border-[#6D6E70] placeholder:text-[#979797] pl-5 placeholder:font-medium"
              placeholder="+91 9087343434"
              autoComplete="off"
              disabled={true}
              required
              type="text"
              onChange={(e) => setPhone(e.target.value)}
              value={Phone}
            ></input>   
            </div>
            <div className=' col-span-12 mb-[30px]'>
              <p className="sm:text-[18px] text-[#000] font-medium pb-[10px] font-poppins">
              Create Pin
            </p>
            <div className="relative">
              <input
                type={passwordType}
                onChange={handlePasswordChange}
                value={passwordInput}
                name="pin"
                className=" focus:bg-white rounded-[5px] w-full py-2.5 border border-[#6D6E70] placeholder:text-[#979797] pl-5 placeholder:font-medium"
                placeholder="Enter Pin"
                autoComplete="off"
                maxLength={6}
                minLength={6}
              ></input>
              <div className="absolute right-4 top-[13px]">
                <button onClick={togglePassword}>
                  {passwordType === "password" ? (
                    <img src={eyeopen} alt="new"></img>
                  ) : (
                    <img src={eyeclose} alt="new"></img>
                  )}
                </button>
              </div>
              </div>   
              {errors.passwordInput && (
                <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                  {errors.passwordInput}
                </div>
              )}  
            </div>
           <div className=' col-span-12 mb-[30px]'>
              <p className="sm:text-[18px] text-[#000] font-medium pb-[10px] font-poppins">
              Confirm Pin
            </p>
            <div className="relative">
              <input
                type={passwordTypeConfirm}
                onChange={handlePasswordChangeConfirm}
                value={passwordInputConfirm}
                name="confirm-pin"
                className=" focus:bg-white rounded-[5px] w-full py-2.5 border border-[#6D6E70] placeholder:text-[#979797] pl-5 placeholder:font-medium"
                placeholder="Enter Confirm Pin"
                autoComplete="off"
                maxLength={6}
                minLength={6}
              ></input>
              <div className="absolute right-4 top-[13px]">
                <button onClick={togglePasswordConfirm}>
                  {passwordTypeConfirm === "password" ? (
                    <img src={eyeopen} alt="new"></img>
                  ) : (
                    <img src={eyeclose} alt="new"></img>
                  )}
                </button>
              </div>
              {errors.passwordInputConfirm && (
                <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                  {errors.passwordInputConfirm}
                </div>
              )} 
              </div>
            </div>
           </div>
           <div className="text-center">
              <button
                type="submit"
                className="bg-[#076AFF] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium w-full rounded-[5px] min-h-[46px] text-white"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        </div>
    </div>
  )
}

export default ConfirmPin