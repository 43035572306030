import React, { useEffect, useState } from "react";
import upload from "../../images/upload.png";
import date from "../../images/date.png";
import { Link, useNavigate } from "react-router-dom";
import Select, { components } from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import arrow from "../../images/rightarr.png";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateProducerMovie,
  GetActorlist,
  GetGenrelist,
  GetLanguagelist,
} from "../../Redux/Action/MovieAction";


function AddProducerMovies() {
  const [Moviename, setMoviename] = useState("");
  const [show, setshow] = useState();
  const [Discount, setDiscount] = useState("");
  const [Price, setPrice] = useState("");
  const [Duration, setDuration] = useState("");
  const [Moviedesc, setMoviedesc] = useState("");
  const [trailerlink, settrailerlink] = useState("");
  const [Language, setLanguage] = useState([]);
  const [Languageoption, setLanguageoption] = useState("");
  const [Genre, setGenre] = useState([]);
  const [Genreoption, setGenreoption] = useState("");
  const [Actor, setActor] = useState([]);
  const [Actoroption, setActoroption] = useState("");
  const [Hours, setHours] = useState("");
  const [Minutes, setMinutes] = useState("");
  const [check, setcheck] = useState(false);

  // Banner image
  const [Bannerimages, setBannerimages] = useState([]);
  const [imageURLs, setImagesURLs] = useState([]);

  const [imageBannerData, setImageBannerData] = useState([]);
  const [newbanner, setnewbanner] = useState([]);
  const [bannerErrorMessage, setBannerErrorMessage] = useState('');

 

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      const image = new Image();
      image.src = reader.result;

      image.onload = () => {
        const width = image.width;
        const height = image.height;
        const size = file.size;

        if (width === 1440 && height === 500 && size <= 1000000) {
          // Image meets the conditions, proceed with handling the image data
          setImageBannerData(reader.result);
          setBannerErrorMessage('');
        } else {
          // Image does not meet the conditions, show an error message
          setnewbanner([]);
          setBannerErrorMessage('Image dimensions must be 1440x500 and size less than 1MB.');
        }
      };
    };

    if (file) {
       reader.readAsDataURL(file);
    }
    // setImageBannerData(file);
    setnewbanner([...event.target.files]);
  };

  // poster image
  const [Posterimages, setPosterimages] = useState([]);
  const [smallimageURLs, setsmallImagesURLs] = useState([]);

  const [imagePosterData, setImagePosterData] = useState([]);
  const [newPoster, setNewPoster] = useState([]);
  const [posterErrorMessage, setPosterErrorMessage] = useState('');

  const handlePosterChange = (event) =>{

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () =>{
      const image = new Image();
      image.src = reader.result;

      image.onload = () =>{
        const width = image.width;
        const height = image.height;
        const size = file.size;

        if(width === 300 && height === 320 && size <= 1000000){
          setImagePosterData(reader.result);
          setPosterErrorMessage('');
        }else{
            setNewPoster([]);
            setPosterErrorMessage('Image dimensions must be 300x320 and size less than 1MB.')
        }
      };
    };
    if(file){
      reader.readAsDataURL(file);
    }
    setNewPoster([...event.target.files]);
  }


  // upload image
  const [uploadimages, setuploadimages] = useState([]);
  const [uploadimagesURLs, setuploadimagesURLs] = useState([]);

  const [startDate, setStartDate] = useState();
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [imageUploadData, setImageUploadData] = useState([]);
  const [newUpload, setNewUpload] = useState([]);
  const [uploadErrorMessage, setUploadErrorMessage] = useState('');

  const [imageUploadData1, setImageUploadData1] = useState([]);
  const [newUpload1, setNewUpload1] = useState([]);
  const [uploadErrorMessage1, setUploadErrorMessage1] = useState('');

  const [imageUploadData2, setImageUploadData2] = useState([]);
  const [newUpload2, setNewUpload2] = useState([]);
  const [uploadErrorMessage2, setUploadErrorMessage2] = useState('');

  const [imageUploadData3, setImageUploadData3] = useState([]);
  const [newUpload3, setNewUpload3] = useState([]);
  const [uploadErrorMessage3, setUploadErrorMessage3] = useState('');

  const handleUploadChange = (event) =>{

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () =>{
      const image = new Image();
      image.src = reader.result;

      image.onload = () =>{
        const width = image.width;
        const height = image.height;
        const size = file.size;

        if(width === 280 && height === 240 && size <= 1000000){
          setImageUploadData(reader.result);
          setUploadErrorMessage('');
        }else{
            setNewUpload([]);
            setUploadErrorMessage('Image dimensions must be 280x240 and size less than 1MB.')
        }
      };
    };
    if(file){
      reader.readAsDataURL(file);
    }
    setNewUpload([...event.target.files]);
  }
  const handleUploadChange1 = (event) =>{

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () =>{
      const image = new Image();
      image.src = reader.result;

      image.onload = () =>{
        const width = image.width;
        const height = image.height;
        const size = file.size;

        if(width === 280 && height === 240 && size <= 1000000){
          setImageUploadData1(reader.result);
          setUploadErrorMessage1('');
        }else{
            setNewUpload1([]);
            setUploadErrorMessage1('Image dimensions must be 280x240 and size less than 1MB.')
        }
      };
    };
    if(file){
      reader.readAsDataURL(file);
    }
    setNewUpload1([...event.target.files]);
  }
  const handleUploadChange2 = (event) =>{

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () =>{
      const image = new Image();
      image.src = reader.result;

      image.onload = () =>{
        const width = image.width;
        const height = image.height;
        const size = file.size;

        if(width === 280 && height === 240 && size <= 1000000){
          setImageUploadData2(reader.result);
          setUploadErrorMessage2('');
        }else{
            setNewUpload2([]);
            setUploadErrorMessage2('Image dimensions must be 280x240 and size less than 1MB.')
        }
      };
    };
    if(file){
      reader.readAsDataURL(file);
    }
    setNewUpload2([...event.target.files]);
  }
  const handleUploadChange3 = (event) =>{

    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () =>{
      const image = new Image();
      image.src = reader.result;

      image.onload = () =>{
        const width = image.width;
        const height = image.height;
        const size = file.size;

        if(width === 280 && height === 240 && size <= 1000000){
          setImageUploadData3(reader.result);
          setUploadErrorMessage3('');
        }else{
            setNewUpload3([]);
            setUploadErrorMessage3('Image dimensions must be 280x240 and size less than 1MB.')
        }
      };
    };
    if(file){
      reader.readAsDataURL(file);
    }
    setNewUpload3([...event.target.files]);
  }
  

  // ------------------- check box for select-------------------------
  const handlecheck = () =>{
    setcheck(!check)
  }
 
  
  const InputOption = ({
    getStyles,
    Icon,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
  }) => {
    const [isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    // styles
    let bg = "transparent";
    if (isFocused) bg = "transparent";
    if (isActive) bg = "#DAE9FF";

    const style = {
      alignItems: "center",
      backgroundColor: bg,
      color: "inherit",
      display: "flex ",
    };

    // prop assignment
    const props = {
      ...innerProps,
      onMouseDown,
      onMouseUp,
      onMouseLeave,
      style,
    };

    return (
      <components.Option
        {...rest}
        isDisabled={isDisabled}
        isFocused={isFocused}
        isSelected={isSelected}
        getStyles={getStyles}
        innerProps={props}
      >
        <input
          type="checkbox"
          defaultChecked={isSelected}
          className="mr-3 cursor-pointer w-[18px] h-[18px]"
          style={{ accentColor: "#028f3b" }}
        />
        {children}
      </components.Option>
    );
  };

  let errorsObj = { image: "", posterimage: "", uploadimage: "" };
  const [errors, setErrors] = useState(errorsObj);

  // -------------------for Banner image--------------------------------

  useEffect(() => {
    if (Bannerimages?.length < 1) return;
    const newImageURLs = [];
    Bannerimages.forEach((image) =>
      newImageURLs.push(URL.createObjectURL(image))
    );
    setImagesURLs(newImageURLs);
  }, [Bannerimages]);

  const onimagechange = (e) => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (e.target.files[0].size > 5000000) {
      errorObj.image = "Image Size must be less than 5MB.";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setBannerimages([...e.target.files]);
  };

  //---------------------- for Poster image---------------------------------

  useEffect(() => {
    if (Posterimages?.length < 1) return;
    const newImageURLs = [];
    Posterimages.forEach((image) =>
      newImageURLs.push(URL.createObjectURL(image))
    );
    setsmallImagesURLs(newImageURLs);
  }, [Posterimages]);

  const onsmallimagechange = (e) => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (e?.target?.files[0]?.size > 5000000) {
      errorObj.posterimage = "Image Size must be less than 5MB.";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setPosterimages([...e.target.files]);
  };

  //--------------------------- for upload images-------------------------------------

  useEffect(() => {
    if (uploadimages?.length < 1) return;
    const newImageURLs = [];
    uploadimages.forEach((image) =>
      newImageURLs.push(URL.createObjectURL(image))
    );
    setuploadimagesURLs(newImageURLs);
  }, [uploadimages]);

  const onuploadimagechange = (e) => {
    let error = false;
    const errorObj = { ...errorsObj };
    if (e?.target?.files[0]?.size > 5000000) {
      errorObj.uploadimage = "Image Size must be less than 5MB.";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setuploadimages([...e.target.files]);
  };

  //-------------------------style for select---------------------------------

  const customStyles = {
    control: (base) => ({
      ...base,
      // border : 1,
      boxShadow: "none",
      minHeight: 60,
      borderColor: "#6D6E70 !important",
    }),
    menu: (provided) => ({
      ...provided,
      borderstyle: "solid",
      boxShadow: "none",
      zIndex: "9999 !important",
    }),
    menuList: (base) => ({
      ...base,
      height: "228px !important",
      "::-webkit-scrollbar": {
        width: "5px",
      },
      "::-webkit-scrollbar-track": {
        background: "#fff",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#DAE9FF",
      },
      "::-webkit-scrollbar-thumb:hover": {
        background: "#DAE9FF",
      },
    }),
    option: (provided) => ({
      ...provided,
      color: "#979797 !important",
      fontWeight: 500,
      cursor: "pointer",
      borderBottom: 1,
      zIndex: 9999,
      paddingTop: 10,
      paddingBottom: 10,
      borderColor: "#DAE9FF",
      borderStyle: "solid",
      ":hover": {
        backgroundColor: "#DAE9FF !important",
        color: "#076AFF !important",
      },
    }),
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#DAE9FF",
      };
    },
    multiValueLabel: (styles) => ({
      ...styles,
      color: "#076AFF",
      fontWeight: 500,
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      color: "#47505F",
      ":hover": {
        backgroundColor: "#47505F",
        color: "white",
      },
    }),
  };

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetLanguagelist());
  }, []);
  useEffect(() => {
    dispatch(GetGenrelist());
  }, []);
  useEffect(() => {
    dispatch(GetActorlist());
  }, []);

  const languagelist = useSelector((state) => state.movie.languagelist.data);
  const genrelist = useSelector((state) => state.movie.genrelist.data);
  const actorlist = useSelector((state) => state.movie.actorlist.data);

  const addmovie = useSelector((state) => state.movie.addmovie);

  useEffect(() => {
    const con =
      languagelist && languagelist.map((c) => ({ value: c, label: c }));
    setLanguageoption(con);
  }, [languagelist]);

  useEffect(() => {
    const con = genrelist && genrelist.map((c) => ({ value: c, label: c }));
    setGenreoption(con);
  }, [genrelist]);

  useEffect(() => {
    const con =
      actorlist && actorlist.map((c) => ({ value: c._id, label: c.actor }));
    setActoroption(con);
  }, [actorlist]);

  useEffect(() => {
    setDuration(Number(Hours * 60) + Number(Minutes));
  }, [Hours, Minutes]);

  const history = useNavigate()
  const oncreatenewmovie = (e) => {
    e?.preventDefault();

    
    dispatch(
      CreateProducerMovie(
        newbanner,
        newPoster,
        newUpload,
        newUpload1,
        newUpload2,
        newUpload3,
        Moviename,
        Moviedesc,
        Language,
        Actor,
        Genre,
        trailerlink,
        Math.floor(new Date(startDate).getTime()),
        Duration,history
      )
    );
      }
      
    useEffect(() => {
   if (addmovie.code === 200) {
    setMoviename("");
    setMoviedesc("");
    setLanguage([]);
    setActor([]);
    setGenre([]);
    settrailerlink("");
    setStartDate("");
    setHours("");
    setMinutes("");
    setDuration("");
    setPrice("");
    setshow("");
    setuploadimages("");
    // setBannerimages("");
    setImageBannerData("")
    // setPosterimages("");
    setPosterErrorMessage(""); 
    setuploadimagesURLs("");
    setsmallImagesURLs("");
    setImagesURLs("");
    setcheck("");
  };
    
}, [addmovie]);

  const role = localStorage.getItem("role");

  return (
    <div className="pt-[20px] pb-[50px]">
      {/* <ToastContainer/> */}
      <div className=" flex items-center pb-[7px]">
        <Link to={'/admin/producer-movies'}>
          <p className=" text-[#076aff] cursor-pointer text-[18px] font-medium font-poppins">
            MOVIES
          </p>
        </Link>
        <img src={arrow} alt="new" className="mx-3"></img>
        <p className=" text-[#161616] text-[18px] font-medium font-poppins">
          Add Movies
        </p>
      </div>
      <div className="mt-[15px] sm:mt-[20px]">
        {/*------------------------------- Movie Form --------------------------------------*/}

        <form
          className=" grid grid-cols-12 gap-x-5 xl:gap-x-9 w-full"
          onSubmit={oncreatenewmovie}
        >
         
          {/*  images section */}
          <div className="w-full col-span-12 md:col-span-6">
            {/*--------------------------------------- Upload Banner Image --------------------------------------*/}

            <label htmlFor="inputTag">
              <div className="bg-[#fff] h-[170px]  border border-dashed border-[#DAE9FF] flex items-center justify-center cursor-pointer overflow-hidden  rounded-[10px]">
                <input
                  id="inputTag"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  // onChange={onimagechange}
                  onChange={handleImageChange}

                />
                {imageBannerData?.length > 0 ? (
                // imageURLs?.length > 0 ? (
                  // imageURLs?.map((m, i) => (
                    <div className="w-full h-full">
                      <img
                        // src={m}
                        src={imageBannerData}
                        className="w-full h-full"
                        alt="new"
                      ></img>
                    </div>
                  // ))
                ) : (
                  <div className=" flex justify-center items-center h-full">
                    <div className=" text-center">
                      <img src={upload} className="mx-auto" alt="new"></img>
                      <p className="text-[#47505F] text-[14px] sm:text-[16px] md:text-[18px] font-medium font-poppins mt-5">
                        Upload Banner Image
                      </p>
                      <p className="text-[12px] text-[#47505F] mt-2">
                        (Img size : 1440 x 500)
                      </p>
                    </div>
                  </div>
                )}
              </div>
              { bannerErrorMessage && (
              // errors.image && (
                <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                  {bannerErrorMessage}
                  {/* {errors.image} */}
                </div>
              )}
            </label>

            {/*-------------------------------------- Upload Poster Image --------------------------------------*/}

            <label htmlFor="inputTags" className="bg-[#fff]  w-[160px] h-[170px] mx-auto sm:h-[240px] sm:w-[225px] mt-[20px] sm:mt-[30px] border border-dashed border-[#DAE9FF] overflow-hidden flex items-center justify-center cursor-pointer  rounded-[10px]">
              <div>
                <input
                  id="inputTags"
                  type="file"
                  accept="image/*"
                  className="hidden"
                  // onChange={onsmallimagechange}
                  onChange={handlePosterChange}
                />
                {
                imagePosterData?.length > 0 ? (
                // smallimageURLs?.length > 0 ? (
                //   smallimageURLs?.map((m, id) => (
                    <div  className="w-full h-full">
                      <img
                        // src={m}
                        src={imagePosterData}
                        className="w-full h-full object-cover"
                        alt="new"
                      ></img>
                    </div>
                  // ))
                ) : (
                  <div className=" flex justify-center items-center h-full">
                    <div className=" text-center">
                      <img src={upload} className="mx-auto" alt="new"></img>
                      <p className="text-[#47505F] text-[14px] sm:text-[16px] md:text-[18px] font-medium font-poppins mt-5">
                        Upload Poster Image
                      </p>
                      <p className="text-[12px] text-[#47505F] mt-2">
                        (Img size : 300 x 320)
                      </p>
                    </div>
                  </div>
                )}
              </div>
              
            </label>
            {posterErrorMessage && (
              // {errors.posterimage && (
                <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                  {posterErrorMessage}
                  {/* {errors.posterimage} */}
                </div>
              )}
            {/*-------------------------------------- Upload Image --------------------------------------*/}

            <div className=" grid grid-cols-12 mt-[20px] sm:mt-[30px] gap-x-3 xl:gap-x-6">
            <label htmlFor="inputTagsf" className=" col-span-6">
                <div className="bg-[#fff]  h-[170px] sm:h-[215px] overflow-hidden border border-dashed border-[#DAE9FF] flex items-center justify-center cursor-pointer  rounded-[10px]">
                  <input
                    id="inputTagsf"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleUploadChange}
                  />
                  {imageUploadData?.length > 0 ? (
                    <div className=" col-span-6 w-full   h-[170px] sm:h-[215px] rounded-[10px] ">
                      <img
                        src={imageUploadData}
                        className="w-full h-full object-cover rounded-[10px]"
                        alt="new"
                      ></img>
                    </div>
                  ) : (
                    <div className=" flex justify-center items-center h-full">
                      <div className=" text-center">
                        <img src={upload} className="mx-auto" alt="new"></img>
                        <p className="text-[#47505F] text-[14px] sm:text-[16px] md:text-[18px] font-medium font-poppins mt-5">
                          Upload Image
                        </p>
                        <p className="text-[12px] text-[#47505F] mt-2">
                          (Img size : 280 x 240)
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {uploadErrorMessage && (
                  <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                    {uploadErrorMessage}
                  </div>
                )}
              </label>
              <label htmlFor="inputTags2" className=" col-span-6">
                <div className="bg-[#fff]  h-[170px] sm:h-[215px] overflow-hidden border border-dashed border-[#DAE9FF] flex items-center justify-center cursor-pointer  rounded-[10px]">
                  <input
                    id="inputTags2"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleUploadChange1}
                  />
                  {imageUploadData1?.length > 0 ? (
                    <div className=" col-span-6 w-full   h-[170px] sm:h-[215px] rounded-[10px] ">
                      <img
                        src={imageUploadData1}
                        className="w-full h-full object-cover rounded-[10px]"
                        alt="new"
                      ></img>
                    </div>
                  ) : (
                    <div className=" flex justify-center items-center h-full">
                      <div className=" text-center">
                        <img src={upload} className="mx-auto" alt="new"></img>
                        <p className="text-[#47505F] text-[14px] sm:text-[16px] md:text-[18px] font-medium font-poppins mt-5">
                          Upload Image
                        </p>
                        <p className="text-[12px] text-[#47505F] mt-2">
                          (Img size : 280 x 240)
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {uploadErrorMessage1 && (
                  <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                    {uploadErrorMessage1}
                  </div>
                )}
              </label>
              <label
                htmlFor="inputTags3"
                className=" col-span-6 mt-[20px] sm:mt-[30px]"
              >
                <div className="bg-[#fff]  h-[170px] sm:h-[215px] overflow-hidden border border-dashed border-[#DAE9FF] flex items-center justify-center cursor-pointer  rounded-[10px]">
                  <input
                    id="inputTags3"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleUploadChange2}
                  />
                  {imageUploadData2?.length > 0 ? (
                    <div className=" col-span-6 w-full   h-[170px] sm:h-[215px] rounded-[10px] ">
                      <img
                        src={imageUploadData2}
                        className="w-full h-full object-cover rounded-[10px]"
                        alt="new"
                      ></img>
                    </div>
                  ) : (
                    <div className=" flex justify-center items-center h-full">
                      <div className=" text-center">
                        <img src={upload} className="mx-auto" alt="new"></img>
                        <p className="text-[#47505F] text-[14px] sm:text-[16px] md:text-[18px] font-medium font-poppins mt-5">
                          Upload Image
                        </p>
                        <p className="text-[12px] text-[#47505F] mt-2">
                          (Img size : 280 x 240)
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {uploadErrorMessage2 && (
                  <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                    {uploadErrorMessage2}
                  </div>
                )}
              </label>
              <label
                htmlFor="inputTagsnew"
                className=" col-span-6 mt-[20px] sm:mt-[30px]"
              >
                <div className="bg-[#fff]  h-[170px] sm:h-[215px] overflow-hidden border border-dashed border-[#DAE9FF] flex items-center justify-center cursor-pointer  rounded-[10px]">
                  <input
                    id="inputTagsnew"
                    type="file"
                    accept="image/*"
                    className="hidden"
                    onChange={handleUploadChange3}
                  />
                  {imageUploadData3?.length > 0 ? (
                    <div className=" col-span-6 w-full   h-[170px] sm:h-[215px] rounded-[10px] ">
                      <img
                        src={imageUploadData3}
                        className="w-full h-full object-cover rounded-[10px]"
                        alt="new"
                      ></img>
                    </div>
                  ) : (
                    <div className=" flex justify-center items-center h-full">
                      <div className=" text-center">
                        <img src={upload} className="mx-auto" alt="new"></img>
                        <p className="text-[#47505F] text-[14px] sm:text-[16px] md:text-[18px] font-medium font-poppins mt-5">
                          Upload Image
                        </p>
                        <p className="text-[12px] text-[#47505F] mt-2">
                          (Img size : 280 x 240)
                        </p>
                      </div>
                    </div>
                  )}
                </div>
                {uploadErrorMessage3 && (
                  <div className="mt-2 text-[#FF0101] text-sm font-poppins">
                    {uploadErrorMessage3}
                  </div>
                )}
              </label>
            </div>
          </div>

          {/*  Form section */}
          <div className="w-full col-span-12 md:col-span-6 mt-[20px] md:mt-0">
            <p className="text-base text-[#161616] font-medium font-poppins">
              Movie Name
            </p>
            <input
              className="h-[46px] rounded-[5px] border border-[#6D6E70]  pl-5 mt-3 w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
              placeholder="Enter Movie Name"
              required
              value={Moviename}
              onChange={(e) => setMoviename(e.target.value)}
            ></input>
            <p className="text-base mt-[20px] text-[#161616] font-medium font-poppins">
              Movie Description
            </p>
            <textarea
              className="py-3.5 rounded-[5px] border border-[#6D6E70]  pl-5 mt-3 min-h-[110px] w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
              placeholder="Enter Movie Description..."
              style={{ resize: "none" }}
              required
              value={Moviedesc}
              onChange={(e) => setMoviedesc(e.target.value)}
            ></textarea>
            <div className="">
              <p className="text-base mb-3 mt-[20px] text-[#161616] font-medium font-poppins">
                Select Language
              </p>
              <Select
                isClearable
                closeMenuOnSelect={false}
                isMulti
                required
                name="colors"
                options={Languageoption}
                value={
                  Languageoption &&
                  Languageoption.find((obj) => obj.value === Language)
                }
                className="basic-multi-select"
                styles={customStyles}
                placeholder={"Select language...."}
                hideSelectedOptions={false}
                onChange={(options) => {
                  if (Array.isArray(options)) {
                    setLanguage(options?.map((opt) => opt.value));
                  }
                }}
                components={{
                  Option: InputOption,
                }}
              />
            </div>
            <div className="">
              <p className="text-base mb-3 mt-[20px] text-[#161616] font-medium font-poppins">
                Select Actor
              </p>
              <Select
                closeMenuOnSelect={false}
                isMulti
                name="colors"
                required
                options={Actoroption}
                value={
                  Actoroption && Actoroption.find((obj) => obj.value === Actor)
                }
                className="basic-multi-select"
                classNamePrefix="select"
                styles={customStyles}
                placeholder={"Select actor...."}
                hideSelectedOptions={false}
                onChange={(options) => {
                  if (Array.isArray(options)) {
                    setActor(options?.map((opt) => opt.value));
                  }
                }}
                components={{
                  Option: InputOption,
                }}
              />
            </div>
            <div className="">
              <p className="text-base mb-3 mt-[20px] text-[#161616] font-medium font-poppins">
                Select Genre
              </p>
              <Select
                closeMenuOnSelect={false}
                isMulti
                name="colors"
                required
                options={Genreoption}
                value={
                  Genreoption && Genreoption.find((obj) => obj.value === Genre)
                }
                className="basic-multi-select"
                classNamePrefix="select"
                styles={customStyles}
                placeholder={"Select Genre...."}
                hideSelectedOptions={false}
                onChange={(options) => {
                  if (Array.isArray(options)) {
                    setGenre(options?.map((opt) => opt.value));
                  }
                }}
                components={{
                  Option: InputOption,
                }}
              />
            </div>
            <div className=" mt-[20px]">
              <p className="text-base text-[#161616] font-medium font-poppins">
                Movie Trailer Link
              </p>
              <input
                className="h-[46px] rounded-[5px] border border-[#6D6E70]  pl-5 mt-3 w-full placeholder:text-[#979797] placeholder:font-medium placeholder:font-poppins"
                placeholder="Enter Movie Trailer Link"
                type="url"
                value={trailerlink}
                required
                onChange={(e) => settrailerlink(e.target.value)}
              ></input>
            </div>
            <div className="mt-[25px] gap-x-3 xl:gap-x-8 grid grid-cols-12">
              <div className=" col-span-6">
                <p className="text-base mb-[15px] text-[#161616] font-medium font-poppins">
                  Release Date
                </p>
                <div className="relative">
                  <div className="absolute right-2 xl:right-5 z-[1] flex items-center h-full cursor-pointer">
                    <img src={date} alt="new"></img>
                  </div>
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    placeholderText="Enter Date"
                    className="bg-white cursor-pointer border border-[#6D6E70]  w-full h-[46px] flex items-center pl-3 xl:pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                  />
                </div>
              </div>
              <div className=" col-span-6">
                <p className="text-base mb-[15px] text-[#161616] font-medium font-poppins">
                  Duration
                </p>
                <div className=" grid grid-cols-12 gap-3">
                  <div className="relative col-span-6">
                    <input
                      className="bg-white w-full border border-[#6D6E70]  h-[46px] flex items-center pl-3 xl:pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                      placeholder="Hours"
                      type="number"
                      onKeyDown={event => {
                        if (event.key === "e" || event.key === "E") {
                            event.preventDefault();
                        }
                        }}
                      value={Hours}
                      min={0}
                      required
                      onChange={(e) =>{e.target.value > 0 ? setHours(e.target.value) : setHours("")}}
                    />
                  </div>
                  <div className="relative col-span-6">
                    <input
                      className="bg-white w-full border border-[#6D6E70]  h-[46px] flex items-center pl-3 xl:pl-5 rounded-[5px] placeholder:font-poppins placeholder:font-medium placeholder:text-[#979797]"
                      placeholder="Mins"
                      type="number"
                      onKeyDown={event => {
                        if (event.key === "e" || event.key === "E") {
                            event.preventDefault();
                        }
                        }}
                      value={Minutes}
                      min={0}
                      onChange={(e) =>{e.target.value > 0 ? setMinutes(e.target.value) : setMinutes("")}}
                    />
                  </div>
                </div>
              </div>
              <div className={`col-span-6 flex items-end mt-5`}>
                <button
                  type="submit"
                  className="text-base font-poppins font-medium px-[3rem] xl:px-[5rem] min-h-[46px] rounded-[5px] text-white bg-[#076aff] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl"
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </form>

        {/*----------------------------------- End Movie Form --------------------------------------*/}
      </div>
    </div>
  );
}

export default AddProducerMovies;
