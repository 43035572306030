import React, { useEffect, useState } from "react";
import contactus from "../images/Layer 1.png";
import ReCAPTCHA from "react-google-recaptcha";
import { useDispatch } from "react-redux";
import { AddContact } from "../Redux/Action/ContactusAction";
import { useNavigate } from "react-router";
import { useRef } from "react";

function Contactus() {
  const [Email, setEmail] = useState("");
  const [Name, setName] = useState("");
  const [Mobile, setMobile] = useState("");
  const [check, setcheck] = useState('Producer');

  function onChangeValue(e) {
    setcheck(e.target.value);
  }

  const captcha = useRef(null);

  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const dispatch = useDispatch();

  const history = useNavigate();
  const onSumbitForm = (e) => {
   e.preventDefault();
   const data = {
    name : Name,
    emailID : Email,
    contact : Mobile,
    type : check
   }
   if(captcha){
    dispatch(AddContact(data , history))
    setName("");
    setEmail("");
    setMobile("");
    setcheck("");
    captcha?.current?.reset();
   }
  
  }

  return (
    <div className="main_container ">
          {/*------------------ Contact Us Form -------------------*/}
          <div className=" flex items-center justify-center">
         <div>
            <img src={contactus} className=" h-[450px] lg:h-[550px] hidden md:flex"></img>
          </div>
          <form onSubmit={onSumbitForm} className=" bg-[#fff] boxshadow rounded-[20px] p-5 md:p-10 relative xl:w-[50%] my-7  sm:my-10">
          
          <p className="text-[30px] lg:text-[36px] font-poppins font-[600] text-center mb-[15px] md:mb-[30px] lg:mb-[30px]">
            <span className="logoheading">Contact Us</span>
          </p>
            <div className="grid grid-cols-12 gap-x-7 lg:mt-[20px]">
              
              <div className=" col-span-12 md:col-span-6 mb-[20px] md:mb-[30px]">
                <p className="text-[15px] xl:text-[18px] text-[#161616] font-medium pb-[10px] md:pb-[15px] font-poppins">
                  Name
                </p>
                <input
                  className=" focus:bg-white border border-[#6D6E70] rounded-[5px] w-full min-h-[46px]  placeholder:text-[#979797] pl-5 placeholder:font-medium"
                  placeholder="Enter Name"
                  autoComplete="off"
                  required
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  value={Name}
                ></input>
              </div>
              <div className=" col-span-12 md:col-span-6 mb-[20px] md:mb-[30px]">
                <p className="text-[15px] xl:text-[18px] text-[#161616] font-medium pb-[10px] md:pb-[15px] font-poppins">
                  Email
                </p>
                <input
                  className=" focus:bg-white border border-[#6D6E70]  rounded-[5px] w-full min-h-[46px]   placeholder:text-[#979797] pl-5 placeholder:font-medium"
                  placeholder="Enter Email"
                  autoComplete="off"
                  required
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={Email}
                ></input>
              </div>
              <div className=" col-span-12 md:col-span-6 mb-[20px] md:mb-[30px]">
                <p className="text-[15px] xl:text-[18px] text-[#161616] font-medium pb-[10px] md:pb-[15px] font-poppins">
                  Mobile No
                </p>
                <input
                  className=" focus:bg-white border border-[#6D6E70] rounded-[5px] w-full min-h-[46px]  placeholder:text-[#979797] pl-5 placeholder:font-medium"
                  placeholder="Enter Mobile No"
                  autoComplete="off"
                  required
                  type="number"
                  onChange={(e) => setMobile(e.target.value)}
                  value={Mobile}
                ></input>
              </div>
              <div className=" col-span-12 md:col-span-6 mb-[20px] md:mb-[30px]">
                <p className="text-[15px] xl:text-[18px] text-[#161616] font-medium pb-[10px] md:pb-[15px] font-poppins">
                  Type of Service You Need
                </p>
                <div
                  className=" flex items-center mt-2"
                >
                  <div className=" mr-10 flex items-center">
                    <input
                      className="w-[20px] min-h-[20px] mr-2 cursor-pointer"
                      required
                      type="radio"
                      checked={check === "Producer"}
                      onChange={onChangeValue}
                      value="Producer"
                      name="check"
                    ></input>
                    <span
                      className={`${
                        check === "Producer" && `${" text-[#076aff]"}`
                      } font-medium font-poppins lg:text-[18px] text-[#161616]`}
                    >
                      Producer
                    </span>
                  </div>
                  <div className=" flex items-center">
                    <input
                      className="w-[20px] min-h-[20px] mr-2  cursor-pointer"
                      required
                      type="radio"
                      checked={check === "VLE"}
                      onChange={onChangeValue}
                      value="VLE"
                      name="check"
                    ></input>
                    <span
                      className={`${
                        check === "VLE" && `${" text-[#076aff]"}`
                      } font-medium font-poppins lg:text-[18px] text-[#161616]`}
                    >
                      VLE
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className=" flex items-center justify-center  flex-col lg:mt-5 ">
              <ReCAPTCHA sitekey={`${process.env.REACT_APP_SITE_KEY}`} ref={captcha} />
              <div className=" mt-5 lg:mt-10">
                <button
                  type="submit"
                  className="bg-[#076AFF] min-w-[150px] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium  rounded-[5px] text-[14px] sm:text-[16px] min-h-[35px] sm:min-h-[46px] text-white"
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
</div>
          {/*------------------End of Contact Us Form -------------------*/}
    </div>
  );
}

export default Contactus;
