import React, { useState } from "react";
import logo from "../images/LOGO 2.svg";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { UserRegister, reset } from "../Redux/Action/AuthAction";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { actionTypes } from "../Redux/Reducer/AuthReducer";
import { useNavigate } from "react-router-dom/dist";

function Register() {
  const [Name, setName] = useState("");
  const [Lastname, setLastname] = useState("");
  const [Mobile, setMobile] = useState("");
  const [Email, setEmail] = useState("");
  const [Role, setRole] = useState("");
  const [msgbox, setmsgbox] = useState(false);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (location.pathname === "/register/vle") {
      setRole("vle");
    } else {
      setRole("producer");
    }
  }, [location, Role]);

  const reister = useSelector((state) => state.Auth.register);
  const history = useNavigate();
  const onSumitForm = (e) => {
    e?.preventDefault();
    dispatch(UserRegister(Name, Lastname, Email, Mobile, Role, history));
    setName("");
    setLastname("");
    setEmail("");
    setMobile("");
    setRole("");
  };

  useEffect(() => {
    if (reister.code === 200) {
      setmsgbox(true);
    }
  }, [reister]);

  const redirect = () => {
    history('/login');
  }
  return (
    <div>
      {/*--------------------------- For Register Vle And Producer------------------------------------ */}
      <ToastContainer />
      {(location?.pathname === "/register/vle" ||
        location?.pathname === "/register/producer") &&  (
        <div className="h-screen  flex items-center loginback">
         {!msgbox && <div className="bg-[#fff] py-[30px] mx-2 sm:mx-auto w-full sm:w-[520px] px-[20px] sm:px-[35px] loginbox rounded-[20px]">
            <div className=" text-center">
              <img src={logo} alt="new" className=" mx-auto"></img>
              <p className="py-[10px] sm:pt-[20px]">
                <span className="text-[18px] sm:text-[24px] font-medium logoheading font-poppins">
                  House Of October Cinemas
                </span>
              </p>
              <p className="sm:pt-[20px] sm:pb-[25px] text-[#076aff] sm:text-[18px] font-medium font-poppins">
                {location?.pathname === "/register/vle" ? "VLE" : "Producer"}
              </p>
            </div>

            {/*--------------------------- Form Start ---------------------------------*/}

            <form onSubmit={onSumitForm}>
              <div className="grid grid-cols-12 gap-x-5">
                <div className="col-span-12 sm:col-span-6 mb-[20px]">
                  <p className="sm:text-[18px] text-[#000] font-medium pb-[10px] font-poppins">
                    First Name
                  </p>
                  <input
                    className=" focus:bg-white rounded-[5px] w-full py-2.5 border border-[#6D6E70] placeholder:text-[#979797] pl-5 placeholder:font-medium"
                    placeholder="Enter First Name"
                    autoComplete="off"
                    required
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    value={Name}
                  ></input>
                </div>
                <div className="col-span-12 sm:col-span-6 mb-[20px]">
                  <p className="sm:text-[18px] text-[#000] font-medium pb-[10px] font-poppins">
                    Last Name
                  </p>
                  <input
                    className=" focus:bg-white  rounded-[5px] w-full py-2.5 border border-[#6D6E70]  placeholder:text-[#979797] pl-5 placeholder:font-medium"
                    placeholder="Enter Last Name"
                    autoComplete="off"
                    required
                    type="text"
                    onChange={(e) => setLastname(e.target.value)}
                    value={Lastname}
                  ></input>
                </div>
                <div className="col-span-12 mb-[20px]">
                  <p className="sm:text-[18px] text-[#000] font-medium pb-[10px] font-poppins">
                    Email ID
                  </p>
                  <div className="relative">
                    <input
                      required
                      onChange={(e) => setEmail(e.target.value)}
                      value={Email}
                      type="email"
                      className=" focus:bg-white rounded-[5px] w-full py-2.5 border border-[#6D6E70] placeholder:text-[#979797] pl-5 placeholder:font-medium"
                      placeholder="Enter Email"
                      autoComplete="off"
                    ></input>
                  </div>
                </div>
                <div className="col-span-12">
                  <p className="sm:text-[18px] text-[#000] font-medium pb-[10px] font-poppins">
                    Mobile No
                  </p>
                  <div className="relative">
                    <input
                      required
                      onChange={(e) => setMobile(e.target.value)}
                      value={Mobile}
                      type="number"
                      className=" focus:bg-white rounded-[5px] w-full py-2.5 border border-[#6D6E70] placeholder:text-[#979797] pl-5 placeholder:font-medium"
                      placeholder="Enter Mobile No"
                      autoComplete="off"
                    ></input>
                  </div>
                </div>
              </div>
              <div className=" flex items-center justify-around mt-[20px] sm:mt-[40px] sm:mb-[10px]">
                  <button type='button' onClick={() => redirect()} className="hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium w-[130px] sm:w-[182px] rounded-[5px] min-h-[40px] text-[#161616] border border-[#000]">
                    Cancel
                  </button>
                <button
                  type="submit"
                  className="bg-[#076AFF] hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins w-[130px] sm:w-[182px] font-medium  rounded-[5px] min-h-[40px] text-white"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>}
        </div>
      )}

      {msgbox && (
        <div className="backcolor absolute z-[50] top-0 bottom-0 right-0 left-0">
          <div className="absolute top-[50%] left-[50%] opacity-100 transform -translate-x-[50%] -translate-y-[50%]">
            <div className="bg-[#fff] py-[30px] sm:py-[60px] mx-2 sm:mx-auto px-[30px] sm:px-[65px] w-[350px] sm:w-[470px] loginbox rounded-[20px]">
              <div className=" text-center">
                <img src={logo} alt="new" className=" mx-auto"></img>
                <p className="py-[10px] sm:pt-[20px]">
                  <span className="text-[18px] sm:text-[24px] font-medium logoheading font-poppins">
                    House Of October Cinemas
                  </span>
                </p>
                <p className="text-[#000] font-medium font-poppins">
                Your request is successfully submitted!
Our backend team will contact soon.
                </p>
              </div>
              <div className=" flex items-center justify-center">
              <button
                onClick={() => {
                  setmsgbox(false);
                  dispatch(reset(actionTypes.RESET_REGISTER));
                  history('/login')
                }}
                className="hover:transform transition-all duration-300 hover:-translate-y-[2px] hover:shadow-xl font-poppins font-medium mt-10 w-[130px] rounded-[5px] min-h-[30px] text-[#161616] border border-[#000]"
              >
                Close
              </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Register;
