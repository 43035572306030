import React, { useEffect } from "react";
import arrow from "../images/rightarr.png";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
// import { OrderDetails } from "../Redux/Action/OrderAction";
import { ResetMovie } from "../Redux/Action/MovieAction";
import { actionTypes } from "../Redux/Reducer/OrderReducer";


function VleTransactionData() {


  useEffect(() => {
    window.scroll({ top: 0, behavior: "smooth" });
  }, []);

  const dispatch = useDispatch();
//   const history = useNavigate();
//   const {id} = useParams();

//   useEffect(() =>{
//    dispatch(OrderDetails(id,history))
//   },[])

//   const orderdetails = useSelector((state) => state?.order?.orderdetail?.data);
  return (
    <div className="main_container sm:min-h-[500px]">
      <div className="flex items-center mt-[16px] sm:mt-[30px] mb-[16px]">
          <Link to="/myorder">
            <p onClick={() => dispatch(ResetMovie(actionTypes.RESET_ORDER_DETAIL))} className=" text-[#076aff] cursor-pointer text-[18px] font-medium font-poppins">
              My Order
            </p>
          </Link>
          <img src={arrow} alt="new" className="mx-3"></img>
          <p className=" text-[#161616] text-[18px] font-medium font-poppins">
            My Show
          </p>
        </div>
     

      {/*---------------------------------Desktop  orderdetail Table ------------------------------ */}
      <div className="grid grid-cols-12 ">
        <table className="w-full border border-[#DAE9FF] mb-[30px] hidden lg:inline-table col-span-12">
          <thead>
            <tr className="bg-[#DAE9FF]">
              <td className="py-[15px] pl-[30px]  text-[#161616] text-[18px] font-medium font-poppins">
                Movies
              </td>
              <td className="py-[15px] text-[#161616] text-[18px] font-medium font-poppins text-center">
               Purchase Show
              </td>
              <td className="py-[15px] text-[#161616] text-[18px] font-medium font-poppins text-center">
               Show Played
              </td>
              <td className="py-[15px] text-[#161616] text-[18px] font-medium font-poppins text-center">
               Show Pending
              </td>
            </tr>
          </thead>
          <tbody>
           
              <tr  className="border-b border-[#DAE9FF]">
                <td className=" pl-[30px] ">
                  <div className="flex items-center min-h-[100px]">
                   
                  <p className="text-[#47505F] ml-3 text-[16px] font-medium font-poppins">
                    Heart of Stone
                  </p>
                  </div>
                  
                </td>
                <td className="min-h-[120px]">
                  <p className="text-[#000] text-[16px] font-medium font-poppins text-center">
                    10
                  </p>
                </td>
                <td className="min-h-[120px]">
                  <p className="text-[#076aff] text-[16px] font-medium font-poppins text-center">
                    5
                  </p>
                </td>
                <td className="min-h-[120px]">
                  <p className="text-red-500 text-[16px] font-medium font-poppins text-center">
                    5
                  </p>
                </td>
              </tr>
          
          </tbody>
        </table>

        {/*---------------------------------End of orderdetail Table ------------------------------ */}

        {/*-------------------------------- mobile view for orderdetail------------------------------ */}

        <div className="col-span-12 md:col-span-6 lg:hidden">
          
            <div
              className=" bg-[#fff] rounded-[10px] boxshadow p-5 mb-5"
            >
              <div className=" flex items-start justify-between">
                <div className=" flex items-start">
                  <div className="ml-3">
                    <p className="text-[#000]  text-[16px]  font-poppins ">
                      Heart of Stone
                    </p>
                   
                    <div className=" my-[10px]">
                      <p className="text-[#000] text-[14px] sm:text-[16px]  font-poppins">
                      Purchase Show :
                        <span className="ml-2  text-[#47505F] text-[14px] sm:text-[16px] font-poppins">
                          10
                        </span>
                      </p>
                    </div>
                    <div className=" my-[10px]">
                      <p className="text-[#000] text-[14px] sm:text-[16px]  font-poppins">
                      Show Played :
                        <span className="ml-2  text-[#076aff] text-[14px] sm:text-[16px] font-poppins">
                          5
                        </span>
                      </p>
                    </div>
                    <div className=" my-[10px]">
                      <p className="text-[#000] text-[14px] sm:text-[16px]  font-poppins">
                      Show Pending :
                        <span className="ml-2  text-red-500 text-[14px] sm:text-[16px] font-poppins">
                          5
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
        </div>

        {/*--------------------------------End mobile view for orderdetail------------------------------ */}

      </div>
    </div>
  );
}

export default VleTransactionData;
