export const actionTypes = {
    MOVIE_DETAIL: "MOVIE_DETAIL",
    RESET_MOVIE_DETAIL: "RESET_MOVIE_DETAIL",
    USER_MOVIE_LIST: "USER_MOVIE_LIST",
    RESET_USER_MOVIE_LIST: "RESET_USER_MOVIE_LIST",
    PRE_LOADER_DETAIL : "PRE_LOADER_DETAIL"

  };
  
  const INITIAL_STATE = {
        moviedetail: [],
        usermovielist: [],
        Preloaderdetail: true,
  };

  const MovieDetailReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case actionTypes.PRE_LOADER_DETAIL:
        return {
          ...state,
          Preloaderdetail: action.Preloaderdetail,
};
      case actionTypes.MOVIE_DETAIL:
        return {
          ...state,
          moviedetail: action.moviedetail,
          Preloaderdetail: action.Preloaderdetail,
        };
      case actionTypes.RESET_MOVIE_DETAIL:
        return {
          ...state,
          moviedetail: action.INITIAL_STATE,

        };
      case actionTypes.USER_MOVIE_LIST:
        return {
          ...state,
          usermovielist: action.usermovielist,
        };
      case actionTypes.RESET_USER_MOVIE_LIST:
        return {
          ...state,
          usermovielist: action.INITIAL_STATE,
        };
      

  
      default:
        return state;
    }
  };
  
  export default MovieDetailReducer;