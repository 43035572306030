import React from "react";

function HomeVideo() {
  return (
    <div className="main_container ">
      <div className="mt-[20px] mb-[20px] sm:mb-[50px] flex mx-auto items-center justify-center">
        <div className="pb-[20px] sm:pb-[50px] bg-[#f3f8ff] px-[10px] sm:px-[50px] rounded-[20px]">
          <p className="video_heading font-poppins font-medium sm:pt-[48px] pt-[20px] pb-[20px] sm:pb-[30px] sm:text-[28px] text-center">
            Excellent Opportunity to Earn by opening Rural Cinema Hall
          </p>
          <iframe
            className=" w-full rounded-[10px] h-[270px] sm:h-[370px]"
            src="https://www.youtube.com/embed/gWWug-OAXaU"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default HomeVideo;
